import { useAppTheme } from '@workwhile/ui'
import { getColorFromRange, Scale } from '../../../utils/colorRangeByScale'

export const useFillRateRanges = () => {
  const { colors } = useAppTheme()

  const goodRange: Scale['ranges'][number] = {
    min: 90,
    max: Infinity,
    color: colors.successes[100],
  }

  const okRange: Scale['ranges'][number] = {
    min: 70,
    max: 89,
    color: colors.warnings[100],
  }

  const poorRange: Scale['ranges'][number] = {
    min: 0,
    max: 69,
    color: colors.errors[100],
  }

  const fillRateColorScale: Scale = {
    ranges: [poorRange, okRange, goodRange],
  }

  const getFillRateColor = (roundedFillRate: number) =>
    getColorFromRange(roundedFillRate, fillRateColorScale)

  return { goodRange, okRange, poorRange, getFillRateColor }
}
