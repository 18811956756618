import React, { useState } from 'react'
import { Box, Button, Card, Flex, Icon, Text } from '@workwhile/ui'
import { Check, ChevronDown, ChevronUp } from 'lucide-react'
import {
  dateCommaTimeFormat,
  getShiftTimezone,
} from 'components/utils/general_util'
import { Info } from 'lucide-react'
import { isShiftFull, isShiftInProgress } from 'components/utils/general_util'
import { useMutation } from '@tanstack/react-query'
import * as moment from 'moment-timezone'
import api from 'api'
import Loading from 'components/Shared/loading'
import RefreshButton from 'components/Shared/RefreshButton'
import { WorkShift } from 'api/typings'
import { useLegacyShiftDetails } from 'queries/shifts'

interface Props {
  shift: WorkShift
}

const Pay = ({ shift }: Props) => {
  const [showActions, setShowActions] = useState(false)

  /** User input */
  const [amount, setAmount] = useState('')
  const [chargeToCompany, setChargeToCompany] = useState(false)

  const pay = useLegacyShiftDetails(shift.id, 'pay')

  const submitBonus = useMutation({
    mutationFn: () =>
      api.post(`/admin/shift/bonus`, {
        shift_id: shift.id,
        charge_to_company: chargeToCompany,
        amount,
      }),
    onSuccess: () => {
      pay.refetch()
      alert(`$${amount} Bonus ADDED to SHIFT ${shift.id}`)
      setAmount('')
      setChargeToCompany(false)
    },
    onError: () => alert(`Failed to apply bonus to Shift ${shift.id}`),
  })

  const onSubmit = (event) => {
    event.preventDefault()

    if (Number(amount) <= 0) {
      alert('Amount must be greater than 0')
      return
    }

    const confirmation = window.confirm(
      `You are about to add a $${amount} Bonus to SHIFT ${shift.id}. Charge to company = ${chargeToCompany}. To execute this action, please press Ok.`
    )

    if (confirmation == true) {
      submitBonus.mutate()
    } else {
      alert('Action cancelled!')
    }
  }

  if (pay.isLoading) {
    return <Loading type="button" />
  }

  if (!pay.isFetching && !pay.data) {
    return <p>Could not find pay info for this shift.</p>
  }

  const timezone = getShiftTimezone(shift)
  const formattedTime = (time: string) => {
    return time ? moment.tz(time, timezone).format(dateCommaTimeFormat) : null
  }

  // Bonus information.
  const bonus = {
    employer: pay.data?.shiftBonuses
      ?.filter((bonus) => !!bonus.companyPaid)
      ?.at(-1),
    // Some bonuses are available to all workers and some are subjective.
    ww: pay.data?.shiftBonuses
      ?.filter(
        (bonus) =>
          !bonus.companyPaid && !bonus.isDynamicBonus && !bonus.isNewWorkerBonus
      )
      ?.at(-1),
    // Custom/subjective bonuses.
    special: pay.data?.shiftBonuses?.reduce((agg, current) => {
      if (current.isDynamicBonus || current.isNewWorkerBonus) {
        agg += `* Selective bonus: $${current.amount} (${current.isNewWorkerBonus ? 'new worker bonus' : 'dynamic bonus'}). Set at ${formattedTime(current.createdAt)}\n`
      }
      return agg
    }, ''),
  }

  const inProgress = isShiftInProgress(shift)
  const isFull = isShiftFull(shift)
  const isInvalid = !shift.id || amount === '' || inProgress

  return (
    <Box width="100%">
      <Flex justifyContent="space-between">
        {pay.data?.payRate && (
          <Text>{`$${pay.data?.payRate}/hr total: $${pay.data?.pay}.`}</Text>
        )}
        {pay.data?.payLumpSum && (
          <Text>{`$${pay.data?.payLumpSum} for the whole shift.`}</Text>
        )}
        {!inProgress && (
          <RefreshButton refreshFn={pay.refetch} loading={pay.isFetching} />
        )}
      </Flex>
      {!!pay.data?.shiftBonuses?.length && (
        <Box>
          <Text>{`$${Number(bonus.ww?.amount || 0) + Number(bonus.employer?.amount || 0)} bonus`}</Text>
          <Text ml="3">
            {`${!!bonus.special ? '*' : ''} $${bonus.employer?.amount || 0} company ${bonus.employer?.createdAt ? `(${formattedTime(bonus.employer.createdAt)})` : ''}`}
          </Text>
          <Text ml="3" style={{ position: 'relative' }}>
            <Text as="span" ml="-10px" style={{ position: 'absolute' }}>
              +
            </Text>
            <Text>{`$${bonus.ww?.amount || 0} WW ${bonus.ww?.createdAt ? `(${formattedTime(bonus.ww?.createdAt)})` : ''}`}</Text>
          </Text>
          {bonus.special && (
            <Text mt="2" ml="2">
              {bonus.special}
            </Text>
          )}
        </Box>
      )}

      {!inProgress && (
        <Button
          my="2"
          variant="secondary"
          kind="small"
          onClick={() => setShowActions(!showActions)}
          type="button"
          block
          disabled={isFull || inProgress}
        >
          <>
            {isFull ? (
              <>
                <Icon color="primary" icon={Check} />
                Shift is full
              </>
            ) : (
              <>
                {showActions ? 'Hide' : 'Show'} actions
                <Icon
                  icon={showActions ? ChevronUp : ChevronDown}
                  color="primary"
                />
              </>
            )}
          </>
        </Button>
      )}

      {showActions && (
        <Card>
          <Flex justifyContent="space-between" alignItems="center">
            <Text as="strong">Add Bonus</Text>
            <Button
              width="fit-content"
              variant="text"
              kind="small"
              onClick={() =>
                window.alert(`If you add another bonus, the bonus offered will be updated and a
          notification will go out. E.g. Lets say you added 10 dollar bonus at
          4PM and 2 workers accept shift, they get a 10 dollar bonus each. Now
          you up it to 20 dollars at 6PM, anyone who accepts shift from 6pm
          onwards will get 20.`)
              }
            >
              <Info />
            </Button>
          </Flex>

          <Flex as="form" flexDirection="column" onSubmit={onSubmit}>
            <Flex as="label" flexDirection="column">
              <Text>Amount</Text>
              <input
                disabled={inProgress}
                name="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                type="number"
                placeholder="Amount"
                min={0}
              />
            </Flex>

            <Flex as="label" alignItems="center" py="2">
              <input
                id="chargeToCompany"
                style={{ height: '20px', width: '20px' }}
                type="checkbox"
                checked={chargeToCompany}
                onChange={() => setChargeToCompany(!chargeToCompany)}
              />
              <Text ml="2" as="label" htmlFor="chargeToCompany">
                Charge to company
              </Text>
            </Flex>

            <Button
              kind="small"
              block
              loading={submitBonus.isPending}
              disabled={isInvalid || submitBonus.isPending}
              type="submit"
            >
              Add bonus
            </Button>

            {submitBonus.isError && (
              <Text>Error: {submitBonus.error.message}</Text>
            )}
          </Flex>
        </Card>
      )}
    </Box>
  )
}

export default Pay
