import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { getShifts, GetShiftsOptions } from 'api/shifts'
import { WorkShift } from 'api/typings'

const QUERIES = {
  slim: `{
    id,
    market,
    startsAt, cancelledAt,
    workersNeeded, numCushionWorkersNeeded, numWorkersScheduled, numWorkersPending,
    assignment { name, id }
    location { name, id, address { city, state, street, zip, lat, long, timezone } },
    company { name, id }
    position {
      id, name,
      needsW2, needsCar, needsLicense, needsFullTime, isDrivingPosition,
      mustHaveRequirements { id, name }
    },
    listings {
      id,
      tier, tierTransitions,
      freeze
    }
  }`,

  verbose: `{
    id, 
    isTryout,
    startsAt, endsAt, cancelledAt, lunchLength, 
    pay, payRate, payLumpSum,
    workersNeeded, numCushionWorkersNeeded, numWorkersScheduled, numWorkersFilled,
    numWorkersStarted, numWorkersFinished(excludeEmployerCancelled:true), numWorkersRejected, numWorkersRemoved,
    shiftBonuses { amount, createdAt, companyPaid, isDynamicBonus, isNewWorkerBonus },
    rosters {
      id, isSpecificWorkerRequest, name,
      workers {
          id, name, profilePicUrl
      }
    },
    rostersHoldExpiresAt,
    listings {id, tier, tierTransitions, numWaitlist },
    company { name, id },
    position {
      name, id,
      requirements2 { id, name, credential }
    },
    location { name, id, address { city, state, street, zip, lat, long, timezone } },
    market,
    work (status:"all") {
        id, status, confirmedAt,
        worker {id, name}
    },
    supervisor, supervisorPhone, supervisors { id, name, phoneNumber, userId, status },
    leadShifts {
      id,
      startsAt,
      endsAt,
      shiftBonuses { amount },
      position { name, id},
      location { name, id, address { city, state, street, zip, lat, long, timezone }}
      locationless
      shiftType, supervisor, supervisorPhone, supervisors { id, name, phoneNumber, userId, status }, workersNeeded, 
      chargeLumpSum, chargeRate, payRate, payLumpSum, lunchLength
      work {
          id, status, confirmedAt
          startedAt, completedAt
          pendingAdminReview
          status
          trips { status, shareUrl, eta, etaSeconds, locationStatus, serviceDeviceId }
          worker { id, name, phoneNumber, profilePicUrl }
      }
    }
  }`,
}

/**
 * Get a list of shifts that match the given filters
 * @example useShifts({ grouping: 'upcoming', unfilled: true }, `{ id }`, { retry: true })
 */
export function useShifts(
  /** GraphQL query */
  queryKey: keyof typeof QUERIES,
  /** Pass through to API */
  filters: GetShiftsOptions,
  /** useQuery options */
  options?: Pick<
    UseQueryOptions,
    'retry' | 'initialData' | 'enabled' | 'placeholderData'
  >
): UseQueryResult<WorkShift[]> {
  return useQuery({
    queryKey: ['shifts', queryKey, filters],
    queryFn: async () => getShifts(filters, QUERIES[queryKey]),
    retry: false,
    placeholderData: keepPreviousData,
    ...options,
  })
}
