import React from 'react'
import { useParams } from 'react-router-dom'
import { ShiftDetailMain } from './ShiftDetailMain'
import { ShiftDetailProvider } from './context/ShiftDetailProvider'
import { ShiftDetailWorkflowsProvider } from './context/ShiftDetailWorkflowsProvider'
import { AssignShiftFlow } from './workflows/AssignShiftFlow/AssignShiftFlow'
import { NuqsAdapter } from 'nuqs/adapters/react'

export const ShiftDetail = () => {
  const { shiftId } = useParams()
  return (
    <NuqsAdapter>
      <ShiftDetailProvider shiftId={Number(shiftId)}>
        <ShiftDetailWorkflowsProvider>
          <ShiftDetailMain />
          <AssignShiftFlow />
        </ShiftDetailWorkflowsProvider>
      </ShiftDetailProvider>
    </NuqsAdapter>
  )
}
