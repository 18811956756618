import { CommandSchema } from 'api/commands'
import { CommandValues } from '../types'
import { replaceAliases } from './aliased_terms'
import { normalizeWordSeparators } from './normalizeWordSeparators'

/** Show all commands by default.
 * If there is a search term or a page type,
 * only show the command if the search term OR page type
 * is found anywhere in the searchable copy */
export function shouldShowCommand(
  command: CommandSchema,
  values: CommandValues
) {
  const searchableParts = [
    command.name,
    command.app_group,
    command.help,
    ...command.args.flatMap((arg) => [arg.name]),
  ].map(normalizeWordSeparators)

  const match = (term: string) => {
    if (!term) return false
    const normalizedTerm = normalizeWordSeparators(term)
    const aliasedTerm = replaceAliases(normalizedTerm)
    return searchableParts.some((part) => part.includes(normalizedTerm) || part.includes(aliasedTerm))
  }

  return (
    (!values.search || match(values.search))
  )
}
