// @ts-nocheck
import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import graphql from '../../graphql'
import LazyLoadingMsg from '../Shared/LazyLoadingMsg'
import QuestDefinitionsFilterBar from '../Shared/QuestDefinitionsFilterBar'
import QuestDefinitionDetail from './QuestDefinitionDetail'
import { filtersAreEmpty } from '../utils/util'
import api from '../../api'
import { Heading, Modal } from '@workwhile/ui'

const QuestDefinitions = () => {
  const [loading, setLoading] = useState(false)
  const [quest_definitions, setQuestDefinitions] = useState([])
  const [filters, setFilters] = useState({})
  const [filteredQuestDefinitions, setFilteredQuestDefinitions] = useState([])
  const [showEditNewModal, setShowEditNewModal] = useState(false)
  const [currentRow, setCurrentRow] = useState([])

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [companyId, setCompanyId] = useState(0)
  const [marketId, setMarketId] = useState(0)
  const [shiftsToComplete, setShiftsToComplete] = useState(0)
  const [daysToComplete, setDaysToComplete] = useState(0)
  const [bonus, setBonus] = useState(0)
  const [bonusChargeToCompany, setBonusChargeToCompany] = useState(false)
  const [prescreened, setPrescreened] = useState(false)
  const [needsCar, setNeedsCar] = useState(false)
  const [needsLicense, setNeedsLicense] = useState(false)
  const [needsFullTime, setNeedsFullTime] = useState(false)
  const [experienceRequirement, setExperienceRequirement] = useState('none')
  const [status, setStatus] = useState('')
  const [questType, setQuestType] = useState('none')
  const [logoUrl, setLogoUrl] = useState('')
  const [title, setTitle] = useState('')
  const [cta, setCta] = useState('')

  const fetchQuestDefinitions = () => {
    setLoading(true)
    setQuestDefinitions([])
    graphql(questDefinitionsQuery())
      .then((response) => {
        if (response) {
          setQuestDefinitions(response.data.data.admin.questDefinitions)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchQuestDefinitions()
  }, [])

  useEffect(() => {
    setFilteredQuestDefinitions(
      filterQuestDefinitions(quest_definitions, filters)
    )
  }, [quest_definitions])

  const handleFilterChange = (selectedOption, actionMeta) => {
    const newFilters = filters
    newFilters[actionMeta.name] = selectedOption
    setFilters(newFilters)
    setFilteredQuestDefinitions(
      filterQuestDefinitions(quest_definitions, newFilters)
    )
  }

  const newQuestDefinitionModal = () => {
    setCurrentRow([])
    setShowEditNewModal(true)
  }

  const editQuestDefinitionModal = (row) => {
    // clean the data for posting back to the server and editing
    setName(row['name'])
    setDescription(row['description'])
    setCompanyId(row['company'] ? row['company']['id'] : null)
    setMarketId(row['market'] ? row['market']['id'] : null)
    setShiftsToComplete(row['shiftsToComplete'])
    setDaysToComplete(row['daysToComplete'])
    setBonus(row['bonus'])
    setBonusChargeToCompany(row['bonusChargeToCompany'] || false)
    setPrescreened(row['prescreened'] || false)
    setNeedsCar(row['needsCar'] || false)
    setNeedsLicense(row['needsLicense'] || false)
    setNeedsFullTime(row['needsFullTime'] || false)
    setExperienceRequirement(row['experienceRequirement'] || 'none')
    setStatus(row['status'])
    setQuestType(row['questType'])
    setLogoUrl(row['logoUrl'])
    setTitle(row['title'])
    setCta(row['cta'])

    setCurrentRow(row)
    setShowEditNewModal(true)
  }

  const columns = getQuestDefinitionColumns(
    fetchQuestDefinitions,
    editQuestDefinitionModal
  )

  const rowStyle = (row, _) => {
    return row.status === 'inactive'
      ? { fontSize: 10, backgroundColor: '#f08080' }
      : { fontSize: 10 }
  }

  const constructPayload = () => {
    var obj = {
      name: name,
      description: description,
      company_id: companyId,
      market_id: marketId,
      shifts_to_complete: shiftsToComplete,
      days_to_complete: daysToComplete,
      bonus: bonus,
      bonus_charge_to_company: bonusChargeToCompany,
      prescreened: prescreened,
      needs_car: needsCar,
      needs_license: needsLicense,
      needs_full_time: needsFullTime,
      experience_requirement: experienceRequirement,
      status: status,
      quest_type: questType,
      logo_url: logoUrl,
      title: title,
      cta: cta,
    }

    // remove nulls
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName]
      }
    }

    return obj
  }

  const sendNewQuestDefinition = (row) => {
    return api.post(`/admin/quest`, constructPayload())
  }

  const sendEditQuestDefinition = (row, id) => {
    return api.put(`/admin/quest/${id}`, constructPayload())
  }

  // when someone saves an edit or ads a new row
  const onSubmit = (event) => {
    event.preventDefault()
    if (currentRow['id']) {
      sendEditQuestDefinition(currentRow, currentRow['id']).then(() => {
        setShowEditNewModal(false)
        setCurrentRow([])
        fetchQuestDefinitions()
      })
    } else {
      sendNewQuestDefinition(currentRow).then(() => {
        setShowEditNewModal(false)
        setCurrentRow([])
        fetchQuestDefinitions()
      })
    }
  }

  return (
    <>
      <div className="users-table mt-5">
        <LazyLoadingMsg in={loading}>
          We're loading your latest information...
        </LazyLoadingMsg>
        <div>
          <Heading
            level={3}
            style={{ display: 'inline', padding: '0 10px 0 0' }}
          >
            Quest Definitions
          </Heading>
          <button
            style={{ position: 'relative', top: '-7px' }}
            onClick={() => newQuestDefinitionModal()}
          >
            New
          </button>
        </div>
        <QuestDefinitionsFilterBar
          showBorderTop
          showBorderBottom
          showCompanyFilter
          showMarketFilter
          showMarketIdFilter
          quest_definitions={quest_definitions}
          handleFilterChange={handleFilterChange}
        />
        <BootstrapTable
          striped
          hover
          keyField="id"
          data={filteredQuestDefinitions}
          columns={columns}
          rowStyle={rowStyle}
        />
      </div>
      <Modal
        open={showEditNewModal}
        onClose={() => setShowEditNewModal(false)}
        title={
          currentRow['id'] ? 'Edit Quest Definition' : 'New Quest Definition'
        }
      >
        <div style={{ padding: '5px' }}>
          <label>Name</label>&nbsp;
          <input
            className="input"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Name"
          />
          <br />
          <label>Description</label>&nbsp;
          <input
            className="input"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            placeholder="Description"
          />
          <br />
          <label>Company Id</label>&nbsp;
          <input
            className="input"
            name="companyId"
            value={companyId}
            onChange={(e) => setCompanyId(parseInt(e.target.value))}
            type="number"
            placeholder="Company Id"
          />
          <br />
          <label>Market Id</label>&nbsp;
          <input
            className="input"
            name="marketId"
            value={marketId}
            onChange={(e) => setMarketId(parseInt(e.target.value))}
            type="number"
            placeholder="Market Id"
          />
          <br />
          <label>Status</label>&nbsp;
          <input
            className="input"
            name="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            type="text"
            placeholder="active or inactive"
          />
          <br />
          <label>Bonus</label>&nbsp;
          <input
            className="input"
            name="bonus"
            value={bonus}
            onChange={(e) => setBonus(parseFloat(e.target.value))}
            type="number"
            placeholder="bonus"
          />
          <br />
          <label>Bonus Charge To Company</label>&nbsp;
          <input
            className="input"
            name="bonusChargeToCompany"
            checked={bonusChargeToCompany}
            onChange={(e) => setBonusChargeToCompany(e.target.checked)}
            type="checkbox"
          />
          <br />
          <label>Shifts to Complete</label>&nbsp;
          <input
            className="input"
            name="shiftsToComplete"
            value={shiftsToComplete}
            onChange={(e) => setShiftsToComplete(parseInt(e.target.value))}
            type="number"
          />
          <br />
          <label>Days to Complete</label>&nbsp;
          <input
            className="input"
            name="daysToComplete"
            value={daysToComplete}
            onChange={(e) => setDaysToComplete(parseInt(e.target.value))}
            type="number"
          />
          <br />
          <label>Needs Car</label>&nbsp;
          <input
            className="input"
            name="needsCar"
            checked={needsCar}
            onChange={(e) => setNeedsCar(e.target.checked)}
            type="checkbox"
          />
          <br />
          <label>Needs License</label>&nbsp;
          <input
            className="input"
            name="needsLicense"
            checked={needsLicense}
            onChange={(e) => setNeedsLicense(e.target.checked)}
            type="checkbox"
          />
          <br />
          <label>Needs Full Time</label>&nbsp;
          <input
            className="input"
            name="needsFullTime"
            checked={needsFullTime}
            onChange={(e) => setNeedsFullTime(e.target.checked)}
            type="checkbox"
          />
          <br />
          <label>Prescreened</label>&nbsp;
          <input
            className="input"
            name="prescreened"
            checked={prescreened}
            onChange={(e) => setPrescreened(e.target.checked)}
            type="checkbox"
          />
          <br />
          <label>Experience Requirement</label>&nbsp;
          <input
            className="input"
            name="experience_requirement"
            value={experienceRequirement}
            onChange={(e) => setExperienceRequirement(e.target.value)}
            type="text"
            placeholder="none if none"
          />
          <br />
          <label>Quest Type</label>&nbsp;
          <input
            className="input"
            name="quest_type"
            value={questType}
            onChange={(e) => setQuestType(e.target.value)}
            type="text"
            placeholder="none if none"
          />
          <br />
          <label>Logo Url (if no company_id)</label>&nbsp;
          <input
            className="input"
            name="logo_url"
            value={logoUrl}
            onChange={(e) => setLogoUrl(e.target.value)}
            type="text"
            placeholder=""
          />
          <br />
          <label>Title (for Special Quest only)</label>&nbsp;
          <input
            className="input"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            placeholder=""
          />
          <br />
          <label>cta (i.e. Call To Action - for Special Quest only)</label>
          &nbsp;
          <input
            className="input"
            name="cta"
            value={cta}
            onChange={(e) => setCta(e.target.value)}
            type="text"
            placeholder=""
          />
          <br />
          <button type="submit" className="ml-2" onClick={onSubmit}>
            Save
          </button>
        </div>
      </Modal>
    </>
  )
}

export default QuestDefinitions

export const questDefinitionsQuery = () => {
  return `
        admin { 
            questDefinitions {
                id, name, description
                company {name, id},
                market {name, id},
                status,
                bonus, bonusChargeToCompany,
                daysToComplete, shiftsToComplete,
                needsCar, needsLicense, needsFullTime, 
                prescreened, experienceRequirement,
                questType, logoUrl, title, cta
            }
        }
    `
}

// Take in quest_definitions and a map of filters, return quest_definitions which meet those filters.
const filterQuestDefinitions = (quest_definitions, filters) => {
  if (filtersAreEmpty(filters)) return quest_definitions
  let filteredQuestDefinitions = []
  for (const quest_definition of quest_definitions) {
    if (matchesQuestDefinitionFilters(filters, quest_definition))
      filteredQuestDefinitions.push(quest_definition)
  }
  return filteredQuestDefinitions
}

const getQuestDefinitionColumns = (
  fetchQuestDefinitions,
  editQuestDefinitionModal
) => {
  return [
    {
      dataField: 'id',
      text: 'Id',
      headerStyle: { width: '1em', fontSize: 10 },
      formatter: (_, row) => {
        return (
          <>
            <p>
              {row['id']}
              <br />
              <b>status</b> {row['status']}
            </p>
            <button onClick={() => editQuestDefinitionModal(row)}>Edit</button>
          </>
        )
      },
    },
    {
      dataField: 'name',
      text: 'Name',
      headerStyle: { width: '1em', fontSize: 10 },
      formatter: (_, row) => {
        return (
          <>
            <p>{row['name']}</p>
            <QuestDefinitionDetail
              description={row['description']}
              bonus_charge_to_company={row['bonus_charge_to_company']}
            />
          </>
        )
      },
    },
    {
      dataField: 'company',
      text: 'Company',
      headerStyle: { width: '2em', fontSize: 10 },
      formatter: (cell, row) => {
        return (
          <>
            <p>
              {row['company']
                ? `${row['company']['name']} (${row['company']['id']})`
                : 'No Associated Company'}
            </p>
          </>
        )
      },
    },
    {
      dataField: 'market',
      text: 'Market',
      headerStyle: { width: '2em', fontSize: 10 },
      formatter: (cell, row) => {
        return (
          <>
            <p>
              {row['market']
                ? `${row['market']['name']} (${row['market']['id']})`
                : 'No Associated Market'}
            </p>
          </>
        )
      },
    },
    {
      dataField: 'bonus',
      text: 'bonus',
      headerStyle: { width: '1em', fontSize: 10 },
    },
    {
      dataField: 'daysToComplete',
      text: 'daysToComplete',
      headerStyle: { width: '1em', fontSize: 10 },
    },
    {
      dataField: 'shiftsToComplete',
      text: 'shiftsToComplete',
      headerStyle: { width: '1em', fontSize: 10 },
    },
    {
      dataField: 'needsCar',
      text: 'needsCar',
      headerStyle: { width: '1em', fontSize: 10 },
    },
    {
      dataField: 'needsLicense',
      text: 'needsLicense',
      headerStyle: { width: '1em', fontSize: 10 },
    },
    {
      dataField: 'needsFullTime',
      text: 'needsFullTime',
      headerStyle: { width: '1em', fontSize: 10 },
    },
    {
      dataField: 'prescreened',
      text: 'prescreened',
      headerStyle: { width: '1em', fontSize: 10 },
    },
    {
      dataField: 'experienceRequirement',
      text: 'experienceRequirement',
      headerStyle: { width: '1em', fontSize: 10 },
    },
    {
      dataField: 'questType',
      text: 'questType',
      headerStyle: { width: '1em', fontSize: 10 },
    },
  ]
}

export const matchesQuestDefinitionFilters = (filters, quest_definition) => {
  let [matchCompany, matchMarket] = [false, false]
  const { company, market } = quest_definition

  const companyFilter = filters.company
  if (companyFilter && companyFilter.length > 0 && company) {
    const companyForComparison = {
      value: parseInt(company.id),
      label: company.name,
    }
    for (let i = 0; i < companyFilter.length; i += 1) {
      if (companyFilter[i].value === companyForComparison.value)
        matchCompany = true
    }
  } else {
    matchCompany = true
  }

  const marketFilter = filters.market
  if (marketFilter && marketFilter.length > 0 && market) {
    const marketForComparison = {
      value: parseInt(market.id),
      label: market.name,
    }
    for (let i = 0; i < marketFilter.length; i += 1) {
      if (marketFilter[i].value === marketForComparison.value)
        matchMarket = true
    }
  } else {
    matchMarket = true
  }

  if (matchCompany && matchMarket) return true

  return false
}
