import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addCompaniesToGroup } from 'api/companyGroup/addCompaniesToGroup'
import { companyGroupDetailKey } from './keys'

export const useAddCompaniesToGroupMutation = (companyGroupId: number) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (companyIds: number[]) =>
      addCompaniesToGroup(companyGroupId, companyIds),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: companyGroupDetailKey(companyGroupId),
      }),
  })
}
