type FormatNumberOptions = Intl.NumberFormatOptions

const currencyPreset: FormatNumberOptions = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const formatCurrency = (
  value: number,
  options?: FormatNumberOptions
) => {
  return new Intl.NumberFormat('en-US', {
    ...currencyPreset,
    ...options,
  }).format(value)
}
