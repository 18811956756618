import { useQuery } from '@tanstack/react-query'
import { getShiftById } from 'api/shifts/getShiftById'
import { shiftDetailKey } from './keys'

export const useShiftByIdQuery = (shiftId: number) => {
  return useQuery({
    queryKey: shiftDetailKey(shiftId),
    queryFn: () => getShiftById(shiftId),
  })
}
