import React from 'react'
import { AccordionCard } from 'components/AccordionCard'
import { DonutProgressChart, Text } from '@workwhile/ui'
import { useShiftDetailContextValue } from '../../context/ShiftDetailProvider'
import { isAfter } from 'date-fns'
import { formatPercentage } from 'lib/percentage'

export const ScheduleRate = () => {
  const {
    state: { workersNeeded, numScheduleRateWorkers, scheduleRate, endsAt },
  } = useShiftDetailContextValue()

  const hasShiftEnded = isAfter(new Date(), endsAt)

  if (hasShiftEnded) {
    return null
  }

  return (
    <AccordionCard
      title="Schedule Rate"
      size="fit"
      contentWrapperProps={{
        marginTop: 2,
      }}
    >
      <Text mb={3} fontSize={2}>
        Accepted {numScheduleRateWorkers} / {workersNeeded}
      </Text>
      <DonutProgressChart
        progress={scheduleRate}
        valueFormatter={() => formatPercentage(scheduleRate)}
        size="md"
      />
    </AccordionCard>
  )
}
