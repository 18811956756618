import React from 'react'
import { createContext } from 'lib/createContext'
import { ShiftDetail } from '../types'
import { useShiftByIdQuery } from 'queries/shifts/useShiftByIdQuery'
import { Loading, Message } from '@workwhile/ui'
import { parseErrorMessage } from 'api'

type ShiftDetailContextState = {
  state: ShiftDetail & {
    dataUpdatedAt: number
    isFetching: boolean
    isError: boolean
    error: string
  }
  actions: {
    refetch: () => void
  }
}

const [Context, useShiftDetailContextValue] =
  createContext<ShiftDetailContextState>({
    name: 'ShiftDetailContext',
  })

export const ShiftDetailProvider = ({
  children,
  shiftId,
}: {
  children: React.ReactNode
  shiftId: number
}) => {
  const {
    data,
    isLoading,
    isError,
    error,
    dataUpdatedAt,
    refetch,
    isFetching,
  } = useShiftByIdQuery(shiftId)

  if (isLoading) {
    return <Loading />
  }

  if (isError) {
    // eslint-disable-next-line no-console
    console.error(error)
    return (
      <Message variant="error" m={4} borderRadius="small">
        Sorry, something went wrong. Please reach out to eng support if this
        persists.
      </Message>
    )
  }

  const value = {
    state: {
      ...data,
      dataUpdatedAt,
      isFetching,
      isError,
      error: parseErrorMessage(error),
    },
    actions: { refetch },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useShiftDetailContextValue }
