import { Box, BoxProps, Text } from '@workwhile/ui'
import React from 'react'
import styled from 'styled-components'

type InfoTableRowProps = BoxProps & {
  icon?: React.ReactNode
  label: string | React.ReactNode
  value?: string | number | boolean
  leftTdProps?: BoxProps
  rightTdProps?: BoxProps
}

const InfoTableRow = ({
  icon,
  label,
  value,
  leftTdProps = {
    textAlign: 'left',
  },
  rightTdProps = {
    textAlign: 'right',
  },
  ...props
}: InfoTableRowProps) => {
  return (
    <Box as="tr" {...props}>
      <Box as="td" gap={1} {...leftTdProps}>
        {icon}
        {typeof label === 'string' ? (
          <Text fontSize={2} as="span" ml={icon ? 1 : 0}>
            {label}
          </Text>
        ) : (
          label
        )}
      </Box>
      {typeof value !== 'undefined' ? (
        <Box as="td" {...rightTdProps}>
          <Text as="span" fontSize={2}>
            {value}
          </Text>
        </Box>
      ) : null}
    </Box>
  )
}

// Use this when InfoTable isn't working for your use case
export const StyledTable = styled.table`
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 0.5rem;
  table-layout: fixed;
  width: 100%;

  td {
    vertical-align: middle;
  }
`

type InfoTableProps = Parameters<typeof StyledTable>[0] & {
  children: React.ReactNode
}

export const InfoTable = ({ children, ...props }: InfoTableProps) => {
  return (
    <StyledTable {...props}>
      <Box as="tbody">{children}</Box>
    </StyledTable>
  )
}

InfoTable.Row = InfoTableRow
