import api from 'api'
import { Shifts } from 'pages/Shifts/Shifts/types'

type GetAllShiftsRequest = {
  page: number
  pageSize: number
  rangeType?: 'past' | 'ongoing' | 'upcoming'
  startDate?: string
  endDate?: string
  shiftIds?: number[]
  marketCodes?: string[]
  companyIds?: number[]
  companyGroupIds?: number[]
  scheduleRateMin?: number
  scheduleRateMax?: number
  fillRateMin?: number
  fillRateMax?: number
}

type GetAllShiftsResponse = {
  items: Shifts
  totalRows: number
}

export const getAllShifts = async (
  request: GetAllShiftsRequest
): Promise<GetAllShiftsResponse> => {
  const response = await api.get<GetAllShiftsResponse>('/admin/shifts', {
    params: {
      range_type: request.rangeType,
      start_date: request.startDate,
      end_date: request.endDate,
      page: request.page,
      page_size: request.pageSize,
      shift_ids: request.shiftIds,
      company_ids: request.companyIds,
      company_group_ids: request.companyGroupIds,
      market_codes: request.marketCodes,
      schedule_rate_min: request.scheduleRateMin,
      schedule_rate_max: request.scheduleRateMax,
      fill_rate_min: request.fillRateMin,
      fill_rate_max: request.fillRateMax,
    },
  })
  return response.data
}
