import React from 'react'
import { User } from '../Shared/common_defs'
import CollapsibleSection from '../Shared/CollapsibleSection'
import { Link, Table } from '@workwhile/ui'

interface Props {
  user: User
}

const UserCompanyWorkerProfile = ({ user }: Props) => {
  const data = React.useMemo(() => {
    return user.companyWorkerProfiles.map((companyWorkerProfile) => {
      return {
        companyId: companyWorkerProfile.companyId,
        workerId: companyWorkerProfile.workerId,
        favorite: companyWorkerProfile.favorite.toString(),
        hired: companyWorkerProfile.hired.toString(),
        blocked: companyWorkerProfile.blocked.toString(),
        blockedReason: companyWorkerProfile.blockedReason?.toString(),
        paidShiftCount: companyWorkerProfile.paidShiftCount?.toString(),
      }
    })
  }, [])

  const columns = React.useMemo(
    () => [
      {
        header: 'Company ID',
        accessorKey: 'companyId',
        cell: ({ row }) => {
          return (
            <Link to={`/companies/${row.original.companyId}`} target="_blank">
              {row.original.companyId}
            </Link>
          )
        },
      },
      {
        header: 'Worker ID',
        accessorKey: 'workerId',
      },
      {
        header: 'Favorite',
        accessorKey: 'favorite',
      },
      {
        header: 'Hired',
        accessorKey: 'hired',
      },
      {
        header: 'Blocked',
        accessorKey: 'blocked',
      },
      {
        header: 'Blocked Reason',
        accessorKey: 'blockedReason',
      },
      {
        header: 'Paid Shift Count',
        accessorKey: 'paidShiftCount',
      },
    ],
    []
  )

  return (
    <CollapsibleSection title="Company Worker Profile">
      {user.companyWorkerProfiles && user.companyWorkerProfiles.length > 0 ? (
        <Table data={data} columns={columns} />
      ) : (
        <p>User has no company worker profiles</p>
      )}
    </CollapsibleSection>
  )
}

export default UserCompanyWorkerProfile
