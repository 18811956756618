import React from 'react'
import { useParams } from 'react-router-dom'
import { CompanyDetailProvider } from './context/CompanyDetailProvider'
import { CompanyDetailMain } from './CompanyDetailMain'
import { CompanyDetailWorkflowsProvider } from './context/CompanyDetailWorkflowProvider'
import { EditCompanyNameFlow } from './workflows/EditCompanyNameFlow'
import { AddCancellationPolicyFlow } from './workflows/AddCancellationPolicyFlow'
import { AddMinimumPayPolicyFlow } from './workflows/AddMinimumPayPolicyFlow/AddMinimumPayPolicyFlow'
import { EditMoneyFlow } from './workflows/EditMoneyFlow/EditMoneyFlow'
import { InviteUserToCompanyFlow } from './workflows/InviteUserToCompanyFlow'
import { ManageLogoFlow } from './workflows/ManageLogoFlow'
import { EditGeneralDetailsFlow } from './workflows/EditGeneralDetailsFlow'
import { NuqsAdapter } from 'nuqs/adapters/react'

export const CompanyDetail = () => {
  const { companyId } = useParams()
  return (
    <NuqsAdapter>
      <CompanyDetailProvider companyId={Number(companyId)}>
        <CompanyDetailWorkflowsProvider>
          <CompanyDetailMain />
          <EditCompanyNameFlow />
          <AddCancellationPolicyFlow />
          <AddMinimumPayPolicyFlow />
          <EditMoneyFlow />
          <InviteUserToCompanyFlow />
          <ManageLogoFlow />
          <EditGeneralDetailsFlow />
        </CompanyDetailWorkflowsProvider>
      </CompanyDetailProvider>
    </NuqsAdapter>
  )
}
