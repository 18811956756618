import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { createContext } from 'lib/createContext'
import { Loading, Message } from '@workwhile/ui'
import { parseErrorMessage } from 'api'
import { useCompanyGroupsQuery } from 'queries/companyGroups/useCompanyGroupsQuery'
import { CompanyGroups } from '../types'
import { FullOptions, MatchData, Searcher } from 'fast-fuzzy'
import {
  parseAsString,
  useQueryState,
  parseAsInteger,
  useQueryStates,
} from 'nuqs'

type CompanyGroupsContextState = {
  state: {
    searchInput: string
    foundClosestAutocompleteMatch: string
    filteredData: CompanyGroups
    data: CompanyGroups
    dataUpdatedAt: number
    isFetching: boolean
    isError: boolean
    error: string
    pagination: {
      pageIndex: number
      pageSize: number
    }
  }
  actions: {
    refetch: () => void
    setSearchInput: (searchInput: string) => void
    setPagination: Dispatch<
      SetStateAction<CompanyGroupsContextState['state']['pagination']>
    >
  }
}

const fuzzyOptions: FullOptions<CompanyGroups[number]> = {
  threshold: 0.6,
  ignoreCase: true,
  ignoreSymbols: true,
  normalizeWhitespace: true,
  returnMatchData: true,
  keySelector: (obj) => [obj.name, obj.id.toString()],
}

const useFuzzySearch = (data: CompanyGroups = []) => {
  const searcher = useMemo(() => {
    return new Searcher<
      CompanyGroups[number],
      FullOptions<CompanyGroups[number]>
    >(data, fuzzyOptions)
  }, [data])

  const fuzzySearch = useCallback(
    (
      query: string
    ): { foundClosestAutocompleteMatch: string; results: CompanyGroups } => {
      if (!query) {
        return {
          foundClosestAutocompleteMatch: '',
          results: data,
        }
      }
      // We add explict type assertion here because `searcher.search` does not seem to pick up the type from the `fuzzyOptions` when passed in as a variable
      const results = searcher.search(
        query,
        fuzzyOptions
      ) as unknown as MatchData<CompanyGroups[number]>[]

      if (results.length > 0) {
        const bestMatch = results[0]

        if (bestMatch.item.name.toLowerCase() !== query.toLowerCase()) {
          return {
            foundClosestAutocompleteMatch: bestMatch.item.name,
            results: results.map((i) => i.item),
          }
        }
      }

      return {
        foundClosestAutocompleteMatch: '',
        results: results.map((i) => i.item),
      }
    },
    [searcher, data]
  )

  return { fuzzySearch }
}

const [Context, useCompanyGroupsContextValue] =
  createContext<CompanyGroupsContextState>({
    name: 'CompanyGroupsContext',
  })

export const CompanyGroupsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [searchInput, setSearchInput] = useQueryState(
    'search',
    parseAsString.withDefault('')
  )
  const [pagination, setPagination] = useQueryStates(
    {
      pageIndex: parseAsInteger.withDefault(0),
      pageSize: parseAsInteger.withDefault(10),
    },
    {
      urlKeys: {
        pageIndex: 'page',
        pageSize: 'page-size',
      },
    }
  )
  const {
    data,
    isLoading,
    isError,
    error,
    dataUpdatedAt,
    refetch,
    isFetching,
  } = useCompanyGroupsQuery()

  const { fuzzySearch } = useFuzzySearch(data)

  const trimmedSearchInput = searchInput.trim()

  const { foundClosestAutocompleteMatch, results: filteredData } =
    useMemo(() => {
      return fuzzySearch(trimmedSearchInput)
    }, [trimmedSearchInput, fuzzySearch, data])

  if (isLoading) {
    return <Loading />
  }

  if (isError) {
    // eslint-disable-next-line no-console
    console.error(error)
    return (
      <Message variant="error">
        Sorry, something went wrong. Please reach out to eng support if this
        persist
      </Message>
    )
  }

  const value = {
    state: {
      searchInput,
      foundClosestAutocompleteMatch,
      filteredData,
      data,
      dataUpdatedAt,
      isFetching,
      isError,
      error: parseErrorMessage(error),
      pagination,
    },
    actions: {
      refetch,
      setSearchInput,
      setPagination,
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useCompanyGroupsContextValue }
