import React from 'react'
import api from '../../../api'
import Loading from '../../Shared/loading'
import BootstrapTable from 'react-bootstrap-table-next'
import {
  Box,
  Button,
  Card,
  Checkbox,
  Field,
  Heading,
  Input,
} from '@workwhile/ui'
import { GoToCompanyDetail } from './GoToCompanyDetail'
const INITIAL_STATE = {
  company_name: '',
  company_id: '',
  contact_email: '',
  companies: [],
  invite_link: '',
  already_sent: null,
  invoice: false,
  w2: false,
  loading: false,
  searchTerm: '',
}

class Company extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    this.fetchCompanies()
  }

  fetchCompanies = () => {
    this.setState({ loading: true })
    api.get('/admin/companies').then((res) => {
      this.setState({
        companies: res.data.companies,
        loading: false,
      })
    })
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onCreateCompany = (event) => {
    event.preventDefault()
    const { company_name, invoice, w2 } = this.state

    if (!company_name) {
      alert('Please add a company name')
      return
    }

    const data = {}
    if (invoice) {
      data['set_to_invoice'] = true
    }
    if (w2) {
      data['set_to_w2'] = true
    }

    api
      .post(`/admin/company/create/${company_name}`, data)
      .then((res) => {
        this.fetchCompanies()
        alert(`Company created! ID: ${res.data.company_id}. See table below`)
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  handleSwitchCompany = async (companyId) => {
    try {
      await api.put(`/admin/company/${companyId}/switch`)
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        // On mobile devices, replace current tab's location
        window.location.replace('https://business.workwhilejobs.com')
      } else {
        // On non-mobile devices, open a new tab
        window.open('https://business.workwhilejobs.com', '_blank')
      }
    } catch (error) {
      console.error('Error switching company:', error)
    }
  }

  handleSearch = (event) => {
    this.setState({ searchTerm: event.target.value })
  }

  getFilteredCompanies = () => {
    const { companies, searchTerm } = this.state
    const term = searchTerm.toLowerCase()
    const isNumericSearch = !isNaN(Number(term))

    return companies.filter((company) => {
      const nameMatch = company.name.toLowerCase().includes(term)
      const idMatch = isNumericSearch && company.id.toString().includes(term)
      return nameMatch || idMatch
    })
  }

  render() {
    const {
      loading,
      company_name,
      company_id,
      contact_email,
      invite_link,
      already_sent,
      invoice,
      searchTerm,
    } = this.state

    const columns = getColumns(this.handleSwitchCompany)
    const filteredCompanies = this.getFilteredCompanies()

    return (
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          p={4}
        >
          <Box flex="1" mr={2}>
            <Heading>Create a Company</Heading>
            <Card width="50%" as={'form'} onSubmit={this.onCreateCompany}>
              <Field label={'Enter Company Name'}>
                <Input
                  name="company_name"
                  value={company_name}
                  onChange={this.onChange}
                  type="text"
                  placeholder="Company Name"
                />
              </Field>
              <Box mb={2}>
                <Checkbox
                  defaultChecked={invoice}
                  onChange={(checked) => this.setState({ invoice: checked })}
                >
                  Check if company will be invoiced
                </Checkbox>
              </Box>
              <Checkbox
                defaultChecked={invoice}
                onChange={(checked) => this.setState({ w2: checked })}
              >
                Check if company is W-2{' '}
              </Checkbox>
              <br />
              <Button type="submit">Create Company</Button>
            </Card>
          </Box>
        </Box>

        <Box mt={5}>
          {loading ? (
            <Loading />
          ) : (
            <Box m={3}>
              <Heading>Companies</Heading>
              <Box my={4}>
                <Input
                  className="search-box"
                  type="text"
                  placeholder="Search companies..."
                  value={searchTerm}
                  onChange={this.handleSearch}
                />
              </Box>

              <BootstrapTable
                striped
                hover
                keyField="id"
                data={filteredCompanies}
                columns={columns}
              />
            </Box>
          )}
        </Box>
      </Box>
    )
  }
}

const getColumns = (handleSwitchCompany) => [
  {
    dataField: 'id',
    text: 'ID',
    formatter: (cell, row) => <span data-context-key="company_id" data-context-value={cell} data-context-label={row.name}>{cell}</span>,
  },
  {
    dataField: 'switch',
    text: 'Switch Company',
    formatter: (cell, row) => (
      <Button variant="xsmall" onClick={() => handleSwitchCompany(row.id)}>
        Switch
      </Button>
    ),
  },
  {
    dataField: 'name',
    text: 'Name',
  },
  {
    dataField: 'take_percentage_estimate',
    text: 'Take Percentage',
    formatter: (cell) => `${cell}%`,
  },
  {
    dataField: 'charge_upfront',
    text: 'On Invoice?',
    formatter: (cell) => <div>{cell ? 'No' : 'Yes'}</div>,
  },
  {
    dataField: 'invoice_due_days',
    text: 'Invoice Due Days',
    formatter: (cell) => <div>{cell || 'N/A'}</div>,
  },
  {
    dataField: 'pending_invites',
    text: 'Pending Invites',
    formatter: (cell) => (
      <div>
        {cell.map((invite) => (
          <p key={invite.email}>{invite.email}</p>
        ))}
      </div>
    ),
  },
  {
    dataField: 'id',
    text: 'Details',
    formatter: (id) => {
      return <GoToCompanyDetail companyId={id} />
    },
  },
]

export default Company
