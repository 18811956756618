import { formatInTimeZone } from 'date-fns-tz'
import { formatDistanceToNowStrict, isSameYear } from 'date-fns'
import { TierEligibility } from 'pages/Shifts/ShiftDetail/types'

const getFormattedTimeToDisplay = (
  date: Date | string,
  timezone: string
): string => {
  const isDateInCurrentYear = isSameYear(date, new Date())
  const format = isDateInCurrentYear
    ? 'MMM d, h:mm a z'
    : 'MMM d, yyyy, h:mm a z'
  const formattedDate = formatInTimeZone(date, timezone, format)

  const formattedDistance = formatDistanceToNowStrict(date, {
    addSuffix: true,
  })

  const stringToDisplay = `${formattedDate} (${formattedDistance})`
  return stringToDisplay
}

export const getTierTransitionTimestamp = (
  eligibility: TierEligibility,
  timezone: string
) => {
  // If its "active", we want to display the time it transitioned at
  if (
    eligibility.activeStatus === 'active' ||
    eligibility.activeStatus === 'past'
  ) {
    return getFormattedTimeToDisplay(eligibility.transitionedAt, timezone)
  }

  if (eligibility.activeStatus === 'not_started') {
    if (eligibility.scheduledAt) {
      return getFormattedTimeToDisplay(eligibility.scheduledAt, timezone)
    }
  }

  return 'Time unavailable'
}
