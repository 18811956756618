import React, { Dispatch, SetStateAction } from 'react'
import { createContext } from 'lib/createContext'
import { Loading, Message } from '@workwhile/ui'
import { parseErrorMessage } from 'api'
import { useShiftsQuery } from 'queries/shifts/useShiftsQuery'
import { format } from 'date-fns'
import { useCompaniesQuery } from 'queries/companies'
import { useCompanyGroupsQuery } from 'queries/companyGroups'
import { useTimeRangeQueryState } from '../hooks/useTimeRangeQueryState'
import { usePaginationQueryState } from '../hooks/usePaginationQueryState'
import { useShiftQueryState } from '../hooks/useShiftQueryState'
import { useCompanyQueryState } from '../hooks/useCompanyQueryState'
import { SelectableColumns } from '../components/ShiftsTable/ColumnsFilter'
import { useSelectedColumnsQueryState } from '../hooks/useSelectedColumnsQueryState'
import { useMarketsQuery } from 'queries/markets'

type ShiftsContextState = {
  state: {
    data: ReturnType<typeof useShiftsQuery>['data']
    dataUpdatedAt: number
    isFetching: boolean
    isError: boolean
    error: string
    companies: ReturnType<typeof useCompaniesQuery>['data']
    companyGroups: ReturnType<typeof useCompanyGroupsQuery>['data']
    pagination: {
      pageIndex: number
      pageSize: number
    }
    timeRangeFilters: {
      type?: 'past' | 'ongoing' | 'upcoming'
      startDate?: Date
      endDate?: Date
    }
    shiftFilters: {
      shiftIds: number[]
      marketCodes: string[]
      scheduleRateMin: number
      scheduleRateMax: number
      fillRateMin: number
      fillRateMax: number
    }
    companyFilters: {
      companyIds: number[]
      companyGroupIds: number[]
    }
    selectedColumnsFilters: SelectableColumns[]
    markets: ReturnType<typeof useMarketsQuery>['data']
  }
  actions: {
    refetch: () => void
    setPagination: ReturnType<typeof usePaginationQueryState>[1]
    setTimeRangeFilters: ReturnType<typeof useTimeRangeQueryState>[1]
    setShiftFilters: ReturnType<typeof useShiftQueryState>[1]
    setCompanyFilters: ReturnType<typeof useCompanyQueryState>[1]
    setSelectedColumnsFilters: ReturnType<
      typeof useSelectedColumnsQueryState
    >[1]
  }
}

const [Context, useShiftsContextValue] = createContext<ShiftsContextState>({
  name: 'ShiftsContext',
})

export const ShiftsProvider = ({ children }: { children: React.ReactNode }) => {
  const [pagination, setPagination] = usePaginationQueryState()
  const [timeRangeFilters, setTimeRangeFilters] = useTimeRangeQueryState()
  const [shiftFilters, setShiftFilters] = useShiftQueryState()
  const [companyFilters, setCompanyFilters] = useCompanyQueryState()
  const [selectedColumnsFilters, setSelectedColumnsFilters] =
    useSelectedColumnsQueryState()

  const {
    data,
    isPending: isShiftsLoading,
    isError: isShiftsError,
    error: shiftsError,
    dataUpdatedAt,
    refetch,
    isFetching,
  } = useShiftsQuery({
    rangeType: timeRangeFilters.type,
    startDate: timeRangeFilters.startDate
      ? format(timeRangeFilters.startDate, 'yyyy-MM-dd')
      : undefined,
    endDate: timeRangeFilters.endDate
      ? format(timeRangeFilters.endDate, 'yyyy-MM-dd')
      : undefined,
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
    shiftIds: shiftFilters.shiftIds,
    marketCodes: shiftFilters.marketCodes,
    companyIds: companyFilters.companyIds,
    companyGroupIds: companyFilters.companyGroupIds,
    scheduleRateMin: shiftFilters.scheduleRateMin,
    scheduleRateMax: shiftFilters.scheduleRateMax,
    fillRateMin: shiftFilters.fillRateMin,
    fillRateMax: shiftFilters.fillRateMax,
  })

  const {
    data: companies,
    isLoading: isCompaniesLoading,
    isError: isCompaniesError,
    error: companiesError,
  } = useCompaniesQuery()

  const {
    data: companyGroups,
    isLoading: isCompanyGroupsLoading,
    isError: isCompanyGroupsError,
    error: companyGroupsError,
  } = useCompanyGroupsQuery()

  const {
    data: markets,
    isLoading: isMarketsLoading,
    isError: isMarketsError,
    error: marketsError,
  } = useMarketsQuery()

  const isAnyLoading =
    isShiftsLoading ||
    isCompaniesLoading ||
    isCompanyGroupsLoading ||
    isMarketsLoading

  const isAnyError =
    isShiftsError || isCompaniesError || isCompanyGroupsError || isMarketsError

  const error =
    shiftsError || companiesError || companyGroupsError || marketsError

  if (isAnyLoading) {
    return <Loading />
  }

  if (isAnyError) {
    // eslint-disable-next-line no-console
    console.error(error)
    return (
      <Message variant="error" m={4} borderRadius="small">
        Sorry, something went wrong. Please reach out to eng support with the
        URL of the page if this persists.
      </Message>
    )
  }

  const value = {
    state: {
      data,
      dataUpdatedAt,
      isFetching,
      isError: isAnyError,
      error: parseErrorMessage(error),
      companies,
      companyGroups,
      pagination,
      timeRangeFilters,
      shiftFilters,
      companyFilters,
      selectedColumnsFilters,
      markets,
    },
    actions: {
      refetch,
      setPagination,
      setTimeRangeFilters,
      setShiftFilters,
      setCompanyFilters,
      setSelectedColumnsFilters,
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useShiftsContextValue }
