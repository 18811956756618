import React, { PropsWithChildren } from 'react'
import { createContext } from 'lib/createContext'
import { parseErrorMessage } from 'api'
import { useRemoveCompanyFromGroupMutation } from 'queries/companyGroup'
import { useCompanyGroupDetailContextValue } from 'pages/CompanyGroupDetail/context/CompanyGroupDetailProvider'
import { useCompanyGroupDetailWorkflowsContextValue } from 'pages/CompanyGroupDetail/context/CompanyGroupDetailWorkflowProvider'

type RemoveCompanyFromGroupFlowProviderContextState = {
  actions: {
    submitFormToServer: ReturnType<
      typeof useRemoveCompanyFromGroupMutation
    >['mutate']
  }
  state: {
    companyId: number
    companyName: string
    companyGroupId: number
    companyGroupName: string
    mutationState: {
      isPending: boolean
      isError: boolean
      errorMessage: string | undefined
    }
  }
}

const [Context, useRemoveCompanyFromGroupFlowContextValue] =
  createContext<RemoveCompanyFromGroupFlowProviderContextState>({
    name: 'RemoveCompanyFromGroupFlowContext',
  })

export const RemoveCompanyFromGroupFlowProvider = ({
  children,
}: PropsWithChildren) => {
  const {
    state: { id: companyGroupId, name: companyGroupName },
  } = useCompanyGroupDetailContextValue()

  const {
    removeCompanyFromGroupFlow: {
      isOpen,
      data: removeCompanyFromGroupFlowData,
    },
  } = useCompanyGroupDetailWorkflowsContextValue()

  const { mutate, isPending, isError, error } =
    useRemoveCompanyFromGroupMutation(companyGroupId)

  if (isOpen && !removeCompanyFromGroupFlowData) {
    throw new Error(
      'Unable to find company from group flow data. Please report this error to the engineering team.'
    )
  }

  const value: RemoveCompanyFromGroupFlowProviderContextState = {
    actions: {
      submitFormToServer: mutate,
    },
    state: {
      ...removeCompanyFromGroupFlowData,
      companyGroupId,
      companyGroupName,
      mutationState: {
        isPending,
        isError,
        errorMessage: parseErrorMessage(error),
      },
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useRemoveCompanyFromGroupFlowContextValue }
