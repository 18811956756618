import React from 'react'
import { Box, Flex } from '@workwhile/ui'
import { ShiftsHeader } from './ShiftsHeader'
import { ShiftsTable } from './ShiftsTable/ShiftsTable'
import { ShiftListFilters } from './ShiftListFilters/ShiftListFilters'
import { ColumnsFilter } from './ShiftsTable/ColumnsFilter/ColumnsFilter'

export const ShiftsMain = () => {
  return (
    <>
      <ShiftsHeader />
      <Box margin={3}>
        <Flex justifyContent="space-between" alignItems="center" mb={3}>
          <ShiftListFilters />
          <ColumnsFilter />
        </Flex>
        <ShiftsTable />
      </Box>
    </>
  )
}
