import React from 'react'
import { Flex, Text, Heading, Table } from '@workwhile/ui'
import { AccordionCard } from 'components/AccordionCard'
import { useTierStatsContextValue } from '../../../context/TierStatsProvider'
import { formatNumber } from 'lib/number'

const columns = [
  {
    header: 'Reason',
    accessorKey: 'reason',
  },
  {
    header: 'Count',
    accessorKey: 'count',
    cell: ({ row }) => {
      return <Text textAlign="right">{formatNumber(row.original.count)}</Text>
    },
  },
]

export const WorkerNotInterestedReasons = () => {
  const {
    state: { tierDetails },
  } = useTierStatsContextValue()

  return (
    <AccordionCard
      title={
        <Flex flexDirection="column">
          <Text fontSize={2} color="lightText">
            Statistics
          </Text>
          <Heading
            level={2}
            fontWeight="400"
            style={{
              margin: 0,
            }}
          >
            Worker Not Interested Reasons
          </Heading>
        </Flex>
      }
      size="small"
    >
      <Table columns={columns} data={tierDetails.notInterestedReasons} />
    </AccordionCard>
  )
}
