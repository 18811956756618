import { useQuery } from '@tanstack/react-query'
import { getPositionTemplate } from 'api/company'
import { positionTemplateKey } from './keys'

export function usePositionTemplate(positionTemplateId?: number) {
  return useQuery({
    queryKey: positionTemplateKey(positionTemplateId),
    queryFn: () => getPositionTemplate(positionTemplateId),
    enabled: !!positionTemplateId,
  })
}
