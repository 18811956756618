import React from 'react'
import { Box } from '@workwhile/ui'
import CommandList from 'components/features/Commands/CommandList'

function CommandsPage() {
  return (
    <Box padding={20}>
      <CommandList enableUrlSync />
    </Box>
  )
}

export default CommandsPage
