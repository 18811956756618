import * as zod from 'zod'

export const createCompanyFlowSchema = zod.object({
  companyName: zod
    .string()
    .min(3, { message: 'Company name must be at least 3 characters' })
    .max(60, { message: 'Company name must not exceed 60 characters' })
    .refine((name) => !name.startsWith('_'), {
      message: 'Company name cannot start with an underscore',
    })
    .refine((name) => /^[a-zA-Z0-9_-\s]+$/.test(name), {
      message:
        'Company name can only contain letters, numbers, underscores, hyphens, and spaces',
    }),

  type: zod.enum(['1099', 'w2']),
  shouldSetToInvoice: zod.boolean(),
})

export type CreateCompanyFlowSchema = zod.infer<typeof createCompanyFlowSchema>
