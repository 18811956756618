import { useCompanyDetailWorkflowsContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailWorkflowProvider'
import React from 'react'
import { useManageLogoFlowContextValue } from '../context/ManageLogoFlowContextProvider'
import {
  Box,
  Button,
  Field,
  Flex,
  Heading,
  Message,
  Modal,
  Text,
  toast,
  useAppTheme,
} from '@workwhile/ui'
import { useCompanyDetailContextValue } from '../../../context/CompanyDetailProvider'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import * as FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import * as FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import * as FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import { FilePond, registerPlugin } from 'react-filepond'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

import { manageLogoFlowSchema } from '../schema/manageLogo'

import styled from 'styled-components'

// Register the plugins
registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview
)

const StyledField = styled(Field)`
  .filepond--root,
  .filepond--root .filepond--file-info-main,
  .filepond--root .filepond--file-info-sub {
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: ${({ theme }) => `${theme.fontSizes[2]}px`};
  }

  .filepond--root {
    height: 140px;
    max-height: 140px;
  }
  .filepond--root .filepond--image-preview {
    background: ${({ theme }) => theme.colors.neutrals[50]};
  }

  .filepond--root .filepond--file-info-sub {
    color: ${({ theme }) => theme.colors.neutrals[100]};
    font-size: ${({ theme }) => `${theme.fontSizes[1]}px`};
  }

  .filepond--drop-label {
    color: ${({ theme }) => theme.colors.neutrals[300]};
    height: 100%;
  }
`
export const ManageLogoFlowMain = () => {
  const { colors, radii, space } = useAppTheme()

  const {
    manageLogoFlow: { isOpen, close },
  } = useCompanyDetailWorkflowsContextValue()

  const {
    state: {
      formData,
      mutationState: { isPending, errorMessage },
    },
    actions: { submitFormToServer, setFormData },
  } = useManageLogoFlowContextValue()

  const {
    state: { name, logoUrl },
  } = useCompanyDetailContextValue()

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<z.infer<typeof manageLogoFlowSchema>>({
    resolver: zodResolver(manageLogoFlowSchema),
    defaultValues: {
      files: formData.files ? formData.files : [],
    },
  })

  const files = watch('files')

  const onSubmit = handleSubmit((data) => {
    setFormData(data)
    submitFormToServer(data.files[0].file, {
      onSuccess: () => {
        toast.success('Updated company logo successfully!', {
          position: 'top-center',
        })
        setFormData({})
        reset({ files: [] })
        close()
      },
    })
  })

  return (
    <Modal
      open={isOpen}
      onClose={close}
      position="top"
      loading={isPending}
      showCancel
      customCta={
        <Flex>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save
          </Button>
        </Flex>
      }
    >
      <Box as="form" onSubmit={onSubmit}>
        <Heading level={3} fontWeight="400" style={{ margin: 0 }}>
          Manage Logo
        </Heading>
        <Text mt={3} fontSize={2}>
          Current logo
        </Text>

        {logoUrl ? (
          <img
            src={logoUrl}
            alt={name}
            width={140}
            height={140}
            style={{
              objectFit: 'contain',
              borderRadius: radii.small,
              border: `1px solid ${colors.neutrals[100]}`,
              marginTop: space[2],
            }}
          />
        ) : (
          <Text fontSize={2} color="lightText">
            N/A
          </Text>
        )}

        <Controller
          name="files"
          control={control}
          render={({ field: { onChange } }) => (
            <StyledField
              label={
                <Text>
                  New logo
                  <br />
                  <Text fontSize={1} color="lightText">
                    Aspect ratios are automatically adjusted to 1:1
                  </Text>
                </Text>
              }
              error={errors.files?.message?.toString()}
              // Intentionally disabled. This should work, unsure why it's throwing an error
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              mt={3}
            >
              <FilePond
                files={files.map((f) => f.file)}
                allowMultiple={false}
                onupdatefiles={onChange}
                stylePanelLayout="compact"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                credits={false}
                maxFiles={1}
                allowFileSizeValidation={true}
                maxFileSize="5MB"
                allowImagePreview={true}
                acceptedFileTypes={['image/jpeg', 'image/png']}
                allowProcess={false}
                imagePreviewHeight={140}
              />
            </StyledField>
          )}
        />

        {errorMessage ? (
          <Message variant="error" mt={3} borderRadius="small">
            {errorMessage}
          </Message>
        ) : null}
      </Box>
    </Modal>
  )
}
