import api from 'api'

type InviteUserToCompanyRequest = {
  companyId: number
  email: string
}

export const inviteUserToCompany = async ({
  companyId,
  email,
}: InviteUserToCompanyRequest) => {
  return api.post(`/admin/company/invite/${companyId}/${email}`)
}
