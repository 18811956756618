import { WorkStatus } from 'api/typings'
import * as zod from 'zod'

export const editShiftWorkFlowSchema = zod
  .object({
    status: zod.nativeEnum(WorkStatus),
    clockedInAtAndClockedOutAt: zod.tuple([
      zod.string().optional(),
      zod.string().optional(),
    ]),
    leftEarly: zod.boolean().optional(),
    existingValues: zod.object({
      status: zod.nativeEnum(WorkStatus),
      clockedInAtAndClockedOutAt: zod.tuple([
        zod.string().optional(),
        zod.string().optional(),
      ]),
      leftEarly: zod.boolean().optional(),
    }),
  })
  .superRefine((data, ctx) => {
    const hasAnyFieldsChanged = Object.keys(data.existingValues).some(
      (key) =>
        // We use json stringify to compare the values of `clockedInAtAndClockedOutAt` tuple
        JSON.stringify(data[key]) !== JSON.stringify(data.existingValues[key])
    )

    if (!hasAnyFieldsChanged) {
      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        message:
          'No changes detected. Please modify at least one field to save.',
        path: ['existingValues'],
      })

      return false
    }

    return true
  })

export type EditShiftWorkFlowSchema = zod.infer<typeof editShiftWorkFlowSchema>
