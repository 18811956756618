import React from 'react'
import { AreaChart, useAppTheme } from '@workwhile/ui'
import { AccordionCard } from 'components/AccordionCard'
import { formatNumber } from 'lib/number'
import { useQueryBasedFeatureFlag } from '../../hooks/useQueryBasedFeatureFlag'

const data = [
  {
    date: 'Sun, 2/9',
    views: 40,
  },
  {
    date: 'Mon, 2/10',
    views: 122,
  },
  {
    date: 'Tue, 2/11',
    views: 165,
  },
  {
    date: 'Wed, 2/12',
    views: 455,
  },
  {
    date: 'Thu, 2/13',
    views: 688,
  },
  {
    date: 'Fri, 2/14',
    views: 722,
  },
  {
    date: 'Sat, 2/15',
    views: 855,
  },
]

export const TotalListingViews = () => {
  const { colors } = useAppTheme()
  const { totalListingViews } = useQueryBasedFeatureFlag()
  if (!totalListingViews) return null
  return (
    <AccordionCard
      title="Total Listing Views (fake data)"
      size="fit"
      // Intentionally disabled. This should work, unsure why it's throwing an error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{
        flex: '1 1 auto',
        whiteSpace: 'nowrap',
        minWidth: '400px',
        maxWidth: '528px',
      }}
    >
      <AreaChart
        data={data}
        dataKey="date"
        height="208px"
        withDots={false}
        series={[
          {
            name: 'views',
            color: colors.primary200,
            label: 'Views',
          },
        ]}
        valueFormatter={formatNumber}
      />
    </AccordionCard>
  )
}
