import { getCompanyDetail } from 'api/company/companyDetail'
import { companyDetailKey } from './keys'
import { useQuery } from '@tanstack/react-query'

export const useCompanyDetailsByIdQuery = (companyId: number) => {
  return useQuery({
    queryKey: companyDetailKey(companyId),
    queryFn: () => getCompanyDetail(companyId),
    refetchOnReconnect: false,
  })
}
