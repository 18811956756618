import { useMutation, useQueryClient } from '@tanstack/react-query'
import { removeCompanyFromGroup } from 'api/companyGroup/removeCompanyFromGroup'
import { companyGroupDetailKey } from './keys'

export const useRemoveCompanyFromGroupMutation = (companyGroupId: number) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (companyId: number) =>
      removeCompanyFromGroup(companyGroupId, companyId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: companyGroupDetailKey(companyGroupId),
      }),
  })
}
