import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'

import { useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import graphql from 'graphql'
import RefreshButton from 'components/Shared/RefreshButton'
import { Box, Flex, Text } from '@workwhile/ui'

const columns = [
  {
    dataField: 'predictedEngagementShift',
    text: 'Predicted Engagement',
    headerStyle: { width: '6em', fontSize: 12 },
    formatter: (cell, row) => <p>Estimate: {row.predictedEngagementShift}</p>,
  },
  {
    dataField: 'numWorkersOpenedApp',
    text: 'Workers opened app in last 30 days',
    headerStyle: { width: '6em', fontSize: 12 },
    formatter: (cell, row) => <p> {row.numWorkersOpenedApp}</p>,
  },
  {
    dataField: 'numWorkersNotified',
    text: 'Workers notified',
    headerStyle: { width: '6em', fontSize: 12 },
    formatter: (cell, row) => <p>{row.numWorkersNotified}</p>,
  },
  {
    dataField: 'listingImpressions',
    text: 'Listing impressions',
    headerStyle: { width: '6em', fontSize: 12 },
    formatter: (cell, row) => <p>Impressions: {row.listingImpressions}</p>,
  },
]

const ShiftAnalytics = () => {
  const { id: shiftId } = useParams()
  const shiftAnalytics = useQuery({
    queryKey: ['shift', shiftId, 'shiftAnalytics'],
    queryFn: async () =>
      await graphql(`
        admin { 
            shiftAnalytics (shiftId: ${shiftId}) {
                shift { id },
                listingImpressions,
                predictedEngagementShift,
                numWorkersNotified,
                numWorkersOpenedApp,
            }
        }`).then((response) =>
        response.data.data.admin.shiftAnalytics
          ? [response.data.data.admin.shiftAnalytics]
          : []
      ),
    enabled: !!shiftId,
  })

  console.log(shiftAnalytics.data)
  return (
    <Box width="100%">
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Text>
          {shiftAnalytics.isFetching
            ? 'Loading shift analytics...'
            : 'Refresh shift analytics'}
        </Text>
        <RefreshButton
          onClick={shiftAnalytics.refetch}
          loading={shiftAnalytics.isFetching}
        />
      </Flex>
      {!shiftAnalytics.isFetching &&
        (shiftAnalytics.data?.length ? (
          <BootstrapTable
            keyField="shift"
            data={shiftAnalytics.data}
            columns={columns}
          />
        ) : (
          <Text>Shift analytics for this shift are not available.</Text>
        ))}
    </Box>
  )
}

export default ShiftAnalytics
