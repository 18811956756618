import { getCompanyPosition } from 'api/company/position'
import { positionKey } from './keys'
import { useQuery } from '@tanstack/react-query'

export function useCompanyPosition(positionId: number | null) {
  return useQuery({
    queryKey: positionKey(positionId),
    queryFn: () => getCompanyPosition(positionId),
    enabled: !!positionId,
  })
}
