import { createContext } from 'lib/createContext'
import React, { PropsWithChildren, useState } from 'react'

type Workflow = {
  open: () => void
  close: () => void
  isOpen: boolean
}

export type CompanyGroupsWorkflowsContextState = {
  createCompanyGroupFlow: Workflow
}

const [CompanyGroupsWorkflowsContext, useCompanyGroupsWorkflowsContextValue] =
  createContext<CompanyGroupsWorkflowsContextState>({
    name: 'CompanyGroupsWorkflowsContext',
  })

type CompanyGroupsWorkflowsProviderProps = PropsWithChildren

export const CompanyGroupsWorkflowsProvider = ({
  children,
}: CompanyGroupsWorkflowsProviderProps) => {
  const [isCreateCompanyGroupFlowOpen, setIsCreateCompanyGroupFlowOpen] =
    useState(false)

  const value: CompanyGroupsWorkflowsContextState = {
    createCompanyGroupFlow: {
      open: () => setIsCreateCompanyGroupFlowOpen(true),
      close: () => setIsCreateCompanyGroupFlowOpen(false),
      isOpen: isCreateCompanyGroupFlowOpen,
    },
  }

  return (
    <CompanyGroupsWorkflowsContext.Provider value={value}>
      {children}
    </CompanyGroupsWorkflowsContext.Provider>
  )
}

export { useCompanyGroupsWorkflowsContextValue }
