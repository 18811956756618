import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createCompanyGroup } from 'api/companyGroup/createCompanyGroup'
import { companyGroupsKey } from './keys'
import { CompanyGroups } from 'pages/CompanyGroups/types'

export const useCreateCompanyGroupMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createCompanyGroup,
    onSuccess: (data) => {
      queryClient.setQueryData(
        companyGroupsKey,
        (old: CompanyGroups) => [data, ...old],
        {
          updatedAt: new Date(data.createdAt).getTime(),
        }
      )
    },
  })
}
