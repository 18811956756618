import React from 'react'
import { User } from 'api/typings'
import CollapsibleSection from '../Shared/CollapsibleSection'
import * as moment from 'moment-timezone'
import UserPenaltyWaive from './UserPenaltyWaive'
import UserStrike from './UserStrike'
import { dateTimeSubmittedFormat, getUserTimezone } from '../utils/general_util'
import { UserPastStrike } from './UserPastStrike'
import { Accordion, Heading, Table } from '@workwhile/ui'
import { keyValueColumns } from 'components/Users/keyValueColumns'
import { ProfilePicture } from 'components/Users/ProfilePicture'

interface Props {
  user: User
  refreshData: (e?: any) => void
}

const UserBasicInfo = ({ user, refreshData }: Props) => {
  const _timezone = getUserTimezone(user)

  const isActivePenalty =
    user.status === 'suspended' || user.status === 'blocked'
  const relevantPenalty = user.penalties
    ? user.penalties.filter((penalty) => penalty.penaltyType === user.status)[0]
    : null

  const getPaymentAccountString = (user) => {
    let accountString = ''
    for (var i = 0; i < user.paymentAccounts.length; i++) {
      let account = user.paymentAccounts[i]
      if (i > 0) {
        accountString += ', '
      }
      accountString += account.accountType
    }
    return accountString
  }

  const data = React.useMemo(
    () =>
      [
        {
          key: 'id',
          value: user.id,
        },
        {
          key: 'Status',
          value: user.status,
        },
        {
          key: isActivePenalty && relevantPenalty ? 'Penalty Policy' : null,
          value:
            isActivePenalty && relevantPenalty ? relevantPenalty.policy : null,
        },
        {
          key:
            isActivePenalty && relevantPenalty ? 'Penalty Triggered At' : null,
          value:
            isActivePenalty && relevantPenalty
              ? moment
                  .tz(relevantPenalty.createdAt, _timezone)
                  .format(dateTimeSubmittedFormat)
              : null,
        },
        {
          key:
            user.status === 'suspended' &&
            relevantPenalty &&
            relevantPenalty.policy === 'reject_late'
              ? 'Reject reason'
              : null,
          value:
            user.status === 'suspended' &&
            relevantPenalty &&
            relevantPenalty.policy === 'reject_late'
              ? relevantPenalty.offenses[0].reason
              : null,
        },
        {
          key:
            user.status === 'suspended' &&
            relevantPenalty &&
            relevantPenalty.policy === 'unhealthy'
              ? 'Health Check'
              : null,
          value:
            user.status === 'suspended' &&
            relevantPenalty &&
            relevantPenalty.policy === 'unhealthy'
              ? relevantPenalty.offenses[0].healthCheckAnswers
              : null,
        },
        {
          key: 'Shadow Blocked',
          value: user.shadowBlocked ? 'true' : 'false',
        },
        {
          key: 'Shadow Blocked Reason',
          value: user.shadowBlockedReason,
        },
        {
          key: 'Phone Number',
          value: user.phoneNumber,
        },
        {
          key: 'Email',
          value: user.email,
        },
        {
          key: 'Address',
          value: user.address
            ? user.address.street +
              ' ' +
              (user.address.unit ? user.address.unit + ' ' : '') +
              user.address.city +
              ' ' +
              user.address.state +
              ' ' +
              user.address.zip
            : 'N/A',
        },
        {
          key: 'Stripe ID',
          value: user.stripeAccountIdProd ? user.stripeAccountIdProd : 'N/A',
          link: `https://dashboard.stripe.com/connect/accounts/${user.stripeAccountIdProd}`,
        },
        {
          key: 'Payment Account type',
          value: getPaymentAccountString(user),
        },
        {
          key: 'Orientation Status',
          value: user.orientationStatus,
        },
        {
          key: 'Rating',
          value: user.rating,
        },
        {
          key: 'Account created at',
          value: user.createdAt
            ? moment
                .tz(user.createdAt, 'America/Los_Angeles')
                .format('YYYY-MM-DD hh:mm A')
            : 'N/A',
        },
      ].filter((item) => item.key || item.value?.toString()),
    []
  )

  return (
    <CollapsibleSection title="Basic Account Info" defaultOpen>
      <Table variant={'borderless'} columns={keyValueColumns} data={data} />
      <Accordion
        type={'single'}
        items={[
          {
            trigger: <Heading level={4}>Profile Picture</Heading>,
            content: (
              <ProfilePicture
                verifiedSelfieUrl={user.verifiedSelfieUrl}
                profilePicUrl={user.profilePicUrl}
                profilePicVerifiedAt={user.profilePicVerifiedAt}
                profilePicManuallyVerifiedBy={user.profilePicManuallyVerifiedBy}
                id={user.id}
                refreshData={refreshData}
              />
            ),
          },
        ]}
      />
      <hr />
      {user.strikes
        ? user.strikes.map((strike) => (
            <UserStrike
              key={strike.id}
              user={user}
              strike={strike}
              refreshData={refreshData}
            />
          ))
        : null}
      {user.allStrikes
        ? user.allStrikes.map((strike) => (
            <UserPastStrike key={strike.id} user={user} strike={strike} />
          ))
        : null}
      <UserPenaltyWaive user={user} refreshData={refreshData} />
    </CollapsibleSection>
  )
}

export default UserBasicInfo
