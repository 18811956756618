import React from 'react'
import { AddCancellationPolicyFlowProvider } from './context/AddCancellationPolicyFlowProvider'
import { AddCancellationPolicyFlowMain } from './components/AddCancellationPolicyFlowMain'

export const AddCancellationPolicyFlow = () => {
  return (
    <AddCancellationPolicyFlowProvider>
      <AddCancellationPolicyFlowMain />
    </AddCancellationPolicyFlowProvider>
  )
}
