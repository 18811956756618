import { Loading } from '@workwhile/ui'
import { createContext } from 'lib/createContext'
import { usePositionTemplates } from 'queries/company'
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { PositionTemplate, PositionTemplateGroup } from 'api/company'
import { useVisibleTemplates } from './useVisibleTemplates'
import { useAllGroups } from './useAllGroups'

interface PositionTemplateContextValue {
  selectedTemplateId?: number
  setSelectedTemplateId: (id: number) => void
  keyword: string
  setKeyword: (keyword: string) => void
  selectedGroupId?: number
  setSelectedGroupId: (id?: number) => void
  visibleTemplates: PositionTemplate[]
  allGroups: PositionTemplateGroup[]
}

const [PositionTemplateContext, usePositionTemplateContext] =
  createContext<PositionTemplateContextValue>({
    name: 'PositionTemplateContext',
  })

interface PositionTemplateProviderProps extends PropsWithChildren {
  selectedTemplateId?: number
  onSelect: (id: number) => void
}

function PositionTemplateProvider({
  children,
  selectedTemplateId,
  onSelect,
}: PositionTemplateProviderProps) {
  const { data: positionTemplates, isLoading } = usePositionTemplates()
  const allGroups = useAllGroups(positionTemplates)
  const [keyword, setKeyword] = useState('')
  const [selectedGroupId, setSelectedGroupId] = useState<number>()
  const visibleTemplates = useVisibleTemplates({
    positionTemplates,
    keyword,
    selectedGroupId,
  })

  useEffect(() => {
    if (keyword && selectedGroupId) {
      setSelectedGroupId(undefined)
    }
  }, [keyword, selectedGroupId])

  const value = useMemo(
    () => ({
      selectedTemplateId,
      setSelectedTemplateId: onSelect,
      keyword,
      setKeyword,
      selectedGroupId,
      setSelectedGroupId,
      visibleTemplates,
      allGroups,
    }),
    [selectedTemplateId, keyword, selectedGroupId, visibleTemplates, allGroups]
  )

  return (
    <PositionTemplateContext.Provider value={value}>
      {isLoading ? <Loading /> : children}
    </PositionTemplateContext.Provider>
  )
}

export { usePositionTemplateContext, PositionTemplateProvider }
