import { AttributesSelector } from '@workwhile/ui'
import React, { useMemo } from 'react'
import { FormProvider, useFormContext } from 'react-hook-form'
import { FiltersSchema } from '../filtersSchema'
import { ShiftIdsFilter } from './ShiftIdsFilter'
import { MarketCodesFilter } from './MarketCodesFilter'
import { ScheduleRateFilter } from './ScheduleRateFilter'
import { FillRateFilter } from './FillRateFilter'

export const ShiftFilters = () => {
  const methods = useFormContext<FiltersSchema>()
  const shift = methods.watch('shift')

  const resetToDefaultValues = () => {
    methods.reset({
      ...methods.getValues(),
      shift: {
        ...methods.getValues('shift'),
        ids: [],
        marketCodes: [],
        scheduleRateMin: undefined,
        scheduleRateMax: undefined,
        fillRateMin: undefined,
        fillRateMax: undefined,
      },
    })
  }

  const scheduleRateSelectedAttributes = useMemo(() => {
    if (shift.scheduleRateMin && shift.scheduleRateMax) {
      return [
        `Schedule Rate ${shift.scheduleRateMin}% - ${shift.scheduleRateMax}%`,
      ]
    }

    if (shift.scheduleRateMin) {
      return [`Schedule Rate ${shift.scheduleRateMin}%`]
    }

    if (shift.scheduleRateMax) {
      return [`Schedule Rate ${shift.scheduleRateMax}%`]
    }

    return []
  }, [shift.scheduleRateMin, shift.scheduleRateMax])

  const fillRateSelectedAttributes = useMemo(() => {
    if (shift.fillRateMin && shift.fillRateMax) {
      return [`Fill Rate ${shift.fillRateMin}% - ${shift.fillRateMax}%`]
    }

    if (shift.fillRateMin) {
      return [`Fill Rate ${shift.fillRateMin}%`]
    }

    if (shift.fillRateMax) {
      return [`Fill Rate ${shift.fillRateMax}%`]
    }

    return []
  }, [shift.fillRateMin, shift.fillRateMax])

  const numberOfShiftIdsSelected = shift.ids.length

  const numberOfMarketCodesSelected = shift.marketCodes.length

  const numberOfScheduleRateSelected = scheduleRateSelectedAttributes.length

  const numberOfFillRateSelected = fillRateSelectedAttributes.length

  const selectedShiftAttributes = useMemo(() => {
    const fields = []
    const pluralRules = new Intl.PluralRules('en-US')

    if (numberOfShiftIdsSelected > 0) {
      const plural = pluralRules.select(numberOfShiftIdsSelected)
      const suffix = plural === 'one' ? 'Shift' : 'Shifts'
      fields.push(`${numberOfShiftIdsSelected} ${suffix}`)
    }

    if (scheduleRateSelectedAttributes.length > 0) {
      fields.push(...scheduleRateSelectedAttributes)
    }

    if (fillRateSelectedAttributes.length > 0) {
      fields.push(...fillRateSelectedAttributes)
    }

    if (numberOfMarketCodesSelected > 0) {
      const plural = pluralRules.select(numberOfMarketCodesSelected)
      const suffix = plural === 'one' ? 'Market' : 'Markets'
      fields.push(`${numberOfMarketCodesSelected} ${suffix}`)
    }

    return fields
  }, [
    numberOfShiftIdsSelected,
    numberOfMarketCodesSelected,
    scheduleRateSelectedAttributes,
    fillRateSelectedAttributes,
  ])

  return (
    <FormProvider {...methods}>
      <AttributesSelector
        label="Shift"
        rootGroupTitle="Filter by"
        selectedAttributes={selectedShiftAttributes}
        onClearAll={resetToDefaultValues}
        selectedAttributeLimit={5}
        groups={[
          <AttributesSelector.Group
            key="shift-ids"
            title="Shift IDs"
            numberOfSelectedAttributes={numberOfShiftIdsSelected}
          >
            <ShiftIdsFilter />
          </AttributesSelector.Group>,
          <AttributesSelector.Group
            key="schedule-rate"
            title="Schedule Rate"
            numberOfSelectedAttributes={numberOfScheduleRateSelected}
          >
            <ScheduleRateFilter />
          </AttributesSelector.Group>,
          <AttributesSelector.Group
            key="fill-rate"
            title="Fill Rate"
            numberOfSelectedAttributes={numberOfFillRateSelected}
          >
            <FillRateFilter />
          </AttributesSelector.Group>,
          <AttributesSelector.Group
            key="market-codes"
            title="Market"
            numberOfSelectedAttributes={numberOfMarketCodesSelected}
          >
            <MarketCodesFilter />
          </AttributesSelector.Group>,
        ]}
      />
    </FormProvider>
  )
}
