import React from 'react'
import { CreateCompanyGroupFlowProvider } from './context/CreateCompanyGroupFlowProvider'
import { CreateCompanyGroupFlowMain } from './components/CreateCompanyGroupFlowMain'

export const CreateCompanyGroupFlow = () => {
  return (
    <CreateCompanyGroupFlowProvider>
      <CreateCompanyGroupFlowMain />
    </CreateCompanyGroupFlowProvider>
  )
}
