import api from '../api'
import { API_HOST } from '../constants/constants'

export function graphql<T = { data: any }>(q: string) {
  return api.post<T>(`${API_HOST}/graphql`, {
    query: `query { ${q} }`,
  })
}

export function graphqlWithVariables<T = { data: any }>(
  query: string,
  variables: any
) {
  return api.post<T>(`${API_HOST}/graphql`, {
    query,
    variables,
  })
}
