import React, { useMemo } from 'react'
import { LineChart, useAppTheme } from '@workwhile/ui'
import { AccordionCard } from 'components/AccordionCard'
import { useShiftDetailContextValue } from '../../context/ShiftDetailProvider'
import { formatInTimeZone } from 'date-fns-tz'

export const WorkerSupplyInsights = () => {
  const { colors } = useAppTheme()
  const {
    state: {
      workerSupplyInsights,
      numCushionWorkersNeeded,
      location: {
        address: { timezone },
      },
    },
  } = useShiftDetailContextValue()

  const chartData = workerSupplyInsights.map(({ date, ...rest }) => {
    const isCurrentYear =
      new Date(date).getFullYear() === new Date().getFullYear()
    return {
      date: formatInTimeZone(
        date,
        timezone,
        isCurrentYear ? 'EEE, M/d' : 'EEE, M/d/yy'
      ),
      ...rest,
    }
  })

  const series = useMemo(() => {
    const cumulativeWorkersScheduled = {
      name: 'cumulativeWorkersScheduled',
      color: colors.primary300,
      label: 'Scheduled',
    }
    const numCushionWorkersNeededWithWorkersNeeded = {
      name: 'numCushionWorkersNeededWithWorkersNeeded',
      color: colors.primary200,
      label: 'Needed + Cushion',
    }
    const numWorkersNeeded = {
      name: 'numWorkersNeeded',
      color: colors.primary200,
      label: 'Needed',
    }

    if (numCushionWorkersNeeded) {
      return [
        cumulativeWorkersScheduled,
        numCushionWorkersNeededWithWorkersNeeded,
      ]
    }

    return [cumulativeWorkersScheduled, numWorkersNeeded]
  }, [colors, numCushionWorkersNeeded])

  return (
    <AccordionCard
      title="Worker Supply Insights"
      size="fit"
      // Intentionally disabled. This should work, unsure why it's throwing an error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{
        flex: '1 1 auto',
        whiteSpace: 'nowrap',
        minWidth: '400px',
        maxWidth: '592px',
      }}
    >
      <LineChart
        data={chartData}
        curveType="bump"
        dataKey="date"
        height="208px"
        series={series}
      />
    </AccordionCard>
  )
}
