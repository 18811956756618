import React, { useId } from 'react'
import { useAppTheme, Box, Heading } from '@workwhile/ui'
import { StyledTable } from 'components/InfoTable'

type ReferenceTableProps = {
  title: string
  data: {
    key: string | React.ReactNode
    description: string
  }[]
}

export const ReferenceTable = ({ title, data }: ReferenceTableProps) => {
  const baseId = useId()
  const { space } = useAppTheme()

  const rows = data.map((row, index) => (
    <tr key={`flexible-table-row-${baseId}-${row.key.toString()}-${index}`}>
      <Box
        as="td"
        width="fit-content"
        style={{
          verticalAlign: 'top',
          whiteSpace: 'nowrap',
          paddingRight: space[1],
        }}
      >
        {row.key}
      </Box>
      <Box
        as="td"
        style={{ textAlign: 'center', verticalAlign: 'top' }}
        color="neutrals.200"
      >
        —
      </Box>
      <Box
        as="td"
        width="fit-content"
        style={{
          verticalAlign: 'top',
        }}
      >
        {row.description}
      </Box>
    </tr>
  ))

  return (
    <>
      <Heading level={4} as="p" fontWeight={500} style={{ margin: 0 }}>
        {title}
      </Heading>
      <StyledTable
        style={{ borderSpacing: `0 ${space[3]}`, tableLayout: 'auto' }}
      >
        <colgroup>
          <col />
          <col span={1} width={40} />
          <col />
        </colgroup>
        <tbody>{rows}</tbody>
      </StyledTable>
    </>
  )
}
