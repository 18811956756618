import React from 'react'
import { Button, Text } from '@workwhile/ui'
import { Link } from 'react-router-dom'
import { ChevronRight } from 'lucide-react'

export const GoToCompanyDetail = ({ companyId }: { companyId: string }) => {
  return (
    <Button
      as={Link}
      to={`/companies/${companyId}`}
      variant="primary"
      kind="medium"
      block
    >
      <Text color="white">
        View <ChevronRight size={16} />
      </Text>
    </Button>
  )
}
