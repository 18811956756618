import React from 'react'
import { Text } from '@workwhile/ui'
import { replaceAliases } from './utils/aliased_terms'

const Highlight = ({ text = '', match }) => {
  // Don't highlight until at least 2 characters
  if (text?.length < 2 || match?.length < 2) {
    return text
  }

  function highlightTerms(text, term) {
    if (!term) {
      return text
    }
    const normalizedTerm = term?.replace(/[-_ ]/g, '\\s*');
    const regex = new RegExp(`(${normalizedTerm})`, 'gi');
    return text.replace(regex, '<span style="background-color: lemonChiffon;">$1</span>');
  }

  let result = highlightTerms(text, match)
  result = highlightTerms(result, replaceAliases(match))

  return <Text as="span" dangerouslySetInnerHTML={{__html: result}} />
}

export default Highlight
