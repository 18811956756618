import React, { useEffect, useMemo, useState } from 'react'
import { Box, Text, Heading } from '@workwhile/ui'
import { RequirementTable } from './RequirementTable'
import { useCurrentRequirements } from './useCurrentRequirements'
import { PositionTemplate } from 'api/company'
import { usePositionEditorContext } from '../PositionEditorContext'

export function RequirementsCompare({
  selectedPositionTemplate,
  onRequirementsChange,
}: {
  selectedPositionTemplate: PositionTemplate
  onRequirementsChange: (ids: string[]) => void
}) {
  const { positionData } = usePositionEditorContext()
  const currentRequirements = useCurrentRequirements(positionData)
  const newRequirements = useMemo(() => {
    return (
      selectedPositionTemplate.requirements?.map((r) => ({
        ...r.requirement,
        isMustHave: false,
        isPreSelected: r.isPreSelected,
        isWorkWhileRequired: r.isWorkwhileRequired,
      })) ?? []
    )
  }, [selectedPositionTemplate])
  const additionalRequirements = newRequirements.filter(
    (nr) => !currentRequirements.some((r) => r.id === nr.id)
  )
  const [selectedIds, setSelectedIds] = useState([
    ...currentRequirements.map((r) => r.id.toString()),
    ...additionalRequirements
      .filter((r) => r.isPreSelected || r.isWorkWhileRequired)
      .map((r) => r.id.toString()),
  ])

  useEffect(() => {
    onRequirementsChange(selectedIds)
  }, [selectedIds])

  return (
    <Box>
      <Heading level={2}>Review Requirements</Heading>
      <Text variant={'subtitle'}>
        Review the requirements for this position and compare them to the
        requirements of the template you are applying.
      </Text>
      <Box>
        <Text my={3} fontWeight={'bold'}>
          Current Requirements
        </Text>
        <RequirementTable
          requirements={currentRequirements}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
      </Box>
      <Box>
        <Text my={3} fontWeight={'bold'}>
          Additional New Requirements
        </Text>
        <RequirementTable
          requirements={additionalRequirements}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          includeMustHave={false}
        />
      </Box>
    </Box>
  )
}
