type Address = {
  street?: string
  unit?: string
  zip?: string
  city?: string
  state?: string
}

export const getAddressString = (address: Address) => {
  const { street, city, state, zip } = address

  return [street, city, `${state} ${zip}`].filter(Boolean).join(', ')
}
