import React, { useState } from 'react'
import {
  Card,
  Heading,
  Flex,
  Box,
  Button,
  Text,
  Modal,
  toast,
} from '@workwhile/ui'
import styled from 'styled-components'
import { I9Submission, ResetNote } from 'api/typings'
import { UserIcon } from 'lucide-react'
import { format, parseISO } from 'date-fns'
import {
  useApproveI9SubmissionMutation,
  useResetI9SubmissionMutation,
} from 'queries/i9/useI9SubmissionsMutation'
import { I9DocumentPreview } from './I9DocumentPreview'
import { ResetSubmissionModal } from './ResetSubmissionModal'

interface ReviewProps {
  submission: I9Submission
}

export function Review({ submission }: ReviewProps) {
  const [showFallback, setShowFallback] = useState(false)
  const [isResetModalOpen, setIsResetModalOpen] = useState(false)
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const approveMutation = useApproveI9SubmissionMutation()
  const resetMutation = useResetI9SubmissionMutation()

  const handleApprove = async () => {
    try {
      await approveMutation.mutateAsync(submission.userId)
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`Failed to approve: ${error.message}`)
      }
    }
  }

  const handleReset = async (reason: string) => {
    try {
      await resetMutation.mutateAsync({ userId: submission.userId, reason })
      setIsResetModalOpen(false)
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`Failed to reset: ${error.message}`)
      }
    }
  }

  // Add an early return if submission becomes null/undefined
  if (!submission) {
    return null
  }

  return (
    <Card mt={3}>
      <Flex mb={4}>
        <Box
          width={200}
          height={200}
          mr={4}
          backgroundColor={'offWhite'}
          style={{
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {showFallback ? (
            <UserIcon size={100} style={{ opacity: 0.5 }} />
          ) : (
            <img
              src={submission.profilePicUrl || ''}
              onError={() => setShowFallback(true)}
              alt="Profile"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '8px',
              }}
            />
          )}
        </Box>
        <Box>
          <Heading level={3} mb={3}>
            W2 Documents Review
          </Heading>
          <Flex mb={2}>
            <Label>Name:</Label>
            <Text>
              {submission.firstName} {submission.lastName}
            </Text>
          </Flex>
          <Flex mb={2}>
            <Label>Status:</Label>
            <StatusText status={submission.status}>
              {submission.status || 'active'}
            </StatusText>
          </Flex>
          {submission.shadowBlocked !== undefined && (
            <Flex mb={2}>
              <Label>Shadow Blocked:</Label>
              <Text color={submission.shadowBlocked ? 'error' : 'success'}>
                {submission.shadowBlocked ? 'Yes' : 'No'}
                {submission.shadowBlockedReason
                  ? ` - ${submission.shadowBlockedReason}`
                  : null}
              </Text>
            </Flex>
          )}
          <Flex mb={2}>
            <Label>Date of Birth:</Label>
            <Text>
              {submission.dob && !isNaN(Date.parse(submission.dob))
                ? format(parseISO(submission.dob), 'MMM d, yyyy')
                : 'N/A'}
            </Text>
          </Flex>
          <Flex mb={2}>
            <Label>Market:</Label>
            <Text>{submission.market || 'N/A'}</Text>
          </Flex>
          <Flex mb={2}>
            <Label>State:</Label>
            <Text>{submission.w2State || 'N/A'}</Text>
          </Flex>

          {(submission.resetNotes as ResetNote[])?.length > 0 && (
            <Flex mb={2}>
              <Button variant="text" onClick={() => setIsNotesModalOpen(true)}>
                View Reset Notes (
                {(submission.resetNotes as ResetNote[]).length})
              </Button>
            </Flex>
          )}
        </Box>
      </Flex>

      <Box mb={4}>
        <Heading level={4} mb={3}>
          Documents
        </Heading>
        <I9DocumentPreview
          urls={submission.documents.map((doc) => doc.documentUrl)}
        />
      </Box>

      <Flex justifyContent="flex-end">
        <Button
          variant="secondary"
          mr={3}
          onClick={() => setIsResetModalOpen(true)}
        >
          Reset
        </Button>
        <Button variant="primary" onClick={handleApprove}>
          Approve
        </Button>
      </Flex>

      <ResetSubmissionModal
        open={isResetModalOpen}
        onClose={() => setIsResetModalOpen(false)}
        onSubmit={handleReset}
        loading={resetMutation.isPending}
      />

      <Modal
        open={isNotesModalOpen}
        onClose={() => setIsNotesModalOpen(false)}
        title="Reset Notes History"
      >
        <Box p={3}>
          {(submission.resetNotes as ResetNote[])?.map((note, index) => (
            <Box key={index} mb={3}>
              <Text fontWeight="bold">
                {format(parseISO(note.createdAt), 'MMM d, yyyy h:mm a')}
              </Text>
              <Text mt={1}>{note.notes}</Text>
            </Box>
          ))}
        </Box>
      </Modal>
    </Card>
  )
}

const StatusText = styled(Text)<{ status?: string }>`
  color: ${({ status, theme }) => {
    switch (status?.toLowerCase()) {
      case 'suspended':
        return theme.colors.warning
      case 'blocked':
        return theme.colors.error
      case 'active':
        return theme.colors.success
      default:
        return theme.colors.text
    }
  }};
  text-transform: capitalize;
`

const Label = styled(Box).attrs({
  fontWeight: 'bold',
  width: 120,
})``
