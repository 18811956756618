import React from 'react'
import { Flex, Text, Heading } from '@workwhile/ui'
import { AccordionCard } from 'components/AccordionCard'

export const WorkerSupply = () => {
  return (
    <AccordionCard
      title={
        <Flex flexDirection="column">
          <Text fontSize={2} color="lightText">
            Statistics
          </Text>
          <Heading
            level={2}
            fontWeight="400"
            style={{
              margin: 0,
            }}
          >
            Worker Supply
          </Heading>
        </Flex>
      }
      size="small"
    >
      <Text color="lightText" height="100%">
        Coming soon!
      </Text>
    </AccordionCard>
  )
}
