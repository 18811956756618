import React from 'react'
import api from '../../api'
import { Select } from '@workwhile/ui'

const INITIAL_STATE = {
  description: null,
  itemType: null,
  unitAmount: null,
  quantity: null,
}

const CHARGE_OPTIONS = [
              { value: 'shift', label: 'shift' },
              { value: 'reimbursement', label: 'reimbursement' },
              { value: 'bonus', label: 'bonus' },
              { value: 'temp_to_hire', label: 'temp_to_hire' },
              { value: 'discount', label: 'discount' },
              { value: 'misc', label: 'misc' }
            ]

class PaymentChargeItem extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.state = { ...INITIAL_STATE }
  }

  onSubmit = (event) => {
    event.preventDefault()
    const { description, itemType, unitAmount, quantity, userId } = this.state
    const { chargeId } = this.props

    if (!description || !itemType || !unitAmount || !quantity) {
      return
    }

    let data = {
      description: description,
      item_type: itemType,
      quantity: quantity,
      unit_amount: unitAmount,
    }
    if (userId) {
      data.user_id = userId
    }
    api
      .post(`/admin/charges/${chargeId}/items`, data)
      .then((response) => {
        console.log(response)
        alert('item added')
        this.setState({
          description: '',
          itemType: null,
          unitAmount: '',
          quantity: '',
          userId: '',
        })
      })
      .catch((err) => {
        console.log('ERROR OCCURED: ', err)
      })
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSelect = (selectedOption) => {
    this.setState({ itemType: selectedOption.value })
  }

  render() {
    const { description, itemType, unitAmount, quantity, userId, error } =
      this.state

    console.log('values : ', description, quantity)
    const isInvalid = description === '' || quantity === ''
    return (
      <div>
        <h5>Add charge item</h5>
        <form onSubmit={this.onSubmit} className="d-flex flex-row">
          <input
            className="input-small"
            name="description"
            value={description}
            onChange={this.onChange}
            type="text"
            placeholder="Description"
          />
          <Select
            value={itemType ? CHARGE_OPTIONS.find(option => option.value === itemType) : null}
            onChange={this.handleSelect}
            options={CHARGE_OPTIONS}
            placeholder="Item type"
          />
          <input
            className="input-small"
            name="quantity"
            value={quantity}
            onChange={this.onChange}
            type="text"
            placeholder="Quantity"
          />
          <input
            className="input-small"
            name="unitAmount"
            value={unitAmount}
            onChange={this.onChange}
            type="text"
            placeholder="Unit amount"
          />
          <input
            className="input-small"
            name="userId"
            value={userId}
            onChange={this.onChange}
            type="text"
            placeholder="User id (optional)"
          />
          <button disabled={isInvalid} type="submit" className="ml-2">
            Add
          </button>

          {error && <p>{error.message}</p>}
        </form>
      </div>
    )
  }
}

export default PaymentChargeItem
