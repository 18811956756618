import { createContext } from 'lib/createContext'
import React, { PropsWithChildren, useState } from 'react'

type Workflow = {
  open: () => void
  close: () => void
  isOpen: boolean
}

export type CompaniesWorkflowsContextState = {
  createCompanyFlow: Workflow
}

const [CompaniesWorkflowsContext, useCompaniesWorkflowsContextValue] =
  createContext<CompaniesWorkflowsContextState>({
    name: 'CompaniesWorkflowsContext',
  })

export const CompaniesWorkflowsProvider = ({ children }: PropsWithChildren) => {
  const [isCreateCompanyFlowOpen, setIsCreateCompanyFlowOpen] = useState(false)

  const value: CompaniesWorkflowsContextState = {
    createCompanyFlow: {
      open: () => setIsCreateCompanyFlowOpen(true),
      close: () => setIsCreateCompanyFlowOpen(false),
      isOpen: isCreateCompanyFlowOpen,
    },
  }

  return (
    <CompaniesWorkflowsContext.Provider value={value}>
      {children}
    </CompaniesWorkflowsContext.Provider>
  )
}

export { useCompaniesWorkflowsContextValue }
