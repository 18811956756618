import * as z from 'zod'

const MAX_FILE_SIZE = 5 * 1024 * 1024 // 5MB

const VALID_FILE_TYPES = ['image/jpeg', 'image/png'] as const
export const manageLogoFlowSchema = z.object({
  files: z
    .array(
      z.object({
        id: z.string().optional(),
        file: z.instanceof(File).optional(),
        fileExtension: z.string().optional(),
        fileSize: z.number().optional(),
        fileType: z.string().optional(),
        filename: z.string().optional(),
        filenameWithoutExtension: z.string().optional(),
      }),
      {
        message:
          'Please upload a valid file in .jpg, .jpeg, or .png format to save the new logo',
      }
    )
    .min(
      1,
      'Please upload a valid file in .jpg, .jpeg, or .png format to save the new logo'
    )
    .max(1, 'Please upload only one file')
    .superRefine((data, ctx) => {
      const [file] = data
      if (
        !file ||
        !file.file ||
        !VALID_FILE_TYPES.includes(
          file.fileType as (typeof VALID_FILE_TYPES)[number]
        )
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'Please upload a valid file in .jpg, .jpeg, or .png format to save the new logo',
        })
        return false
      }

      if (file.fileSize > MAX_FILE_SIZE) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please upload a file that is less than 5MB',
        })
        return false
      }

      return true
    }),
})
