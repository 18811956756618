import React from 'react'
import { Box, Flex } from '@workwhile/ui'
import { TierListingViews } from './TierListingViews'
import { WorkerSupply } from './WorkerSupply'
import { WorkerNotInterestedReasons } from './WorkerNotInterestedReasons'
import { IneligibilityReasons } from './IneligibilityReasons'

export const ExploreTier = () => {
  return (
    <Box>
      <Flex gap={3} flexWrap="wrap">
        <TierListingViews />
        <WorkerSupply />
        <WorkerNotInterestedReasons />
        <IneligibilityReasons />
      </Flex>
    </Box>
  )
}
