import { useQueryStates, parseAsBoolean } from 'nuqs'

export const useQueryBasedFeatureFlag = () => {
  const [value] = useQueryStates(
    {
      totalListingViews: parseAsBoolean.withDefault(false),
      tierExploration: parseAsBoolean.withDefault(false),
    },
    {
      urlKeys: {
        totalListingViews: 'total-listing-views',
        tierExploration: 'tier-exploration',
      },
    }
  )

  return value
}
