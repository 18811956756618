import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createCompany } from 'api/companies'
import { companiesKey } from './keys'

export const useCreateCompanyMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createCompany,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: companiesKey }),
  })
}
