import React from 'react'
import { Box, Text } from '@workwhile/ui'
import { usePositionTemplateContext } from './PositionTemplateContext'
import { TemplateItem } from './TemplateItem'

export function TemplateList() {
  const { visibleTemplates } = usePositionTemplateContext()

  return (
    <Box>
      {visibleTemplates.map((template) => (
        <TemplateItem key={template.id} template={template} />
      ))}
      {visibleTemplates.length === 0 ? (
        <Text textAlign={'center'} color={'lightText'} p={4}>
          No matching positions found
        </Text>
      ) : null}
    </Box>
  )
}
