import React from 'react'
import { AddMinimumPayPolicyFlowProvider } from './context/AddMinimumPayPolicyFlowProvider'
import { AddMinimumPayPolicyFlowMain } from './components/AddMinimumPayPolicyFlowMain'

export const AddMinimumPayPolicyFlow = () => {
  return (
    <AddMinimumPayPolicyFlowProvider>
      <AddMinimumPayPolicyFlowMain />
    </AddMinimumPayPolicyFlowProvider>
  )
}
