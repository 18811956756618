import api from 'api'
import { TierEligibilityStats } from 'pages/Shifts/ShiftDetail/types'

export const getTierEligibilitiesByShiftId = async (
  shiftId: number
): Promise<TierEligibilityStats> => {
  const response = await api.get<TierEligibilityStats>(
    `/admin/shift/${shiftId}/tier_stats`
  )

  return response.data
}
