import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { FiltersSchema } from '../filtersSchema'
import { Field, Flex, Input } from '@workwhile/ui'

export const ScheduleRateFilter = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FiltersSchema>()

  return (
    <Flex flexDirection="column" width="240px">
      <Controller
        control={control}
        name="shift.scheduleRateMin"
        render={({ field }) => (
          <Field
            label={'Minimum %'}
            error={errors.shift?.scheduleRateMin?.message?.toString()}
          >
            <Input
              aria-label={'Minimum schedule rate %'}
              placeholder={`e.g. 0%`}
              type="number"
              value={field.value ?? ''}
              onChange={(e) => {
                const value = e.target.value
                field.onChange(value === '' ? null : Number(value))
              }}
            />
          </Field>
        )}
      />
      <Controller
        control={control}
        name="shift.scheduleRateMax"
        render={({ field }) => (
          <Field
            label={'Maximum %'}
            error={errors.shift?.scheduleRateMax?.message?.toString()}
          >
            <Input
              aria-label={'Maximum schedule rate %'}
              placeholder={`e.g. 89%`}
              type="number"
              value={field.value ?? ''}
              onChange={(e) => {
                const value = e.target.value
                field.onChange(value === '' ? null : Number(value))
              }}
            />
          </Field>
        )}
      />
    </Flex>
  )
}
