import React, { useId, useMemo } from 'react'
import {
  useFieldArray,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form'
import { Button, Flex, useAppTheme } from '@workwhile/ui'
import { CircleMinus } from 'lucide-react'
import { AddCompanySchema } from '../schema/addCompany'
import { prop, indexBy } from 'ramda'
import { useAddCompaniesFlowContextValue } from '../context/AddCompaniesFlowProvider'
import { Companies } from 'pages/Companies/types'
const AddedCompanyRow = ({
  remove,
  index,
  id,
  foundCompany,
  isLast,
}: {
  remove: UseFieldArrayRemove
  index: number
  id: number
  foundCompany?: Companies[number]
  isLast: boolean
}) => {
  const { colors, radii, space } = useAppTheme()
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={space[2]}
      mb={isLast ? 0 : space[2]}
    >
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        border="1px solid"
        borderColor={colors.neutrals[100]}
        p={2}
        borderRadius={radii.small}
        width="100%"
        position="relative"
      >
        {foundCompany
          ? `${foundCompany.name} (${id})`
          : `Unknown company (${id})`}
      </Flex>
      <Button
        onClick={() => remove(index)}
        variant="text"
        color="red"
        size="small"
        danger
        style={{
          margin: 0,
          height: '1.6rem', // This is to resolve a bug in our UI library where the width and height is being set to `small` (literally) instead of a `px` value
        }}
      >
        <CircleMinus size={16} />
      </Button>
    </Flex>
  )
}

export const AddedCompanies = () => {
  const baseId = useId()
  const { watch, control } = useFormContext<AddCompanySchema>()

  const { remove } = useFieldArray({
    name: 'companiesToAdd',
    control,
  })

  const {
    state: {
      allCompaniesQueryState: { data: companyListData },
    },
  } = useAddCompaniesFlowContextValue()

  const companyMap = useMemo(() => {
    if (!companyListData) return {}
    const groupedCompanies = indexBy(prop('id'), companyListData)
    return groupedCompanies
  }, [companyListData])

  const companies = watch('companiesToAdd')

  const addedCompanyRows = companies.map((company, index) => {
    const foundCompany = company.id ? companyMap[company.id] : undefined
    return (
      <AddedCompanyRow
        key={`added-companies-${baseId}-${company.id}-${index}`}
        remove={remove}
        index={index}
        id={company.id}
        foundCompany={foundCompany}
        isLast={index === companies.length - 1}
      />
    )
  })

  return <>{addedCompanyRows}</>
}
