import React, { useEffect, useState } from 'react'
import { useInterval } from 'ahooks'
import { formatDistanceToNow } from 'date-fns'
import { Text } from '@workwhile/ui'

const formatLastUpdated = (date: Date) => {
  const lastUpdatedAgo = formatDistanceToNow(date, {
    addSuffix: true,
  })

  return lastUpdatedAgo
}

const useQueryStatusLastUpdated = (dataUpdatedAt: number) => {
  const dataUpdatedAtDate = new Date(dataUpdatedAt)

  const [lastUpdated, setLastUpdated] = useState<string>(() => {
    return formatLastUpdated(dataUpdatedAtDate)
  })

  const clearInterval = useInterval(() => {
    setLastUpdated(formatLastUpdated(dataUpdatedAtDate))
  }, 60000) // 1 minute

  useEffect(() => clearInterval, [clearInterval])

  return lastUpdated
}

type SuccessProps = {
  dataUpdatedAt: number
}

export const Success = ({ dataUpdatedAt }: SuccessProps) => {
  const lastUpdatedAgo = useQueryStatusLastUpdated(dataUpdatedAt)

  return (
    <Text color="lightText" fontSize={1}>
      Last updated {lastUpdatedAgo}
    </Text>
  )
}
