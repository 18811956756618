import { useMemo } from 'react'
import { useShiftsContextValue } from 'pages/Shifts/Shifts/context/ShiftsProvider'
import { Shifts } from 'pages/Shifts/Shifts/types'
import { ColumnDef } from '@tanstack/react-table'
import { IDColumn, DetailColumn, selectableColumns } from './columns'

export const useSelectedColumns = (): ColumnDef<Shifts[number]>[] => {
  const {
    state: { selectedColumnsFilters },
  } = useShiftsContextValue()

  const columnsToDisplay = useMemo(() => {
    const filteredColumns = selectableColumns
      .filter((column) => selectedColumnsFilters.includes(column.id))
      .map((column) => column.columnDef)

    return [IDColumn, ...filteredColumns, DetailColumn]
  }, [selectedColumnsFilters])

  return columnsToDisplay
}
