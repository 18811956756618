import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { createContext } from 'lib/createContext'
import { Loading, Message } from '@workwhile/ui'
import { parseErrorMessage } from 'api'
import { useCompaniesQuery } from 'queries/companies/useCompaniesQuery'
import { Companies } from '../types'
import { FullOptions, MatchData, Searcher } from 'fast-fuzzy'
import {
  useQueryStates,
  parseAsInteger,
  parseAsString,
  useQueryState,
} from 'nuqs'

type CompaniesContextState = {
  state: {
    searchInput: string
    foundClosestAutocompleteMatch: string
    alternateKeywords: string[]
    filteredData: Companies
    dataUpdatedAt: number
    isFetching: boolean
    isError: boolean
    error: string
    pagination: {
      pageIndex: number
      pageSize: number
    }
  }
  actions: {
    refetch: () => void
    setSearchInput: (searchInput: string) => void
    setPagination: Dispatch<
      SetStateAction<CompaniesContextState['state']['pagination']>
    >
  }
}

const [Context, useCompaniesContextValue] =
  createContext<CompaniesContextState>({
    name: 'CompaniesContext',
  })

const fuzzyOptions: FullOptions<Companies[number]> = {
  threshold: 0.7,
  ignoreCase: true,
  ignoreSymbols: true,
  normalizeWhitespace: true,
  returnMatchData: true,
  keySelector: (obj) => [obj.name, obj.id.toString()],
}

const useFuzzySearch = (data: Companies = []) => {
  const searcher = useMemo(() => {
    return new Searcher<Companies[number], FullOptions<Companies[number]>>(
      data,
      fuzzyOptions
    )
  }, [data])

  const fuzzySearch = useCallback(
    (
      query: string
    ): {
      foundClosestAutocompleteMatch: string
      alternateKeywords: string[]
      results: Companies
    } => {
      if (!query) {
        return {
          foundClosestAutocompleteMatch: '',
          alternateKeywords: [],
          results: data,
        }
      }

      // We add explict type assertion here because `searcher.search` does not seem to pick up the type from the `fuzzyOptions` when passed in as a variable
      const results = searcher.search(
        query,
        fuzzyOptions
      ) as unknown as MatchData<Companies[number]>[]

      if (results.length > 0) {
        const bestMatch = results[0]

        if (bestMatch.item.name.toLowerCase() !== query.toLowerCase()) {
          return {
            foundClosestAutocompleteMatch: bestMatch.item.name,
            alternateKeywords: [],
            results: results.map((i) => i.item),
          }
        }
      }

      const alternateKeywords = searcher.search(query, {
        ...fuzzyOptions,
        threshold: 0.5,
        useSellers: false,
      }) as unknown as MatchData<Companies[number]>[]

      const uniqueAlternateKeywords = new Set(
        alternateKeywords.map((i) => i.item.name)
      )

      const cappedAlternateKeywords = Array.from(uniqueAlternateKeywords).slice(
        0,
        3
      )

      return {
        foundClosestAutocompleteMatch: '',
        alternateKeywords: cappedAlternateKeywords,
        results: results.map((i) => i.item),
      }
    },
    [searcher, data]
  )

  return { fuzzySearch }
}

export const CompaniesProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [searchInput, setSearchInput] = useQueryState(
    'search',
    parseAsString.withDefault('')
  )
  const [pagination, setPagination] = useQueryStates(
    {
      pageIndex: parseAsInteger.withDefault(0),
      pageSize: parseAsInteger.withDefault(10),
    },
    {
      urlKeys: {
        pageIndex: 'page',
        pageSize: 'page-size',
      },
    }
  )

  const {
    data,
    isLoading,
    isError,
    error,
    dataUpdatedAt,
    refetch,
    isFetching,
  } = useCompaniesQuery()

  const { fuzzySearch } = useFuzzySearch(data)

  const trimmedSearchInput = searchInput.trim()

  const {
    foundClosestAutocompleteMatch,
    alternateKeywords,
    results: filteredData,
  } = useMemo(() => {
    return fuzzySearch(trimmedSearchInput)
  }, [trimmedSearchInput, fuzzySearch, data])

  if (isLoading) {
    return <Loading />
  }

  if (isError) {
    // eslint-disable-next-line no-console
    console.error(error)
    return (
      <Message variant="error">
        Sorry, something went wrong. Please reach out to eng support if this
        persist
      </Message>
    )
  }

  const value: CompaniesContextState = {
    state: {
      searchInput,
      alternateKeywords,
      foundClosestAutocompleteMatch,
      filteredData,
      dataUpdatedAt,
      isFetching,
      isError,
      error: parseErrorMessage(error),
      pagination,
    },
    actions: { refetch, setSearchInput, setPagination },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useCompaniesContextValue }
