import api from 'api'

export function uploadProfilePic(
  userId: string,
  file: File,
  manually_verify = false
) {
  const formData = new FormData()
  formData.append('file', file, 'image.jpg')
  formData.append('manually_verify', manually_verify.toString())
  return api.put(`/admin/user/${userId}/profile_pic`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
