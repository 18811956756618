import * as zod from 'zod'

export const addMinimumPayPolicySchema = zod.object({
  startsAt: zod.date({
    required_error: 'Start date is required',
  }),
  minPayLengthInMinutes: zod.coerce
    .number({
      required_error: 'Minimum pay length is required',
    })
    .min(240, {
      message: 'Minimum pay length must be at least 4 hours (240 minutes)',
    }),
})

export type AddMinimumPayPolicySchema = zod.infer<
  typeof addMinimumPayPolicySchema
>
