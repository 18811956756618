import React from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Message,
  Modal,
  toast,
  Text,
  Field,
  Input,
} from '@workwhile/ui'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAssignShiftFlowContextValue } from '../context/AssignShiftFlowProvider'
import { useShiftDetailWorkflowsContextValue } from 'pages/Shifts/ShiftDetail/context/ShiftDetailWorkflowsProvider'
import {
  assignShiftFlowSchema,
  AssignShiftFlowSchema,
} from '../schema/assignShift'
import { AddedWorkers } from './AddedWorkers'

export const AssignShiftFlowMain = () => {
  const {
    assignShiftFlow: { isOpen, close },
  } = useShiftDetailWorkflowsContextValue()

  const {
    state: {
      shiftId,
      formData,
      mutationState: { isPending, errorMessage: mutationErrorMessage },
    },
    actions: { submitFormToServer },
  } = useAssignShiftFlowContextValue()

  const methods = useForm<AssignShiftFlowSchema>({
    resolver: zodResolver(assignShiftFlowSchema),
    defaultValues: formData,
  })

  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
    trigger,
    getValues,
    setValue,
  } = methods

  const errorMessage = mutationErrorMessage || errors.workersToAssign?.message

  const onSubmit = handleSubmit((data) => {
    const workerIds = data.workersToAssign.map((worker) => worker.id)
    submitFormToServer(
      {
        shiftId,
        userIds: workerIds,
      },
      {
        onSuccess: () => {
          toast.success('Assigned workers to this shift successfully!', {
            position: 'top-center',
          })
          reset({
            workersToAssign: [],
            workerIdInput: undefined,
          })
          close()
        },
      }
    )
  })

  const { append } = useFieldArray({
    name: 'workersToAssign',
    control: methods.control,
  })

  return (
    <Modal
      open={isOpen}
      onClose={close}
      position="top"
      loading={isPending}
      showCancel
      customCta={
        <Flex>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Assign
          </Button>
        </Flex>
      }
    >
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Heading level={3} fontWeight="400" style={{ margin: 0 }}>
          Assign shift
        </Heading>
        <Text mt={3} fontSize={2} mb={1}>
          Shift
        </Text>
        <Text fontSize={2} color="lightText" mb={3}>
          {shiftId}
        </Text>

        <Field label="Worker ID" error={errors.workerIdInput?.message}>
          <Flex alignItems="center" gap={2} width="100%">
            <Input
              placeholder={`e.g. 1272`}
              block
              {...register('workerIdInput')}
              type="number"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              mb={2}
            />
            <Button
              variant="primary"
              onClick={async () => {
                const isInputValid = await trigger('workerIdInput')
                const workerId = Number(getValues('workerIdInput'))
                const addedWorkers = getValues('workersToAssign')
                const doesInputExistInCurrentGroup = addedWorkers.some(
                  (worker) => worker.id === workerId
                )

                const isValid = isInputValid && !doesInputExistInCurrentGroup
                if (isValid) {
                  append({ id: Number(workerId) })
                  setValue('workerIdInput', undefined)
                }

                if (doesInputExistInCurrentGroup) {
                  setValue('workerIdInput', undefined)
                }
              }}
            >
              Add
            </Button>
          </Flex>
        </Field>

        <FormProvider {...methods}>
          <AddedWorkers />
        </FormProvider>

        {errorMessage ? (
          <Message variant="error" mt={3} borderRadius="small">
            {errorMessage}
          </Message>
        ) : null}
      </Box>
    </Modal>
  )
}
