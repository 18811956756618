import { AttributesSelector, Checkbox, Field } from '@workwhile/ui'
import React, { useMemo } from 'react'
import { useCompanyDetailContextValue } from '../../../../context/CompanyDetailProvider'
import { selectableColumns } from './columns'

export const ColumnsFilter = () => {
  const {
    state: {
      shifts: { selectedColumnsFilters },
    },
    actions: {
      shifts: { setSelectedColumnsFilters },
    },
  } = useCompanyDetailContextValue()

  const selectedShiftAttributes = useMemo(() => {
    const selectedColumnsSet = new Set(selectedColumnsFilters)
    return selectableColumns
      .map((column) => {
        return selectedColumnsSet.has(column.id) ? column.label : null
      })
      .filter(Boolean)
  }, [selectedColumnsFilters])

  const selectableColumnsCheckboxes = useMemo(() => {
    return selectableColumns.map((column) => {
      const isSelected = selectedColumnsFilters.includes(column.id)
      return (
        <Field key={column.id}>
          <Checkbox
            value={isSelected}
            onChange={() => {
              setSelectedColumnsFilters((prev) => {
                if (prev.includes(column.id)) {
                  return prev.filter((c) => c !== column.id)
                }
                return [...prev, column.id]
              })
            }}
          >
            <span>{column.label}</span>
          </Checkbox>
        </Field>
      )
    })
  }, [selectedColumnsFilters])

  return (
    <AttributesSelector
      label="Columns"
      selectedAttributes={selectedShiftAttributes}
      onClearAll={() => {
        setSelectedColumnsFilters([])
      }}
      selectedAttributeLimit={0}
      groups={[
        <AttributesSelector.Group
          key="shift-ids"
          title="Shift IDs"
          numberOfSelectedAttributes={selectedShiftAttributes.length}
        >
          {selectableColumnsCheckboxes}
        </AttributesSelector.Group>,
      ]}
    />
  )
}
