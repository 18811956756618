import React from 'react'
import * as Popover from '@radix-ui/react-popover'
import {
  Box,
  Button,
  Card,
  CardProps,
  Flex,
  Heading,
  Text,
  useAppTheme,
} from '@workwhile/ui'
import { X } from 'lucide-react'

type ReferencePopoverProps = {
  trigger: React.ReactNode
  subTitle: string
  title: string
  contentProps: CardProps & { children: React.ReactNode }
  showCloseButton?: boolean
}
export const ReferencePopover = ({
  trigger,
  subTitle,
  title,
  contentProps: { children, ...restCardProps },
  showCloseButton = true,
}: ReferencePopoverProps) => {
  const { space } = useAppTheme()
  return (
    <Popover.Root>
      <Popover.Trigger asChild>{trigger}</Popover.Trigger>
      <Popover.Content
        side="right"
        align="start"
        sideOffset={5}
        asChild
        style={{ zIndex: 1 }}
      >
        <Card {...restCardProps} borderColor="neutrals.100">
          {showCloseButton ? (
            <Popover.Close
              asChild
              style={{ position: 'absolute', top: space[1], right: space[1] }}
            >
              <Button
                variant="text"
                p={1}
                style={{
                  height: 'auto',
                  padding: space[1],
                }}
              >
                <X size={18} />
              </Button>
            </Popover.Close>
          ) : null}
          {title || subTitle ? (
            <Flex flexDirection="column" gap={space[1]}>
              <Text as="p" color="lightText">
                {subTitle}
              </Text>
              <Heading
                level={3}
                as="p"
                fontWeight={400}
                style={{ margin: 0, marginBottom: space[3] }}
                color="neutrals.400"
              >
                {title}
              </Heading>
            </Flex>
          ) : null}
          {children}
        </Card>
      </Popover.Content>
    </Popover.Root>
  )
}
