import React from 'react'
import { Box, Button, DropdownMenu, Flex } from '@workwhile/ui'
import { GeneralDetails } from './components/GeneralDetails'
import { Companies } from './components/Companies'
import { useCompanyGroupDetailContextValue } from './context/CompanyGroupDetailProvider'
import { Header } from 'components/Header'
import { QueryStatus } from 'components/QueryStatus'
import { Ellipsis, Plus } from 'lucide-react'
import { useCompanyGroupDetailWorkflowsContextValue } from './context/CompanyGroupDetailWorkflowProvider'

const CompanyGroupDetailHeader = () => {
  const { state, actions } = useCompanyGroupDetailContextValue()
  const { addCompaniesToGroupFlow } =
    useCompanyGroupDetailWorkflowsContextValue()
  return (
    <Header
      title={`${state.name} (${state.id})`}
      subTitle="Company Group Detail"
      rightContent={
        <Flex alignItems="center" gap={2}>
          <QueryStatus
            dataUpdatedAt={state.dataUpdatedAt}
            isFetching={state.isFetching}
            refetch={actions.refetch}
            isError={state.isError}
            error={state.error}
          />
          <DropdownMenu
            trigger={
              <Button variant="secondary" style={{ padding: 4 }} kind="medium">
                <Ellipsis />
              </Button>
            }
          >
            <DropdownMenu.Item onClick={addCompaniesToGroupFlow.open} asChild>
              <Flex as="span" alignItems="center" style={{ gap: 8 }}>
                <Plus size={14} />
                Add Companies
              </Flex>
            </DropdownMenu.Item>
          </DropdownMenu>
        </Flex>
      }
    />
  )
}

export const CompanyGroupDetailMain = () => {
  return (
    <>
      <CompanyGroupDetailHeader />
      <Box margin={3}>
        <Flex gap={3}>
          <GeneralDetails />
        </Flex>
        <Flex gap={3} wrap="wrap" marginTop={3}>
          <Companies />
        </Flex>
      </Box>
    </>
  )
}
