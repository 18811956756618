import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addOrUpdateLogo } from 'api/company/addOrUpdateLogo'
import { companyDetailKey } from './keys'

export const useAddOrUpdateCompanyLogoMutation = (companyId: number) => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (file: File) => addOrUpdateLogo(companyId, file),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: companyDetailKey(companyId) }),
  })

  return mutation
}
