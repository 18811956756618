import { WorkShift } from 'api/typings'
import graphql from 'graphql'

type ShiftResult = { data: { admin: { shift: WorkShift } } }

export const getLegacyShiftById = (
  shiftId: WorkShift['id'] | string,
  query: string
) =>
  graphql<ShiftResult>(`
    admin {
        shift (shiftId: ${shiftId}) ${query}
    }`).then((result) => result.data.data.admin.shift)
