import React, { useEffect, useMemo, useState } from 'react'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import ShiftsFilterBar, {
  mapQueryToFilters,
  QueryParams,
} from './ShiftsFilterBar'
import { Link, useSearchParams } from 'react-router-dom'
import { Box, Button, Flex, Heading, Icon, Label, Text } from '@workwhile/ui'
import { useShifts } from 'queries/shifts'
import moment from 'moment'
import { CheckCircle, ChevronDown } from 'lucide-react'
import { WorkShift } from 'api/typings'
import { parseISO } from 'date-fns'
import { Shifts } from 'pages/Shifts/Shifts'

const PAGE_SIZE = 15

/** Parse tierTransitions string to object */
const jsonReadyString = (value: string): { [key: string]: string } =>
  value ? JSON.parse(value?.replace(/'/g, '"')) : ''

/** Calculate fill percent based on number of workers and cushions requested */
export const calculateFillPercent = (shift: WorkShift): number => {
  if (!shift) return null
  if (!shift?.numWorkersScheduled) return 0
  if (!shift.workersNeeded && !shift.numCushionWorkersNeeded) return 100

  return Math.floor(
    (shift.numWorkersScheduled /
      ((shift.workersNeeded || 0) + (shift.numCushionWorkersNeeded || 0))) *
      100
  )
}

/** Create an ordered list of group name and associated shifts */
type GroupBy = 'market' | 'date' | 'companyGroup'
/** Locale date format @example November 13, 2024 */
const GROUP_BY_DATE = 'LL'
const groupShiftsBy: Record<
  GroupBy,
  (shifts: WorkShift[], query: URLSearchParams) => [string, WorkShift[]][]
> = {
  /**
   * @returns [
   *    ['February 14, 2024', WorkShift[]],
   *    ['February 15, 2024', WorkShift[]],
   * ]
   */
  date: (shifts, query) => {
    const fromDate =
      query.get(QueryParams.fromDate) ||
      (query.get(QueryParams.range) === 'past'
        ? moment().subtract(2, 'day')
        : moment())
    const toDate =
      query.get(QueryParams.toDate) ||
      (query.get(QueryParams.range) === 'upcoming'
        ? moment().add(3, 'day')
        : moment())

    return Object.entries(
      shifts.reduce(
        (all, shift) => {
          const date = moment(shift.startsAt).format(GROUP_BY_DATE)

          return {
            ...all,
            [date]: (all[date] || [])?.concat(shift),
          }
        },
        /** Create a group for every day from the selected start date to the selected end date
         * in order to display "No results" feedback as needed for a given day in the range
         */
        new Array(
          Math.abs(
            moment(fromDate)
              .startOf('day')
              .diff(moment(toDate).add(1, 'day').startOf('day'), 'day')
          ) || 0
        )
          .fill(moment(fromDate).startOf('day'))
          .reduce(
            (dates, date, index) => ({
              ...dates,
              [moment(date).add(index, 'day').format(GROUP_BY_DATE)]: [],
            }),
            {}
          ) as Record<string, WorkShift[]>
      )
    ).sort(([prev], [next]) =>
      query.get(QueryParams.range) === 'past'
        ? // Sort desc
          moment(prev).isAfter(moment(next))
          ? -1
          : 1
        : // Sort asc
          moment(prev).isBefore(moment(next))
          ? -1
          : 1
    )
  },

  /**
   * @returns [
   *    ['LAX', WorkShift[]],
   *    ['NYC', WorkShift[]],
   * ]
   */
  market: (shifts, query) =>
    Object.entries(
      shifts.reduce(
        (all, shift) => {
          return {
            ...all,
            [shift.market.toUpperCase()]: (
              all[shift.market.toUpperCase()] || []
            ).concat(shift),
          }
        },
        {} as Record<string, WorkShift[]>
      )
    ).sort(([a], [b]) => (a < b ? -1 : 1)),
  /**
   * @returns [
   *    ['Jitsu', WorkShift[]],
   *    ['Veyer', WorkShift[]],
   * ]
   */
  companyGroup: (shifts, query) =>
    Object.entries(
      shifts.reduce(
        (all, shift) => {
          return {
            ...all,
            [shift.company.name.split(' - ')[0]]: (
              all[shift.company.name.split(' - ')[0]] || []
            ).concat(shift),
          }
        },
        {} as Record<string, WorkShift[]>
      )
    ).sort((a, b) => ([a] < [b] ? -1 : 1)),
}

/** Apply a red left border to indicate an unfilled shift */
export const getRowStyles = (row, rowIndex) => {
  let styles = {}

  if (!!row.cancelledAt) {
    styles['borderLeft'] = '3px solid gray'
  }

  if (row.cancelledAt == null && calculateFillPercent(row) < 100)
    styles['borderLeft'] = '3px solid red'

  return styles
}

const Timestamp = (shift: WorkShift) => {
  const [isRelative, setIsRelative] = useState(true)
  const listing = shift.listings?.[0]

  if (!listing) return null

  const tiers = jsonReadyString(listing.tierTransitions)

  if (shift.cancelledAt) return <Label variant="error">CANCELLED</Label>
  if (shift.listing?.freeze) return <Label variant="information">FROZEN</Label>

  return (
    <Box>
      <Text as="strong">{listing?.tier}</Text>
      <Text onClick={() => setIsRelative(!isRelative)}>
        {isRelative
          ? `Since ${moment(tiers[listing?.tier]).fromNow()}`
          : `Since ${moment(tiers[listing.tier]).format('lll')}`}
      </Text>
      <Text
        data-context-key="listing_id"
        data-context-value={listing.id}
        data-context-label={`${listing.freeze ? 'FROZEN ' : ''}Tier ${listing.tier} for Shift ${shift.id}`}
      >
        Listing ({listing.id})
      </Text>
    </Box>
  )
}

const ShiftSearch = () => {
  const [limit, setLimit] = useState(PAGE_SIZE)
  const [query] = useSearchParams()
  const filters = mapQueryToFilters(query)
  const shifts = useShifts('slim', filters)

  /** Reset limit when updating filters */
  useEffect(() => {
    setLimit(PAGE_SIZE)
  }, [query])

  const columns = useMemo(
    (): ColumnDescription<WorkShift>[] => [
      {
        style: { textAlign: 'center' },
        headerStyle: { width: '100px' },
        dataField: 'id',
        text: 'Shift ID',
        formatter: (cell, row) => (
          <Link
            data-context-key="shift_id"
            data-context-value={cell}
            data-context-label={parseISO(row.startsAt)}
            style={{ fontSize: '1.2em' }}
            to={`/shifts/legacy/${cell}`}
            state={{ search: filters }}
          >
            {cell}
          </Link>
        ),
      },
      {
        dataField: 'startsAt',
        text: 'Start time',
        sort: true,
        formatter: (cell, row) => {
          const timezone =
            row.location && row.location.address
              ? row.location.address.timezone
              : moment.tz.guess(true)
          return (
            <>
              <Text as="strong">{`${moment.tz(cell, timezone).format(query.get(QueryParams.groupBy) !== 'date' ? 'lll zz' : 'h:mma zz')}`}</Text>
              <Text>{`${moment(cell).isBefore(moment()) ? 'Started' : 'Starts'} ${moment(cell).fromNow()}`}</Text>
            </>
          )
        },
      },
      {
        dataField: 'company',
        text: 'Company / Assignment / Position',
        formatter: (cell, row) => {
          if (!row?.position) return null
          const mustHaveRequirements = row.position?.mustHaveRequirements
          return (
            <>
              {row.position.needsW2 && <Text>W2</Text>}
              <Text
                as="strong"
                data-context-key="company_id"
                data-context-value={row.company.id}
                data-context-label={row.company.name}
              >
                {cell.name}{' '}
                <Link to={`/companies/${cell.id}`} target="_blank">
                  ({cell.id})
                </Link>
              </Text>
              {row.assignment ? (
                <Text
                  data-context-key="assignment_id"
                  data-context-value={row.assignment.id}
                  data-context-label={row.assignment.name}
                >
                  {row.assignment.name} ({row.assignment.id})
                </Text>
              ) : (
                ''
              )}
              <Text
                data-context-key="position_id"
                data-context-value={row.position.id}
                data-context-label={row.position.name}
              >
                {row.position.name} ({row.position.id})
              </Text>
              {mustHaveRequirements &&
                mustHaveRequirements?.map((req) => (
                  <Text key={req.id} style={{ color: 'red' }}>
                    • {req.name}
                  </Text>
                ))}
            </>
          )
        },
      },
      {
        dataField: 'location',
        text: 'Location',
        formatter: (cell, row) => {
          return (
            <>
              {cell.name && <Text as="strong">{cell.name}</Text>}
              <Text
                data-context-key="location_id"
                data-context-value={row.location.id}
                data-context-label={row.location.name}
              >{`${cell.address.city}, ${cell.address.state}`}</Text>
            </>
          )
        },
      },
      {
        dataField: 'listings[0].tier',
        text: 'Tier',
        formatter: (cell, row) => <Timestamp {...row} />,
      },
      {
        dataField: 'workersNeeded',
        text: 'Requested',
        formatter: (cell, row) => (
          <>
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: '1.2em',
                textAlign: 'center',
              }}
            >{`${row.workersNeeded + row.numCushionWorkersNeeded}`}</Text>

            <Text style={{ textAlign: 'center' }}>needed</Text>
            {!!row.numCushionWorkersNeeded && (
              <Text
                style={{ textAlign: 'center' }}
              >{`+ ${row.numCushionWorkersNeeded} cushion`}</Text>
            )}
          </>
        ),
      },
      {
        headerStyle: { width: '100px' },
        dataField: 'numWorkersScheduled',
        text: 'Scheduled',
        formatter: (cell) => (
          <>
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: '1.2em',
                textAlign: 'center',
              }}
            >
              {cell}
            </Text>
            <Text
              style={{
                textAlign: 'center',
              }}
            >
              filled
            </Text>
          </>
        ),
      },
      {
        dataField: 'fillConfidence',
        text: 'Fill rate',
        formatter: (cell, row) => {
          const fillPercent = calculateFillPercent(row)

          return (
            <Box style={{ textAlign: 'center' }}>
              <Flex justifyContent="center" alignItems="center">
                {!!row?.cancelledAt && (
                  <Label ml="2" variant="error">{`CANCELLED`}</Label>
                )}
                {!!row?.listings?.[0]?.freeze && (
                  <Label ml="2" variant="information">{`FROZEN`}</Label>
                )}
                {!!row?.isTryout && (
                  <Label ml="2" variant="neutral">{`TRYOUT`}</Label>
                )}
              </Flex>
              <Text as="strong" style={{ fontSize: '1.2em' }}>
                {fillPercent === 100 ? (
                  <Icon color="primary" size={15} icon={CheckCircle} />
                ) : (
                  `${fillPercent}%`
                )}
              </Text>
              {!!row.numWorkersPending && (
                <Text
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {row.numWorkersPending} unfilled
                </Text>
              )}
            </Box>
          )
        },
      },
    ],
    [filters, query]
  )

  const filteredShifts = useMemo(
    () =>
      (shifts.data || []).filter((shift) => {
        const range = query.get(QueryParams.range) || 'upcoming'
        const showUnfilledShiftsOnly =
          range === 'upcoming'
            ? !query.get(QueryParams.showUnfilledShiftsOnly) ||
              query.get(QueryParams.showUnfilledShiftsOnly) !== 'false'
            : query.get(QueryParams.showUnfilledShiftsOnly) === 'true'
        return [
          /** Only show cancelled shifts */
          query.get(QueryParams.showCancelledShiftsOnly) !== 'true' ||
            !!shift.cancelledAt,
          /** Only show unfilled shifts */
          !showUnfilledShiftsOnly ||
            shift.workersNeeded > shift.numWorkersScheduled,
          /** Company ID (companyGroupId populates this param, too) */
          !query.get(QueryParams.companyIds) ||
            query
              .get(QueryParams.companyIds)
              .split(',')
              ?.includes(shift.company.id),
          /** Shift ID */
          !query.get(QueryParams.shiftId) ||
            String(shift.id) === query.get(QueryParams.shiftId),
          /** Market */
          !query.get(QueryParams.market) ||
            shift.market === query.get(QueryParams.market),
          /** Min unfilled spots */
          !query.get(QueryParams.minUnfilledSpots) ||
            Number(query.get(QueryParams.minUnfilledSpots)) <=
              shift.workersNeeded +
                shift.numCushionWorkersNeeded -
                shift.numWorkersScheduled,
          /** Max fill percent */
          !query.get(QueryParams.maxFillPercent) ||
            Number(query.get(QueryParams.maxFillPercent)) >=
              calculateFillPercent(shift),
          /** Ongoing date range displays today's shifts only */
          range !== 'ongoing' ||
            moment(shift.startsAt).isBetween(
              moment().subtract(30, 'minutes'),
              moment().add(30, 'minutes')
            ),
          /** Shift starts on/after the "from" date. Defaults to today for "upcoming" or 2 days ago for "past" shifts */
          !query.get(QueryParams.fromDate)
            ? moment(shift.startsAt).isSameOrAfter(
                moment().subtract(range === 'past' ? 3 : 0, 'day'),
                'day'
              )
            : moment(shift.startsAt).isSameOrAfter(
                query.get(QueryParams.fromDate),
                'day'
              ),
          /** Shift starts on/before the "to" date. Defaults to 3 days from now for "upcoming" and today for "past" shifts */
          !query.get(QueryParams.toDate)
            ? moment(shift.startsAt).isSameOrBefore(
                moment().add(range === 'past' ? 0 : 3, 'day'),
                'day'
              )
            : moment(shift.startsAt).isSameOrBefore(
                query.get(QueryParams.toDate),
                'day'
              ),
          /** Listing tier */
          !query.get(QueryParams.tier) ||
            query
              .get(QueryParams.tier)
              .split(',')
              ?.includes(shift.listings?.[0]?.tier),
          /** W2 */
          !query.get(QueryParams.w2) ||
            (query.get(QueryParams.w2) !== 'false' && shift.position.needsW2) ||
            /** 1099 */
            !query.get(QueryParams['1099']) ||
            (query.get(QueryParams['1099']) !== 'false' &&
              !shift.position.needsW2),

          /** Must have requirements */
          !query.get(QueryParams.mustHaveRequirementIds) ||
            query
              .get(QueryParams.mustHaveRequirementIds)
              .split(',')
              .some((id) =>
                shift.position.mustHaveRequirements.some((req) => req.id === id)
              ),
        ].every(Boolean)
      }),
    [shifts.data, query]
  )

  console.log(shifts.data, filteredShifts, query.get(QueryParams['1099']))

  const groupedShifts: ReturnType<(typeof groupShiftsBy)[GroupBy]> = useMemo(
    () => groupShiftsBy[query.get('groupBy') || 'date'](filteredShifts, query),
    [filteredShifts, query]
  )

  return (
    <ShiftsFilterBar shifts={shifts}>
      <Box>
        {!!filteredShifts.length &&
          groupedShifts?.map(([name, group], index) => {
            /** client-side "infinite" load the results to control GraphQL queries which are triggered by each displayed row */
            const currentCount = groupedShifts
              .slice(0, index)
              .reduce((acc, item) => acc + item[1].length, 0)

            if (limit - currentCount < 0) {
              /** Hidden until display limit is increased */
              return null
            }

            return (
              <>
                {group?.length ? (
                  <Box key={`${name}_results`}>
                    <Heading level="2">{name}</Heading>
                    <BootstrapTable
                      key={name}
                      hover
                      keyField="id"
                      data={group.slice(0, limit - currentCount)}
                      columns={columns}
                      rowStyle={getRowStyles}
                    />
                  </Box>
                ) : (
                  <Box key={`${name}_noResults`}>
                    <Heading level="2">{name}</Heading>
                    <Text>No shifts match the current settings.</Text>
                  </Box>
                )}
              </>
            )
          })}
        {!shifts.isFetching && !filteredShifts.length && (
          <Box key="noResults">
            <Heading level="2">No results</Heading>
            <Text>No shifts match the current settings.</Text>
          </Box>
        )}
        <Button
          mt="2"
          variant="secondary"
          block
          onClick={() => setLimit(limit + PAGE_SIZE)}
          /** All results are already displayed */
          disabled={filteredShifts.length <= limit}
        >
          {filteredShifts.length > limit ? (
            <>
              {`Showing ${limit} of ${filteredShifts.length} results. Show more `}
              <Icon icon={ChevronDown} color="primary" />
            </>
          ) : (
            `Showing ${filteredShifts.length} of ${filteredShifts.length} results`
          )}
        </Button>
      </Box>
    </ShiftsFilterBar>
  )
}

export default ShiftSearch
