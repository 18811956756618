import React from 'react'
import { AreaChart, Flex, useAppTheme, Text, Heading } from '@workwhile/ui'
import { AccordionCard } from 'components/AccordionCard'
import { useTierStatsContextValue } from '../../../context/TierStatsProvider'
import { format } from 'date-fns-tz'
import { useShiftDetailContextValue } from 'pages/Shifts/ShiftDetail/context/ShiftDetailProvider'
import { formatNumber } from 'lib/number'

export const TierListingViews = () => {
  const { colors } = useAppTheme()
  const {
    state: { tierDetails },
  } = useTierStatsContextValue()

  const {
    state: {
      location: {
        address: { timezone },
      },
    },
  } = useShiftDetailContextValue()

  const chartData = tierDetails.listingViews.map(({ date, ...rest }) => {
    const isCurrentYear =
      new Date(date).getFullYear() === new Date().getFullYear()
    return {
      date: format(date, isCurrentYear ? 'EEE, M/d' : 'EEE, M/d/yy', {
        timeZone: timezone,
      }),
      ...rest,
    }
  })

  return (
    <AccordionCard
      title={
        <Flex flexDirection="column">
          <Text fontSize={2} color="lightText">
            Statistics
          </Text>
          <Heading
            level={2}
            fontWeight="400"
            style={{
              margin: 0,
            }}
          >
            Tier Listing Views
          </Heading>
        </Flex>
      }
      size="small"
      // Intentionally disabled. This should work, unsure why it's throwing an error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{
        flex: '1 1 auto',
        whiteSpace: 'nowrap',
      }}
    >
      <AreaChart
        data={chartData}
        dataKey="date"
        height="240px"
        withDots={false}
        curveType="bump"
        series={[
          {
            name: 'views',
            color: colors.primary200,
            label: 'Views',
          },
        ]}
        valueFormatter={formatNumber}
      />
    </AccordionCard>
  )
}
