import React, { useRef } from 'react'
import styled from 'styled-components'
import { Transition } from 'react-transition-group'
import { device } from './device'
import { defs } from './defs'
import '../Shared/shared.css'

function LazyLoadingMsg(props) {
  const nodeRef = useRef(null);
  return (
    <Transition nodeRef={nodeRef} in={props.in} timeout={1}>
      {(state) => (
        // state change: exited -> entering -> entered -> exiting -> exited
        <MsgWrapper state={state}>
          <div className="animate-flicker" ref={nodeRef}>{props.children}</div>
        </MsgWrapper>
      )}
    </Transition>
  )
}

export default LazyLoadingMsg

const MsgWrapper = styled.div`
  position: fixed;
  z-index: 998;
  top: 10;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  padding: ${defs.paddingSS};
  border: 1px solid;
  border-radius: ${defs.borderRadiusS};
  color: ${defs.darkAlertYellow};
  background-color: ${defs.alertYellow};
  border-color: ${defs.lightAlertYellow};
  font-size: ${defs.fontM};
  align-items: center;
  justify-content: center;
  width: 100vw;
  transition: transform
    ${({ state }) => (state === 'entering' ? '200ms' : '1000ms')} ease-in-out;
  transform: ${({ state }) => {
    switch (state) {
      case 'entering':
        return null
      case 'entered':
        return null
      case 'exiting':
        return null
      case 'exited':
        return 'translateY(-130%)'
    }
  }};
  @media ${device.smallerThanTablet} {
    left: 0;
    width: 100vw;
    z-index: 996;
    top: ${defs.mobileNavHeight};
    transform: ${({ state }) => {
      switch (state) {
        case 'entering':
          return null
        case 'entered':
          return null
        case 'exiting':
          return null
        case 'exited':
          return 'translateY(-300%)'
      }
    }};
  }
  @media ${device.smallerThanMobileL} {
    font-size: ${defs.fontS};
  }
`
