import React from 'react'
import { AssignShiftFlowProvider } from './context/AssignShiftFlowProvider'
import { AssignShiftFlowMain } from './components/AssignShiftFlowMain'

export const AssignShiftFlow = () => {
  return (
    <AssignShiftFlowProvider>
      <AssignShiftFlowMain />
    </AssignShiftFlowProvider>
  )
}
