import React from 'react'
import { RemoveCompanyFromGroupFlowProvider } from './context/RemoveCompanyFromGroupFlow'
import { RemoveCompanyFromGroupFlowMain } from './components/RemoveCompanyFromGroupFlowMain'

export const RemoveCompanyFromGroupFlow = () => {
  return (
    <RemoveCompanyFromGroupFlowProvider>
      <RemoveCompanyFromGroupFlowMain />
    </RemoveCompanyFromGroupFlowProvider>
  )
}
