import api from 'api'

export const addOrUpdateLogo = async (companyId: number, file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  return await api.put(`/admin/company/${companyId}/logo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
