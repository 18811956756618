import React from 'react'
import { Box, SearchInput, Text } from '@workwhile/ui'
import { useCompanyGroupsContextValue } from '../context/CompanyGroupsProvider'

export const Search = () => {
  const {
    state: { searchInput, foundClosestAutocompleteMatch },
    actions: { setSearchInput },
  } = useCompanyGroupsContextValue()
  return (
    <Box mb={3} maxWidth={['100%', '100%', '100%', '50%']}>
      <SearchInput
        keyword={searchInput}
        setKeyword={setSearchInput}
        placeholder="Search by name or ID"
        block
        // FIXME: There is a double clear button on the input, we'll remove that one and remove `clearable` prop
        clearable={false}
      />
      <Text
        color="lightText"
        mt={2}
        style={{
          // We use visibility to avoid layout shift when the text is hidden
          visibility: foundClosestAutocompleteMatch ? 'visible' : 'hidden',
        }}
      >
        Did you mean "{foundClosestAutocompleteMatch}"?
      </Text>
    </Box>
  )
}
