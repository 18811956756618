import React from 'react'
import PositionOverview from './PositionOverview'
import { approvePosition } from '../../../src/actions/company_actions'
import CompanyOverview from '../features/Company/CompanyOverview'
import ClockInOutCodes from './ClockInOut'
import MinPayPolicy, { hasCustomPolicy } from './MinPayPolicy'
import BundlingEditor from './BundlingEditor'
import RequirementsForPosition from './RequirementsForPosition'
import CollapsibleSection from '../Shared/CollapsibleSection'
import UpcomingShiftsOverview from './UpcomingShiftsOverview'
import CustomCancellationPolicy, {
  hasCustomPolicies,
} from './CustomCancellationPolicy'
import LocationEditor from './LocationEditor'
import { Heading } from '@workwhile/ui'
import { PositionTemplate } from './PositionTemplate'
import { usePositionEditorContext } from './PositionEditorContext'

export const PositionEditorContainer = () => {
  const { positionId, positionData, refetchPositionData } =
    usePositionEditorContext()

  function onApprovePosition() {
    const responseCallback = (response) => {
      alert('Success')
      refetchPositionData()
    }
    const errorCallback = (error) => {
      alert('Error: could not approve')
      console.log(error)
    }

    approvePosition(positionId, responseCallback, errorCallback)
  }

  return (
    <div>
      <CollapsibleSection
        title="Position Overview"
        defaultOpen={true}
        titleStyles={headerStyle}
      >
        <Heading level={4}>
          {' '}
          Position is currently{' '}
          {positionData.isApproved ? 'APPROVED ✅' : 'NOT YET APPROVED 🚨'}.
          Click text to edit
        </Heading>
        <PositionOverview
          positionData={positionData}
          refreshCallback={refetchPositionData}
        />
      </CollapsibleSection>
      <CollapsibleSection
        title="Position Template"
        defaultOpen={true}
        titleStyles={headerStyle}
      >
        <PositionTemplate />
      </CollapsibleSection>

      <CollapsibleSection
        title="Company Info"
        defaultOpen={true}
        titleStyles={headerStyle}
      >
        <CompanyOverview
          company={positionData ? positionData.company : null}
          refreshCallback={refetchPositionData}
        />
      </CollapsibleSection>

      <CollapsibleSection
        title="Upcoming Shift(s)"
        defaultOpen={false}
        titleStyles={headerStyle}
      >
        <UpcomingShiftsOverview
          positionId={positionId}
          companyId={Number(positionData.company.id)}
        />
      </CollapsibleSection>

      <CollapsibleSection
        title="Requirements"
        defaultOpen={false}
        titleStyles={headerStyle}
      >
        <RequirementsForPosition positionId={positionId} />
      </CollapsibleSection>

      <CollapsibleSection
        title="Location & Clock-In Radius "
        defaultOpen={false}
        titleStyles={headerStyle}
      >
        <LocationEditor positionData={positionData} />
      </CollapsibleSection>

      <CollapsibleSection
        title={`Change bundling: Bundling is currently ${positionData.bundling === 'none' ? 'Off' : 'On'}`}
        defaultOpen={false}
        titleStyles={headerStyle}
      >
        <BundlingEditor
          currentlyBundled={
            positionData ? positionData.bundling !== 'none' : false
          }
          positionId={positionId}
        />
      </CollapsibleSection>

      <CollapsibleSection
        title="Configure Clock In/Out"
        defaultOpen={false}
        titleStyles={headerStyle}
      >
        <ClockInOutCodes
          positionId={positionId}
          clockInActivated={positionData.clockInCodeEnabled}
          clockOutActivated={positionData.clockOutCodeEnabled}
        />
      </CollapsibleSection>

      <CollapsibleSection
        title={`Min Pay Policy: ${hasCustomPolicy(positionData.company) ? 'Custom policy configured' : 'No custom policy. Default pay for full shift.'}`}
        defaultOpen={hasCustomPolicy(positionData.company)}
        titleStyles={headerStyle}
      >
        <MinPayPolicy companyData={positionData.company} />
      </CollapsibleSection>

      <CollapsibleSection
        title={`Auto Cushion is currently: ${positionData.allowAutocushion ? 'On' : 'Off'}`}
        defaultOpen={false}
        titleStyles={headerStyle}
      >
        <>
          To turn {positionData.allowAutocushion ? 'Off' : 'On'}
          <br />
          <code>
            @wobot company toggle-autocushion {positionId}{' '}
            {positionData.allowAutocushion ? '--off' : '--on'}
          </code>
        </>
      </CollapsibleSection>

      <CollapsibleSection
        title={`Configure Tip: Currently ${positionData.isTipEligible ? 'On' : 'Off'}`}
        defaultOpen={false}
        titleStyles={headerStyle}
      >
        <>To change this run</>
        <br />
        <code>
          @wobot company toggle-tip-enabled {positionId}{' '}
          {positionData.company.id}{' '}
          {positionData.isTipEligible ? '--off' : '--on'}
        </code>
      </CollapsibleSection>

      <CollapsibleSection
        title={`Delay Pay. Currently set to: ${positionData.company.companyShiftEndPaymentDelayHours} hours`}
        defaultOpen={false}
        titleStyles={headerStyle}
      >
        <>
          Our default is 24 hours. To provide anything outside of that run the
          following with a whole number hours
        </>
        <br />
        <code>
          @wobot company update-payment-delay-hours {positionData.company.id}{' '}
          num_hours
        </code>
      </CollapsibleSection>

      <CollapsibleSection
        title={`Custom Cancellation Policy: ${hasCustomPolicies(positionData.company.cancellationPolicies) ? '✅ Has Custom' : '❌ None Configured'}`}
        defaultOpen={false}
        titleStyles={headerStyle}
      >
        <CustomCancellationPolicy
          companyId={Number(positionData.company.id)}
          cancellationPolicies={positionData.company.cancellationPolicies}
        />
      </CollapsibleSection>

      {positionData.isApproved ? (
        <Heading level={3}>
          👍🏾 Position already approved. You are done.{' '}
        </Heading>
      ) : (
        <button
          type="submit"
          className="submit-button"
          style={{ minWidth: '150px' }}
          onClick={onApprovePosition}
        >
          Approve
        </button>
      )}
    </div>
  )
}

const headerStyle = {
  alignItems: 'left',
  fontSize: 30,
}
