export interface User {
  id?: string
  firebaseId?: string
  name?: string
  phoneNumber?: string
  email?: string
  status?: string
  shadowBlocked?: boolean
  shadowBlockedReason?: string
  orientationStatus?: string
  onboardingStatus?: OnboardingStatus
  checkrStatus?: CheckrStatus
  yardstikStatus?: YardstikStatus
  penalties?: Array<Penalty>
  userStats?: UserStats
  rating?: number
  address?: Address
  berbixToken?: string
  // berbixStatus represents personaStatus. Referenced as the latter in the codebase.
  berbixStatus?: string
  backgroundCheckStatus?: string
  backgroundCheckMvrStatus?: string
  companyWorkerProfiles?: Array<CompanyWorkerProfile>
  strikes?: Array<Strike>
  allStrikes?: Array<Strike>
  stripeAccountIdProd?: string
  paymentAccounts?: Array<PaymentAccount>
  hasSsn?: boolean
  tierExperienced?: boolean
  workProfile?: WorkProfile
  work?: Array<Work>
  createdAt?: string
  w2Status: string
  w2States?: Array<string>
  w2OnboardingStep?: string
  credentials?: Array<Credential>
  referrals?: Array<Referral>
  reference?: Referral
  profilePicUrl?: string
  verifiedSelfieUrl?: string
  profilePicVerifiedAt?: string
  profilePicManuallyVerifiedBy?: User
  workerTutorials?: Array<WorkerTutorial>
  w2EvereeId?: string
}

interface PaymentAccount {
  accountType: string
}

interface OnboardingStatus {
  orientationStatus?: string | null
  status?: string | null
}

type DrugScreeningStatus =
  | 'canceled'
  | 'clear'
  | 'consider'
  | 'pending'
  | 'rejected'
  | 'suspended'
  // This means the type can be one of the specified literal string values ('canceled', 'clear', etc.), or any string value with no constraints
  // This creates a nominal or branding effect, to have let the TypeScript compiler enforce stricter type differentiation when dealing with intersections
  | (string & object)

interface DrugScreeningTransition {
  status: DrugScreeningStatus
  occurredAt: string
}

export interface DrugScreening {
  reportId?: string | null
  status: DrugScreeningStatus
  transitions?: Array<DrugScreeningTransition>
  expiresAt?: string
}

interface CheckrStatus {
  candidateId?: string | null
  criminalStatus?: string | null
  criminalSubmitted?: string | null
  mvrStatus?: string | null
  mvrSubmitted?: string | null
  drugScreenings?: Array<DrugScreening>
}

interface YardstikStatus {
  candidateId?: string | null
  criminalReportId?: string | null
  criminalStatus?: string | null
  criminalSubmitted?: string | null
  mvrReportId?: string | null
  mvrStatus?: string | null
  mvrSubmitted?: string | null
  drugScreenings?: Array<DrugScreening>
}

export interface Penalty {
  createdAt: string
  userId: string
  penaltyType: string
  expiresAt: string
  extras: object
  status: string
  policy: string
  offenses: Array<Offense>
}

export interface Offense {
  userId: string
  shiftId: string
  offenseType: string
  excluded: boolean
  reason: string
  healthCheckAnswers: string
}

export interface UserStats {
  shiftCount: number
  penaltyCount: number
}

export interface Address {
  city: string
  state: string
  street: string
  unit: string
  zip: number
  lat: string
  long: string
  timezone: string
}

export interface CompanyWorkerProfile {
  companyId: string
  workerId: string
  favorite: boolean
  blocked: boolean
  blockedReason?: string
  hired: boolean
  paidShiftCount?: number
}

export interface Strike {
  date: string
  excuse?: string
  shiftId?: number
  id: number
  reason: string
  isCancelledForIllness?: boolean
  excluded?: boolean
}

export interface WorkerCertificate {
  createdAt: string
  id: number
  workerRequirement: {
    id: number
    name: string
  }
}

export interface WorkProfile {
  hasLicense: boolean
  hasCar: boolean
  currentlyEmployed: boolean
  numHoursDesired: number
  wantsFullTime: boolean
  telehealthBenefitStatus: string
  isElite: boolean
  certificates?: Array<WorkerCertificate>
}

export interface Work {
  id?: number
  status?: string
  earnings?: string
  startedAt?: string
  completedAt?: string
  shift?: WorkShift
  confirmedAt?: string
  worker?: User
  reasonEarlyClockout?: string
  supervisorReasonEarlyClockOut?: string
  supervisorMarkedNoShow?: boolean
  isCancelledForIllness?: boolean
  noShowMarkedByShiftLeadId?: number
  isReplaceable?: boolean
  replacedWork?: Work // the work that replaced this one (if any) see backend/lib/replace_unconfirmed.py
  replacedAt: string // the time this work was replaced see backend/lib/replace_unconfirmed.py
  adminReviewDetail?: string
  associatedOffense?: {
    userExplanation: string
  }
  adminReview?: {
    workerClaimsTheyWorked: boolean
    workerSubmittedDocsForBail: boolean
  }
  trackingIssueReason?: string
}

export interface Assignment {
  name: string
  id: number
}

export interface WorkShift {
  id?: number
  assignment?: Assignment
  startsAt?: string
  endsAt?: string
  payRate?: string
  payLumpSum?: string
  pay?: string
  company?: Company
  position?: Position
  isTryout?: boolean
  location?: Location
  cancelledAt?: boolean
  listing?: Listing
  listings?: Array<Listing>
  shiftBonuses?: Array<ShiftBonus>
  work?: Array<Work>
  workersNeeded?: number
  numCushionWorkersNeeded?: number
  numWorkersFilled?: number
  numWorkersScheduled?: number
  numWorkersStarted?: number
  numWorkersFinished?: number
  numWorkersRejected?: number
  numWorkersRemoved?: number
  numWorkersPending?: number
  leadShifts?: Array<WorkShift>
  rosters?: Array<Roster>
  rostersHoldExpiresAt?: string
  supervisors?: Array<ShiftSupervisor>
  supervisor?: ShiftSupervisor
  supervisorPhone?: ShiftSupervisor['phoneNumber']
  lunchLength?: number
  market?: string
}

export interface Roster {
  id: string
  name: string
  isSpecificWorkerRequest: boolean
  workers: Array<User>
}

export interface Listing {
  id: string
  tier: string
  tierTransitions: string
  numWaitlist: number
  location?: Location
  company?: Company
  shifts?: Array<WorkShift>
  freeze?: boolean
  position?: Position
}

export interface ShiftBonus {
  amount: number
  createdAt: string
  companyPaid: boolean
  isDynamicBonus: boolean
  isNewWorkerBonus: boolean
}

export interface Company {
  id?: string
  name?: string
  companyGroupId?: string
}

export interface WorkerTutorial {
  id: string
  tutorialId: string
  quizScore: number
}

export interface Tutorial {
  id: string
  name: string
}

export interface PositionTemplate {
  id: string
  name: string
  relevantPositionTemplateWorkerCategories?: Array<{
    name: string
  }>
}

export interface Position {
  id?: string
  name?: string
  isDrivingPosition?: boolean
  isApproved?: boolean
  tutorials?: Array<Tutorial>
  needsCar?: boolean
  needsFullTime?: boolean
  needsLicense?: boolean
  needsW2?: boolean
  requirements2?: WorkerRequirement[]
  mustHaveRequirements?: WorkerRequirement[]
  about?: string
  positionTemplate?: PositionTemplate
}

export interface WorkerRequirement {
  id?: string
  name?: string
  description?: string
  category?: string
  credential?: string
  isWorkerVisible?: boolean
  documentNeeded?: boolean // True if a document needs to be uploaded for verification aka. Certificate requirement
  verifiable?: boolean // True if we can verify the requirement, aka. certificate or experience requirement
  verificationType?: string
}

export interface Location {
  id: string
  name: string
  address: Address
  clockInRadius?: number
}

export interface ShiftSupervisor {
  id?: string
  name: string
  phoneNumber?: string
  userId?: string
  status?: string
}

export interface Credential {
  id?: number
  credential?: string
  workerRequirement?: WorkerRequirement
  createdAt?: string
  verifiedAt?: string
}

export interface Referral {
  id?: number
  status: string
  referred: User
  referredName: string
  referrer: User
}

export interface ResetNote {
  createdAt: string
  notes: string
}

export interface I9Submission {
  submittedAt: string
  userId: number
  firstName: string | null
  lastName: string | null
  dob: string | null
  w2State: string | null
  market: string | null
  profilePicUrl: string | null
  documents: {
    documentName: string
    documentUrl: string
  }[]
  resetNotes: ResetNote[]
  status?: string
  shadowBlocked?: boolean
  shadowBlockedReason?: string
}

declare global {
  interface Window {
    google: any // Adds GoogleAPI to the window object so MapView can access it
  }
}

export enum WorkStatus {
  Scheduled = 'scheduled',
  Rejected = 'rejected',
  Started = 'started',
  Bailed = 'bailed',
  Completed = 'completed',
  Approved = 'approved',
  Paid = 'paid',
  Removed = 'removed',
  AdminReview = 'admin_review',
  EmployerReview = 'employer_review',
  EmployerApproved = 'employer_approved',
  EmployerCancelled = 'employer_cancelled',
  WorkerDeclinedReq = 'worker_declined_req',
  SystemDeclined = 'system_declined',
}
