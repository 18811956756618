import { useMutation, useQueryClient } from '@tanstack/react-query'
import { companyDetailKey } from './keys'
import { CompanyDetail } from 'pages/Company/CompanyDetail/types'
import { editMoney } from 'api/company/editMoney'

export const useEditCompanyMoneyMutation = (companyId: number) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (request: Omit<Parameters<typeof editMoney>[0], 'companyId'>) =>
      editMoney({
        ...request,
        companyId,
      }),
    onMutate: async (request) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: companyDetailKey(companyId) })

      const previousCompanyDetail = queryClient.getQueryData(
        companyDetailKey(companyId)
      )

      // Optimistically update to the new value
      queryClient.setQueryData<CompanyDetail>(
        companyDetailKey(companyId),
        (old) => ({
          ...old,
          invoiceDueDays: request.invoiceDueDays ?? old.invoiceDueDays,
          takeRate1099: request.takeRate ?? old.takeRate1099,
          bonusTakeRate: request.bonusTakeRate ?? old.bonusTakeRate,
          tipTakeRate: request.tipTakeRate ?? old.tipTakeRate,
          w2RegularTakeRate: request.w2RegularTakeRate ?? old.w2RegularTakeRate,
          w2DoubleTakeRate: request.w2DoubleTakeRate ?? old.w2DoubleTakeRate,
          w2OvertimeTakeRate:
            request.w2OvertimeTakeRate ?? old.w2OvertimeTakeRate,
        })
      )

      return { previousCompanyDetail }
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(
        companyDetailKey(companyId),
        context.previousCompanyDetail
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: companyDetailKey(companyId) })
    },
  })
}
