import { useCompanyDetailWorkflowsContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailWorkflowProvider'
import React from 'react'
import {
  Box,
  Button,
  Field,
  Flex,
  Heading,
  Message,
  Modal,
  RadioGroup,
  toast,
} from '@workwhile/ui'
import { useCompanyDetailContextValue } from '../../../context/CompanyDetailProvider'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { editGeneralDetailsFlowSchema } from '../schemas/editGeneralDetailsFlowSchema'
import { useEditGeneralDetailsFlowContextValue } from '../context/EditGeneralDetailsFlowProvider'

export const EditGeneralDetailsFlowMain = () => {
  const {
    editGeneralDetailsFlow: { isOpen, close },
  } = useCompanyDetailWorkflowsContextValue()

  const {
    state: {
      formData,
      mutationState: { isPending, errorMessage },
    },
    actions: { submitFormToServer, setFormData },
  } = useEditGeneralDetailsFlowContextValue()

  const {
    state: { type },
  } = useCompanyDetailContextValue()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(editGeneralDetailsFlowSchema),
    values: {
      type: formData.type ?? type,
      currentType: type,
    },
  })

  const onSubmit = handleSubmit((data) => {
    setFormData({ type: data.type })
    submitFormToServer(
      {
        type: data.type,
      },
      {
        onSuccess: () => {
          toast.success('Updated general details successfully!', {
            position: 'top-center',
          })
          setFormData({})
          reset()
          close()
        },
      }
    )
  })

  return (
    <Modal
      open={isOpen}
      onClose={close}
      position="top"
      loading={isPending}
      showCancel
      customCta={
        <Flex>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save
          </Button>
        </Flex>
      }
    >
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Heading level={3} fontWeight="400" style={{ margin: 0 }}>
          Edit General details
        </Heading>

        <Field
          label={'Type'}
          error={errors.type?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mt={3}
        >
          <Controller
            name={'type'}
            control={control}
            render={({ field }) => {
              return (
                <RadioGroup
                  {...field}
                  // Intentionally disabled. This should work, unsure why it's throwing an error
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  flexDirection={['column', 'row']}
                  gap={5}
                  options={[
                    {
                      value: 'w2',
                      label: 'W2',
                    },
                    {
                      value: '1099',
                      label: '1099',
                    },
                  ]}
                />
              )
            }}
          />
        </Field>
        {errorMessage ? (
          <Message variant="error" mt={3} borderRadius="small">
            {errorMessage}
          </Message>
        ) : null}
      </Box>
    </Modal>
  )
}
