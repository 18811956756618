/** Allow us to match related terms/types with one search
 * e.g. "user id" will match "worker id"
 * e.g. "can worker see listing" will match "can use see listing"
 */
// TODO: Convert to graph if multiple aliases are needed for the same term
export const aliased_terms = {
  worker: 'user',
  user: 'worker',
  lta: 'assignment',
  assignment: 'lta',
}

export const replaceAliases = (text: string) => {
  return text?.replace(/(worker|user|lta|assignment)/g, (match) => {
    return aliased_terms[match] || match;
  })
}