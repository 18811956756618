import React from 'react'
import { createContext } from 'lib/createContext'
import { CompanyGroupDetail } from '../types'
import { useCompanyGroupDetailsByIdQuery } from 'queries/companyGroup'
import { Loading, Message } from '@workwhile/ui'
import { parseErrorMessage } from 'api'

type CompanyGroupDetailContextState = {
  state: CompanyGroupDetail & {
    dataUpdatedAt: number
    isFetching: boolean
    isError: boolean
    error: string
  }
  actions: {
    refetch: () => void
  }
}

const [Context, useCompanyGroupDetailContextValue] =
  createContext<CompanyGroupDetailContextState>({
    name: 'CompanyGroupDetailContext',
  })

export const CompanyGroupDetailProvider = ({
  children,
  companyGroupId,
}: {
  children: React.ReactNode
  companyGroupId: number
}) => {
  const {
    data,
    isLoading,
    isError,
    error,
    dataUpdatedAt,
    refetch,
    isFetching,
  } = useCompanyGroupDetailsByIdQuery(companyGroupId)

  if (isLoading) {
    return <Loading />
  }

  if (isError) {
    // eslint-disable-next-line no-console
    console.error(error)
    return (
      <Message variant="error">
        Sorry, something went wrong. Please reach out to eng support if this
        persist
      </Message>
    )
  }

  const value = {
    state: {
      ...data,
      dataUpdatedAt,
      isFetching,
      isError,
      error: parseErrorMessage(error),
    },
    actions: { refetch },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useCompanyGroupDetailContextValue }
