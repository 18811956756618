import React from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Message,
  Modal,
  toast,
  Text,
  Field,
  Input,
} from '@workwhile/ui'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCompanyGroupDetailWorkflowsContextValue } from 'pages/CompanyGroupDetail/context/CompanyGroupDetailWorkflowProvider'
import { useCompanyGroupDetailContextValue } from 'pages/CompanyGroupDetail/context/CompanyGroupDetailProvider'
import { AddCompanySchema, addCompanySchema } from '../schema/addCompany'
import { useAddCompaniesFlowContextValue } from '../context/AddCompaniesFlowProvider'
import { AddedCompanies } from './AddedCompanies'

export const AddCompaniesFlowMain = () => {
  const {
    addCompaniesToGroupFlow: { isOpen, close },
  } = useCompanyGroupDetailWorkflowsContextValue()

  const {
    state: {
      companiesInGroup,
      allCompaniesQueryState: {
        data: allCompanies,
        isLoading: isCompaniesLoading,
        errorMessage: companiesErrorMessage,
      },
      mutationState: { isPending, errorMessage: mutationErrorMessage },
    },
    actions: { submitFormToServer },
  } = useAddCompaniesFlowContextValue()

  const {
    state: { id: companyGroupId, name: companyGroupName },
  } = useCompanyGroupDetailContextValue()

  const methods = useForm<AddCompanySchema>({
    resolver: zodResolver(addCompanySchema),
    values: {
      companiesToAdd: [],
      companiesInGroup,
      allCompaniesInGroup: allCompanies,
    },
  })

  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
    trigger,
    getValues,
    setValue,
  } = methods

  const errorMessage =
    mutationErrorMessage ||
    companiesErrorMessage ||
    errors.companiesToAdd?.message

  const onSubmit = handleSubmit((data) => {
    const companyIds = data.companiesToAdd.map((company) => company.id)
    submitFormToServer(companyIds, {
      onSuccess: () => {
        toast.success('Added companies to this group successfully!', {
          position: 'top-center',
        })
        reset({
          companiesInGroup: [],
          companiesToAdd: [],
          allCompaniesInGroup: [],
        })
        close()
      },
    })
  })

  const { append } = useFieldArray({
    name: 'companiesToAdd',
    control: methods.control,
  })

  return (
    <Modal
      open={isOpen}
      onClose={close}
      position="top"
      loading={isPending || isCompaniesLoading}
      showCancel
      customCta={
        <Flex>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save
          </Button>
        </Flex>
      }
    >
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Heading level={3} fontWeight="400" style={{ margin: 0 }}>
          Add Companies to Group
        </Heading>
        <Text mt={3} fontSize={2} mb={1}>
          Group
        </Text>
        <Text fontSize={2} color="lightText" mb={3}>
          {companyGroupName} ({companyGroupId})
        </Text>

        <Field label="Company Id" error={errors.companyIdInput?.message}>
          <Flex alignItems="center" gap={2} width="100%">
            <Input
              placeholder={`e.g. 1272`}
              block
              {...register('companyIdInput')}
              type="number"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              mb={2}
            />
            <Button
              variant="primary"
              onClick={async () => {
                const isInputValid = await trigger('companyIdInput')
                const companyId = Number(getValues('companyIdInput'))
                const addedCompanies = getValues('companiesToAdd')
                const doesInputExistInCurrentGroup = addedCompanies.some(
                  (company) => company.id === companyId
                )

                const isValid = isInputValid && !doesInputExistInCurrentGroup
                if (isValid) {
                  append({ id: Number(companyId) })
                  setValue('companyIdInput', undefined)
                }

                if (doesInputExistInCurrentGroup) {
                  setValue('companyIdInput', undefined)
                }
              }}
            >
              Add
            </Button>
          </Flex>
        </Field>

        <FormProvider {...methods}>
          <AddedCompanies />
        </FormProvider>

        {errorMessage ? (
          <Message variant="error" mt={3} borderRadius="small">
            {errorMessage}
          </Message>
        ) : null}
      </Box>
    </Modal>
  )
}
