import React from 'react'
import { Header } from 'components/Header'
import { Flex } from '@workwhile/ui'
import { QueryStatus } from 'components/QueryStatus'
import { useShiftsContextValue } from '../context/ShiftsProvider'

export const ShiftsHeader = () => {
  const { state, actions } = useShiftsContextValue()
  return (
    <Header
      title="Shifts"
      rightContent={
        <Flex alignItems="center" gap={2}>
          <QueryStatus
            dataUpdatedAt={state.dataUpdatedAt}
            isFetching={state.isFetching}
            refetch={actions.refetch}
            isError={state.isError}
            error={state.error}
          />
        </Flex>
      }
    />
  )
}
