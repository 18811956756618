import { useSearchParams } from 'react-router-dom'
import { CommandValues } from './types'
import { useEffect } from 'react'

/** Optionally synchronize changed values to the URL */
export function useUrlContext({
  enabled,
  init,
}: {
  enabled: boolean
  init: CommandValues
}) {
  const [params, setParams] = useSearchParams()
  const values = enabled ? Object.fromEntries(params.entries()) : {}

  useEffect(() => {
    enabled && onChange({...init, ...values})
  }, [enabled, init])

  const onChange = (next: CommandValues) => {
    if (enabled) {
      if (!next) {
        setParams({})
      } else {
        setParams((prev) => {
          Object.entries({ ...values, ...next }).forEach(([key, value]) => {
            if (value) {
              prev.set(key, value)
            } else {
              prev.delete(key)
            }
          })

          return prev
        })
      }
    }
  }

  return { values, onChange }
}
