import React from 'react'
import { ReferencePopover } from 'components/ReferencePopover'
import { TriangleAlert } from 'lucide-react'
import { Position } from '../types'
import { Box } from '@workwhile/ui'

const PositionRequirements = ({
  requirements,
}: {
  requirements: Position['requirements']
}) => {
  const positionRequirements = requirements.map((requirement, index) => {
    const isLast = index === requirements.length - 1
    return (
      <Box as="li" key={requirement.id} mb={isLast ? 0 : 2}>
        {requirement.name}
      </Box>
    )
  })

  return (
    <Box as="ol" ml={3} style={{ listStyleType: 'auto' }} maxWidth={296}>
      {positionRequirements}
    </Box>
  )
}

export const PositionRequirementsReferencePopover = ({
  position,
}: {
  position: Position
}) => {
  if (
    !Array.isArray(position.requirements) ||
    position.requirements.length === 0
  ) {
    return null
  }

  return (
    <ReferencePopover
      trigger={<TriangleAlert size={14} style={{ alignSelf: 'center' }} />}
      subTitle="Reference"
      title="Position Requirements"
      contentProps={{
        children: <PositionRequirements requirements={position.requirements} />,
      }}
    />
  )
}
