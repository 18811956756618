import React, { useState } from 'react'
import { usePositionTemplateContext } from './PositionTemplateContext'
import { Box, Icon, Input } from '@workwhile/ui'
import { SearchIcon } from 'lucide-react'
import { useDebounceEffect } from 'ahooks'

export function SearchBar() {
  const { keyword, setKeyword } = usePositionTemplateContext()
  // internal state to debounce
  const [value, setValue] = useState(keyword)

  useDebounceEffect(
    () => {
      setKeyword(value)
    },
    [value],
    { wait: 300 }
  )

  return (
    <Box
      position={'sticky'}
      top={-1}
      backgroundColor={'white'}
      zIndex={1}
      py={2}
    >
      <Input
        value={value}
        icon={<Icon icon={SearchIcon} size={16} />}
        type={'search'}
        onChange={(e) => setValue(e.target.value)}
        placeholder={'Search by position name'}
        block
      />
    </Box>
  )
}
