import React, { useId, useMemo } from 'react'
import {
  Controller,
  useFieldArray,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form'
import { FiltersSchema } from '../filtersSchema'
import { Box, Button, Field, Flex, Select, useAppTheme } from '@workwhile/ui'
import { CircleMinus } from 'lucide-react'
import { useShiftsContextValue } from 'pages/Shifts/Shifts/context/ShiftsProvider'
import { indexBy, prop } from 'ramda'

const AddedCompanyGroupRow = ({
  remove,
  index,
  value,
  isLast,
}: {
  remove: UseFieldArrayRemove
  index: number
  value: string
  isLast: boolean
}) => {
  const { colors, radii, space } = useAppTheme()
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={space[2]}
      mb={isLast ? 0 : space[2]}
    >
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        border="1px solid"
        borderColor={colors.neutrals[100]}
        p={2}
        borderRadius={radii.small}
        width="100%"
        position="relative"
      >
        {value}
      </Flex>
      <Button
        onClick={() => remove(index)}
        variant="text"
        color="red"
        size="small"
        danger
        style={{
          margin: 0,
          height: '1.6rem', // This is to resolve a bug in our UI library where the width and height is being set to `small` (literally) instead of a `px` value
        }}
      >
        <CircleMinus size={16} />
      </Button>
    </Flex>
  )
}

const AddedCompanyIds = () => {
  const baseId = useId()
  const { watch, control } = useFormContext<FiltersSchema>()
  const {
    state: { companyGroups },
  } = useShiftsContextValue()

  const { remove } = useFieldArray({
    name: 'company.companyGroupIds',
    control,
  })

  const companyGroupIds = watch('company.companyGroupIds')

  const companyGroupMap = useMemo(() => {
    if (!companyGroups) return {}
    const groupedCompanies = indexBy(prop('id'), companyGroups)
    return groupedCompanies
  }, [companyGroups])

  const addedCompanyGroupRows = companyGroupIds.map(({ id }, index) => (
    <AddedCompanyGroupRow
      key={`added-company-group-${baseId}-${id}-${index}`}
      remove={remove}
      index={index}
      value={`${companyGroupMap[id]?.name} (${id})`}
      isLast={index === companyGroupIds.length - 1}
    />
  ))
  return (
    <Box width="256px" maxHeight="360px" overflow="auto">
      {addedCompanyGroupRows}
    </Box>
  )
}

export const CompanyGroupIdsFilter = () => {
  const { control, trigger, getValues, setValue, resetField } =
    useFormContext<FiltersSchema>()
  const {
    state: { companyGroups },
  } = useShiftsContextValue()

  const companyGroupOptions = useMemo(() => {
    return companyGroups?.map((companyGroup) => ({
      label: `${companyGroup.name} (${companyGroup.id})`,
      value: companyGroup.id,
    }))
  }, [companyGroups])

  const { append } = useFieldArray({
    name: 'company.companyGroupIds',
    control,
  })

  return (
    <Box>
      <Controller
        control={control}
        name="company.companyGroupIdUserInput"
        defaultValue={undefined}
        render={({ field, fieldState: { error, invalid } }) => (
          <Field error={error?.message?.toString()}>
            <Flex gap={2} width="256px" maxHeight="360px">
              <Select
                block
                {...field}
                options={companyGroupOptions}
                isClearable
                isSearchable
                value={
                  companyGroupOptions.find(
                    (option) => option.value === field.value
                  ) || null
                }
                onChange={(option) => {
                  field.onChange(option?.value)
                }}
                styles={{
                  container: (base) => ({
                    ...base,
                    width: '100%',
                  }),
                }}
              />
              <Button
                variant="primary"
                disabled={invalid}
                onClick={async () => {
                  const isInputValid = await trigger(
                    'company.companyGroupIdUserInput'
                  )
                  const companyGroupId = Number(
                    getValues('company.companyGroupIdUserInput')
                  )
                  const addedCompanies = getValues('company.companyGroupIds')
                  const doesInputExistInCurrentGroup = addedCompanies.some(
                    (companyGroup) => companyGroup.id === companyGroupId
                  )

                  // we add this check bceause zod does not throw an error if the input is not a number
                  const isValidNumber = !Number.isNaN(companyGroupId)
                  const isValid =
                    isInputValid &&
                    !doesInputExistInCurrentGroup &&
                    isValidNumber

                  if (isValid) {
                    append({ id: companyGroupId })
                    resetField('company.companyGroupIdUserInput', {
                      defaultValue: undefined,
                    })
                    setValue('company.companyGroupIdUserInput', undefined)
                  }

                  if (doesInputExistInCurrentGroup) {
                    resetField('company.companyGroupIdUserInput', {
                      defaultValue: undefined,
                    })
                  }
                }}
              >
                Add
              </Button>
            </Flex>
          </Field>
        )}
      />

      <AddedCompanyIds />
    </Box>
  )
}
