import React, { PropsWithChildren, useState } from 'react'

import * as zod from 'zod'
import { editGeneralDetailsFlowSchema } from '../schemas/editGeneralDetailsFlowSchema'
import { createContext } from 'lib/createContext'
import { useCompanyDetailContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailProvider'
import { parseErrorMessage } from 'api'
import { useEditGeneralDetailsMutation } from 'queries/company'

type EditGeneralDetailsFlowProviderContextState = {
  actions: {
    submitFormToServer: ReturnType<
      typeof useEditGeneralDetailsMutation
    >['mutate']
    setFormData: (data: zod.infer<typeof editGeneralDetailsFlowSchema>) => void
  }
  state: {
    formData: zod.infer<typeof editGeneralDetailsFlowSchema>
    mutationState: {
      isPending: boolean
      isError: boolean
      errorMessage: string | undefined
    }
  }
}

const [Context, useEditGeneralDetailsFlowContextValue] =
  createContext<EditGeneralDetailsFlowProviderContextState>({
    name: 'EditGeneralDetailsFlowContext',
  })

export const EditGeneralDetailsFlowProvider = ({
  children,
}: PropsWithChildren) => {
  const [formData, setFormData] = useState<
    zod.infer<typeof editGeneralDetailsFlowSchema>
  >({})

  const {
    state: { id: companyId },
  } = useCompanyDetailContextValue()

  const { mutate, isPending, isError, error } =
    useEditGeneralDetailsMutation(companyId)

  const value: EditGeneralDetailsFlowProviderContextState = {
    actions: {
      submitFormToServer: mutate,
      setFormData,
    },
    state: {
      formData,
      mutationState: {
        isPending,
        isError,
        errorMessage: parseErrorMessage(error),
      },
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useEditGeneralDetailsFlowContextValue }
