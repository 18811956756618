import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  useAppTheme,
  Table,
} from '@workwhile/ui'
import React, { useMemo, useState } from 'react'
import { useCompaniesContextValue } from '../context/CompaniesProvider'
import { ColumnDef } from '@tanstack/react-table'
import { Companies } from '../types'
import { ArrowRight } from 'lucide-react'
import { formatDistanceToNowStrict, formatDate } from 'date-fns'

const columns: ColumnDef<Companies[number]>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Created',
    accessorKey: 'createdAt',
    cell: ({ row }) => {
      const createdOn = formatDate(row.original.createdAt, 'MMM, dd, yyyy')
      const createdAt = formatDate(
        row.original.createdAt,
        'MMM, dd, yyyy, hh:mm a'
      )
      const distance = formatDistanceToNowStrict(row.original.createdAt, {
        addSuffix: true,
      })
      return (
        <Text title={createdAt}>
          {createdOn} ({distance})
        </Text>
      )
    },
  },
  {
    header: 'Type',
    accessorKey: 'type',
  },
  {
    header: 'Switch',
    cell: ({ row }) => {
      return (
        <Button
          as={Link}
          to={`/company/switch/${row.original.id}`}
          variant="primary"
          kind="medium"
          target="_blank"
        >
          <Text color="white">Switch</Text>
        </Button>
      )
    },
  },
  {
    header: 'Detail',
    cell: ({ row }) => {
      const { colors } = useAppTheme()
      return (
        <Link to={`/companies/${row.original.id}`}>
          View <ArrowRight size={14} color={colors.primary} />
        </Link>
      )
    },
  },
]

const AlternateKeywords = ({
  alternateKeywords,
}: {
  alternateKeywords: string[]
}) => {
  if (alternateKeywords.length === 0) {
    return null
  }

  const lastKeyword = alternateKeywords.pop()
  const keywordsString =
    alternateKeywords.length > 0
      ? `${alternateKeywords
          .map((v) => `"${v}"`)
          .join(', ')} or "${lastKeyword}"`
      : `"${lastKeyword}"`

  return <Text mt={1}>Maybe you're looking for {keywordsString}?</Text>
}

export const CompaniesTableContainer = () => {
  const {
    state: { filteredData, alternateKeywords, pagination },
    actions: { setPagination },
  } = useCompaniesContextValue()

  const doesHaveData = filteredData.length > 0

  return (
    <Box>
      {doesHaveData ? (
        <Table
          columns={columns}
          data={filteredData}
          enableSorting
          clientPagination={{ state: pagination, setState: setPagination }}
        />
      ) : (
        <Flex
          mb={2}
          flexDirection="column"
          alignItems="center"
          color="lightText"
          height={258}
          justifyContent="center"
          maxWidth={['100%', '100%', '50%', '50%']}
          margin="auto"
        >
          <Text>
            No companies matched your search. Try using different keywords.{' '}
          </Text>
          <AlternateKeywords alternateKeywords={alternateKeywords} />
        </Flex>
      )}
    </Box>
  )
}
