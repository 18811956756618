import React from 'react'
import { Box, Text, useAppTheme } from '@workwhile/ui'
import { toZonedTime, format as tzFormat } from 'date-fns-tz'
import CollapsibleSection from 'components/Shared/CollapsibleSection'
import { WILL_OR_HAS_WORKED_STATUSES } from 'lib/work'
import { WorkShift } from 'api/typings'
import { ShiftLocationChange } from './ShiftLocationChange'
import { parseISO } from 'date-fns'

type Props = {
  shift: WorkShift
  currShiftId: number | null
  idx: number
  refetchLTA?: () => void
}

export const ShiftCollapsibleSection = ({
  shift,
  currShiftId,
  idx,
  refetchLTA,
}: Props) => {
  const { colors } = useAppTheme()

  const timeZone = shift?.location?.address?.timezone
  const startsAtZoned = toZonedTime(shift.startsAt, timeZone)
  const endsAtZoned = toZonedTime(shift.endsAt, timeZone)

  const startsAtFormatted = tzFormat(startsAtZoned, 'yyyy-MM-dd h:mma zzz', {
    timeZone,
  })
  const endsAtFormatted = tzFormat(endsAtZoned, 'yyyy-MM-dd h:mma zzz', {
    timeZone,
  })
  const isPastShift = new Date() > new Date(shift.startsAt)
  const isCurrentlyViewingShift = shift.id === currShiftId
  const numScheduledWorkers = shift?.work?.filter((work) =>
    WILL_OR_HAS_WORKED_STATUSES.includes(work?.status)
  )?.length

  return (
    <CollapsibleSection
      title={
        <Text
          data-context-key="shift_id"
          data-context-value={shift.id}
          data-context-label={parseISO(shift.startsAt)}
          fontWeight={isCurrentlyViewingShift ? 'bold' : 'normal'}
          fontSize={2}
          color={isPastShift ? 'neutral200' : null}
        >
          {idx + 1}. {isPastShift ? '(Past) ' : ''}

            <Text as="span">Shift {shift.id}</Text>{' | '}
            <Text as="span">
              {numScheduledWorkers} of {shift.workersNeeded} workers
            </Text>{' | '}
            <Text as="span">
              {startsAtFormatted} - {endsAtFormatted}
            </Text>{' | '}
            <Text
              as="span"
              data-context-key="location_id"
              data-context-value={shift?.location?.id}
              data-context-label={`${shift?.location?.name}, ${shift?.location?.address?.city} ${shift?.location?.address?.state}`}
            >
              {shift?.location?.name} ({shift?.location?.id}) |{' '}
              {shift?.location?.address?.street}
              {shift?.location?.address?.unit
                ? ` ${shift?.location?.address?.unit}`
                : ''}{' '}
              {shift?.location?.address?.city} {shift?.location?.address.state}{' '}
              {shift?.location?.address.zip}
            </Text>

        </Text>
      }
      contentStyles={{ marginBottom: 0, padding: 0 }}
    >
      <Box p={3} bg={colors.neutrals[100]}>
        <ShiftLocationChange shift={shift} successCallback={refetchLTA} />
      </Box>
    </CollapsibleSection>
  )
}
