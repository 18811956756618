import api from "../api";
import graphql from "../graphql";

export function onW2Change(companyId, responseCallback, errorCallback) {
    api
      .put(`/admin/company/${companyId}/toggle_w2`)
      .then(() => {
        responseCallback()
      })
      .catch((error) => {
        errorCallback(error)
      });

}

export function onPositionDataChange(positionId, data, responseCallback, errorCallback) {
  api
      .put(`/admin/positions/${positionId}`, data)
      .then((response) => {
        responseCallback(response)
      })
      .catch((error) => {
        errorCallback(error)
      });
}


export function approvePosition(positionId, responseCallback, errorCallback) {
  api
      .post(`/admin/listing/approve`, {position_id: positionId})
      .then((response) => {
        responseCallback(response)
      })
      .catch((error) => {
        errorCallback(error)
      });
}


export function fetchPositionCompanyData(positionId, responseCallback, errorCallback) {
    graphql(
        `admin {
            positions(positionId: ${positionId}) {
                    id
                    positionTemplate {
                        id
                        name
                    }
                    isApproved
                    bundling
                    name
                    about
                    prescreened
                    isTipEligible
                    allowAutocushion
                    clockInCodeEnabled
                    clockOutCodeEnabled
                    requirements2 {
                        id
                        name
                        category
                        credential
                        verifiable
                        isWorkerVisible
                    }
                    mustHaveRequirements {
                        id
                    }
                    company {
                        id
                        name
                        isW2
                        isW2Changeable
                        chargeUpfront
                        defaultPaymentSource
                        isAbleToPostShifts
                        companyShiftEndPaymentDelayHours
                        minPayPolicies {
                            startsAt
                            minPayLength
                        }
                        takePercentages {
                            rate1099
                            w2Regular
                            w2Overtime
                            w2Double
                            bonus
                            tip
                        }
                        cancellationPolicies {
                          startsAt
                          policyWindows {
                            cutoffTimeHours
                            chargePercentage
                          }
                        }
                    }
            }
        }`
    ).then(response => {
        responseCallback(response)
      }).catch(error => {
        errorCallback(error)
      })
}


export function updateMinPayPolicy(companyId, newMinutes, newStartDate, responseCallback, errorCallback) {
  api
      .post(`/admin/company/${companyId}/min_pay_policy`, {
        min_pay_length_in_minutes: newMinutes,
        policy_start_date: newStartDate
      })
      .then((response) => {
        responseCallback(response)
      })
      .catch((error) => {
        errorCallback(error)
      });
}

export function updateTakePercentage(companyId, data, responseCallback, errorCallback) {
  // Data may need to be rekeyed to match the backend.
  if (data.rate1099) {
    data.rate_1099 = data.rate1099
    delete data.rate1099
  }
  if (data.w2Regular) {
    data.w2_regular = data.w2Regular
    delete data.w2Regular
  }
  if (data.w2Overtime) {
    data.w2_overtime = data.w2Overtime
    delete data.w2Overtime
  }
  if (data.w2Double) {
    data.w2_double = data.w2Double
    delete data.w2Double
  }
  api
      .post(`/admin/company/${companyId}/take_rate_percentage`, data)
      .then((response) => {
        responseCallback(response)
      })
      .catch((error) => {
        errorCallback(error)
      });
}


export function addNewRequirementToPosition(payload, responseCallback, errorCallback) {
    api
    .post(`/admin/position_requirements`, payload)
    .then((response) => {
       responseCallback(response)
    })
    .catch((error) => {
       errorCallback(error)
    });
}


export function addRequirementToPosition (positionId, requirementId, responseCallback, errorCallback) {
  api
    .put(`/admin/position_requirements`, {
        position_id: positionId,
        requirement_id: requirementId,
      })
    .then((response) => {
        responseCallback(response)
    })
    .catch((error) => {
        errorCallback(error)
    });


}
export function removeRequirementFromPosition (positionId, requirementId, responseCallback, errorCallback) {
  api
      .delete(`/admin/position_requirements`, {
          params: {
              position_id: positionId,
              requirement_id: requirementId
          }
      })
    .then((response) => {
        responseCallback(response)
    })
    .catch((error) => {
      errorCallback(error)

    });
}

export function fetchRequirementData(positionId, responseCallback, errorCallback) {
  graphql(
    `admin {
        positions(positionId: ${positionId}) {
          id
          name
          about
          prescreened
          isTipEligible
          requirements2 {
              id
              name
              category
              credential
              verifiable
              isWorkerVisible
          }
          mustHaveRequirements {
              id
          }
          company {
            id
            name
          }
        }
    }`
  ).then(response => {
    responseCallback(response)

  }).catch(error => {
    errorCallback(error)
  })
}


export function fetchAllRequirementsForParams(params, responseCallback, errorCallback)  {
  graphql(
    `admin {
        allRequirements
            ${params}
         {
            id
            name
            category
            credential
            verifiable
            isWorkerVisible
         }
    }`
  ).then(response => {
      responseCallback(response)
    }).catch(error => {
      errorCallback(error)
    })
}

export function fetchUpcomingShiftsForPosition(positionId, companyId, responseCallback, errorCallback
  ) {
  graphql(
    `admin {
      companies(ids: [${companyId}]) {
        name,
        shifts2(positionId:${positionId}, status:"scheduled") {
          items {
            id
            listing {
              id
            }
            location {
              id
              name
              arrivalInstructions
              address {
                street
                unit
                city
                state
                zip
                lat
                long
                radius
              }
              clockInLat
              clockInLong
              clockInRadius
            }
            startsAt
            endsAt
            workersNeeded
            pay
            createdBy {
              id
              name
            }
            leadShifts {
              id
              startsAt
              endsAt
              position {
                id
              }
              workersNeeded
            }
            arrivalInstructions
          }
        }
      }
    }`
  )
    .then((response) => {
      responseCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
}
