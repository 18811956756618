import api from 'api'

type AssignShiftRequest = {
  shiftId: number
  userIds: number[]
}

export const assignShift = async ({
  shiftId,
  userIds,
}: AssignShiftRequest): Promise<void> => {
  // FIXME: Backend should expect an array of user ids instead of a comma-separated string
  const transformedUserIds = userIds.join(',')
  const response = await api.put(`/admin/shifts/assign`, {
    user_ids: transformedUserIds,
    shift_id: shiftId,
  })

  return response.data
}
