import React from 'react'
import { AccordionCard } from 'components/AccordionCard'
import { useCompanyDetailContextValue } from '../context/CompanyDetailProvider'
import { Box, Flex, Label, Link, Text, useAppTheme } from '@workwhile/ui'
import { CompanyDetail } from '../types'

const PositionRow = ({
  position,
  isLast,
}: {
  position: CompanyDetail['positions'][0]
  isLast: boolean
}) => {
  const { radii } = useAppTheme()

  return (
    <Box
      border="1px solid"
      borderColor="neutrals.100"
      p={3}
      borderRadius={radii.standard}
      width="100%"
      mb={isLast ? 0 : 3}
      justifyContent="space-between"
    >
      <Flex
        flexDirection="column"
        data-context-key="position_id"
        data-context-value={position.id}
        data-context-label={position.name}
      >
        <Text>
          {position.name}{' '}
          <Link
            to={`/position_editor?positionId=${position.id}`}
            target="_blank"
          >
            ({position.id})
          </Link>
        </Text>
      </Flex>
    </Box>
  )
}

export const Positions = () => {
  const {
    state: { positions },
  } = useCompanyDetailContextValue()

  const positionRows = positions.map((position, index) => {
    return (
      <PositionRow
        key={`position-rows-${position.id}-${index}`}
        position={position}
        isLast={index === positions.length - 1}
      />
    )
  })

  return (
    <AccordionCard
      title={
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize={2} color="lightText">
            Positions
          </Text>
          <Label
            variant="none"
            size="medium"
            ml={2}
            style={{
              padding: 8,
            }}
          >
            {positions.length}
          </Label>
        </Flex>
      }
      // Intentionally disabled. This should work, unsure why it's throwing an error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      width="100%"
      isAccordion
      defaultOpen={false}
    >
      {positionRows.length > 0 ? (
        positionRows
      ) : (
        <Text color="lightText" fontSize={1}>
          No positions found
        </Text>
      )}
    </AccordionCard>
  )
}
