import React, { useState } from 'react'
import { User } from '../Shared/common_defs'
import { defs } from '../Shared/defs'
import { Heading, Text } from '@workwhile/ui'

interface Props {
  user: User
}

const UserHeader = ({ user }: Props) => {
  const [copied, _setCopied] = useState(false)

  const wasCopied = () => {
    _setCopied(true)
    setTimeout(() => _setCopied(false), 1000)
  }

  const copyToClipboard = (str: string) => {
    let textArea = document.createElement('textarea')
    textArea.value = str
    // make the textarea out of viewport
    textArea.style.position = 'fixed'
    textArea.style.left = '-999999px'
    textArea.style.top = '-999999px'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    return new Promise((res, rej) => {
      let successful = document.execCommand('copy')
      if (successful) {
        wasCopied()
      }
      textArea.remove()
    })
  }

  return (
    <span>
      <b>
        <Text
          as={'a'}
          href={`https://console.cloud.google.com/logs/query;query=resource.type%3D"gae_app" log_name%3D"projects%2Fworkwhile-247820%2Flogs%2Flib.observability.structured" jsonPayload.message.type%3D"app_request_log" jsonPayload.message.user_id%3D${user.id};timeRange=PT24H;summaryFields=jsonPayload%2Fmessage%2Fstatus_code,jsonPayload%2Fmessage%2Fmethod,jsonPayload%2Fmessage%2Fpath:false:32:beginning?project=workwhile-247820&folder=true&organizationId=136924707921&query=`}
          target="_blank"
          fontWeight={2}
          fontSize={3}
        >
          {user.name}
        </Text>
      </b>
      <span style={{ marginLeft: 14, color: defs.mediumBlack }}>|</span>
      <span style={{ marginLeft: 14, color: defs.mediumBlack }}>
        <a
          data-context-key="user_id"
          data-context-value={user.id}
          data-context-label={user.name}
          href={`https://app.intercom.com/apps/ozmz3fh8/users/show?user_id=${user.id}`}
          target="_blank"
        >
          {user.id}
        </a>
        <button
          style={copyButtonStyle}
          onClick={() => copyToClipboard(user.id)}
        >
          Copy
        </button>
      </span>
      <span style={{ marginLeft: 14, color: defs.mediumBlack }}>|</span>
      <span style={{ marginLeft: 14, color: defs.mediumBlack }}>
        <a
          href={`https://analytics.amplitude.com/workwhile/project/241707/search/user_id%3D${user.firebaseId}`}
          target="_blank"
        >
          {user.firebaseId}
        </a>
        <button
          style={copyButtonStyle}
          onClick={() => copyToClipboard(user.firebaseId)}
        >
          Copy
        </button>
      </span>
      {copied && (
        <span style={{ marginLeft: 14, color: defs.darkAlertGreen }}>
          Copied!
        </span>
      )}
    </span>
  )
}

const copyButtonStyle = {
  marginLeft: 14,
  borderColor: defs.mediumBlack,
  borderWidth: 1,
  backgroundColor: defs.white,
  borderRadius: defs.borderRadiusM,
  fontSize: 12,
}

export default UserHeader
