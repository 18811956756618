import * as zod from 'zod'

export const addCompanySchema = zod
  .object({
    companyIdInput: zod.coerce
      .number({
        invalid_type_error: 'Company Id must be a number',
      })
      .optional(),
    // This must be an array of objects instead of array of numbers based on the recommendation here:
    // https://github.com/orgs/react-hook-form/discussions/7586#discussioncomment-1999615
    companiesToAdd: zod
      .array(
        zod.object({
          id: zod.number().positive(),
        }),
        {
          required_error: 'Please add at least one company',
        }
      )
      .min(1, {
        message: 'Please add at least one company',
      }),
    companiesInGroup: zod.array(
      zod.object({
        id: zod.number().positive(),
      })
    ),
    allCompaniesInGroup: zod.array(
      zod.object({
        id: zod.number().positive(),
      })
    ),
  })
  .superRefine((data, ctx) => {
    const { companiesToAdd, companiesInGroup, allCompaniesInGroup } = data
    const companiesToAddIds = companiesToAdd.map((company) => company.id)
    const companiesToAddSet = new Set(companiesToAddIds)
    const companiesInGroupIds = companiesInGroup.map((company) => company.id)
    const companiesInGroupSet = new Set(companiesInGroupIds)

    const allCompaniesInGroupIds = allCompaniesInGroup.map(
      (company) => company.id
    )
    const allCompaniesInGroupSet = new Set(allCompaniesInGroupIds)

    const duplicatesWithCompaniesInGroup =
      companiesToAddSet.intersection(companiesInGroupSet)

    const unknownCompanies = companiesToAddSet.difference(
      allCompaniesInGroupSet
    )

    if (duplicatesWithCompaniesInGroup.size > 0) {
      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        message: `These companies (${Array.from(duplicatesWithCompaniesInGroup).join(', ')}) are already in the group. Please remove them from the list and try again.`,
        path: ['companiesToAdd'],
      })
      return false
    }

    if (unknownCompanies.size > 0) {
      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        message: `Found unknown companies (${Array.from(unknownCompanies).join(', ')}) in the list. Please remove them and try again.`,
        path: ['companiesToAdd'],
      })
      return false
    }

    return true
  })

export type AddCompanySchema = zod.infer<typeof addCompanySchema>
