import React from 'react'
import { PositionEditorContextProvider } from './PositionEditorContext'
import { PositionEditorContainer } from './PositionEditorContainer'

export function PositionEditor() {
  return (
    <PositionEditorContextProvider>
      <PositionEditorContainer />
    </PositionEditorContextProvider>
  )
}
