import { AttributesSelector } from '@workwhile/ui'
import React, { useMemo } from 'react'
import { FormProvider, useFormContext } from 'react-hook-form'
import { FiltersSchema } from '../filtersSchema'
import { CompanyIdsFilter } from './CompanyIdsFilter'
import { CompanyGroupIdsFilter } from './CompanyGroupIdsFilter'

export const CompanyFilters = () => {
  const methods = useFormContext<FiltersSchema>()

  const company = methods.watch('company')

  const resetToDefaultValues = () => {
    methods.reset({
      ...methods.getValues(),
      company: {
        companyGroupIds: [],
        companyIds: [],
      },
    })
  }

  const numberOfCompanyIdsSelected = useMemo(() => {
    return company.companyIds?.length || 0
  }, [company.companyIds])

  const numberOfCompanyGroupIdsSelected = useMemo(() => {
    return company.companyGroupIds?.length || 0
  }, [company.companyGroupIds])

  const selectedAttributes = useMemo(() => {
    const fields = []
    const pluralRules = new Intl.PluralRules('en-US')

    if (numberOfCompanyIdsSelected > 0) {
      const plural = pluralRules.select(numberOfCompanyIdsSelected)
      const suffix = plural === 'one' ? 'Company' : 'Companies'
      fields.push(`${numberOfCompanyIdsSelected} ${suffix}`)
    }

    if (numberOfCompanyGroupIdsSelected > 0) {
      const plural = pluralRules.select(numberOfCompanyGroupIdsSelected)
      const suffix = plural === 'one' ? 'Company Group' : 'Company Groups'
      fields.push(`${numberOfCompanyGroupIdsSelected} ${suffix}`)
    }

    return fields
  }, [numberOfCompanyIdsSelected, numberOfCompanyGroupIdsSelected])

  return (
    <FormProvider {...methods}>
      <AttributesSelector
        label="Company"
        rootGroupTitle="Filter by"
        selectedAttributes={selectedAttributes}
        onClearAll={resetToDefaultValues}
        selectedAttributeLimit={5}
        groups={[
          <AttributesSelector.Group
            key="company-ids"
            title="Company"
            numberOfSelectedAttributes={numberOfCompanyIdsSelected}
          >
            <CompanyIdsFilter />
          </AttributesSelector.Group>,
          <AttributesSelector.Group
            key="company-group-ids"
            title="Company Group"
            numberOfSelectedAttributes={numberOfCompanyGroupIdsSelected}
          >
            <CompanyGroupIdsFilter />
          </AttributesSelector.Group>,
        ]}
      />
    </FormProvider>
  )
}
