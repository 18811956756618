import React from 'react'
import { AddCompaniesFlowProvider } from './context/AddCompaniesFlowProvider'
import { AddCompaniesFlowMain } from './components/AddCompaniesFlowMain'

export const AddCompaniesFlow = () => {
  return (
    <AddCompaniesFlowProvider>
      <AddCompaniesFlowMain />
    </AddCompaniesFlowProvider>
  )
}
