import React from 'react'
import api from '../../api'

import {
  Box,
  Button,
  Card,
  Checkbox,
  Field,
  Flex,
  Heading,
  Input,
  Message,
  Select,
  Textarea,
} from '@workwhile/ui'

const INITIAL_STATE = {
  company_name: '',
  company_logo_url: '',
  position_name: '',
  pay: '',
  expires_at: '',
  needs_car: false,
  needs_license: false,
  needs_full_time: false,
  experience_requirement: 'none',
  street: '',
  unit: '',
  city: '',
  state: '',
  zip: '',
  about: '',
  error: '',
}

// NOTE: This is intended to map to the `CompanyPosition.ExperienceRequirement`
//       in the `backend` codebase.
const requirementOptions = [
  { value: 'none', label: 'None' },
  { value: 'delivery_driver', label: 'Delivery Driver' },
  { value: 'warehouse', label: 'Warehouse' },
  { value: 'event_staff', label: 'Event Staff' },
  { value: 'retail_associate', label: 'Retail Associate' },
  { value: 'food', label: 'Food' },
]

class ListingAdCreate extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.state = { ...INITIAL_STATE }
  }

  onSubmit = (event) => {
    event.preventDefault()
    const {
      company_name,
      company_logo_url,
      position_name,
      pay,
      expires_at,
      needs_car,
      needs_license,
      needs_full_time,
      experience_requirement,
      street,
      unit,
      city,
      state,
      zip,
      about,
    } = this.state

    if (
      company_name === '' ||
      company_logo_url === '' ||
      position_name === '' ||
      pay === '' ||
      expires_at === '' ||
      experience_requirement === '' ||
      zip === ''
    ) {
      alert('Please complete all required fields')
      return
    }

    api
      .post('/admin/listing_ad/create', {
        company_name,
        company_logo_url,
        position_name,
        pay,
        expires_at,
        needs_car,
        needs_license,
        needs_full_time,
        experience_requirement,
        street,
        unit,
        city,
        state,
        zip,
        about,
      })
      .then((response) => {
        console.log(response)
        alert('created listing ad!')
        this.setState({ ...INITIAL_STATE })
      })
      .catch((error) => {
        alert(`Error: ${error}`)
        console.log(error)
      })
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const {
      company_name,
      company_logo_url,
      position_name,
      pay,
      expires_at,
      needs_car,
      needs_license,
      needs_full_time,
      experience_requirement,
      street,
      unit,
      city,
      state,
      zip,
      about,
      error,
    } = this.state

    return (
      <Card width={[1, 600]}>
        <Heading>Create Listing Ad</Heading>
        <form onSubmit={this.onSubmit} className="form">
          <Flex>
            <Box pr={3} flex={1}>
              <Field label="Company Name*">
                <Input
                  name="company_name"
                  required
                  value={company_name}
                  onChange={this.onChange}
                  type="text"
                  placeholder="e.g. Wine.com"
                />
              </Field>
              <Field label="Company Logo URL*">
                <Input
                  name="company_logo_url"
                  required
                  value={company_logo_url}
                  onChange={this.onChange}
                  type="text"
                  placeholder="e.g. http://www.blahblahimg.com/blahsfl"
                />
              </Field>
              <Field label="Position Name*">
                <Input
                  name="position_name"
                  required
                  value={position_name}
                  onChange={this.onChange}
                  type="text"
                  placeholder="e.g. Warehouse Associate"
                />
              </Field>
              <Field label="Pay*">
                <Input
                  name="pay"
                  required
                  value={pay}
                  onChange={this.onChange}
                  type="text"
                  placeholder="e.g. up to $500 per week"
                />
              </Field>
              <Field label="Expires At (YYYY-MM-DD)*">
                <Input
                  name="expires_at"
                  required
                  value={expires_at}
                  onChange={this.onChange}
                  type="text"
                  placeholder="e.g. 2020-12-23"
                />
              </Field>
              <Checkbox
                name="needs_car"
                defaultChecked={needs_car}
                onChange={(checked) => this.setState({ needs_car: checked })}
              >
                Needs car
              </Checkbox>
              <Box my={3}>
                <Checkbox
                  name="needs_license"
                  defaultChecked={needs_license}
                  onChange={(checked) =>
                    this.setState({ needs_license: checked })
                  }
                >
                  Needs license
                </Checkbox>
              </Box>
              <Box my={3}>
                <Checkbox
                  name="needs_full_time"
                  defaultChecked={needs_full_time}
                  onChange={(checked) =>
                    this.setState({ needs_full_time: checked })
                  }
                >
                  Needs full time
                </Checkbox>
              </Box>
              <Field label="Experience requirement*">
                <Select
                  name="experience_requirement"
                  required
                  value={requirementOptions.find(
                    (option) => option.value === experience_requirement
                  )}
                  onChange={(option) => {
                    this.setState({ experience_requirement: option.value })
                  }}
                  options={requirementOptions}
                />
              </Field>
            </Box>
            <Box flex={1}>
              <Field label="Street">
                <Input
                  name="street"
                  value={street}
                  onChange={this.onChange}
                  type="text"
                  placeholder="e.g. 169 11th St"
                />
              </Field>
              <Field label="Unit">
                <Input
                  name="unit"
                  value={unit}
                  onChange={this.onChange}
                  type="text"
                  placeholder="e.g. #1"
                />
              </Field>
              <Field label="City">
                <Input
                  name="city"
                  value={city}
                  onChange={this.onChange}
                  type="text"
                  placeholder="e.g. San Francisco"
                />
              </Field>
              <Field label="State">
                <Input
                  name="state"
                  value={state}
                  onChange={this.onChange}
                  type="text"
                  placeholder="e.g. CA"
                />
              </Field>
              <Field label="Zip*">
                <Input
                  name="zip"
                  required
                  value={zip}
                  onChange={this.onChange}
                  type="text"
                  placeholder="e.g. 94103"
                />
              </Field>
              <Field label="Job Description">
                <Textarea
                  name="about"
                  rows={4}
                  value={about}
                  onChange={this.onChange}
                  placeholder="e.g. We are looking for a warehouse associate to help us with blah blah blah"
                />
              </Field>
            </Box>
          </Flex>
          {error ? (
            <Message my={3} variant="error">
              {error}
            </Message>
          ) : null}
          <Button mt={3} type="submit" width={200}>
            Send
          </Button>
        </form>
      </Card>
    )
  }
}

export default ListingAdCreate
