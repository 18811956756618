import * as zod from 'zod'

export const editMoneyFlowSchema = zod
  .object({
    // Only used for 1099 companies
    takeRate: zod.coerce.number().min(0).max(100).optional(),
    bonusTakeRate: zod.coerce.number().min(0).max(100).optional(),
    tipTakeRate: zod.coerce.number().min(0).max(100).optional(),

    // Only used for W2 companies
    w2RegularTakeRate: zod.coerce.number().min(0).max(100).optional(),
    w2DoubleTakeRate: zod.coerce.number().min(0).max(100).optional(),
    w2OvertimeTakeRate: zod.coerce.number().min(0).max(100).optional(),

    // Only used for companies that have payment method set to "invoice"
    invoiceDueDays: zod.coerce.number().min(0).optional(),

    // Used to check if the values have changed
    currentValues: zod.object({
      // Only used for 1099 companies
      takeRate: zod.number().optional(),
      bonusTakeRate: zod.number().optional(),
      tipTakeRate: zod.number().optional(),

      // Only used for W2 companies
      w2RegularTakeRate: zod.number().optional(),
      w2DoubleTakeRate: zod.number().optional(),
      w2OvertimeTakeRate: zod.number().optional(),

      // Only used for companies that have payment method set to "invoice"
      invoiceDueDays: zod.number().optional(),
    }),
  })
  .superRefine((data, ctx) => {
    const haveAnyFieldsChanged = Object.keys(data.currentValues).some(
      (key) => data[key] !== data.currentValues[key]
    )

    if (!haveAnyFieldsChanged) {
      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        message:
          'No changes detected. Please modify at least one field to save.',
        path: ['currentValues'],
      })

      return false
    }

    return true
  })
