import api from 'api'
import { CompanyGroups } from 'pages/CompanyGroups/types'

type GetCompanyGroupsResponse = {
  items: CompanyGroups
}

export const getCompanyGroups = async () => {
  const response = await api.get<GetCompanyGroupsResponse>(
    '/admin/company_groups'
  )

  return response.data.items
}
