import React from 'react'
import { EditGeneralDetailsFlowProvider } from './context/EditGeneralDetailsFlowProvider'
import { EditGeneralDetailsFlowMain } from './components/EditGeneralDetailsFlowMain'

export const EditGeneralDetailsFlow = () => {
  return (
    <EditGeneralDetailsFlowProvider>
      <EditGeneralDetailsFlowMain />
    </EditGeneralDetailsFlowProvider>
  )
}
