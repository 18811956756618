import {
  useQueryStates,
  parseAsArrayOf,
  parseAsInteger,
  parseAsString,
} from 'nuqs'

export const useShiftQueryState = () => {
  const queryStates = useQueryStates(
    {
      shiftIds: parseAsArrayOf(parseAsInteger).withDefault([]),
      marketCodes: parseAsArrayOf(parseAsString).withDefault([]),
      scheduleRateMin: parseAsInteger,
      scheduleRateMax: parseAsInteger,
      fillRateMin: parseAsInteger,
      fillRateMax: parseAsInteger,
    },
    {
      urlKeys: {
        shiftIds: 'shift-ids',
        marketCodes: 'market-codes',
        scheduleRateMin: 'schedule-rate-min',
        scheduleRateMax: 'schedule-rate-max',
        fillRateMin: 'fill-rate-min',
        fillRateMax: 'fill-rate-max',
      },
    }
  )

  return queryStates
}
