import api from 'api'
import { WorkStatus } from 'api/typings'

type EditShiftWorkRequest = {
  shiftId: number
  workId: number
  status?: WorkStatus
  clockedInAt?: string
  clockedOutAt?: string
  leftEarly?: boolean
}

export const editShiftWork = async (request: EditShiftWorkRequest) => {
  const { shiftId, workId, status, clockedInAt, clockedOutAt, leftEarly } =
    request

  const response = await api.patch(`/admin/shift/${shiftId}/work/${workId}`, {
    status,
    clocked_in_at: clockedInAt,
    clocked_out_at: clockedOutAt,
    left_early: leftEarly,
  })

  return response.data
}
