import { useQuery } from '@tanstack/react-query'
import { getCompanies } from 'api/companies'
import { companiesKey } from './keys'

export const useCompaniesQuery = (
  options?: Pick<Parameters<typeof useQuery>[0], 'enabled'>
) => {
  return useQuery({
    queryKey: companiesKey,
    queryFn: getCompanies,
    staleTime: 1000 * 60 * 60 * 5, // 5 hours
    ...options,
  })
}
