import React from 'react'
import { ReferencePopover } from 'components/ReferencePopover'
import { Info } from 'lucide-react'
import { Box, Text } from '@workwhile/ui'
import { ReferenceTable } from 'components/ReferenceTable'
import { useFillRateRanges } from './useFillRateRanges'

const FillRateReferences = () => {
  const { goodRange, okRange, poorRange } = useFillRateRanges()

  const classifications = [
    {
      key: (
        <Box bg={goodRange.color} width={24} height={24} borderRadius="small" />
      ),
      description: `Rate above ${goodRange.min} is classified as "good"`,
    },
    {
      key: (
        <Box bg={okRange.color} width={24} height={24} borderRadius="small" />
      ),
      description: `Rate between ${okRange.min} - ${okRange.max} is classified as "ok"`,
    },
    {
      key: (
        <Box bg={poorRange.color} width={24} height={24} borderRadius="small" />
      ),
      description: `Rate below or equal to ${poorRange.max} is classified as "poor"`,
    },
  ]

  return (
    <Box
      maxWidth={376}
      color="neutrals.400"
      style={{ maxHeight: '424px', overflowY: 'auto' }}
    >
      <Text mb={3}>
        The color of the rating donut chart shows two things about the fill rate
        <br />
        <br />
        <Box as="ol" ml={3} style={{ listStyleType: 'auto' }}>
          <Box as="li">What is the fill rate of this shift?</Box>
          <Box as="li">Is this fill rate considered good, ok or bad?</Box>
        </Box>
        <br />
        Refer to visuals below to learn more.
      </Text>
      <ReferenceTable title="Classifications" data={classifications} />
    </Box>
  )
}

export const FillRateReferencePopover = () => {
  return (
    <ReferencePopover
      trigger={<Info size={14} style={{ marginLeft: 4 }} />}
      subTitle="Reference"
      title="Fill Rate"
      contentProps={{
        children: <FillRateReferences />,
      }}
    />
  )
}
