import React, { useMemo } from 'react'
import { useCompanyDetailContextValue } from '../context/CompanyDetailProvider'
import { InfoTable } from 'components/InfoTable'
import { AccordionCard } from 'components/AccordionCard'
import {
  Calendar,
  EllipsisVertical,
  Pencil,
  Percent,
  ReceiptText,
} from 'lucide-react'
import { formatPercentage } from 'lib/percentage'
import { Button, DropdownMenu, Flex, Text, useAppTheme } from '@workwhile/ui'
import { useCompanyDetailWorkflowsContextValue } from '../context/CompanyDetailWorkflowProvider'
import { formatDuration } from 'date-fns'

export const Money = () => {
  const { space } = useAppTheme()
  const {
    state: {
      type,
      takeRate1099,
      paymentMethod,
      bonusTakeRate,
      tipTakeRate,
      w2RegularTakeRate,
      w2DoubleTakeRate,
      w2OvertimeTakeRate,
      invoiceDueDays,
    },
  } = useCompanyDetailContextValue()

  const { editMoneyFlow } = useCompanyDetailWorkflowsContextValue()

  const isOnInvoice = paymentMethod === 'invoice'

  const formattedPaymentMethod = useMemo(() => {
    if (!paymentMethod) {
      return 'Not set'
    }

    if (paymentMethod === 'invoice') {
      return 'Invoice'
    }

    if (paymentMethod === 'card') {
      return 'Card'
    }

    if (paymentMethod === 'bank_account') {
      return 'Bank'
    }

    return paymentMethod
  }, [paymentMethod])

  return (
    <AccordionCard
      title={
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          width="100%"
        >
          <Text fontSize={2} color="lightText">
            Money
          </Text>

          <DropdownMenu
            trigger={
              <Button
                variant="secondary"
                kind="small"
                style={{ paddingLeft: space[1], paddingRight: space[1] }}
              >
                <EllipsisVertical size={14} />
              </Button>
            }
          >
            <DropdownMenu.Item onClick={editMoneyFlow.open}>
              <Flex as="span" alignItems="center" style={{ gap: 8 }}>
                <Pencil size={14} />
                Edit
              </Flex>
            </DropdownMenu.Item>
          </DropdownMenu>
        </Flex>
      }
      // Intentionally disabled. This should work, unsure why it's throwing an error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      maxWidth="50%"
    >
      <InfoTable>
        <InfoTable.Row
          icon={<ReceiptText size={14} />}
          label="Payment method"
          value={formattedPaymentMethod}
        />

        {isOnInvoice ? (
          <InfoTable.Row
            icon={<Calendar size={14} />}
            label="Invoice terms"
            value={formatDuration({ days: invoiceDueDays })}
          />
        ) : null}

        {type === '1099' ? (
          <>
            <InfoTable.Row
              icon={<Percent size={14} />}
              label="Take rate"
              value={formatPercentage(takeRate1099)}
            />

            <InfoTable.Row
              icon={<Percent size={14} />}
              label="Bonus take rate"
              value={formatPercentage(bonusTakeRate)}
            />

            <InfoTable.Row
              icon={<Percent size={14} />}
              label="Tip take rate"
              value={
                typeof tipTakeRate === 'number'
                  ? formatPercentage(tipTakeRate)
                  : 'N/A'
              }
            />
          </>
        ) : null}

        {type === 'w2' ? (
          <>
            <InfoTable.Row
              icon={<Percent size={14} />}
              label="W2 Regular take rate"
              value={formatPercentage(w2RegularTakeRate)}
            />

            <InfoTable.Row
              icon={<Percent size={14} />}
              label="W2 Double take rate"
              value={formatPercentage(w2DoubleTakeRate)}
            />

            <InfoTable.Row
              icon={<Percent size={14} />}
              label="W2 Overtime take rate"
              value={formatPercentage(w2OvertimeTakeRate)}
            />
          </>
        ) : null}
      </InfoTable>
    </AccordionCard>
  )
}
