import { useMemo } from 'react'

export interface UserData {
  lng: number
  lat: number
  days_since_last_seen: number
  shift_count: number
  is_w2_active: boolean
  is_w2_eligible: boolean
}

export interface WorkerData {
  users: UserData[]
  latitude: number
  longitude: number
  in_market: boolean
  is_w2_state: boolean
  is_w2_auto_eligibility_state: boolean
}

const isWithinDistance = (
  workerLat: number,
  workerLng: number,
  centerLat: number,
  centerLng: number,
  miles: number
) => {
  const latDiff = Math.abs(workerLat - centerLat)
  const lngDiff = Math.abs(workerLng - centerLng)
  return latDiff <= miles / 69 && lngDiff <= miles / 69
}

export function useFilteredData(
  workerData: WorkerData,
  filters: {
    distance: number
    minShifts: number
    lastActive: number
    isW2Active: boolean
    isW2Eligible: boolean
  }
) {
  const { distance, minShifts, lastActive, isW2Active, isW2Eligible } = filters

  return useMemo(() => {
    if (!workerData) {
      return {
        withinDistanceCount: 0,
        qualifiedWorkersCount: 0,
      }
    }
    let withinDistanceCount = 0
    let qualifiedWorkersCount = 0
    workerData.users.forEach((user) => {
      if (
        isWithinDistance(
          user.lat,
          user.lng,
          workerData.latitude,
          workerData.longitude,
          distance
        )
      ) {
        withinDistanceCount++

        // When both isW2Active and isW2Eligible are true, include workers that are either active or eligible
        // When only one is true, check for that specific requirement
        // When both are false, include all workers
        const meetsW2Requirements =
          isW2Active && isW2Eligible
            ? user.is_w2_active || user.is_w2_eligible
            : (isW2Active ? user.is_w2_active : true) &&
              (isW2Eligible ? user.is_w2_eligible : true)

        if (
          user.shift_count >= minShifts &&
          user.days_since_last_seen <= lastActive &&
          meetsW2Requirements
        ) {
          qualifiedWorkersCount++
        }
      }
    })

    return {
      withinDistanceCount,
      qualifiedWorkersCount,
    }
  }, [workerData, distance, minShifts, lastActive, isW2Active, isW2Eligible])
}
