import api from 'api'
import { Companies } from '../../pages/Companies/types'

type GetCompaniesResponse = {
  companies: Companies
}

export const getCompanies = async (): Promise<Companies> => {
  const response = await api.get<GetCompaniesResponse>('/admin/companies')
  return response.data.companies
}
