import React from 'react'
import { AccordionCard } from 'components/AccordionCard'
import { DonutProgressChart, Text } from '@workwhile/ui'
import { useShiftDetailContextValue } from '../../../context/ShiftDetailProvider'
import { isAfter } from 'date-fns'
import { useFillRateRanges } from './useFillRateRanges'
import { FillRateReferencePopover } from './FillRateReferencePopover'
import { formatPercentage } from 'lib/percentage'

export const FillRate = () => {
  const {
    state: { numFillRateWorkers, fillRate, workersNeeded, endsAt },
  } = useShiftDetailContextValue()

  const { getFillRateColor } = useFillRateRanges()
  const hasShiftEnded = isAfter(new Date(), endsAt)

  if (!hasShiftEnded) {
    return null
  }

  const fillRateColor = getFillRateColor(fillRate)

  return (
    <AccordionCard
      title={
        <Text fontSize={2} color="lightText">
          Fill Rate <FillRateReferencePopover />
        </Text>
      }
      size="fit"
      contentWrapperProps={{
        marginTop: 2,
      }}
    >
      <Text mb={3} fontSize={2}>
        Completed {numFillRateWorkers} / {workersNeeded}
      </Text>
      <DonutProgressChart
        progress={fillRate}
        valueFormatter={() => formatPercentage(fillRate)}
        size="md"
        color={fillRateColor}
      />
    </AccordionCard>
  )
}
