import React from 'react'
import { AccordionCard } from 'components/AccordionCard'
import { Button, Flex, Label, Text, useAppTheme } from '@workwhile/ui'
import { Link } from 'react-router-dom'
import { CompanyInGroup } from '../types'
import { Trash2 } from 'lucide-react'
import { useCompanyGroupDetailContextValue } from '../context/CompanyGroupDetailProvider'
import { formatDistanceToNowStrict } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { getLocalTimezone } from 'lib/time'
import { useCompanyGroupDetailWorkflowsContextValue } from '../context/CompanyGroupDetailWorkflowProvider'
type CompanyRowProps = {
  company: CompanyInGroup
  isLast: boolean
}

const CompanyRow = ({ company, isLast }: CompanyRowProps) => {
  const { radii } = useAppTheme()
  const { removeCompanyFromGroupFlow } =
    useCompanyGroupDetailWorkflowsContextValue()
  const timezone = getLocalTimezone()

  const createdAt = formatInTimeZone(company.createdAt, timezone, 'MMM d, yyyy')

  const createdAtDayTitle = formatInTimeZone(
    company.createdAt,
    timezone,
    'MMMM d, yyyy, h:mm a z'
  )

  const createdAtDayDistance = formatDistanceToNowStrict(company.createdAt, {
    addSuffix: true,
  })

  const handleRemoveCompany = () => {
    removeCompanyFromGroupFlow.open({
      companyId: company.id,
      companyName: company.name,
    })
  }

  return (
    <Flex
      border="1px solid"
      borderColor="neutrals.100"
      p={3}
      borderRadius={radii.standard}
      width="100%"
      mb={isLast ? 0 : 3}
      justifyContent="space-between"
    >
      <Flex flexDirection="column">
        <Text>
          {company.name}{' '}
          <Link to={`/companies/${company.id}`}>({company.id})</Link>
        </Text>
        <Text
          color="lightText"
          title={`Created at ${createdAtDayTitle} (${createdAtDayDistance})`}
        >
          Created on {createdAt}
        </Text>
      </Flex>
      <Flex alignItems="center" gap={3}>
        <Button
          variant="secondary"
          kind="medium"
          danger
          color="errors.200"
          onClick={handleRemoveCompany}
          title={`Remove "${company.name}" from group`}
        >
          <Trash2 size={16} />
        </Button>

        <Button
          as={Link}
          to={`/company/switch/${company.id}`}
          variant="primary"
          kind="medium"
          target="_blank"
        >
          <Text color="white">Switch</Text>
        </Button>
      </Flex>
    </Flex>
  )
}

export const Companies = () => {
  const { state } = useCompanyGroupDetailContextValue()
  const { companies } = state

  const companyRows = companies.map((company, index) => {
    return (
      <CompanyRow
        key={`company-row-${company.id}-${index}`}
        company={company}
        isLast={index === companies.length - 1}
      />
    )
  })

  return (
    <AccordionCard
      title={
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize={2} color="lightText">
            Companies
          </Text>
          <Label
            variant="none"
            size="medium"
            ml={2}
            style={{
              padding: 8,
            }}
          >
            {companies.length}
          </Label>
        </Flex>
      }
      isAccordion
      defaultOpen={true}
    >
      {companyRows.length > 0 ? (
        companyRows
      ) : (
        <Text color="lightText" fontSize={1}>
          No companies found
        </Text>
      )}
    </AccordionCard>
  )
}
