import React, { useContext, useState } from 'react'
import './Commands.css'
import { Button, Box } from '@workwhile/ui'

import Highlight from './Highlight'
import CommandField from './CommandField'
import { CommandFieldSchema, CommandSchema } from 'api/commands'
import { CommandContext } from './CommandProvider'

const getSortOrder = (
  a: Pick<CommandFieldSchema, 'type' | 'name' | 'multiple'>,
  b: Pick<CommandFieldSchema, 'type' | 'name' | 'multiple'>
): number => {
  // Checkboxes are last
  if (a.type === 'checkbox') {
    return 4
  }
  // Dates come after other types
  if (a.name.includes('date')) {
    return 3
  }
  // IDS come first, though
  if (!a.name.includes('_id') && b.name.includes('_id')) {
    return 2
  }
  // Generally, alphabetical order
  return a.name > b.name ? 1 : -1
}

const Command = ({
  help,
  args,
  options,
  name,
  app_group,
}: CommandSchema) => {
  const { values, onSubmit } = useContext(CommandContext)
  const [error, setError] = useState(null)
  const [output, setOutput] = useState(null)
  const [isExecuting, setIsExecuting] = useState(false)

  const handleSubmit = async () => {
    setIsExecuting(true)
    onSubmit(app_group, name)
      .then(result => setOutput(result.output))
      .catch(error => setError(error.message))
      .finally(() => setIsExecuting(false))
  }

  return (
    <Box>
      {help ? (
        <Box mb="4" style={{ whiteSpace: 'pre-line' }}>
          <Highlight text={help} match={values.search} />
        </Box>
      ) : null}
      <Box mb="2">
        {[...args, ...options]
          ?.sort(getSortOrder)
          ?.map((field) => (
            <CommandField
              key={`${name}-${field.name}`}
              htmlFor={`${name}-${field.name}`}
              value={values[field.name]}
              match={values.search}
              {...field}
            />
          ))}
      </Box>
      <Box>
        <Button loading={isExecuting} onClick={handleSubmit}>
          Execute
        </Button>
        {error && <p className="Command-error">Error: {error}</p>}
        {output && <pre className="Command-output">{output}</pre>}
      </Box>
    </Box>
  )
}

export default Command
