import React, { createContext, PropsWithChildren, useState } from 'react'
import { useSessionContext } from './useSessionContext'
import { useUrlContext } from './useUrlContext'
import { CommandValues } from './types'
import usePageContext from './usePageContext'
import { pick } from 'ramda'
import api from 'api'
import { useLocation } from 'react-router-dom'
import { useCommandsList } from 'queries/commands'
import { CommandSchema } from 'api/commands'

export interface CommandContextValueProps {
  /** Lists of CLI commands grouped by app_group */
  commands: CommandSchema[]
  /** Unsaved values pulled from data attributes in the DOM, to be selected */
  contextValues: Record<string, { key: string; label: string; value: string }[]>
  /** Persists selected values to local storage or the URL until submit */
  onChange: (next: CommandValues) => void
  /** Executes the given command with its subset of form fields */
  onSubmit: (
    group: CommandSchema['app_group'],
    name: CommandSchema['name']
  ) => Promise<{ output: string }>
  /** Scan for page context */
  refreshPageContext: () => void
  /** Saved values which will be persisted through navigation events */
  values: CommandValues
  /** Syncing to the URL is off by default until enabled */
  setEnableUrlSync: (setting: boolean) => void
}

export const CommandContext = createContext<CommandContextValueProps>(null!)

export const CommandProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation()
  /** Load data from data attributes on the page */
  const pageContext = usePageContext(location.pathname)
  /** Load data from local storage */
  const sessionContext = useSessionContext('commands')
  /** Sync local storage with URL */
  const [enableUrlSync, setEnableUrlSync] = useState(false)
  const urlContext = useUrlContext({
    enabled: enableUrlSync,
    init: sessionContext.values,
  })

  const values = {
    ...sessionContext.values,
    ...urlContext.values,
  }

  const onChange = (next) => {
    sessionContext.onChange(next)
    urlContext.onChange(next)
  }

  const { data: commands } = useCommandsList()

  /** Given a command group and name, get the field names for that command
   *  and pick those values from saved values to submit */
  const onSubmit: CommandContextValueProps['onSubmit'] = async (
    appGroup,
    commandName
  ) => {
    const command = commands.find(
      (command) => command.name === commandName && command.app_group === appGroup
    )
    if (!command) {
      throw new Error(`${appGroup} command not found: ${commandName}`)
    }
    return api
      .post<{ exception: string; output: string }>('/admin/commands', {
        command: command.name,
        arguments: pick(
          [...command.args, ...command.options].map(({ name }) => name),
          values
        ),
      })
      .then((result) => {
        if (result.data?.exception) {
          throw new Error(result.data?.exception)
        }
        return result.data
      })
  }

  return (
    <CommandContext.Provider
      value={{
        commands,
        contextValues: pageContext.values,
        onChange,
        onSubmit,
        refreshPageContext: pageContext.refresh,
        values,
        setEnableUrlSync,
      }}
    >
      {children}
    </CommandContext.Provider>
  )
}
