import { useMutation, useQueryClient } from '@tanstack/react-query'
import { companyDetailKey } from './keys'
import { CompanyDetail } from 'pages/Company/CompanyDetail/types'
import { editGeneralInfo } from 'api/company/editGeneralInfo'

export const useEditGeneralDetailsMutation = (companyId: number) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (
      request: Required<Pick<Parameters<typeof editGeneralInfo>[1], 'type'>>
    ) => editGeneralInfo(companyId, request),
    onMutate: async ({ type }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: companyDetailKey(companyId) })

      const previousCompanyDetail = queryClient.getQueryData(
        companyDetailKey(companyId)
      )

      // Optimistically update to the new value
      queryClient.setQueryData<CompanyDetail>(
        companyDetailKey(companyId),
        (old) => ({
          ...old,
          type: type,
        })
      )

      return { previousCompanyDetail }
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(
        companyDetailKey(companyId),
        context.previousCompanyDetail
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: companyDetailKey(companyId) })
    },
  })
}
