import React from 'react'
import {
  Box,
  Flex,
  Text,
  SwitchRoot as Switch,
  SwitchThumb,
} from '@workwhile/ui'
import { WorkerData } from './useFilteredData'

interface FilterInputs {
  distance: number
  minShifts: number
  lastActive: number
  isW2Active: boolean
  isW2Eligible: boolean
}

interface Props {
  workerData: WorkerData | null
  filterInputs: FilterInputs
  appliedFilters: FilterInputs
  stats: {
    withinDistanceCount: number
    qualifiedWorkersCount: number
  }
  onFilterChange: (field: string, value: string | boolean) => void
  onApplyFilters: () => void
}

export const WorkerFilters: React.FC<Props> = ({
  workerData,
  filterInputs,
  appliedFilters,
  stats,
  onFilterChange,
  onApplyFilters,
}) => {
  if (!workerData) return null

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize={24} fontWeight="bold">
          Worker Supply
        </Text>
        <Text as="span" color="error" fontSize={14}>
          **Confidential - For Internal Use Only**
        </Text>
      </Flex>

      {/* Filter Controls */}
      <Flex gap={4} mb={4} alignItems="center">
        <Flex flexDirection="column">
          <Text fontSize={14}>Distance (miles)</Text>
          <input
            type="number"
            value={filterInputs.distance}
            onChange={(e) => onFilterChange('distance', e.target.value)}
            min={0}
            style={{ width: '80px' }}
          />
        </Flex>

        <Flex flexDirection="column">
          <Text fontSize={14}>Minimum Shifts</Text>
          <input
            type="number"
            value={filterInputs.minShifts}
            onChange={(e) => onFilterChange('minShifts', e.target.value)}
            min={0}
            style={{ width: '80px' }}
          />
        </Flex>

        <Flex flexDirection="column">
          <Text fontSize={14}>Days Since Active</Text>
          <input
            type="number"
            value={filterInputs.lastActive}
            onChange={(e) => onFilterChange('lastActive', e.target.value)}
            min={0}
            style={{ width: '80px' }}
          />
        </Flex>

        <Flex flexDirection="column" justifyContent="flex-end" height="100%">
          <Flex gap={4}>
            <Flex flexDirection="column" alignItems="center">
              <Text fontSize={14}>W2 Eligible</Text>
              <Switch
                checked={filterInputs.isW2Eligible}
                onCheckedChange={(checked) =>
                  onFilterChange('isW2Eligible', checked)
                }
              >
                <SwitchThumb />
              </Switch>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              <Text fontSize={14}>W2 Active</Text>
              <Switch
                checked={filterInputs.isW2Active}
                onCheckedChange={(checked) =>
                  onFilterChange('isW2Active', checked)
                }
              >
                <SwitchThumb />
              </Switch>
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection="column" justifyContent="flex-end" height="100%">
          <button
            onClick={onApplyFilters}
            style={{
              padding: '8px 16px',
              backgroundColor: '#00A3A3',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Apply Filters
          </button>
        </Flex>
      </Flex>

      {/* Stats Display */}
      <Flex justifyContent="space-around" mb={4}>
        <Flex flexDirection="column" alignItems="center">
          <Text fontSize={40} fontWeight="bold">
            {stats.withinDistanceCount}
          </Text>
          <Text fontSize={14} color="gray.600">
            Total workers within {appliedFilters.distance} miles
          </Text>
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <Text fontSize={40} fontWeight="bold">
            {stats.qualifiedWorkersCount}
          </Text>
          <Text fontSize={14} color="gray.600">
            Qualified workers:
            <Box as="ul" ml={2} mt={1} mb={0}>
              <li>- Within {appliedFilters.distance}mi</li>
              <li>- {appliedFilters.minShifts}+ shifts</li>
              <li>- {appliedFilters.lastActive} days active</li>
              {appliedFilters.isW2Active ? <li>- W2 active</li> : null}
            </Box>
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}
