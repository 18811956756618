import { graphql } from 'api/graphql'
import { path } from 'ramda'
import { PositionTemplate } from './positionTemplates'
import api from 'api'

export type PositionTemplateWithRequirements = Required<
  Pick<PositionTemplate, 'requirements'>
> &
  Omit<PositionTemplate, 'requirements'>

export async function getPositionTemplate(positionTemplateId: number) {
  const data = await graphql(
    `
        company {
          positionTemplates (positionTemplateId: ${positionTemplateId}) {
            id,
            name,
            requirements {
              isPreSelected,
              isWorkwhileRequired,
              requirement {
                id,
                category,
                name,
                photoUploadNeeded,
              }
            }
          }
        }
      `
  )

  const template = path(
    ['data', 'data', 'company', 'positionTemplates', 0],
    data
  ) as PositionTemplateWithRequirements

  return template
    ? {
        ...template,
        id: Number(template.id),
      }
    : undefined
}

export function updatePositionPositionTemplate({
  positionId,
  positionTemplateId,
}: {
  positionId: number
  positionTemplateId: number
}) {
  return api.put(`/admin/positions/${positionId}`, {
    position_template_id: positionTemplateId,
  })
}
