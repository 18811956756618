import React from 'react'
import { AccordionCard } from 'components/AccordionCard'
import { useShiftDetailContextValue } from '../context/ShiftDetailProvider'
import { useAppTheme, Text, Flex, Heading, Link } from '@workwhile/ui'
import { formatInTimeZone } from 'date-fns-tz'
import { isSameYear, isBefore, formatDistanceToNowStrict } from 'date-fns'
import { InfoTable } from 'components/InfoTable'

export const LongTermAssignment = () => {
  const { space } = useAppTheme()
  const {
    state: {
      assignment,
      location: {
        address: { timezone },
      },
    },
  } = useShiftDetailContextValue()

  if (!assignment) {
    return null
  }

  const shiftRows = assignment.shifts.map((shift, index) => {
    const isCurrentYear = isSameYear(new Date(), new Date(shift.startsAt))
    const startTime = formatInTimeZone(
      shift.startsAt,
      timezone,
      isCurrentYear ? 'MMM d, h:mm a' : 'MMM d, yyyy, h:mm a'
    )
    const dayDistance = formatDistanceToNowStrict(shift.startsAt, {
      addSuffix: true,
    })
    const isShiftInPast = isBefore(shift.startsAt, new Date())

    return (
      <InfoTable.Row
        key={`long-term-assignment-${assignment.id}-${index}`}
        label={
          <Link to={`/shifts/${shift.id}`} target="_blank">
            {shift.id}
          </Link>
        }
        value={
          <Text
            color={isShiftInPast ? 'lightText' : 'text'}
            title={
              isShiftInPast
                ? `Completed ${dayDistance}`
                : `Coming up ${dayDistance}`
            }
          >
            {startTime} ({dayDistance})
          </Text>
        }
        rightTdProps={{
          textAlign: 'left',
        }}
      />
    )
  })

  return (
    <AccordionCard
      title={
        <Flex flexDirection="column">
          <Text fontSize={2} color="lightText">
            Part of "{assignment.name}" assignment
          </Text>
          <Heading
            level={2}
            fontWeight="400"
            style={{
              margin: 0,
            }}
          >
            {assignment.shifts.length} shifts
          </Heading>
        </Flex>
      }
      size="fit"
      contentWrapperProps={{
        width: '376px',
        maxHeight: '300px',
        overflow: 'auto',
      }}
    >
      <InfoTable
        style={{
          borderSpacing: `0 ${space[2]}`,
          marginTop: `-${space[2]}`,
          marginBottom: `-${space[2]}`,
          tableLayout: 'auto',
        }}
      >
        {shiftRows}
      </InfoTable>
    </AccordionCard>
  )
}
