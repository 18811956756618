import { useCompanyDetailWorkflowsContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailWorkflowProvider'
import React from 'react'
import { useEditMoneyFlowContextValue } from '../context/EditMoneyFlowProvider'
import {
  Box,
  Button,
  Flex,
  Heading,
  Message,
  Modal,
  toast,
} from '@workwhile/ui'
import { useCompanyDetailContextValue } from '../../../context/CompanyDetailProvider'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { editMoneyFlowSchema } from '../schemas/editMoneyFlow'
import { Rate1099Form } from './Rate1099Form'
import { RateW2Form } from './RateW2Form'

export const EditMoneyFlowMain = () => {
  const {
    editMoneyFlow: { isOpen, close },
  } = useCompanyDetailWorkflowsContextValue()

  const {
    state: {
      formData,
      mutationState: { isPending, errorMessage },
    },
    actions: { submitFormToServer, setFormData },
  } = useEditMoneyFlowContextValue()

  const {
    state: {
      type,
      paymentMethod,
      invoiceDueDays,
      takeRate1099,
      bonusTakeRate,
      tipTakeRate,
      w2RegularTakeRate,
      w2DoubleTakeRate,
      w2OvertimeTakeRate,
    },
  } = useCompanyDetailContextValue()

  const isOnInvoice = paymentMethod === 'invoice'

  const methods = useForm({
    resolver: zodResolver(editMoneyFlowSchema),
    values: {
      takeRate: takeRate1099,
      bonusTakeRate: bonusTakeRate,
      tipTakeRate: tipTakeRate,
      w2RegularTakeRate: w2RegularTakeRate,
      w2DoubleTakeRate: w2DoubleTakeRate,
      w2OvertimeTakeRate: w2OvertimeTakeRate,
      invoiceDueDays: isOnInvoice ? invoiceDueDays : undefined,
      ...formData,
      currentValues: {
        takeRate: takeRate1099,
        invoiceDueDays: isOnInvoice ? invoiceDueDays : undefined,
        bonusTakeRate,
        tipTakeRate,
        w2RegularTakeRate,
        w2DoubleTakeRate,
        w2OvertimeTakeRate,
      },
    },
  })

  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = methods

  const onSubmit = handleSubmit((data) => {
    setFormData(data)
    submitFormToServer(data, {
      onSuccess: () => {
        toast.success('Updated financial information successfully!', {
          position: 'top-center',
        })
        setFormData({})
        reset()
        close()
      },
    })
  })

  return (
    <Modal
      open={isOpen}
      onClose={close}
      position="top"
      loading={isPending}
      showCancel
      customCta={
        <Flex>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save
          </Button>
        </Flex>
      }
    >
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Heading level={3} fontWeight="400" style={{ margin: 0 }}>
          Edit Money
        </Heading>

        <FormProvider {...methods}>
          {type === '1099' ? <Rate1099Form /> : null}
          {type === 'w2' ? <RateW2Form /> : null}
        </FormProvider>

        {errors.currentValues ? (
          <Message variant="error" mt={3} borderRadius="small">
            {errors.currentValues.message}
          </Message>
        ) : null}
        {errorMessage ? (
          <Message variant="error" mt={3} borderRadius="small">
            {errorMessage}
          </Message>
        ) : null}
      </Box>
    </Modal>
  )
}
