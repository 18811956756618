import React, { PropsWithChildren, useState } from 'react'

import * as zod from 'zod'
import { createContext } from 'lib/createContext'
import { useCompanyDetailContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailProvider'
import { parseErrorMessage } from 'api'
import { manageLogoFlowSchema } from '../schema/manageLogo'
import { useAddOrUpdateCompanyLogoMutation } from 'queries/company'

type ManageLogoFlowProviderContextState = {
  actions: {
    submitFormToServer: ReturnType<
      typeof useAddOrUpdateCompanyLogoMutation
    >['mutate']
    setFormData: (data: zod.infer<typeof manageLogoFlowSchema>) => void
  }
  state: {
    formData: zod.infer<typeof manageLogoFlowSchema>
    mutationState: {
      isPending: boolean
      isError: boolean
      errorMessage: string | undefined
    }
  }
}

const [Context, useManageLogoFlowContextValue] =
  createContext<ManageLogoFlowProviderContextState>({
    name: 'ManageLogoFlowContext',
  })

export const ManageLogoFlowContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [manageLogoFormData, setManageLogoFormData] = useState<
    zod.infer<typeof manageLogoFlowSchema>
  >({})

  const {
    state: { id: companyId },
  } = useCompanyDetailContextValue()

  const { mutate, isPending, isError, error } =
    useAddOrUpdateCompanyLogoMutation(companyId)

  const value: ManageLogoFlowProviderContextState = {
    actions: {
      submitFormToServer: mutate,
      setFormData: (data: zod.infer<typeof manageLogoFlowSchema>) => {
        setManageLogoFormData((prev) => ({
          ...prev,
          ...data,
        }))
      },
    },
    state: {
      formData: manageLogoFormData,
      mutationState: {
        isPending,
        isError,
        errorMessage: parseErrorMessage(error),
      },
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useManageLogoFlowContextValue }
