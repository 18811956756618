import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Box, Heading, Icon, IconButton } from '@workwhile/ui'
import { CommandIcon } from 'lucide-react'

import CommandList from 'components/features/Commands/CommandList'
import { CommandContext } from 'components/features/Commands/CommandProvider'
import { Sidebar } from 'react-pro-sidebar'
import { useAuthContext } from 'components/auth'
import { useMatch } from 'react-router-dom'

const CommandPanelButton = styled(IconButton)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  border: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const label = {
  shift: 'Shift',
  company: 'Company',
  user: 'User',
  position: 'Position',
  lta: 'Assignment',
}

export function CommandPanel() {
  const { values } = useContext(CommandContext)
  const [isOpen, setIsOpen] = useState(false)
  const { isAuthenticated } = useAuthContext()
  const enableUrlSync = useMatch({path: '/commands'})

  return (
    isAuthenticated && (
      <>
        <CommandPanelButton
          backgroundColor={'primary'}
          onClick={() => setIsOpen((wasOpen) => !wasOpen)}
          color={'white'}
          hoverBackgroundColor={'primary300'}
        >
          <Icon icon={CommandIcon} />
        </CommandPanelButton>
        {isOpen && (
          <Sidebar
            rootStyles={{ overflowY: 'scroll', height: '100%' }}
            collapsed={!isOpen}
            width="300px"
          >
            {' '}
            {isOpen ? (
              <Box p={20} overflowY="scroll" minHeight="100%" height="100%">
                <Heading level={2}>{label[values.type]} Commands</Heading>
                <CommandList enableUrlSync={!!enableUrlSync} />
              </Box>
            ) : null}
          </Sidebar>
        )}
      </>
    )
  )
}
