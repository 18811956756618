import * as zod from 'zod'

export const inviteUserToCompanyFlowSchema = zod
  .object({
    email: zod
      .string()
      .email('Please enter a valid email address')
      .toLowerCase(),
    currentMembers: zod.array(zod.string()),
  })
  .superRefine((data, ctx) => {
    if (data.currentMembers.includes(data.email.toLowerCase())) {
      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        message:
          'The email address provided is already associated with an existing company member. Please use a different email address.',
        path: ['email'],
      })
    }
  })
