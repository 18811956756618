import { CommandFieldSchema } from 'api/commands'
import { groupBy, prop } from 'ramda'
import { useState } from 'react'
import { Location } from 'react-router'

type PageValue = {
  key: CommandFieldSchema['name']
  value: string
  label: string
}

/** Scan the DOM for values stored as data attributes  */
const getValues = (): Record<PageValue['key'], PageValue[]> => {
  const idElems = Array.from(document.querySelectorAll(`[data-context-key]`))

  // Deduplicate found values
  const uniqueMap = new Map()
  idElems.forEach((elem) => {
    const key = elem.getAttribute(`data-context-key`)
    const value = elem.getAttribute(`data-context-value`)
    const label = elem.getAttribute(`data-context-label`)
    const compositeKey = `${key}:${value}`

    if (!uniqueMap.has(compositeKey)) {
      uniqueMap.set(compositeKey, { key, value, label })
    }
  })

  return groupBy<PageValue>(prop('key'), Array.from(uniqueMap.values()))
}

function usePageContext(page: Location['pathname']) {
  const [values, setValues] = useState(page && getValues())

  const refresh = () => {
    setValues({...values, ...getValues()})
  }

  return { values, refresh }
}

export default usePageContext
