import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { Box, Table, Checkbox, Flex } from '@workwhile/ui'
import { columns, columnHelper, ExtendedRequirement } from './columns'

interface RequirementTableProps {
  requirements: ExtendedRequirement[]
  selectedIds: string[]
  setSelectedIds: Dispatch<SetStateAction<string[]>>
  includeMustHave?: boolean
}

export function RequirementTable({
  requirements,
  selectedIds,
  setSelectedIds,
  includeMustHave = true,
}: RequirementTableProps) {
  const handleCheckboxChange = (id: string, checked: boolean) => {
    setSelectedIds((prevSelectedIds) => {
      const currentChecked = prevSelectedIds.includes(id.toString())
      if (currentChecked === checked) {
        return prevSelectedIds
      }
      if (checked) {
        return [...prevSelectedIds, id.toString()]
      } else {
        return prevSelectedIds.filter((i) => i !== id.toString())
      }
    })
  }

  const columnsWithCheckbox = useMemo(() => {
    return [
      columnHelper.accessor('id', {
        header: 'Included',
        cell: (info) => (
          <Flex justifyContent={'center'}>
            <Checkbox
              disabled={info.row.original.isWorkWhileRequired}
              defaultChecked={selectedIds.includes(
                info.row.original.id.toString()
              )}
              onChange={(checked) =>
                handleCheckboxChange(info.row.original.id, checked)
              }
            />
          </Flex>
        ),
      }),
      ...(includeMustHave ? columns : columns.slice(0, -1)),
    ]
  }, [])

  return (
    <Box overflowX="auto">
      <Table columns={columnsWithCheckbox} data={requirements} />
    </Box>
  )
}
