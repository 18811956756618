import React from 'react'
import { EditMoneyFlowMain } from './components/EditMoneyFlowMain'
import { EditMoneyFlowProvider } from './context/EditMoneyFlowProvider'

export const EditMoneyFlow = () => {
  return (
    <EditMoneyFlowProvider>
      <EditMoneyFlowMain />
    </EditMoneyFlowProvider>
  )
}
