import * as zod from 'zod'

export const editGeneralDetailsFlowSchema = zod
  .object({
    type: zod.enum(['w2', '1099']),
    currentType: zod.enum(['w2', '1099']),
  })
  .superRefine((data, ctx) => {
    if (data.currentType === data.type) {
      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        message:
          'No changes detected. Please modify at least one field to save.',
        path: ['type'],
      })
    }
  })
