import React from 'react'
import { AccordionCard } from 'components/AccordionCard'
import { useCompanyDetailContextValue } from '../context/CompanyDetailProvider'
import { Box, Button, Flex, Label, Text, useAppTheme } from '@workwhile/ui'
import { CancellationPolicy } from '../types'
import { InfoTable } from 'components/InfoTable'
import { Clock, Percent, Plus } from 'lucide-react'
import { formatPercentage } from 'lib/percentage'
import { useCompanyDetailWorkflowsContextValue } from '../context/CompanyDetailWorkflowProvider'
import { format, formatDistanceToNowStrict, isAfter, parseISO } from 'date-fns'

const WindowRow = ({
  window,
  isLast,
}: {
  window: CancellationPolicy['policyWindows'][0]
  isLast: boolean
}) => {
  const { space } = useAppTheme()

  return (
    <Box
      as="span"
      display="block"
      borderBottom={isLast ? 'none' : '1px solid'}
      borderColor="neutrals.100"
      width="100%"
      mb={isLast ? 0 : 3}
      justifyContent="space-between"
      maxWidth="100%"
    >
      <InfoTable style={{ borderSpacing: `0 ${space[2]}` }}>
        <InfoTable.Row
          icon={<Clock size={14} />}
          label="Cutoff Time"
          value={`${window.cutoffTimeHours} hrs`}
        />
        <InfoTable.Row
          icon={<Percent size={14} />}
          label="Charge Percentage"
          value={formatPercentage(window.chargePercentage)}
        />
      </InfoTable>
    </Box>
  )
}

const PolicyRow = ({
  policy,
  isLast,
}: {
  policy: CancellationPolicy
  isLast: boolean
}) => {
  const { radii } = useAppTheme()

  const windowRows = policy.policyWindows.map((window, index) => {
    return (
      <WindowRow
        key={`window-row-${window.cutoffTimeHours}-${window.chargePercentage}-${index}`}
        window={window}
        isLast={index === policy.policyWindows.length - 1}
      />
    )
  })

  const isDateInFuture = isAfter(parseISO(policy.startsAt), new Date())
  const formattedDate = format(policy.startsAt, 'MMM d, yyyy')
  const distanceToNow = formatDistanceToNowStrict(policy.startsAt, {
    addSuffix: true,
  })

  return (
    <Box
      display="block"
      border="1px solid"
      borderColor="neutrals.100"
      p={3}
      borderRadius={radii.standard}
      width="100%"
      mb={isLast ? 0 : 3}
    >
      <Flex alignItems="center" justifyContent="space-between">
        {/* We hide the date if the policy is default because date is set to the beginning of the unix time */}
        {!policy.isDefault ? (
          <Text
            mb={3}
            title={`${format(policy.startsAt, 'MMM d, yyyy hh:mm a')} (${distanceToNow})`}
          >
            {isDateInFuture ? 'Starts on' : 'Active since'} {formattedDate} (
            {distanceToNow})
          </Text>
        ) : null}
        {policy.isDefault ? (
          <Label mb={3} variant="neutral" size="small">
            Default
          </Label>
        ) : null}
      </Flex>
      <Text fontSize={1} color="lightText" my={2}>
        Policy windows
      </Text>
      {windowRows}
    </Box>
  )
}

export const CancellationPolicies = () => {
  const { space } = useAppTheme()
  const {
    state: { activeCancellationPolicy, upcomingCancellationPolicies },
  } = useCompanyDetailContextValue()

  const {
    addCancellationPolicyFlow: { open: openAddCancellationPolicyFlow },
  } = useCompanyDetailWorkflowsContextValue()

  const upcomingCancellationPolicy = upcomingCancellationPolicies.map(
    (policy, index) => {
      return (
        <PolicyRow
          key={`upcoming-cancellation-policy-rows-${policy.id}-${index}`}
          policy={policy}
          isLast={index === upcomingCancellationPolicies.length - 1}
        />
      )
    }
  )

  return (
    <AccordionCard
      size="small"
      title={
        <Flex justifyContent="space-between" alignItems="center" gap={2}>
          <Text fontSize={2} color="lightText">
            Cancellation Policies
          </Text>
          <Button
            variant="primary"
            kind="small"
            style={{ paddingLeft: space[2], paddingRight: space[2] }}
            onClick={openAddCancellationPolicyFlow}
          >
            <Plus size={14} />
            <Text as="span" ml={1}>
              Add
            </Text>
          </Button>
        </Flex>
      }
      // Intentionally disabled. This should work, unsure why it's throwing an error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      maxWidth="50%"
      isAccordion
      defaultOpen={false}
    >
      <Text mb={3} color="success200" fontWeight={500}>
        Active Policy
      </Text>
      <PolicyRow policy={activeCancellationPolicy} isLast={false} />

      {upcomingCancellationPolicy.length > 0 ? (
        <>
          <Text mb={3} fontWeight={500}>
            Upcoming Policies
          </Text>
          {upcomingCancellationPolicy}
        </>
      ) : null}
    </AccordionCard>
  )
}
