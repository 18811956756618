import { createContext } from 'lib/createContext'
import React, { PropsWithChildren, useState } from 'react'

type Workflow<Args extends Record<string, unknown> | undefined = undefined> = {
  open: Args extends undefined ? () => void : (args: Args) => void
  close: () => void
  isOpen: boolean
} & (Args extends undefined ? object : { data?: Args })

export type CompanyGroupDetailWorkflowsContextState = {
  removeCompanyFromGroupFlow: Workflow<{
    companyId: number
    companyName: string
  }>
  addCompaniesToGroupFlow: Workflow
}

type WorkflowState<T extends Record<string, unknown> | undefined = undefined> =
  T extends undefined
    ? boolean
    : {
        isOpen: boolean
        data?: T
      }

const [
  CompanyGroupDetailWorkflowsContext,
  useCompanyGroupDetailWorkflowsContextValue,
] = createContext<CompanyGroupDetailWorkflowsContextState>({
  name: 'CompanyGroupDetailWorkflowsContext',
})

type CompanyGroupDetailWorkflowsProviderProps = PropsWithChildren

export const CompanyGroupDetailWorkflowsProvider = ({
  children,
}: CompanyGroupDetailWorkflowsProviderProps) => {
  const [removeCompanyFromGroupFlow, setRemoveCompanyFromGroupFlow] = useState<
    WorkflowState<{
      companyId: number
      companyName: string
    }>
  >({ isOpen: false, data: undefined })

  const [isAddCompaniesToGroupFlowOpen, setIsAddCompaniesToGroupFlowOpen] =
    useState<WorkflowState>(false)

  const value: CompanyGroupDetailWorkflowsContextState = {
    removeCompanyFromGroupFlow: {
      open: (data) => setRemoveCompanyFromGroupFlow({ isOpen: true, data }),
      close: () =>
        setRemoveCompanyFromGroupFlow({ isOpen: false, data: undefined }),
      isOpen: removeCompanyFromGroupFlow.isOpen,
      data: removeCompanyFromGroupFlow.data,
    },
    addCompaniesToGroupFlow: {
      open: () => setIsAddCompaniesToGroupFlowOpen(true),
      close: () => setIsAddCompaniesToGroupFlowOpen(false),
      isOpen: isAddCompaniesToGroupFlowOpen,
    },
  }

  return (
    <CompanyGroupDetailWorkflowsContext.Provider value={value}>
      {children}
    </CompanyGroupDetailWorkflowsContext.Provider>
  )
}

export { useCompanyGroupDetailWorkflowsContextValue }
