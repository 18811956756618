import React, { useContext, useEffect, useMemo, useRef } from 'react'
import './Commands.css'
import {
  Box,
  Heading,
  Icon,
  Input,
  Pluralize,
  Accordion,
  Flex,
  Button,
} from '@workwhile/ui'
import { SearchIcon, XCircleIcon, XIcon } from 'lucide-react'
import Highlight from './Highlight'
import debounce from 'lodash.debounce'
import lodash from 'lodash'
import { CommandContext } from './CommandProvider'
import Command from './Command'
import { shouldShowCommand } from './utils/shouldShowCommand'
import { CommandSchema } from 'api/commands'

type CommandListProps = {
  enableUrlSync?: boolean
}

const TRUNCATE_CHARACTERS = 20

function CommandList({ enableUrlSync }: CommandListProps) {
  const { values, onChange, commands, setEnableUrlSync } =
    useContext(CommandContext)

  useEffect(() => {
    setEnableUrlSync(enableUrlSync)
  }, [enableUrlSync])

  const searchRef = useRef(null)
  const handleSearch = debounce((event) => {
    onChange({ search: event.target.value })
  }, 200)
  const handleClearSearch = () => {
    searchRef.current.value = ''
    onChange({ search: '' })
  }

  /** Group commands by app_group name */
  const commandGroups = useMemo(
    () =>
      Object.entries(
        commands?.reduce(
          (groups, command) => {
            if (shouldShowCommand(command, values)) {
              const groupName = command.app_group
              if (!groups[groupName]) {
                groups[groupName] = []
              }
              groups[groupName].push(command)
            }

            return groups
          },
          {} as Record<CommandSchema['app_group'], CommandSchema[]>
        ) || {}
      )?.sort(([a], [b]) => (a < b ? -1 : 1)),
    [values.search, values.type, commands]
  )

  return (
    <Flex flexDirection="column" gap="2">
      {/* Search bar */}
      <Flex alignItems="center">
        <Input
          ref={searchRef}
          name="search"
          type="text"
          icon={<Icon color="lightText" size="20" icon={SearchIcon} />}
          onChange={handleSearch}
          defaultValue={values.search}
          placeholder="Search to filter the list of commands by matching name, description, or field"
          block
        />
        {!!values.search && (
          <Box ml="1">
            <Icon
              color="primary"
              icon={XCircleIcon}
              onClick={handleClearSearch}
            />
          </Box>
        )}
      </Flex>

      {/* Saved value buttons */}
      <Box>
        {Object.entries(values).map(
          ([key, value]) =>
            !['search'].includes(key) &&
            typeof value === 'string' &&
            !!value && (
              <Button
                mr="1"
                mb="1"
                variant="secondary"
                onClick={() => onChange({ [key]: '' })}
              >
                {`${lodash.startCase(key)}: ${value.substring(0, TRUNCATE_CHARACTERS)}${value.substring(TRUNCATE_CHARACTERS).length ? '...' : ''} `}
                <Icon icon={XIcon} size="20" />
              </Button>
            )
        )}
      </Box>

      {/* Results */}
      <Box>
        {commandGroups.map(
          ([appGroupName, appGroupCommands]) =>
            !!appGroupCommands.length && (
              <>
                <Flex alignItems="center" justifyContent="space-between" mr="2">
                  <Heading level={3}>
                    <Highlight
                      text={lodash.startCase(appGroupName)}
                      match={values.search}
                    />
                  </Heading>
                  <Pluralize
                    count={appGroupCommands.length}
                    oneText="command"
                    manyText="commands"
                  />
                </Flex>

                <Accordion
                  key={appGroupName}
                  type="multiple"
                  items={appGroupCommands
                    ?.sort((a, b) => (a.name < b.name ? -1 : 1))
                    ?.map((command) => ({
                      id: command.name,
                      trigger: (
                        <Highlight
                          key={command.name}
                          text={lodash.startCase(command.name)}
                          match={values.search}
                        />
                      ),
                      content: <Command key={command.name} {...command} />,
                    }))}
                />
              </>
            )
        )}
      </Box>
    </Flex>
  )
}

export default CommandList
