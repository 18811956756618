import { useState } from 'react'
import { CommandValues } from './types'

// TODO: move to session storage to clear more often
export function useSessionContext(storageKey: string) {
  // Snapshot localStorage on mount and return optimistic updates
  // to avoid fetching localStorage on every change
  const [values, setValues] = useState<CommandValues>(
    JSON.parse(localStorage.getItem(storageKey) || '{}')
  )

  const onChange = (next: CommandValues) => {
    if (!next) {
      localStorage.clear()
    } else {
      const update = Object.entries({ ...values, ...next }).reduce(
        (clean, [key, value]) => {
          if (value) {
            clean[key] = value
          }
          return clean
        },
        {} as CommandValues
      )
      localStorage.setItem(storageKey, JSON.stringify(update))
      setValues(update)
    }
  }

  return { values, onChange }
}
