import React, { useEffect, useState } from 'react'
import { User } from '../Shared/common_defs'
import { workColumns, USER_WORK_QUERY } from '../Shared/work'
import BootstrapTable from 'react-bootstrap-table-next'
import { graphqlWithVariables } from '../../api/graphql'
import { defs } from '../Shared/defs'
import { formatStatusOptionsForSelect } from '../utils/util'
import {
  Box,
  Button,
  DatePicker,
  Flex,
  Input,
  Select,
  Text,
} from '@workwhile/ui'
import moment from 'moment'

interface Props {
  user: User
}

const groupingOptions = [
  { label: 'All', value: null },
  { label: 'upcoming', value: 'upcoming' },
  { label: 'ongoing', value: 'ongoing' },
  { label: 'past', value: 'past' },
]

const UserWorkList = ({ user }: Props) => {
  const [submittingWorkRequest, setSubmittingWorkRequest] = useState(false)
  const [work, setWork] = useState([])
  const [limit, setLimit] = useState(10)
  const [grouping, setGrouping] = useState(null)
  const [status, setStatus] = useState(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  useEffect(() => {
    requestWork()
  }, [])

  const requestWork = (e?) => {
    if (e) {
      e.preventDefault()
    }
    setWork([]) // clear the current work list
    setSubmittingWorkRequest(true)
    graphqlWithVariables(USER_WORK_QUERY, {
      userId: user.id,
      limit,
      grouping,
      status,
      startDate:
        startDate === null ? null : moment(startDate).format('YYYY-MM-DD'),
      endDate: endDate === null ? null : moment(endDate).format('YYYY-MM-DD'),
    })
      .then((response) => {
        const workResponse = response.data.data.admin.user.work
        if (workResponse) {
          setWork(workResponse)
        }
        setSubmittingWorkRequest(false)
      })
      .catch((error) => {
        setSubmittingWorkRequest(false)
      })
  }

  const searchGroup = (
    <Flex alignItems={'center'} gap={3} maxWidth="100%" flexWrap="wrap">
      <Text mr={2}>Entries:</Text>
      <Input
        width={50}
        name="limit"
        type="number"
        value={limit}
        onChange={(e) => setLimit(parseInt(e.target.value))}
        placeholder="Entries"
      />
      <Select
        isSearchable
        isClearable
        options={groupingOptions}
        styles={customStyles}
        name="grouping"
        placeholder="Grouping"
        onChange={(option) => {
          if (option) setGrouping((option as { value: string }).value)
          else setGrouping(null)
        }}
      />
      <Select
        isSearchable
        isClearable
        options={formatStatusOptionsForSelect()}
        styles={customStyles}
        name="status"
        placeholder="Status"
        onChange={(option) => {
          if (option) setStatus((option as { value: string }).value)
          else setStatus(null)
        }}
      />
      <Box width={200}>
        <DatePicker
          placeholder="Start date"
          mode="single"
          selected={startDate}
          onSelect={(range) => {
            if (range instanceof Date) {
              setStartDate(range)
              return
            }

            if (range === null) {
              setEndDate(null)
              return
            }

            throw new Error('invariant violation')
          }}
        />
      </Box>
      <Box width={200}>
        <DatePicker
          placeholder="End date"
          mode="single"
          selected={endDate}
          onSelect={(range) => {
            if (range instanceof Date) {
              setEndDate(range)
              return
            }

            if (range === null) {
              setEndDate(null)
              return
            }

            throw new Error('invariant violation')
          }}
        />
      </Box>
      <Button loading={submittingWorkRequest} onClick={() => requestWork()}>
        Request Data
      </Button>
    </Flex>
  )

  return (
    <div>
      {searchGroup}
      {work && work.length > 0 ? (
        <>
          <BootstrapTable
            striped
            keyField="shift_id"
            data={work}
            columns={workColumns(requestWork)}
            rowStyle={{ fontSize: 12 }}
          />
        </>
      ) : (
        <p>User is not associated with any shifts</p>
      )}
    </div>
  )
}

export const customStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 220,
    marginTop: defs.marginXS,
    marginBottom: defs.marginXS,
    fontSize: defs.fontS,
  }),
}

export default UserWorkList
