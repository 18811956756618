import * as zod from 'zod'

export const createCompanyGroupFlowSchema = zod.object({
  companyGroupName: zod
    .string({
      required_error: 'Company group name is required',
    })
    .min(3, { message: 'Company group name must be at least 3 characters' })
    .max(60, { message: 'Company group name must not exceed 60 characters' }),
})

export type CreateCompanyGroupFlowSchema = zod.infer<
  typeof createCompanyGroupFlowSchema
>
