import React, { useId, useMemo } from 'react'
import {
  Controller,
  useFieldArray,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form'
import { FiltersSchema } from '../filtersSchema'
import { Box, Button, Field, Flex, Select, useAppTheme } from '@workwhile/ui'
import { CircleMinus } from 'lucide-react'
import { useShiftsContextValue } from 'pages/Shifts/Shifts/context/ShiftsProvider'
import { indexBy, prop } from 'ramda'

const AddedCompanyRow = ({
  remove,
  index,
  value,
  isLast,
}: {
  remove: UseFieldArrayRemove
  index: number
  value: string
  isLast: boolean
}) => {
  const { colors, radii, space } = useAppTheme()
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={space[2]}
      mb={isLast ? 0 : space[2]}
    >
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        border="1px solid"
        borderColor={colors.neutrals[100]}
        p={2}
        borderRadius={radii.small}
        width="100%"
        position="relative"
      >
        {value}
      </Flex>
      <Button
        onClick={() => remove(index)}
        variant="text"
        color="red"
        size="small"
        danger
        style={{
          margin: 0,
          height: '1.6rem', // This is to resolve a bug in our UI library where the width and height is being set to `small` (literally) instead of a `px` value
        }}
      >
        <CircleMinus size={16} />
      </Button>
    </Flex>
  )
}

const AddedCompanyIds = () => {
  const baseId = useId()
  const { watch, control } = useFormContext<FiltersSchema>()
  const {
    state: { companies },
  } = useShiftsContextValue()

  const { remove } = useFieldArray({
    name: 'company.companyIds',
    control,
  })

  const companyIds = watch('company.companyIds')

  const companyMap = useMemo(() => {
    if (!companies) return {}
    const groupedCompanies = indexBy(prop('id'), companies)
    return groupedCompanies
  }, [companies])

  const addedCompanyRows = companyIds.map(({ id }, index) => (
    <AddedCompanyRow
      key={`added-company-${baseId}-${id}-${index}`}
      remove={remove}
      index={index}
      value={`${companyMap[id]?.name} (${id})`}
      isLast={index === companyIds.length - 1}
    />
  ))
  return (
    <Box width="256px" maxHeight="360px" overflow="auto">
      {addedCompanyRows}
    </Box>
  )
}

export const CompanyIdsFilter = () => {
  const { control, trigger, getValues, setValue, resetField } =
    useFormContext<FiltersSchema>()
  const {
    state: { companies },
  } = useShiftsContextValue()

  const companyOptions = useMemo(() => {
    return companies?.map((company) => ({
      label: `${company.name} (${company.id})`,
      value: company.id,
    }))
  }, [companies])

  const { append } = useFieldArray({
    name: 'company.companyIds',
    control,
  })

  return (
    <Box>
      <Controller
        control={control}
        name="company.companyIdUserInput"
        defaultValue={undefined}
        render={({ field, fieldState: { error, invalid } }) => (
          <Field error={error?.message?.toString()}>
            <Flex gap={2} width="256px" maxHeight="360px">
              <Select
                block
                {...field}
                options={companyOptions}
                isClearable
                isSearchable
                value={
                  companyOptions.find(
                    (option) => option.value === field.value
                  ) || null
                }
                onChange={(option) => {
                  field.onChange(option?.value)
                }}
                styles={{
                  container: (base) => ({
                    ...base,
                    width: '100%',
                  }),
                }}
              />
              <Button
                variant="primary"
                disabled={invalid}
                onClick={async () => {
                  const isInputValid = await trigger(
                    'company.companyIdUserInput'
                  )
                  const companyId = Number(
                    getValues('company.companyIdUserInput')
                  )
                  const addedCompanies = getValues('company.companyIds')
                  const doesInputExistInCurrentGroup = addedCompanies.some(
                    (company) => company.id === companyId
                  )
                  const isValidNumber = !Number.isNaN(companyId)

                  const isValid =
                    isInputValid &&
                    !doesInputExistInCurrentGroup &&
                    isValidNumber

                  if (isValid) {
                    append({ id: companyId })
                    resetField('company.companyIdUserInput', {
                      defaultValue: undefined,
                    })
                    setValue('company.companyIdUserInput', undefined)
                  }

                  if (doesInputExistInCurrentGroup) {
                    resetField('company.companyIdUserInput', {
                      defaultValue: undefined,
                    })
                  }
                }}
              >
                Add
              </Button>
            </Flex>
          </Field>
        )}
      />

      <AddedCompanyIds />
    </Box>
  )
}
