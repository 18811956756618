import React, { PropsWithChildren, useState } from 'react'
import { AddMinimumPayPolicySchema } from '../schemas/minimumPayPolicy'
import { createContext } from 'lib/createContext'
import { useAddMinimumPayPolicyMutation } from 'queries/company'
import { useCompanyDetailContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailProvider'
import { parseErrorMessage } from 'api'

type AddMinimumPayPolicyFlowProviderContextState = {
  actions: {
    submitFormToServer: ReturnType<
      typeof useAddMinimumPayPolicyMutation
    >['mutate']
    setFormData: (data: AddMinimumPayPolicySchema) => void
  }
  state: {
    formData: AddMinimumPayPolicySchema
    mutationState: {
      isPending: boolean
      isError: boolean
      errorMessage: string | undefined
    }
  }
}

const [Context, useAddMinimumPayPolicyFlowContextValue] =
  createContext<AddMinimumPayPolicyFlowProviderContextState>({
    name: 'AddMinimumPayPolicyFlowContext',
  })

export const AddMinimumPayPolicyFlowProvider = ({
  children,
}: PropsWithChildren) => {
  const [addMinimumPayPolicyFormData, setAddMinimumPayPolicyFormData] =
    useState<AddMinimumPayPolicySchema>({})

  const {
    state: { id: companyId },
  } = useCompanyDetailContextValue()

  const {
    mutate: addMinimumPayPolicyMutation,
    isPending,
    isError,
    error,
  } = useAddMinimumPayPolicyMutation(companyId)

  const value: AddMinimumPayPolicyFlowProviderContextState = {
    actions: {
      submitFormToServer: addMinimumPayPolicyMutation,
      setFormData: (data: AddMinimumPayPolicySchema) => {
        setAddMinimumPayPolicyFormData((prev) => ({
          ...prev,
          ...data,
        }))
      },
    },
    state: {
      formData: addMinimumPayPolicyFormData,
      mutationState: {
        isPending,
        isError,
        errorMessage: parseErrorMessage(error),
      },
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useAddMinimumPayPolicyFlowContextValue }
