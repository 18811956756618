import React from 'react'
import { CompaniesProvider } from './context/CompaniesProvider'
import { CompaniesMain } from './components/CompaniesMain'
import { CompaniesWorkflowsProvider } from './context/CompaniesWorkflowsProvider'
import { CreateCompanyFlow } from './workflows/CreateCompanyFlow/CreateCompanyFlow'
import { NuqsAdapter } from 'nuqs/adapters/react'

export const Companies = () => {
  return (
    <NuqsAdapter>
      <CompaniesProvider>
        <CompaniesWorkflowsProvider>
          <CompaniesMain />
          <CreateCompanyFlow />
        </CompaniesWorkflowsProvider>
      </CompaniesProvider>
    </NuqsAdapter>
  )
}
