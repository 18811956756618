import { useCompanyDetailWorkflowsContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailWorkflowProvider'
import React from 'react'
import { useInviteUserToCompanyFlowContextValue } from '../context/InviteUserToCompanyFlowProvider'
import {
  Box,
  Button,
  Field,
  Flex,
  Heading,
  Input,
  Message,
  Modal,
  toast,
} from '@workwhile/ui'
import { useCompanyDetailContextValue } from '../../../context/CompanyDetailProvider'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { inviteUserToCompanyFlowSchema } from '../schemas/inviteUserToCompanyFlowSchema'

export const InviteUserToCompanyFlowMain = () => {
  const {
    inviteUserToCompanyFlow: { isOpen, close },
  } = useCompanyDetailWorkflowsContextValue()

  const {
    state: {
      formData,
      mutationState: { isPending, errorMessage },
    },
    actions: { submitFormToServer, setFormData },
  } = useInviteUserToCompanyFlowContextValue()

  const {
    state: { id: companyId, members },
  } = useCompanyDetailContextValue()

  const memberEmails = members.map((member) => member.email.toLocaleLowerCase())
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(inviteUserToCompanyFlowSchema),
    values: {
      currentMembers: memberEmails,
    },
    defaultValues: formData,
  })

  const onSubmit = handleSubmit((data) => {
    setFormData({ email: data.email })
    submitFormToServer(
      {
        companyId: companyId,
        email: data.email,
      },
      {
        onSuccess: () => {
          toast.success('Invited user to company successfully!', {
            position: 'top-center',
          })
          setFormData({})
          reset()
          close()
        },
      }
    )
  })

  return (
    <Modal
      open={isOpen}
      onClose={close}
      position="top"
      loading={isPending}
      showCancel
      customCta={
        <Flex>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Invite
          </Button>
        </Flex>
      }
    >
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Heading level={3} fontWeight="400" style={{ margin: 0 }}>
          Invite user to company
        </Heading>

        <Field
          label={'Email'}
          error={errors.email?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mt={3}
        >
          <Input
            {...register('email')}
            aria-label={'Email'}
            placeholder={`e.g. jane@companyname.com`}
            type="email"
          />
        </Field>
        {errorMessage ? (
          <Message variant="error" mt={3} borderRadius="small">
            {errorMessage}
          </Message>
        ) : null}
      </Box>
    </Modal>
  )
}
