type ColorRange = {
  min: number
  max: number
  color: string
}

export type Scale = {
  ranges: ColorRange[]
}

/**
 * Finds the corresponding color for a given value within a specified scale.
 *
 * @param value - The value to map to a color.
 * @param scaleName - The name of the scale to use.
 * @param scales - The array of scales containing defined color ranges.
 * @returns The corresponding color as a hex string or undefined if no match is found.
 */
export const getColorFromRange = (
  value: number,
  scale: Scale
): string | undefined => {
  for (const range of scale.ranges) {
    if (value >= range.min && value <= range.max) {
      return range.color
    }
  }

  return undefined
}
