import { Company, Position } from 'api/typings'
import { pathOr } from 'ramda'
import { graphql } from 'api/graphql'

export interface CompanyPosition extends Position {
  company: Company & {
    companyShiftEndPaymentDelayHours?: number
    cancellationPolicies?: Array<unknown>
  }
  allowAutocushion: boolean
  clockInCodeEnabled: boolean
  clockOutCodeEnabled: boolean
  isTipEligible: boolean
  bundling: string
}

export async function getCompanyPosition(positionId: number) {
  const response = await graphql(
    `admin {
        positions(positionId: ${positionId}) {
            id
            positionTemplate {
                id
                name
                relevantPositionTemplateWorkerCategories {
                    name
                }
            }
            isApproved
            bundling
            name
            about
            prescreened
            isTipEligible
            allowAutocushion
            clockInCodeEnabled
            clockOutCodeEnabled
            requirements2 {
                id
                name
                category
                credential
                verifiable
                isWorkerVisible
            }
            mustHaveRequirements {
                id
            }
            company {
                id
                name
                isW2
                isW2Changeable
                chargeUpfront
                defaultPaymentSource
                isAbleToPostShifts
                companyShiftEndPaymentDelayHours
                minPayPolicies {
                    startsAt
                    minPayLength
                }
                takePercentages {
                    rate1099
                    w2Regular
                    w2Overtime
                    w2Double
                    bonus
                    tip
                }
                cancellationPolicies {
                    startsAt
                    policyWindows {
                    cutoffTimeHours
                    chargePercentage
                    }
                }
            }
        }  
    }`
  )
  return pathOr<CompanyPosition | null>(
    null,
    ['data', 'data', 'admin', 'positions', 0],
    response
  )
}
