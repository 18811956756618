import React from 'react'
import { ListingsOverviewProvider } from './context/ListingsOverviewProvider'
import { ListingsOverviewMain } from './components/ListingsOverviewMain'

export const ListingsOverview = () => {
  return (
    <ListingsOverviewProvider>
      <ListingsOverviewMain />
    </ListingsOverviewProvider>
  )
}
