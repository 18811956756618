import { useCompanyDetailWorkflowsContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailWorkflowProvider'
import React from 'react'
import {
  Box,
  Button,
  DatePicker,
  Field,
  Flex,
  Heading,
  Input,
  Message,
  Modal,
  Text,
  toast,
} from '@workwhile/ui'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { addMinimumPayPolicySchema } from '../schemas/minimumPayPolicy'
import { useAddMinimumPayPolicyFlowContextValue } from '../context/AddMinimumPayPolicyFlowProvider'
import { useCompanyDetailContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailProvider'
import { isToday } from 'date-fns'
import { getLongDuration } from 'lib/time'

export const AddMinimumPayPolicyFlowMain = () => {
  const {
    addMinimumPayPolicyFlow: { isOpen, close },
  } = useCompanyDetailWorkflowsContextValue()

  const {
    state: {
      formData,
      mutationState: { isPending, errorMessage },
    },
    actions: { submitFormToServer, setFormData },
  } = useAddMinimumPayPolicyFlowContextValue()

  const {
    state: { id: companyId },
  } = useCompanyDetailContextValue()

  const methods = useForm({
    resolver: zodResolver(addMinimumPayPolicySchema),
    defaultValues: {
      startsAt: formData?.startsAt ? new Date(formData.startsAt) : new Date(),
      minPayLengthInMinutes: formData?.minPayLengthInMinutes,
    },
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = methods

  const onSubmit = handleSubmit((data) => {
    setFormData(data)
    submitFormToServer(
      {
        companyId,
        startsAt: isToday(data.startsAt)
          ? undefined // We set undefined if the start date is today to let backend handle the "today" case
          : data.startsAt.toISOString(),
        minPayLengthInMinutes: data.minPayLengthInMinutes,
      },
      {
        onSuccess: () => {
          toast.success('Added new minimum pay policy successfully!', {
            position: 'top-center',
          })
          setFormData({})
          reset()
          close()
        },
      }
    )
  })

  const startsAt = watch('startsAt')
  const minPayLengthInMinutes = watch('minPayLengthInMinutes')
  const isGreaterThanOneHour = minPayLengthInMinutes > 60

  return (
    <Modal
      open={isOpen}
      onClose={close}
      position="top"
      loading={isPending}
      showCancel
      customCta={
        <Flex>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Add
          </Button>
        </Flex>
      }
    >
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Heading level={3} fontWeight="400" style={{ margin: 0 }}>
          Add Minimum Pay Policy
        </Heading>
        <Field
          label="Start date"
          error={errors.startsAt?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mt={3}
        >
          <DatePicker
            {...register('startsAt')}
            mode="single"
            aria-label={'Start date'}
            disabled={{
              before: new Date(),
            }}
            selected={startsAt}
            onSelect={(date) => {
              setValue('startsAt', date as Date)
            }}
          />
        </Field>
        <Field
          label="Minimum pay length"
          error={errors.minPayLengthInMinutes?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mt={3}
          cta={
            isGreaterThanOneHour ? (
              <Text fontSize={1} color="lightText">
                {getLongDuration(minPayLengthInMinutes)}
              </Text>
            ) : null
          }
        >
          <Input
            {...register('minPayLengthInMinutes')}
            type="number"
            placeholder="e.g. 120"
          />
        </Field>
        {errorMessage ? (
          <Message variant="error" mt={3} borderRadius="small">
            {errorMessage}
          </Message>
        ) : null}
      </Box>
    </Modal>
  )
}
