import { useMemo } from 'react'
import { Position } from 'api/typings'

export function useCurrentRequirements(position: Position) {
  return useMemo(() => {
    const allRequirements = position.requirements2
    const mustHaveIds = position.mustHaveRequirements.map((r) =>
      r.id.toString()
    )
    return allRequirements.map((r) => {
      return {
        ...r,
        isMustHave: mustHaveIds.includes(r.id.toString()),
      }
    })
  }, [position])
}
