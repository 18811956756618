import {
  Card as UICard,
  Text,
  Box,
  useAppTheme,
  CardProps as UICardProps,
  Flex,
  Button,
  BoxProps,
} from '@workwhile/ui'
import { ChevronDown } from 'lucide-react'
import { ChevronRight } from 'lucide-react'
import React, { useState } from 'react'

export type AccordionCardProps = UICardProps & {
  size?: 'small' | 'large' | 'fit'
  title: React.ReactNode | string
  children?: React.ReactNode
  isAccordion?: boolean
  defaultOpen?: boolean
  contentWrapperProps?: BoxProps
}

export const AccordionCard = ({
  title,
  children,
  isAccordion = false,
  defaultOpen = true,
  size = 'large',
  contentWrapperProps = {},
  ...props
}: AccordionCardProps) => {
  const { colors } = useAppTheme()
  const [isOpen, setIsOpen] = useState(defaultOpen)
  return (
    <UICard
      shadowless
      borderColor={colors.neutrals[100]}
      {...props}
      // `position: unset` is a workaround to fix the position of the dropdown menu
      style={{
        alignSelf: isOpen ? 'unset' : 'baseline',
        position: 'unset',
        maxWidth: size === 'small' ? 'calc(50% - 0.8rem)' : '100%',
        width: size === 'fit' ? 'fit-content' : '100%',
        display: size === 'fit' ? 'inline-flex' : 'flex',
        // Intentionally disabled. This should work, unsure why it's throwing an error
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...props.style,
      }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        {typeof title === 'string' ? (
          <Text fontSize={2} color="lightText">
            {title}
          </Text>
        ) : (
          title
        )}
        {isAccordion ? (
          <Button
            variant="text"
            onClick={() => setIsOpen((prev) => !prev)}
            kind="medium"
            ml={2}
            style={{
              padding: 2,
            }}
          >
            {isOpen ? <ChevronDown /> : <ChevronRight />}
          </Button>
        ) : null}
      </Flex>

      {isOpen ? (
        children ? (
          <Box mt={3} {...contentWrapperProps}>
            {children}
          </Box>
        ) : null
      ) : null}
    </UICard>
  )
}
