import React, { useMemo, useState } from 'react'
import { Box, Button, Text } from '@workwhile/ui'
import { PositionTemplateModal } from './PositionTemplateModal'
import { usePositionEditorContext } from '../PositionEditorContext'

export function PositionTemplate() {
  const { positionData } = usePositionEditorContext()
  const [isEditing, setIsEditing] = useState(false)
  const workerCategories = useMemo(() => {
    if (
      !positionData?.positionTemplate?.relevantPositionTemplateWorkerCategories
    ) {
      return []
    }
    return positionData.positionTemplate.relevantPositionTemplateWorkerCategories.map(
      (wc) => wc.name
    )
  }, [positionData])

  return (
    <Box>
      <Text>
        Template Id: <strong>{positionData.positionTemplate?.id}</strong>
      </Text>
      <Text>
        Template Name: <strong>{positionData.positionTemplate?.name}</strong>
      </Text>
      {workerCategories.length > 0 ? (
        <Text>
          Worker Categories: <strong>{workerCategories.join(', ')}</strong>
        </Text>
      ) : null}
      <Button
        mt={2}
        variant={'secondary'}
        kind={'medium'}
        onClick={() => setIsEditing(!isEditing)}
      >
        Change Template
      </Button>
      <PositionTemplateModal
        isOpen={isEditing}
        onClose={() => setIsEditing(false)}
      />
    </Box>
  )
}
