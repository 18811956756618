import { MutationOptions, useMutation } from '@tanstack/react-query'
import { positionPositionTemplateMutationKey } from './keys'
import { updatePositionPositionTemplate } from 'api/company/positionTemplate'
import { addPositionRequirement } from 'api/company/positionRequirements'
import { removePositionRequirement } from 'api/company/positionRequirements'

interface UpdatePositionPositionTemplateMutationParams {
  newPositionTemplateId: number
  removedRequirementIds: string[]
  newRequirementIds: string[]
}

export const useUpdatePositionPositionTemplateMutation = (
  positionId: number,
  options?: MutationOptions<
    void,
    Error,
    UpdatePositionPositionTemplateMutationParams
  >
) => {
  return useMutation({
    mutationKey: positionPositionTemplateMutationKey(positionId),
    mutationFn: async ({
      newPositionTemplateId,
      removedRequirementIds,
      newRequirementIds,
    }: UpdatePositionPositionTemplateMutationParams) => {
      // first update the position template
      await updatePositionPositionTemplate({
        positionId,
        positionTemplateId: newPositionTemplateId,
      })

      // then remove the requirements
      await Promise.all(
        removedRequirementIds.map((id) =>
          removePositionRequirement(positionId, Number(id))
        )
      )

      // then add the new requirements
      await Promise.all(
        newRequirementIds.map((id) =>
          addPositionRequirement(positionId, Number(id))
        )
      )
    },
    ...options,
  })
}
