import React, { PropsWithChildren, useState } from 'react'

import * as zod from 'zod'
import { createCompanyGroupFlowSchema } from '../schemas/companyGroup'
import { createContext } from 'lib/createContext'
import { useCreateCompanyGroupMutation } from 'queries/companyGroups'
import { parseErrorMessage } from 'api'

type CreateCompanyGroupFlowProviderContextState = {
  actions: {
    submitFormToServer: ReturnType<
      typeof useCreateCompanyGroupMutation
    >['mutate']
    setFormData: (data: zod.infer<typeof createCompanyGroupFlowSchema>) => void
  }
  state: {
    formData: zod.infer<typeof createCompanyGroupFlowSchema>
    mutationState: {
      isPending: boolean
      isError: boolean
      errorMessage: string | undefined
    }
  }
}

const [Context, useCreateCompanyGroupFlowContextValue] =
  createContext<CreateCompanyGroupFlowProviderContextState>({
    name: 'CreateCompanyGroupFlowContext',
  })

export const CreateCompanyGroupFlowProvider = ({
  children,
}: PropsWithChildren) => {
  const [createCompanyGroupFormData, setCreateCompanyGroupFormData] = useState<
    zod.infer<typeof createCompanyGroupFlowSchema>
  >({ companyGroupName: '' })

  const { mutate, isPending, isError, error } = useCreateCompanyGroupMutation()

  const value: CreateCompanyGroupFlowProviderContextState = {
    actions: {
      submitFormToServer: mutate,
      setFormData: (data: zod.infer<typeof createCompanyGroupFlowSchema>) => {
        setCreateCompanyGroupFormData((prev) => ({
          ...prev,
          ...data,
        }))
      },
    },
    state: {
      formData: createCompanyGroupFormData,
      mutationState: {
        isPending,
        isError,
        errorMessage: parseErrorMessage(error),
      },
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useCreateCompanyGroupFlowContextValue }
