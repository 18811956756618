import { useQuery } from '@tanstack/react-query'
import { getAllMarkets } from 'api/markets/getAllMarkets'
import { marketsKey } from './keys'

export const useMarketsQuery = () => {
  return useQuery({
    queryKey: marketsKey,
    queryFn: getAllMarkets,
    staleTime: 1000 * 60 * 60 * 8, // 8 hours
  })
}
