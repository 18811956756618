import { Box } from '@workwhile/ui'
import React from 'react'
import { TierInsightsPanel } from './TierInsightsPanel/TierInsightsPanel'
import { TierStatsProvider } from '../context/TierStatsProvider'
import { SectionHeading } from './SectionHeading'
import { useListingsOverviewContextValue } from '../context/ListingsOverviewProvider'
export const ListingsOverviewMain = () => {
  const {
    state: { dataUpdatedAt, isFetching, isError, error, listingId },
    actions: { refetch },
  } = useListingsOverviewContextValue()

  return (
    <Box>
      <SectionHeading
        dataUpdatedAt={dataUpdatedAt}
        isFetching={isFetching}
        refetch={refetch}
        isError={isError}
        error={error}
        listingId={listingId}
      />
      <TierStatsProvider>
        <TierInsightsPanel />
      </TierStatsProvider>
    </Box>
  )
}
