import { Box, Flex, Loading, Text, Table } from '@workwhile/ui'
import React from 'react'
import { useShiftsContextValue } from '../../context/ShiftsProvider'
import { useSelectedColumns } from './ColumnsFilter/useSelectedColumns'

export const ShiftsTable = () => {
  const {
    state: { data, pagination, isFetching },
    actions: { setPagination },
  } = useShiftsContextValue()

  const doesHaveData = data?.items.length > 0
  const rowCount = data?.totalRows
  const columns = useSelectedColumns()
  return (
    <Box>
      {doesHaveData ? (
        <Table
          columns={columns}
          data={data.items}
          // FIX ME: stickyHeader's z-index is set to 1, which collides with the z-index of the AttributesSelector. We should bump AttributesSelector's z-index to 2.
          // stickyHeader
          textVerticalAlign="top"
          serverPagination={{
            rowCount,
            state: pagination,
            setState: setPagination,
          }}
        />
      ) : (
        <Flex
          mb={2}
          flexDirection="column"
          alignItems="center"
          color="lightText"
          height={258}
          justifyContent="center"
          maxWidth={['100%', '100%', '50%', '50%']}
          margin="auto"
        >
          {isFetching ? (
            <Loading />
          ) : (
            <Text>
              No shifts matched your search. Try using different filters.
            </Text>
          )}
        </Flex>
      )}
    </Box>
  )
}
