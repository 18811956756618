import { addDays, format } from 'date-fns'
import qs from 'qs'
import { SHIFTS } from 'constants/routes'

const getDefaultQueryParams = () => {
  const params = qs.stringify({
    'range-type': 'upcoming',
    'start-date': format(new Date(), 'yyyy-MM-dd'),
    'end-date': format(addDays(new Date(), 4), 'yyyy-MM-dd'),
  })
  return params
}

export const getRouteWithDefaultQueryParams = () => {
  const defaultParams = getDefaultQueryParams()
  return `${SHIFTS}?${defaultParams}`
}
