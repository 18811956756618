import React from 'react'
import { useCreateCompanyFlowContextValue } from '../context/CreateCompanyFlowProvider'
import {
  Box,
  Button,
  Field,
  Flex,
  Heading,
  Input,
  Message,
  Modal,
  RadioGroup,
  toast,
} from '@workwhile/ui'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { createCompanyFlowSchema } from '../schemas/createCompany'
import { useCompaniesWorkflowsContextValue } from 'pages/Companies/context/CompaniesWorkflowsProvider'

export const CreateCompanyFlowMain = () => {
  const {
    createCompanyFlow: { isOpen, close },
  } = useCompaniesWorkflowsContextValue()

  const {
    state: {
      formData,
      mutationState: { isPending, errorMessage },
    },
    actions: { submitFormToServer, setFormData },
  } = useCreateCompanyFlowContextValue()

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: zodResolver(createCompanyFlowSchema),
    defaultValues: formData,
  })

  const onSubmit = handleSubmit((data) => {
    const dataToSubmit = {
      companyName: data.companyName.trim(),
      type: data.type,
      shouldSetToInvoice: data.shouldSetToInvoice,
    }

    setFormData(dataToSubmit)
    submitFormToServer(dataToSubmit, {
      onSuccess: () => {
        toast.success(`Successfully created "${data.companyName}" company!`, {
          position: 'top-center',
        })
        setFormData({
          companyName: '',
          type: '1099',
          shouldSetToInvoice: false,
        })
        reset()
        close()
      },
    })
  })

  return (
    <Modal
      open={isOpen}
      onClose={close}
      position="top"
      loading={isPending}
      showCancel
      customCta={
        <Flex>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Create
          </Button>
        </Flex>
      }
    >
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Heading level={3} fontWeight="400" style={{ margin: 0 }}>
          Create company
        </Heading>
        <Field
          label={'Company name'}
          error={errors.companyName?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mt={3}
        >
          <Input
            {...register('companyName')}
            aria-label={'Company name'}
            placeholder={`e.g. Acme Inc.`}
          />
        </Field>
        <Controller
          name={'type'}
          control={control}
          render={({ field }) => {
            return (
              <Field
                label={'Type'}
                error={errors.type?.message?.toString()}
                // Intentionally disabled. This should work, unsure why it's throwing an error
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mt={4}
                width="fit-content"
              >
                <RadioGroup
                  {...field}
                  // Intentionally disabled. This should work, unsure why it's throwing an error
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  flexDirection={['column', 'row']}
                  gap={5}
                  options={[
                    { label: '1099', value: '1099' },
                    { label: 'W2', value: 'w2' },
                  ]}
                />
              </Field>
            )
          }}
        />
        <Controller
          name={'shouldSetToInvoice'}
          control={control}
          render={({ field }) => {
            return (
              <Field
                label={'Set to invoice'}
                error={errors.shouldSetToInvoice?.message?.toString()}
                // Intentionally disabled. This should work, unsure why it's throwing an error
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mt={4}
                width="fit-content"
              >
                <RadioGroup
                  {...field}
                  // Intentionally disabled. This should work, unsure why it's throwing an error
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  flexDirection={['column', 'row']}
                  gap={5}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              </Field>
            )
          }}
        />
        {errorMessage ? (
          <Message variant="error" mt={3} borderRadius="small">
            {errorMessage}
          </Message>
        ) : null}
      </Box>
    </Modal>
  )
}
