import { parseAsArrayOf, parseAsStringLiteral, useQueryState } from 'nuqs'
import {
  SelectableColumns,
  selectableColumns,
} from '../components/Shifts/ShiftsTable/ColumnsFilter'

export const useSelectedColumnsQueryState = () => {
  const selectedColumnsParser = parseAsStringLiteral(
    selectableColumns.map((column) => column.id)
  )

  const queryStates = useQueryState(
    'shifts-selected-columns',
    parseAsArrayOf<SelectableColumns>(selectedColumnsParser).withDefault([
      'time',
      'location',
      'position',
      'schedule-rate',
      'fill-rate',
      'tier',
    ])
  )

  return queryStates
}
