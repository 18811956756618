import api from 'api'

export function addPositionRequirement(
  positionId: number,
  requirementId: number
) {
  return api.put('/admin/position_requirements', {
    position_id: positionId,
    requirement_id: requirementId,
  })
}

export function removePositionRequirement(
  positionId: number,
  requirementId: number
) {
  return api.delete(`/admin/position_requirements`, {
    params: {
      position_id: positionId,
      requirement_id: requirementId,
    },
  })
}
