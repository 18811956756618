import { useCompanyDetailWorkflowsContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailWorkflowProvider'
import React from 'react'
import { useEditCompanyNameFlowContextValue } from '../context/EditCompanyNameFlowProvider'
import {
  Box,
  Button,
  Field,
  Flex,
  Heading,
  Input,
  Message,
  Modal,
  Text,
  toast,
} from '@workwhile/ui'
import { useCompanyDetailContextValue } from '../../../context/CompanyDetailProvider'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { editCompanyNameFlowSchema } from '../schemas/companyName'

export const EditCompanyNameFlowMain = () => {
  const {
    editCompanyNameFlow: { isOpen, close },
  } = useCompanyDetailWorkflowsContextValue()

  const {
    state: {
      formData,
      mutationState: { isPending, errorMessage },
    },
    actions: { submitFormToServer, setFormData },
  } = useEditCompanyNameFlowContextValue()

  const {
    state: { name },
  } = useCompanyDetailContextValue()

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(editCompanyNameFlowSchema),
    defaultValues: formData,
    values: {
      currentName: name,
    },
  })

  const onSubmit = handleSubmit((data) => {
    setFormData({ companyName: data.companyName })
    submitFormToServer(
      {
        companyName: data.companyName,
      },
      {
        onSuccess: () => {
          toast.success('Updated company name successfully!', {
            position: 'top-center',
          })
          setFormData({})
          reset()
          close()
        },
      }
    )
  })

  return (
    <Modal
      open={isOpen}
      onClose={close}
      position="top"
      loading={isPending}
      showCancel
      customCta={
        <Flex>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save
          </Button>
        </Flex>
      }
    >
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Heading level={3} fontWeight="400" style={{ margin: 0 }}>
          Edit company name
        </Heading>
        <Text mt={3} fontSize={2}>
          Current name
        </Text>
        <Text fontSize={2} color="lightText">
          {name}
        </Text>

        <Field
          label={'New name'}
          error={errors.companyName?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mt={3}
        >
          <Input
            {...register('companyName')}
            aria-label={'New name'}
            placeholder={`e.g. Acne Inc.`}
          />
        </Field>
        {errorMessage ? (
          <Message variant="error" mt={3} borderRadius="small">
            {errorMessage}
          </Message>
        ) : null}
      </Box>
    </Modal>
  )
}
