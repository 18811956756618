import { graphql } from 'api/graphql'
import { WorkerRequirement } from 'api/typings'
import { pathOr } from 'ramda'

// NOTE: these types differ from the ones in common_defs.ts slightly
// since new position templates to groups are many-to-many
export interface PositionTemplateGroup {
  id: number
  name: string
}

export interface PositionTemplate {
  id: number
  name: string
  relevantPositionTemplateGroups: PositionTemplateGroup[]
  relevantPositionTemplateWorkerCategories: Array<{
    name: string
  }>
  requirements?: Array<{
    requirement: WorkerRequirement
    isPreSelected: boolean
    isWorkwhileRequired: boolean
  }>
}

export async function getPositionTemplates() {
  const data = await graphql(
    `
      company {
        positionTemplates {
          id,
          name,
          relevantPositionTemplateGroups {
            id,
            name
          }
          relevantPositionTemplateWorkerCategories {
            name
          }
        }
      }
    `
  )

  const templates = pathOr(
    [],
    ['data', 'data', 'company', 'positionTemplates'],
    data
  ) as PositionTemplate[]

  return templates.map((template) => ({
    ...template,
    id: Number(template.id),
  }))
}
