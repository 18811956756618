import React from 'react'
import { Button } from '@workwhile/ui'
import { useFormContext } from 'react-hook-form'
import { FiltersSchema } from './filtersSchema'
import { useShiftsContextValue } from '../../context/ShiftsProvider'

export const SearchButton = () => {
  const {
    actions: {
      setTimeRangeFilters,
      setShiftFilters,
      setCompanyFilters,
      setPagination,
    },
  } = useShiftsContextValue()

  const {
    handleSubmit,
    formState: { isValid },
  } = useFormContext<FiltersSchema>()

  const onSubmit = (data: FiltersSchema) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }))
    setTimeRangeFilters({
      type: data.timeRange.type,
      startDate: data.timeRange.dateRange.from,
      endDate: data.timeRange.dateRange.to,
    })
    setShiftFilters({
      shiftIds: data.shift.ids.map((id) => id.id),
      marketCodes: data.shift.marketCodes.map((code) => code.code),
      scheduleRateMin: data.shift.scheduleRateMin ?? null,
      scheduleRateMax: data.shift.scheduleRateMax ?? null,
      fillRateMin: data.shift.fillRateMin ?? null,
      fillRateMax: data.shift.fillRateMax ?? null,
    })
    setCompanyFilters({
      companyIds: data.company.companyIds.map((id) => id.id),
      companyGroupIds: data.company.companyGroupIds.map((id) => id.id),
    })
  }

  return (
    <Button
      block={false}
      kind="medium"
      onClick={handleSubmit(onSubmit)}
      disabled={!isValid}
    >
      Search
    </Button>
  )
}
