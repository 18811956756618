import React from 'react'
import { AccordionCard } from 'components/AccordionCard'
import { useCompanyDetailContextValue } from '../context/CompanyDetailProvider'
import {
  Button,
  Flex,
  Label,
  Link,
  Text,
  toast,
  useAppTheme,
} from '@workwhile/ui'
import { CompanyMember, CompanyMemberPending } from '../types'
import { Link2 } from 'lucide-react'

const MemberRow = ({
  member,
  isLast,
}: {
  member: CompanyMemberPending | CompanyMember
  isLast: boolean
}) => {
  const { radii } = useAppTheme()

  const commonStyles = {
    border: '1px solid',
    borderColor: 'neutrals.100',
    p: 3,
    borderRadius: radii.standard,
    width: '100%',
    mb: isLast ? 0 : 3,
    justifyContent: 'space-between',
    alignItems: 'center',
  }

  if (!('name' in member)) {
    const onCopyInviteLink = () => {
      navigator.clipboard
        .writeText(member.inviteLink)
        .then(() => {
          toast.success('Copied Invite Link to clipboard')
        })
        .catch(() => {
          toast.error('Failed to copy Invite Link')
        })
    }
    return (
      <Flex {...commonStyles}>
        <Flex flexDirection="column" gap={1}>
          <Text style={{ minWidth: 280 }}>{member.email}</Text>
          <Text color="lightText">{member.companyRoleDescription}</Text>
        </Flex>
        <Flex alignItems="center" gap={4}>
          <Button
            variant="text"
            kind="small"
            title="Copy invite link"
            onClick={onCopyInviteLink}
          >
            <Link2 size={16} />
          </Button>
          <Label variant="information" size="small">
            Pending Invite
          </Label>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex {...commonStyles}>
      <Flex flexDirection="column" gap={1}>
        <Flex flexDirection="row" gap={3} alignItems="center">
          <Text as="span">
            {member.name}{' '}
            <Link to={`/users/${member.id}`} target="_blank">
              ({member.id})
            </Link>
          </Text>
        </Flex>
        <Text color="lightText">{member.companyRoleDescription}</Text>
      </Flex>
    </Flex>
  )
}

export const Members = () => {
  const {
    state: { members },
  } = useCompanyDetailContextValue()

  const memberRows = members.map((member, index) => {
    return (
      <MemberRow
        key={`member-rows-${'id' in member ? member.id : null}-${member.email}-${index}`}
        member={member}
        isLast={index === members.length - 1}
      />
    )
  })

  return (
    <AccordionCard
      title={
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize={2} color="lightText">
            Members
          </Text>
          <Label
            variant="none"
            size="medium"
            ml={2}
            style={{
              padding: 8,
            }}
          >
            {members.length}
          </Label>
        </Flex>
      }
      // Intentionally disabled. This should work, unsure why it's throwing an error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      width="100%"
      isAccordion
      defaultOpen={false}
    >
      {memberRows.length > 0 ? (
        memberRows
      ) : (
        <Text color="lightText" fontSize={1}>
          No members found
        </Text>
      )}
    </AccordionCard>
  )
}
