import { useMutation, useQueryClient } from '@tanstack/react-query'
import { companyDetailKey } from './keys'
import { CompanyDetail } from 'pages/Company/CompanyDetail/types'
import { addMinutes, parseISO } from 'date-fns'
import { addMinimumPayPolicy } from 'api/company/addMinimumPayPolicy'

export const useAddMinimumPayPolicyMutation = (companyId: number) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (request: Parameters<typeof addMinimumPayPolicy>[0]) =>
      addMinimumPayPolicy(request),
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: companyDetailKey(companyId) })

      const previousCompanyDetail = queryClient.getQueryData(
        companyDetailKey(companyId)
      )

      // Optimistically update to the new value
      queryClient.setQueryData<CompanyDetail>(
        companyDetailKey(companyId),
        (old) => {
          const newCompanyDetail = {
            ...old,
          }

          const newUpcomingMinimumPayPolicy = [
            ...old.upcomingMinPayPolicies,
            {
              id: Math.floor(Math.random() * 1000000),
              isDefault: false,
              startsAt: variables.startsAt
                ? variables.startsAt
                : addMinutes(Date.now(), 2).toISOString(),
              minPayLengthInMinutes: variables.minPayLengthInMinutes,
            },
          ].sort(
            (a, b) =>
              parseISO(a.startsAt).getTime() - parseISO(b.startsAt).getTime()
          )

          newCompanyDetail.upcomingMinPayPolicies = newUpcomingMinimumPayPolicy

          return newCompanyDetail
        }
      )

      return { previousCompanyDetail }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        companyDetailKey(companyId),
        context.previousCompanyDetail
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: companyDetailKey(companyId) })
    },
  })
}
