import {
  useFieldArray,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form'
import { AddCancellationPolicySchema } from '../schemas/cancellationPolicy'
import React from 'react'
import {
  Box,
  Button,
  Field,
  Flex,
  Input,
  Text,
  useAppTheme,
} from '@workwhile/ui'
import { CircleMinus } from 'lucide-react'

const PolicyWindowRow = ({
  remove,
  index,
  canBeRemoved,
}: {
  remove: UseFieldArrayRemove
  index: number
  canBeRemoved: boolean
}) => {
  const { colors, radii, space } = useAppTheme()

  const {
    register,
    formState: { errors },
  } = useFormContext<AddCancellationPolicySchema>()

  return (
    <Flex flexDirection="column">
      {canBeRemoved ? (
        <Button
          onClick={() => remove(index)}
          variant="text"
          color="red"
          size="small"
          danger
          style={{
            margin: 0,
            height: '1.6rem', // This is to resolve a bug in our UI library where the width and height is being set to `small` (literally) instead of a `px` value
            alignSelf: 'flex-end',
            marginBottom: space[1],
          }}
        >
          <CircleMinus size={16} />
        </Button>
      ) : null}
      <Flex
        gap={3}
        alignItems="flex-start"
        justifyContent="space-between"
        border="1px solid"
        borderColor={colors.neutrals[100]}
        p={3}
        pb={0}
        borderRadius={radii.small}
        mb={3}
        position="relative"
      >
        <Field
          label={'Cutoff time (hours)'}
          error={errors.policyWindows?.[index]?.cutoffTime?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          width="50%"
        >
          <Input
            {...register(`policyWindows.${index}.cutoffTime`)}
            aria-label={'Cutoff time'}
            placeholder={`e.g. 12`}
          />
        </Field>
        <Field
          label={'Charge percentage'}
          error={errors.policyWindows?.[
            index
          ]?.chargePercentage?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          width="50%"
        >
          <Input
            {...register(`policyWindows.${index}.chargePercentage`)}
            aria-label={'Charge percentage'}
            placeholder={`e.g. 100`}
          />
        </Field>
      </Flex>
    </Flex>
  )
}

export const AddPolicyWindows = () => {
  const { control } = useFormContext<AddCancellationPolicySchema>()
  const { fields, append, remove } = useFieldArray({
    name: 'policyWindows',
    control,
  })

  const policyWindowRows = fields.map((field, index) => (
    <PolicyWindowRow
      key={field.id}
      index={index}
      remove={remove}
      canBeRemoved={fields.length > 1}
    />
  ))

  return (
    <Box>
      <Text mb={2}>Policy windows</Text>
      {policyWindowRows}
      <Button onClick={append} block variant="secondary">
        Add policy window
      </Button>
    </Box>
  )
}
