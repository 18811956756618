import React from 'react'
import { Button } from '@workwhile/ui'
import { useFormContext } from 'react-hook-form'
import { FiltersSchema } from './filtersSchema'
import { useCompanyDetailContextValue } from '../../../context/CompanyDetailProvider'

export const SearchButton = () => {
  const {
    actions: {
      shifts: { setTimeRangeFilters, setPagination },
    },
  } = useCompanyDetailContextValue()

  const {
    handleSubmit,
    formState: { isValid },
  } = useFormContext<FiltersSchema>()

  const onSubmit = (data: FiltersSchema) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }))

    setTimeRangeFilters({
      type: data.timeRange.type,
      startDate: data.timeRange.dateRange.from,
      endDate: data.timeRange.dateRange.to,
    })
  }

  return (
    <Button
      block={false}
      kind="medium"
      onClick={handleSubmit(onSubmit)}
      disabled={!isValid}
    >
      Search
    </Button>
  )
}
