import React from 'react'
import { ShiftsMain } from './components/ShiftsMain'
import { ShiftsProvider } from './context/ShiftsProvider'
import { NuqsAdapter } from 'nuqs/adapters/react'

export const Shifts = () => {
  return (
    <NuqsAdapter>
      <ShiftsProvider>
        <ShiftsMain />
      </ShiftsProvider>
    </NuqsAdapter>
  )
}
