import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { Text, Box } from '@workwhile/ui'
import { BooleanIcon } from './BooleanIcon'
import { WorkerRequirement } from 'api/typings'

export interface ExtendedRequirement extends WorkerRequirement {
  isMustHave?: boolean
  isPreSelected?: boolean
  isWorkWhileRequired?: boolean
}

export const columnHelper = createColumnHelper<ExtendedRequirement>()

export const columns = [
  columnHelper.accessor('name', {
    header: 'Requirement',
    cell: (info) => {
      const name = info.getValue()
      const requirement = info.row.original
      return (
        <Box>
          <Text>
            {requirement.id} - {name}
          </Text>
          <Text fontSize={1} color={'lightText'}>
            {requirement.category}
          </Text>
          {requirement.isWorkWhileRequired ? (
            <Text fontSize={1} color={'error'}>
              WorkWhile Required
            </Text>
          ) : null}
        </Box>
      )
    },
  }),
  columnHelper.accessor('verifiable', {
    header: 'Verifiable',
    cell: (info) => <BooleanIcon value={info.getValue()} />,
  }),
  columnHelper.accessor('isWorkerVisible', {
    header: 'Visible to Workers',
    cell: (info) => <BooleanIcon value={info.getValue()} />,
  }),
  columnHelper.accessor('isMustHave', {
    header: 'Must Have',
    cell: (info) => <BooleanIcon value={info.getValue()} />,
  }),
]
