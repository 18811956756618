import React from 'react'
import { Box, Flex, Heading, Text } from '@workwhile/ui'
import { WorkerRow } from '../WorkerRow'
import { useShiftDetailContextValue } from '../../context/ShiftDetailProvider'

export const Workers = () => {
  const {
    state: { id: shiftId, work, startsAt },
  } = useShiftDetailContextValue()

  const workers = work.map((w, index) => {
    return (
      <WorkerRow
        key={`worker-${w.id}-${index}`}
        work={w}
        shiftId={shiftId}
        shiftStartsAt={startsAt}
        allowEdit
      />
    )
  })

  return (
    <Box>
      <Heading level={2} fontWeight="400">
        Workers
      </Heading>
      <Flex gap={3} flexDirection="column">
        {workers.length > 0 ? (
          workers
        ) : (
          <Text color="lightText">No workers scheduled</Text>
        )}
      </Flex>
    </Box>
  )
}
