import React from 'react'
import { AccordionCard, AccordionCardProps } from 'components/AccordionCard'
import { Display, Text } from '@workwhile/ui'

type StatCardProps = {
  title: string
  value: string | number
  color?: string
  size?: 'fit' | 'large'
  // Intentionally disabled. This should work, unsure why it's throwing an error
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  style?: AccordionCardProps['style']
}

export const StatCard = ({
  title,
  value,
  color,
  size = 'large',
  style,
}: StatCardProps) => {
  return (
    <AccordionCard
      title={title}
      size="fit"
      // Intentionally disabled. This should work, unsure why it's throwing an error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={
        size === 'large'
          ? {
              width: '140px',
              height: '138px',
              ...style,
            }
          : {
              padding: 8,
              ...style,
            }
      }
      contentWrapperProps={{
        marginTop: size === 'large' ? 2 : 1,
      }}
    >
      {size === 'large' ? (
        <Display
          level={3}
          fontWeight="400"
          color={color}
          style={{
            marginTop: 0,
          }}
        >
          {value}
        </Display>
      ) : null}

      {size === 'fit' ? (
        <Text fontSize={3} color={color}>
          {value}
        </Text>
      ) : null}
    </AccordionCard>
  )
}
