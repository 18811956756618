import React from 'react'
import { EditCompanyNameFlowMain } from './components/EditCompanyNameFlowMain'
import { EditCompanyNameFlowProvider } from './context/EditCompanyNameFlowProvider'

export const EditCompanyNameFlow = () => {
  return (
    <EditCompanyNameFlowProvider>
      <EditCompanyNameFlowMain />
    </EditCompanyNameFlowProvider>
  )
}
