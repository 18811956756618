import React from 'react'
import { Box, Button, DropdownMenu, Flex, Text, Link } from '@workwhile/ui'
import { useCompanyDetailContextValue } from './context/CompanyDetailProvider'
import { UserPlus, Ellipsis, Pencil, FileImage } from 'lucide-react'
import { GeneralDetails } from './components/GeneralDetails'
import { Money } from './components/Money'
import { Members } from './components/Members'
import { Positions } from './components/Positions'
import { CancellationPolicies } from './components/CancellationPolicies'
import { useCompanyDetailWorkflowsContextValue } from './context/CompanyDetailWorkflowProvider'
import { Header } from 'components/Header'
import { QueryStatus } from 'components/QueryStatus'
import { MinimumPayPolicy } from './components/MinimumPayPolicy'
import { Shifts } from './components/Shifts/Shifts'

const CompanyDetailHeader = () => {
  const { state, actions } = useCompanyDetailContextValue()
  const { editCompanyNameFlow, inviteUserToCompanyFlow, manageLogoFlow } =
    useCompanyDetailWorkflowsContextValue()

  return (
    <Header
      title={`${state.name} (${state.id})`}
      subTitle="Company Detail"
      leftContent={
        state.logoUrl ? (
          <img
            src={state.logoUrl}
            alt={state.name}
            width={50}
            height={50}
            style={{ objectFit: 'contain' }}
          />
        ) : undefined
      }
      rightContent={
        <Flex
          alignItems="center"
          gap={2}
          data-context-key="company_id"
          data-context-value={state.id}
          data-context-label={state.name}
        >
          <QueryStatus
            dataUpdatedAt={state.dataUpdatedAt}
            isFetching={state.isFetching}
            refetch={actions.refetch}
            isError={state.isError}
            error={state.error}
          />
          <Button
            as={Link}
            to={`/company/switch/${state.id}`}
            variant="primary"
            kind="medium"
            target="_blank"
          >
            <Text color="white">Switch</Text>
          </Button>
          <DropdownMenu
            trigger={
              <Button variant="secondary" style={{ padding: 4 }} kind="medium">
                <Ellipsis />
              </Button>
            }
          >
            <DropdownMenu.Item onClick={inviteUserToCompanyFlow.open}>
              <Flex as="span" alignItems="center" style={{ gap: 8 }}>
                <UserPlus size={14} />
                Invite to Company
              </Flex>
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={editCompanyNameFlow.open}>
              <Flex as="span" alignItems="center" style={{ gap: 8 }}>
                <Pencil size={14} />
                Edit Company Name
              </Flex>
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={manageLogoFlow.open}>
              <Flex as="span" alignItems="center" style={{ gap: 8 }}>
                <FileImage size={14} />
                Manage Logo
              </Flex>
            </DropdownMenu.Item>
          </DropdownMenu>
        </Flex>
      }
    />
  )
}

export const CompanyDetailMain = () => {
  return (
    <>
      <CompanyDetailHeader />
      <Box margin={3}>
        <Flex gap={3}>
          <GeneralDetails />
          <Money />
        </Flex>
        <Flex gap={3} wrap="wrap" marginTop={3}>
          <Shifts />
        </Flex>

        <Flex gap={3} wrap="wrap" marginTop={3}>
          <CancellationPolicies />
          <MinimumPayPolicy />
        </Flex>
        <Flex gap={3} wrap="wrap" marginTop={3}>
          <Members />
        </Flex>
        <Flex gap={3} wrap="wrap" marginTop={3}>
          <Positions />
        </Flex>
      </Box>
    </>
  )
}
