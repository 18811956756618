import { graphql } from 'api/graphql'
import { path } from 'ramda'
import { User } from 'api/typings'

export async function searchUsers(keyword: string) {
  const result = await graphql(`
      admin {
          users (searchTerm:"${keyword}") {
            id, firebaseId, name, phoneNumber, email, status,
            profilePicUrl, verifiedSelfieUrl, profilePicVerifiedAt,
            profilePicManuallyVerifiedBy {
              id, name
            },
            shadowBlocked, shadowBlockedReason, orientationStatus, rating, createdAt, w2Status, w2States, w2OnboardingStep, w2EvereeId
            companyWorkerProfiles {
              companyId, workerId, favorite, blocked, hired, blockedReason, paidShiftCount
            },
            paymentAccounts {
              accountType
            },
            penalties {
              createdAt,
              userId,
              penaltyType,
              expiresAt,
              status,
              policy,
              offenses {
                shiftId,
                reason,
                healthCheckAnswers
                isCancelledForIllness
              }
            },
            referrals {
              id,
              status,
              referredName,
              referred {
                id,
                name,
              }
            },
            reference {
              id,
              status,
              referredName,
              referrer {
                id,
                name,
              }
            },
            userStats {shiftCount, penaltyCount},
            address {city, state, street, unit, zip, lat, long, timezone},
            berbixToken, berbixStatus, backgroundCheckStatus, backgroundCheckMvrStatus, identityVerificationId
            checkrStatus {
              candidateId,
              criminalStatus,
              criminalSubmitted,
              mvrStatus,
              mvrSubmitted,
              drugScreenings {
                reportId
                status
                transitions {
                  status
                  occurredAt
                }
                expiresAt
              }
            },
            yardstikStatus {
              candidateId,
              criminalReportId,
              criminalStatus,
              criminalSubmitted,
              mvrReportId,
              mvrStatus,
              mvrSubmitted,
              drugScreenings {
                reportId
                status
                transitions {
                  status
                  occurredAt
                }
              }
            },
            strikes { date, excuse, id, reason, isCancelledForIllness, shiftId },
            allStrikes { date, excuse, id, reason, isCancelledForIllness, shiftId, excluded },
            stripeAccountIdProd, hasSsn, tierExperienced,
            workProfile {
              hasLicense, hasCar, isElite, currentlyEmployed, numHoursDesired, wantsFullTime, telehealthBenefitStatus
            }
            credentials{
              id
              workerRequirement{
                name
                documentNeeded
              }
            }
            workerTutorials {
              tutorialId, quizScore
            }
          }
      }
  `)

  return path(['data', 'data', 'admin', 'users'], result) as User[]
}
