import React from 'react'
import { Flex } from '@workwhile/ui'
import { StatCard } from '../StatCard'
import { useShiftDetailContextValue } from '../../context/ShiftDetailProvider'
import { toTitleCase } from 'lib/string'

const fillConfidenceColorMap = {
  high: 'successes.100',
  medium: 'warnings.100',
  low: 'errors.100',
}

export const ConfidenceAndCushion = () => {
  const {
    state: { fillConfidence, numCushionWorkersFilled, numCushionWorkersNeeded },
  } = useShiftDetailContextValue()

  const cushionValue = numCushionWorkersNeeded
    ? `${numCushionWorkersFilled}/${numCushionWorkersNeeded}`
    : 'N/A'

  return (
    <Flex gap={3} flexDirection="column">
      <StatCard
        title="Fill confidence"
        value={fillConfidence ? toTitleCase(fillConfidence) : '-'}
        color={fillConfidenceColorMap[fillConfidence]}
      />
      <StatCard title="Cushion" value={cushionValue} />
    </Flex>
  )
}
