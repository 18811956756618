import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import EditableCellFormatter from '../Shared/EditableCellFormatter'
import { onPositionDataChange } from '../../actions/company_actions'
import Loading from '../Shared/loading'

interface Props {
  positionData
  refreshCallback
}
const PositionOverview = ({ positionData, refreshCallback }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const editableFormatter = (cell, row, field) => {
    const errorCallback = (error) => {
      setIsLoading(false)
      alert(error)
    }
    const responseCallback = (response) => {
      setIsLoading(false)
      alert('Success. Page will refresh now...')
      refreshCallback()
    }

    const onSaveCallback = (newValue) => {
      setIsLoading(true)
      const data = {
        [field]: newValue,
      }
      onPositionDataChange(row.id, data, responseCallback, errorCallback)
    }

    return (
      <EditableCellFormatter
        cell={cell}
        row={row}
        onSaveCallback={onSaveCallback}
      />
    )
  }
  const positionColumns = [
    {
      dataField: 'id',
      text: 'Position ID',
      formatter: (cell, row) => <span data-context-key="position_id" data-context-value={cell} data-context-label={row.name}>{cell}</span>,
    },
    {
      dataField: 'name',
      text: 'Position Name',
      formatter: (cell, row) => editableFormatter(cell, row, 'name'),
    },
    {
      dataField: 'about',
      text: 'Description',
      formatter: (cell, row) => editableFormatter(cell, row, 'about'),
    },
  ]
  if (positionData === null) {
    return <></>
  }
  return isLoading ? (
    <Loading />
  ) : (
    <>
      <BootstrapTable
        keyField="id"
        data={[positionData]}
        columns={positionColumns}
        striped
        rowStyle={{ fontSize: 14 }}
      />
      <br />
    </>
  )
}
export default PositionOverview
