import { AttributesSelector } from '@workwhile/ui'
import React, { useMemo } from 'react'
import { FormProvider, useFormContext } from 'react-hook-form'
import { TimeRangeInputs } from './TimeRangeInputs'
import { format } from 'date-fns'
import { FiltersSchema } from '../filtersSchema'

export const TimeRangeFilters = () => {
  const methods = useFormContext<FiltersSchema>()

  const dateRange = methods.watch('timeRange.dateRange')
  const type = methods.watch('timeRange.type')

  const resetToDefaultValues = () => {
    methods.resetField('timeRange.type', {
      defaultValue: null,
    })
    methods.resetField('timeRange.dateRange', {
      defaultValue: {
        from: null,
        to: null,
      },
    })
  }

  const selectedAttributes = useMemo(() => {
    const attributes = []

    if (type === 'past') {
      attributes.push('Past')
    }

    if (type === 'ongoing') {
      attributes.push('Ongoing')
    }

    if (type === 'upcoming') {
      attributes.push('Upcoming')
    }

    const startDate = dateRange?.from
      ? format(dateRange.from, 'MMM d, yyyy ')
      : ''
    const endDate = dateRange?.to ? format(dateRange.to, 'MMM d, yyyy') : ''

    if (startDate && endDate) {
      attributes.push(`${startDate} to ${endDate}`)
    }

    return attributes
  }, [dateRange])

  return (
    <FormProvider {...methods}>
      <AttributesSelector
        label="Time Range"
        selectedAttributes={selectedAttributes}
        onClearAll={resetToDefaultValues}
        groups={
          <AttributesSelector.Group
            key="time-range"
            title="Time range"
            numberOfSelectedAttributes={selectedAttributes.length}
          >
            <TimeRangeInputs />
          </AttributesSelector.Group>
        }
      />
    </FormProvider>
  )
}
