import { Supervisor } from '../types'
import { formatPhoneNumber } from 'lib/phone'
export const formatSupervisors = (supervisors: Supervisor[]) => {
  return supervisors
    .map((supervisor) =>
      supervisor.phoneNumber
        ? `${supervisor.name} (${formatPhoneNumber(supervisor.phoneNumber)})`
        : supervisor.name
    )
    .join(', ')
}
