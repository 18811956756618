import { TierType } from 'pages/Shifts/ShiftDetail/types'

export const shiftKey = ['shift']

export const shiftDetailKey = (shiftId: number) => [...shiftKey, shiftId]
export const tierEligibilitiesByShiftIdKey = (shiftId: number) => [
  ...shiftDetailKey(shiftId),
  'tier-eligibilities',
]

export const tierStatsByShiftIdKey = (shiftId: number, tier: TierType) => [
  ...tierEligibilitiesByShiftIdKey(shiftId),
  tier,
]

export const shiftsKey = ['shifts']
export const shiftsKeyWithParams = (
  params: (string | number | (number | string)[])[]
) => [...shiftsKey, ...params]
