import React from 'react'
import { Flex, Text, useAppTheme } from '@workwhile/ui'
import { CircleAlert } from 'lucide-react'
import { ErrorReferencePopover } from './ErrorReferencePopover'

type ErrorProps = {
  error: string
}

export const Error = ({ error }: ErrorProps) => {
  return (
    <Flex
      as="span"
      color="errors.200"
      fontSize={1}
      justifyContent="center"
      alignItems="center"
      gap={2}
    >
      <ErrorReferencePopover error={error} />
      <Text as="span">Failed to load data. Please try again.</Text>
    </Flex>
  )
}
