import React from 'react'
import { WorkShift } from '../../Shared/common_defs'
import Loading from '../../Shared/loading'
import { isShiftFull, isShiftInProgress } from '../../utils/general_util'
import ListingInfo from './ListingInfo'
import { useLegacyShiftDetails } from 'queries/shifts'

interface Props {
  shift: WorkShift
}

const Listings = (props: Props) => {
  const shift = useLegacyShiftDetails(props.shift.id, 'listingTiers')
  const shiftFull = isShiftFull(shift.data)
  const inProgress = isShiftInProgress(shift.data)

  if (shift.isFetching) {
    return <Loading type="button" />
  }

  if (!shift.data?.listings) {
    return <p>Could not find listings info for this shift.</p>
  }

  return (
    <div>
      {shift.data?.listings?.map((listing) => (
        <ListingInfo
          key={listing.id}
          listing={listing}
          shift={shift.data}
          shiftFull={shiftFull}
          shiftInProgress={inProgress}
          fetchListings={shift.refetch}
        />
      ))}
    </div>
  )
}

export default Listings
