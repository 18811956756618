import React from 'react'
import { InviteUserToCompanyFlowProvider } from './context/InviteUserToCompanyFlowProvider'
import { InviteUserToCompanyFlowMain } from './components/InviteUserToCompanyFlowMain'

export const InviteUserToCompanyFlow = () => {
  return (
    <InviteUserToCompanyFlowProvider>
      <InviteUserToCompanyFlowMain />
    </InviteUserToCompanyFlowProvider>
  )
}
