import { Flex, Input, Field } from '@workwhile/ui'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { editMoneyFlowSchema } from '../schemas/editMoneyFlow'
import * as zod from 'zod'
import { InvoiceField } from './InvoiceField'

export const RateW2Form = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<zod.infer<typeof editMoneyFlowSchema>>()

  return (
    <>
      <Flex flexDirection="row" gap={3}>
        <Field
          label={'W2 Regular take rate %'}
          error={errors.w2RegularTakeRate?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mt={3}
          width="50%"
        >
          <Input
            {...register('w2RegularTakeRate')}
            aria-label={'W2 Regular take rate'}
            placeholder={`e.g. 35%`}
          />
        </Field>
        <Field
          label={'W2 Double take rate %'}
          error={errors.w2DoubleTakeRate?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mt={3}
          width="50%"
        >
          <Input
            {...register('w2DoubleTakeRate')}
            aria-label={'W2 Double take rate'}
            placeholder={`e.g. 10%`}
          />
        </Field>
      </Flex>
      <Flex flexDirection="row" gap={3}>
        <Field
          label={'W2 Overtime take rate %'}
          error={errors.w2OvertimeTakeRate?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mt={3}
          width="50%"
        >
          <Input
            {...register('w2OvertimeTakeRate')}
            aria-label={'W2 Overtime take rate'}
          />
        </Field>
        <InvoiceField />
      </Flex>
    </>
  )
}
