import React from 'react'
import { CompanyGroupsProvider } from './context/CompanyGroupsProvider'
import { CompanyGroupsMain } from './components/CompanyGroupsMain'
import { CompanyGroupsWorkflowsProvider } from './context/CompanyGroupsWorkflowsProvider'
import { CreateCompanyGroupFlow } from './workflows/CreateCompanyGroupFlow/CreateCompanyGroupFlow'
import { NuqsAdapter } from 'nuqs/adapters/react'

export const CompanyGroups = () => {
  return (
    <NuqsAdapter>
      <CompanyGroupsProvider>
        <CompanyGroupsWorkflowsProvider>
          <CompanyGroupsMain />
          <CreateCompanyGroupFlow />
        </CompanyGroupsWorkflowsProvider>
      </CompanyGroupsProvider>
    </NuqsAdapter>
  )
}
