import React, { PropsWithChildren } from 'react'
import { createContext } from 'lib/createContext'
import { useAddCompaniesToGroupMutation } from 'queries/companyGroup'
import { parseErrorMessage } from 'api'
import { useCompanyGroupDetailContextValue } from 'pages/CompanyGroupDetail/context/CompanyGroupDetailProvider'
import { useCompanyGroupDetailWorkflowsContextValue } from 'pages/CompanyGroupDetail/context/CompanyGroupDetailWorkflowProvider'
import { useCompaniesQuery } from 'queries/companies/useCompaniesQuery'
import { CompanyInGroup } from 'pages/CompanyGroupDetail/types'

type AddCompaniesFlowProviderContextState = {
  actions: {
    submitFormToServer: ReturnType<
      typeof useAddCompaniesToGroupMutation
    >['mutate']
  }
  state: {
    companiesInGroup: CompanyInGroup[]
    allCompaniesQueryState: {
      data: ReturnType<typeof useCompaniesQuery>['data']
      isLoading: boolean
      errorMessage: string | undefined
    }
    mutationState: {
      isPending: boolean
      isError: boolean
      errorMessage: string | undefined
    }
  }
}

const [Context, useAddCompaniesFlowContextValue] =
  createContext<AddCompaniesFlowProviderContextState>({
    name: 'AddCompaniesFlowContext',
  })

const AddCompaniesContextFlowProvider = ({ children }: PropsWithChildren) => {
  const {
    state: { id: companyGroupId, companies: companiesInGroup },
  } = useCompanyGroupDetailContextValue()

  const {
    mutate: addCompaniesToGroupMutation,
    isPending,
    isError,
    error,
  } = useAddCompaniesToGroupMutation(companyGroupId)

  const {
    addCompaniesToGroupFlow: { isOpen },
  } = useCompanyGroupDetailWorkflowsContextValue()

  const {
    data: companies,
    isLoading: isCompaniesLoading,
    error: companiesError,
  } = useCompaniesQuery({ enabled: isOpen })

  const value: AddCompaniesFlowProviderContextState = {
    actions: {
      submitFormToServer: addCompaniesToGroupMutation,
    },
    state: {
      companiesInGroup,
      allCompaniesQueryState: {
        data: companies ?? [],
        isLoading: isCompaniesLoading,
        errorMessage: parseErrorMessage(companiesError),
      },
      mutationState: {
        isPending,
        isError,
        errorMessage: parseErrorMessage(error),
      },
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const AddCompaniesFlowProvider = ({ children }: PropsWithChildren) => {
  return (
    <AddCompaniesContextFlowProvider>
      {children}
    </AddCompaniesContextFlowProvider>
  )
}

export { useAddCompaniesFlowContextValue }
