import React, { PropsWithChildren, useState } from 'react'
import * as zod from 'zod'
import { createCompanyFlowSchema } from '../schemas/createCompany'
import { createContext } from 'lib/createContext'
import { useCreateCompanyMutation } from 'queries/companies'
import { parseErrorMessage } from 'api'

type CreateCompanyFlowProviderContextState = {
  actions: {
    submitFormToServer: ReturnType<typeof useCreateCompanyMutation>['mutate']
    setFormData: (data: zod.infer<typeof createCompanyFlowSchema>) => void
  }
  state: {
    formData: zod.infer<typeof createCompanyFlowSchema>
    mutationState: {
      isPending: boolean
      isError: boolean
      errorMessage: string | undefined
    }
  }
}

const [Context, useCreateCompanyFlowContextValue] =
  createContext<CreateCompanyFlowProviderContextState>({
    name: 'CreateCompanyFlowContext',
  })

export const CreateCompanyFlowProvider = ({ children }: PropsWithChildren) => {
  const [createCompanyFormData, setCreateCompanyFormData] = useState<
    zod.infer<typeof createCompanyFlowSchema>
  >({ companyName: '', type: '1099', shouldSetToInvoice: false })

  const { mutate, isPending, isError, error } = useCreateCompanyMutation()

  const value: CreateCompanyFlowProviderContextState = {
    actions: {
      submitFormToServer: mutate,
      setFormData: (data: zod.infer<typeof createCompanyFlowSchema>) => {
        setCreateCompanyFormData((prev) => ({
          ...prev,
          ...data,
        }))
      },
    },
    state: {
      formData: createCompanyFormData,
      mutationState: {
        isPending,
        isError,
        errorMessage: parseErrorMessage(error),
      },
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useCreateCompanyFlowContextValue }
