import api from 'api'

export const removeCompanyFromGroup = async (
  companyGroupId: number,
  companyId: number
) => {
  const response = await api.delete(
    `/admin/company_group/${companyGroupId}/company/${companyId}`
  )
  return response.data
}
