import React from 'react'
import * as zod from 'zod'
import { useFormContext } from 'react-hook-form'
import { editMoneyFlowSchema } from '../schemas/editMoneyFlow'
import { Field, Input } from '@workwhile/ui'
import { useCompanyDetailContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailProvider'

export const InvoiceField = () => {
  const {
    state: { paymentMethod },
  } = useCompanyDetailContextValue()

  const isOnInvoice = paymentMethod === 'invoice'

  const {
    register,
    formState: { errors },
  } = useFormContext<zod.infer<typeof editMoneyFlowSchema>>()

  if (!isOnInvoice) return null

  return (
    <Field
      label={'Invoice terms (days)'}
      error={errors.invoiceDueDays?.message?.toString()}
      // Intentionally disabled. This should work, unsure why it's throwing an error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mt={3}
      width="50%"
    >
      <Input
        {...register('invoiceDueDays')}
        aria-label={'Invoice terms'}
        placeholder={`e.g. 7 days`}
      />
    </Field>
  )
}
