import React, { useContext } from 'react'
import './Commands.css'
import { Box, DatePicker, Input, Checkbox, Select } from '@workwhile/ui'
import Creatable from 'react-select/creatable'
import { format, parse } from 'date-fns'
import Highlight from './Highlight'
import { CommandContext } from './CommandProvider'
import { CommandFieldSchema } from 'api/commands'

type CommandFieldProps = CommandFieldSchema & {
  htmlFor: string
  onChange?: (arg: any) => void
  value: any
  match?: string
}

const CommandField = ({
  htmlFor,
  name,
  required,
  type,
  multiple,
  label,
  choices,
  match,
}: CommandFieldProps) => {
  const { onChange, values, contextValues, refreshPageContext } =
    useContext(CommandContext)

  const handleChange = (value) => onChange({ [name]: value })

  const labelContent = (
    <Highlight text={`${label}${required ? '*' : ''}`} match={match} />
  )

  /** e.g. "company_ids" will pre-populate with context values from "company_id" but retain its own argument name on submit */
  let contextField = name.replace('worker_id', 'user_id')
  contextField = contextField.endsWith('_ids')
    ? contextField.substring(0, contextField.length - 1)
    : contextField
  const options = contextValues[contextField]?.map((each) => ({
    label: each.label ? `${each.label} (${each.value})` : each.value,
    value: each.value,
  }))

  const selection: string | boolean = values[name]
  typeof selection === 'string' &&
    selection?.split(',').forEach((selectedValue) => {
      if (!options?.find((option) => option.value === selectedValue)) {
        options?.push({ label: selectedValue, value: selectedValue })
      }
    })

  return (
    <Box
      display={type === 'checkbox' ? 'flex' : 'inline-block'}
      min-width="25%"
      width="100%"
      pb="2"
      className="Command-input"
    >
      {type !== 'checkbox' && <label htmlFor={htmlFor}>{labelContent}</label>}
      {options?.length || (type === 'text' && multiple) ? (
        <Creatable
          styles={{
            // Fixes menu hiding under accordion boundary
            menu: (provided) => ({ ...provided, position: 'relative' }),
          }}
          name={name}
          isMulti={multiple}
          isSearchable
          placeholder="Select or type to create"
          required={required}
          options={options}
          onFocus={() => {
            refreshPageContext()
          }}
          onChange={(selected) => {
            onChange({
              [name]: multiple
                ? selected?.map((each) => each.value).join(',')
                : selected.value,
            })
          }}
          /** e.g. Option { key: "listing_id", label: "41241 (freeze)"", value: 41241 } should be selected for saved value "41241,43431" */
          value={options?.filter((option) =>
            multiple
              ? selection?.split(',')?.find((one) => one === option.value)
              : option.value === selection
          )}
        />
      ) : name.includes('date') || type === 'datetime' ? (
        <DatePicker
          selected={
            selection ? parse(selection, 'yyyy-MM-dd', new Date()) : null
          }
          mode="single"
          id={htmlFor}
          onSelect={(date) =>
            handleChange(date ? format(date as Date, 'yyyy-MM-dd') : null)
          }
        />
      ) : type === 'checkbox' ? (
        <Checkbox value={selection} onChange={handleChange}>
          {labelContent}
        </Checkbox>
      ) : type === 'select' ? (
        <Select
          styles={{
            // Fixes menu hiding under accordion boundary
            menu: (provided) => ({ ...provided, position: 'relative' }),
          }}
          options={choices?.map((choice) => ({
            label: choice,
            value: choice,
          }))}
        />
      ) : (
        <>
          <Input
            onFocus={() => {
              refreshPageContext()
            }}
            type={type}
            name={name}
            id={htmlFor}
            required={required}
            onChange={(event) => handleChange(event.target.value)}
            value={selection}
          />
        </>
      )}
    </Box>
  )
}

export default CommandField
