import { DatePicker, Field, Box, Select } from '@workwhile/ui'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { addDays, subDays } from 'date-fns'
import { FiltersSchema } from '../filtersSchema'

type TimeRangeTypeOptions = {
  label: string
  value: string
}[]

const timeRangeTypeOptions: TimeRangeTypeOptions = [
  {
    label: 'Past',
    value: 'past',
  },
  {
    label: 'Ongoing',
    value: 'ongoing',
  },
  {
    label: 'Upcoming',
    value: 'upcoming',
  },
]

export const TimeRangeInputs = () => {
  const { control, watch, setValue } = useFormContext<FiltersSchema>()

  const type = watch('timeRange.type')

  const disabled = useMemo(() => {
    if (type === 'past') {
      return {
        after: new Date(),
      }
    }

    if (type === 'ongoing') {
      return {
        before: new Date(),
        after: new Date(),
      }
    }

    if (type === 'upcoming') {
      return {
        before: new Date(),
      }
    }

    return undefined
  }, [type])

  return (
    <Box>
      <Controller
        name={'timeRange.type'}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const foundValue = timeRangeTypeOptions.find(
            (option) => option.value === field.value
          )
          return (
            <Field error={error?.message?.toString()}>
              <Select
                placeholder="Select type"
                isSearchable={false}
                options={timeRangeTypeOptions}
                value={foundValue ?? null}
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={async (option) => {
                  const value: 'past' | 'ongoing' | 'upcoming' = (option as any)
                    .value
                  field.onChange(value)

                  if (value === 'past') {
                    setValue(
                      'timeRange.dateRange',
                      {
                        from: subDays(new Date(), 4),
                        to: new Date(),
                      },
                      {
                        shouldValidate: true,
                      }
                    )

                    return
                  }

                  if (value === 'ongoing') {
                    setValue(
                      'timeRange.dateRange',
                      {
                        from: new Date(),
                        to: new Date(),
                      },
                      {
                        shouldValidate: true,
                      }
                    )

                    return
                  }

                  if (value === 'upcoming') {
                    setValue(
                      'timeRange.dateRange',
                      {
                        from: new Date(),
                        to: addDays(new Date(), 4),
                      },
                      {
                        shouldValidate: true,
                      }
                    )
                  }
                }}
              />
            </Field>
          )
        }}
      />
      {type !== 'ongoing' ? (
        <Controller
          name={'timeRange.dateRange'}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Field error={error?.message?.toString()}>
              <DatePicker
                mode="range"
                disabled={disabled}
                selected={field.value as any}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ref={field.ref}
                onSelect={(days) => {
                  field.onChange(days)
                }}
                placeholder={'Select Date range'}
              />
            </Field>
          )}
        />
      ) : null}
    </Box>
  )
}
