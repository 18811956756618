import * as z from 'zod'

export const filtersSchema = z
  .object({
    timeRange: z
      .object({
        type: z.enum(['past', 'ongoing', 'upcoming']).nullable().optional(),
        dateRange: z
          .object({
            from: z.date().nullable().optional(),
            to: z.date().nullable().optional(),
          })
          .optional(),
      })
      .optional(),
  })
  .superRefine((data, ctx) => {
    const type = data.timeRange?.type
    const dateRange = data.timeRange?.dateRange
    const isOneOfDateRangePresent = Boolean(dateRange?.from ?? dateRange?.to)

    if (typeof type == 'string' && !isOneOfDateRangePresent) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please select a valid date range',
        path: ['timeRange', 'dateRange'],
      })
      return false
    }
    return true
  })

export type FiltersSchema = z.infer<typeof filtersSchema>
