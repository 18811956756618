import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { FiltersSchema } from '../filtersSchema'
import { Field, Flex, Input } from '@workwhile/ui'

export const FillRateFilter = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FiltersSchema>()

  return (
    <Flex flexDirection="column" width="240px">
      <Controller
        control={control}
        name="shift.fillRateMin"
        render={({ field }) => (
          <Field
            label={'Minimum %'}
            error={errors.shift?.fillRateMin?.message?.toString()}
          >
            <Input
              aria-label={'Minimum fill rate %'}
              placeholder={`e.g. 0%`}
              type="number"
              value={field.value ?? ''}
              onChange={(e) => {
                const value = e.target.value
                field.onChange(value === '' ? null : Number(value))
              }}
            />
          </Field>
        )}
      />
      <Controller
        control={control}
        name="shift.fillRateMax"
        render={({ field }) => (
          <Field
            label={'Maximum %'}
            error={errors.shift?.fillRateMax?.message?.toString()}
          >
            <Input
              aria-label={'Maximum fill rate %'}
              placeholder={`e.g. 89%`}
              type="number"
              value={field.value ?? ''}
              onChange={(e) => {
                const value = e.target.value
                field.onChange(value === '' ? null : Number(value))
              }}
            />
          </Field>
        )}
      />
    </Flex>
  )
}
