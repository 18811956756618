import { useMutation, useQueryClient } from '@tanstack/react-query'
import { companyDetailKey } from './keys'
import { inviteUserToCompany } from 'api/company/inviteUserToCompany'

export const useInviteUserToCompanyMutation = (companyId: number) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (request: Parameters<typeof inviteUserToCompany>[0]) =>
      inviteUserToCompany(request),
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: companyDetailKey(companyId) }),
  })
}
