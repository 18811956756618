import api from 'api'

export const editCompanyName = async (
  companyId: number,
  companyName: string
): Promise<void> => {
  return api.put(`/admin/company/${companyId}/name`, {
    new_name: companyName,
  })
}
