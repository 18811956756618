import api from 'api'

type Market = {
  name: string
  code: string
  createdAt: string
  updatedAt: string
}

type GetAllMarketsResponse = {
  items: Market[]
}

export const getAllMarkets = async (): Promise<Market[]> => {
  const response = await api.get<GetAllMarketsResponse>('/admin/markets')
  return response.data.items
}
