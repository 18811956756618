import { useQueryStates, parseAsIndex, parseAsInteger } from 'nuqs'

export const usePaginationQueryState = () => {
  const queryStates = useQueryStates(
    {
      pageIndex: parseAsIndex.withDefault(0),
      pageSize: parseAsInteger.withDefault(10),
    },
    {
      urlKeys: {
        pageIndex: 'shifts-page',
        pageSize: 'shifts-page-size',
      },
    }
  )

  return queryStates
}
