import React from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Message,
  Modal,
  Text,
  toast,
} from '@workwhile/ui'
import { useRemoveCompanyFromGroupFlowContextValue } from '../context/RemoveCompanyFromGroupFlow'
import { useCompanyGroupDetailWorkflowsContextValue } from 'pages/CompanyGroupDetail/context/CompanyGroupDetailWorkflowProvider'

export const RemoveCompanyFromGroupFlowMain = () => {
  const {
    removeCompanyFromGroupFlow: { isOpen, close },
  } = useCompanyGroupDetailWorkflowsContextValue()

  const {
    state: {
      companyId,
      companyName,
      companyGroupId,
      companyGroupName,
      mutationState: { isPending, isError, errorMessage },
    },
    actions: { submitFormToServer },
  } = useRemoveCompanyFromGroupFlowContextValue()

  const handleRemoveCompany = () => {
    submitFormToServer(companyId, {
      onSuccess: () => {
        toast.success(`Removed "${companyName}" from this group successfully`, {
          position: 'top-center',
        })
        close()
      },
    })
  }

  return (
    <Modal
      open={isOpen}
      onClose={close}
      position="top"
      loading={isPending}
      showCancel
      customCta={
        <Flex>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleRemoveCompany}
            color="errors.200"
          >
            Remove
          </Button>
        </Flex>
      }
    >
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          handleRemoveCompany()
        }}
      >
        <Heading level={3} fontWeight="400" style={{ margin: 0 }}>
          Are you sure?
        </Heading>

        <Text mt={3}>
          You're about to remove <strong>{companyName}</strong> ({companyId})
          from the <strong>{companyGroupName}</strong> ({companyGroupId}) group
        </Text>
        <Message mt={4} variant="warning">
          This action is permanent and cannot be undone. If you remove this
          company, you'll need to add it back manually if needed
        </Message>

        {isError ? (
          <Text color="danger" mt={3}>
            Error: {errorMessage}
          </Text>
        ) : null}
      </Box>
    </Modal>
  )
}
