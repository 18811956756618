type FormatNumberOptions = Intl.NumberFormatOptions

const percentPreset: FormatNumberOptions = {
  style: 'unit',
  unit: 'percent',
  maximumFractionDigits: 0,
}

export const formatPercentage = (
  value: number,
  options?: FormatNumberOptions
) => {
  return new Intl.NumberFormat('en-US', {
    ...percentPreset,
    ...options,
  }).format(value)
}
