import api from 'api'
import graphql from 'graphql'
import { toSnakecaseKeys } from 'lib/string'
import { pathOr } from 'ramda'

export interface UserW2I9Form {
  userId: number
  firstName: string
  lastName: string
  middleName?: string
  dob: string
  citizenshipStatus: 'permanent-resident' | 'citizen' | 'non-citizen'
  uscis?: string
  documentListType: string
  submittedAt: string
  verifiedAt?: string
  processedAt?: string
  startDate?: string
  ssn: string
}

export async function getUserW2I9Form(userId: number) {
  const response = await graphql(`
    admin {
      userW2I9Form(userId: ${userId}) {
        userId
        firstName
        lastName
        middleName
        dob
        ssn
        citizenshipStatus
        uscis
        documentListType
        submittedAt
        verifiedAt
        startDate
      }
    }
  `)

  return pathOr(
    null,
    ['data', 'data', 'admin', 'userW2I9Form'],
    response
  ) as UserW2I9Form | null
}

interface I9Document {
  title: string
  issuingAuthority: string
  documentNumber: string
  expiryDate?: string
}

export interface I9DocumentsApprovalRequest {
  doc1: I9Document
  doc2?: I9Document
}

export function acceptUserI9Documents(
  userId: number,
  formData: I9DocumentsApprovalRequest
) {
  return api.post(
    `/admin/user/${userId}/i9_documents/accept`,
    toSnakecaseKeys(formData, { deep: true })
  )
}

export function rejectUserI9Documents(userId: number) {
  return api.post(`/admin/user/${userId}/i9_documents/reject`)
}

export async function getUnprocessedI9Forms() {
  const response = await graphql(
    `
    admin {
      unprocessedEVerifyI9Forms {
        userId
        firstName
        lastName
      }
    }
    `
  )

  return pathOr(
    [],
    ['data', 'data', 'admin', 'unprocessedEVerifyI9Forms'],
    response
  ) as Pick<UserW2I9Form, 'userId' | 'firstName' | 'lastName'>[]
}

export function processUserI9Documents(userId: number) {
  return api.post(`/admin/user/${userId}/i9_documents/process`)
}
