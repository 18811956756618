import { Flex, Input, Field } from '@workwhile/ui'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { editMoneyFlowSchema } from '../schemas/editMoneyFlow'
import * as zod from 'zod'
import { InvoiceField } from './InvoiceField'

export const Rate1099Form = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<zod.infer<typeof editMoneyFlowSchema>>()

  return (
    <>
      <Flex flexDirection="row" gap={3}>
        <Field
          label={'Take rate %'}
          error={errors.takeRate?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mt={3}
          width="50%"
        >
          <Input
            {...register('takeRate')}
            aria-label={'Take rate'}
            placeholder={`e.g. 25%`}
          />
        </Field>
        <Field
          label={'Bonus take rate %'}
          error={errors.bonusTakeRate?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mt={3}
          width="50%"
        >
          <Input
            {...register('bonusTakeRate')}
            aria-label={'Bonus take rate'}
            placeholder={`e.g. 10%`}
          />
        </Field>
      </Flex>

      <Flex flexDirection="row" gap={3}>
        <Field
          label={'Tip take rate %'}
          error={errors.tipTakeRate?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mt={3}
          width="calc(50% - 8px)"
        >
          <Input {...register('tipTakeRate')} aria-label={'Tip take rate'} />
        </Field>
        <InvoiceField />
      </Flex>
    </>
  )
}
