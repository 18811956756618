import api from 'api'

type CreateCompanyRequest = {
  companyName: string
  type: '1099' | 'w2'
  shouldSetToInvoice: boolean
}

export const createCompany = async (request: CreateCompanyRequest) => {
  const response = await api.post(
    `/admin/company/create/${request.companyName}`,
    {
      // FIXME: backend should expect the type as is
      set_to_w2: request.type === 'w2',
      set_to_invoice: request.shouldSetToInvoice,
    }
  )
  return response.data
}
