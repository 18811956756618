import { Table } from '@workwhile/ui'
import React, { useState } from 'react'
import * as moment from 'moment-timezone'

import api from 'api'
import CollapsibleSection from 'components/Shared/CollapsibleSection'
import { User } from 'components/Shared/common_defs'
import StatusSetter from 'components/Shared/StatusSetter'
import { keyValueColumns } from 'components/Users/keyValueColumns'
import { type BackgroundCheckStatus } from 'types/backgroundCheck'
import { UserDrugScreenings } from './UserDrugScreenings'

interface Props {
  user: User
  refreshData: () => void
  defaultOpen: boolean
}

const PROVIDER_TEXT = 'Yardstik'
const ALLOWED_STATUSES: BackgroundCheckStatus[] = ['manually_clear']
const STATUS_OPTIONS = ALLOWED_STATUSES.map((status) => {
  return { label: status, value: status }
})
const DATE_TIME_SUBMITTED_FORMAT = 'YYYY-MM-DD h:mma z'

const REPORT_TYPES = [
  'basic',
  'mvr',
  'basic_plus_mvr',
  'eight_panel_drug_screening',
  'ten_panel_minus_thc_drug_screening',
  'ten_panel_drug_screening',
  'four_panel_minus_thc_drug_screening',
] as const

type ReportType = (typeof REPORT_TYPES)[number]

const REPORT_TYPES_TEXT: Record<ReportType, string> = {
  basic: 'Basic',
  mvr: 'MVR',
  basic_plus_mvr: 'Basic + MVR',
  eight_panel_drug_screening: '8 panel drug screening',
  ten_panel_minus_thc_drug_screening: '10 panel minus THC drug screening',
  ten_panel_drug_screening: '10 panel drug screening',
  four_panel_minus_thc_drug_screening: '4 panel minus THC drug screening',
}
const REPORT_TYPES_OPTIONS = Object.entries(REPORT_TYPES_TEXT).map(
  ([value, label]) => {
    return { label, value }
  }
)

const makeReportUrl = (reportId: string) =>
  `https://app.yardstik.com/report/${encodeURIComponent(reportId)}`

const UserYardstikVerification = (props: Props) => {
  const { user, refreshData } = props
  const timezone = user.address?.timezone || moment.tz.guess(true)

  const [newCriminalStatus, setNewCriminalStatus] =
    useState<BackgroundCheckStatus>('manually_clear') // input defaults to manually_clear
  const [submittingCriminalStatus, setSubmittingCriminalStatus] =
    useState(false)

  const [newMvrStatus, setNewMvrStatus] =
    useState<BackgroundCheckStatus>('manually_clear') // input defaults to manually_clear
  const [submittingMvrStatus, setSubmittingMvrStatus] = useState(false)

  const [reportType, setReportType] = useState<ReportType>('basic') // input defaults to basic
  const [submittingOrderReport, setSubmittingOrderReport] = useState(false)

  const providerStatusMetadata = user.yardstikStatus

  const criminalStatus = providerStatusMetadata?.criminalStatus
  const criminalSubmitted = providerStatusMetadata?.criminalSubmitted
  const mvrStatus = providerStatusMetadata?.mvrStatus
  const mvrSubmitted = providerStatusMetadata?.mvrSubmitted

  const candidateId = providerStatusMetadata?.candidateId
  const hasCandidate = Boolean(candidateId)
  const setStatusDisabled = !hasCandidate

  const criminalReportID = providerStatusMetadata?.criminalReportId
  const mvrReportID = providerStatusMetadata?.mvrReportId

  const shouldLinkCriminal =
    Boolean(criminalStatus) && Boolean(criminalReportID)
  const shouldLinkMvr = Boolean(mvrStatus) && Boolean(mvrReportID)
  const hasAnyStatus = Boolean(criminalStatus) || Boolean(mvrStatus)
  const defaultOpen = props.defaultOpen && hasAnyStatus

  const data = React.useMemo(
    () => [
      {
        key: 'criminal_status',
        value: criminalStatus || 'N/A',
        link: shouldLinkCriminal ? makeReportUrl(criminalReportID) : null,
      },
      {
        key: 'criminal_submitted_at',
        value: criminalSubmitted
          ? moment
              .tz(criminalSubmitted, timezone)
              .format(DATE_TIME_SUBMITTED_FORMAT)
          : 'N/A',
      },
      {
        key: 'mvr_status',
        value: mvrStatus || 'N/A',
        link: shouldLinkMvr ? makeReportUrl(mvrReportID) : null,
      },
      {
        key: 'mvr_submitted_at',
        value: mvrSubmitted
          ? moment.tz(mvrSubmitted, timezone).format(DATE_TIME_SUBMITTED_FORMAT)
          : 'N/A',
      },
    ],
    []
  )

  const submitCriminalStatusChange = () => {
    setSubmittingCriminalStatus(true)
    const r = window.prompt(
      `You are about to set USER(${user.id}) ${PROVIDER_TEXT} Criminal status to: ${newCriminalStatus}. To execute this action, please press OK.`
    )
    if (r != null) {
      api
        .post(`/admin/user/${user.id}/yardstik`, {
          status_type: 'criminal',
          status: newCriminalStatus,
          reason: r,
        })
        .then((_response) => {
          alert(`${PROVIDER_TEXT} Criminal status is now ${newCriminalStatus}`)
          setSubmittingCriminalStatus(false)
          refreshData()
        })
        .catch((error) => {
          console.log('ERROR OCCURED: ', error)
          setSubmittingCriminalStatus(false)
        })
    } else {
      alert('Action Cancelled!')
      setSubmittingCriminalStatus(false)
    }
    return
  }

  const submitMvrStatusChange = () => {
    setSubmittingMvrStatus(true)
    const r = window.prompt(
      `You are about to set USER(${user.id}) ${PROVIDER_TEXT} MVR status to: ${newMvrStatus}. To execute this action, please press OK.`
    )
    if (r != null) {
      api
        .post(`/admin/user/${user.id}/yardstik`, {
          status_type: 'mvr',
          status: newMvrStatus,
          reason: r,
        })
        .then((_response) => {
          alert(`${PROVIDER_TEXT} MVR status is now ${newMvrStatus}`)
          setSubmittingMvrStatus(false)
          refreshData()
        })
        .catch((error) => {
          console.log('ERROR OCCURED: ', error)
          setSubmittingMvrStatus(false)
        })
    } else {
      alert('Action Cancelled!')
      setSubmittingMvrStatus(false)
    }
    return
  }

  const submitOrderReport = () => {
    setSubmittingOrderReport(true)
    const r = window.prompt(
      `You are about to order a ${PROVIDER_TEXT} report for USER(${user.id}): ${reportType}. To execute this action, please press OK.`
    )
    if (r != null) {
      api
        .post(`/admin/user/${user.id}/yardstik/order_report`, {
          report_type: reportType,
        })
        .then((_response) => {
          alert(`${PROVIDER_TEXT} report ordered: ${reportType}`)
          setSubmittingOrderReport(false)
          refreshData()
        })
        .catch((error) => {
          console.log('ERROR OCCURED: ', error)
          setSubmittingOrderReport(false)
        })
    } else {
      alert('Action Cancelled!')
      setSubmittingOrderReport(false)
    }
    return
  }

  return (
    <CollapsibleSection
      title={`${PROVIDER_TEXT} Details`}
      defaultOpen={defaultOpen}
    >
      <Table variant="borderless" data={data} columns={keyValueColumns} />
      <StatusSetter
        label={`Set ${PROVIDER_TEXT} Criminal Status`}
        disabled={setStatusDisabled}
        name="newCriminalStatus"
        value={newCriminalStatus}
        handleChange={(status) => setNewCriminalStatus(status)}
        isLoading={submittingCriminalStatus}
        submitStatusChange={submitCriminalStatusChange}
        options={STATUS_OPTIONS}
        buttonType="confirm"
      />
      <StatusSetter
        label={`Set ${PROVIDER_TEXT} MVR Status`}
        disabled={setStatusDisabled}
        name="newMvrStatus"
        value={newMvrStatus}
        handleChange={(status) => setNewMvrStatus(status)}
        isLoading={submittingMvrStatus}
        submitStatusChange={submitMvrStatusChange}
        options={STATUS_OPTIONS}
        buttonType="confirm"
      />
      <StatusSetter
        label={`Order ${PROVIDER_TEXT} Report`}
        name="orderReport"
        value={reportType}
        handleChange={(reportType) => setReportType(reportType)}
        isLoading={submittingOrderReport}
        submitStatusChange={submitOrderReport}
        options={REPORT_TYPES_OPTIONS}
        buttonType="confirm"
        buttonText="Order Report"
      />
      <UserDrugScreenings
        drugScreenings={providerStatusMetadata.drugScreenings}
        makeReportUrl={makeReportUrl}
        userTimeZone={timezone}
      />
    </CollapsibleSection>
  )
}

export default UserYardstikVerification
