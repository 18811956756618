import { useMutation, useQueryClient } from '@tanstack/react-query'
import { editShiftWork } from 'api/shifts/editShiftWork'
import { shiftDetailKey } from './keys'

export const useEditShiftWorkMutation = (shiftId: number) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      ...rest
    }: Omit<Parameters<typeof editShiftWork>[0], 'shiftId'>) =>
      editShiftWork({ shiftId, ...rest }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: shiftDetailKey(shiftId) })
    },
  })
}
