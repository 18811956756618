import React from 'react'
import { AccordionCard } from 'components/AccordionCard'
import { InfoTable } from 'components/InfoTable'
import { useCompanyDetailContextValue } from '../context/CompanyDetailProvider'
import {
  BriefcaseBusiness,
  EllipsisVertical,
  Group,
  Pencil,
} from 'lucide-react'
import {
  Button,
  DropdownMenu,
  Flex,
  Link,
  Text,
  useAppTheme,
} from '@workwhile/ui'
import { useCompanyDetailWorkflowsContextValue } from '../context/CompanyDetailWorkflowProvider'

export const GeneralDetails = () => {
  const { space } = useAppTheme()
  const {
    state: { type, companyGroup },
  } = useCompanyDetailContextValue()

  const { editGeneralDetailsFlow } = useCompanyDetailWorkflowsContextValue()

  return (
    <AccordionCard
      title={
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          width="100%"
        >
          <Text fontSize={2} color="lightText">
            General Details
          </Text>

          <DropdownMenu
            trigger={
              <Button
                variant="secondary"
                kind="small"
                style={{ paddingLeft: space[1], paddingRight: space[1] }}
              >
                <EllipsisVertical size={14} />
              </Button>
            }
          >
            <DropdownMenu.Item onClick={editGeneralDetailsFlow.open}>
              <Flex as="span" alignItems="center" style={{ gap: 8 }}>
                <Pencil size={14} />
                Edit
              </Flex>
            </DropdownMenu.Item>
          </DropdownMenu>
        </Flex>
      }
      // Intentionally disabled. This should work, unsure why it's throwing an error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      maxWidth="50%"
    >
      <InfoTable style={{ maxWidth: '60%' }}>
        <InfoTable.Row
          icon={<BriefcaseBusiness size={14} />}
          label="Type"
          value={type}
        />
        <InfoTable.Row
          icon={<Group size={14} />}
          label="Company Group"
          value={
            companyGroup ? (
              <Flex flexDirection="column" gap={1}>
                <Text>
                  {companyGroup.name}{' '}
                  <Link to={`/company-groups/${companyGroup.id}`}>
                    ({companyGroup.id})
                  </Link>
                </Text>
              </Flex>
            ) : (
              'None'
            )
          }
        />
      </InfoTable>
    </AccordionCard>
  )
}
