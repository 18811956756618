import React from 'react'
import { ReferencePopover } from 'components/ReferencePopover'
import { Info } from 'lucide-react'
import { Box } from '@workwhile/ui'
import { TierType } from 'pages/Shifts/ShiftDetail/types'
import { getTierTransitionLabel } from './utils/getTierTransitionLabel'
import { ReferenceTable } from 'components/ReferenceTable'

const dataPoints = [
  {
    key: 'Total',
    description:
      'All the workers in the market who meets the basic criteria within a given tier',
  },
  {
    key: 'Eligible',
    description:
      'Number of workers out of the total workers who pass our eligibility criteria within a tier',
  },
  {
    key: 'Views',
    description:
      'Number of workers out of the eligible workers who have viewed this listing',
  },
]

const tiers: {
  key: TierType
  description: string
}[] = [
  {
    key: 'unset',
    description: 'Initial tier when a listing is created',
  },
  {
    key: 'roster',
    description: 'Workers who have been added to a roster by the company',
  },
  {
    key: 'favorite',
    description: 'Workers who have been favorited by the company',
  },
  {
    key: 'regular_1',
    description:
      'Worked 10 or more shifts for the same company, position and city',
  },
  {
    key: 'bench_1',
    description: 'Worked 7 or more shifts for the same company and position',
  },
  {
    key: 'bench_2',
    description:
      'Worked between 3 to 6 shifts for the same company and position',
  },
  {
    key: 'company_1',
    description: 'Worked 7 or more shifts for the same company',
  },
  {
    key: 'company_2',
    description: 'Worked between 3 to 6 shifts for the same company',
  },
  {
    key: 'position_1',
    description: 'Worked 2 or more shifts for the same position',
  },
  {
    key: 'workwhile_1',
    description: 'Worked 7 or more shifts with WorkWhile',
  },
  {
    key: 'workwhile_2',
    description: 'Worked between 3 to 6 shifts with WorkWhile',
  },
  {
    key: 'workwhile_3',
    description: 'Worked between 1 to 3 shifts for WorkWhile',
  },
  {
    key: 'new',
    description:
      'New workers on the platform who have not worked any shifts on the platform yet',
  },
  {
    key: 'reserve',
    description:
      'Demoted workers with low priority (late cancel, no show, etc.)',
  },
]

const TierTransitionsReferences = () => {
  const transformedTierKeys = tiers.map((tier) => ({
    key: getTierTransitionLabel(tier.key),
    description: tier.description,
  }))

  return (
    <Box
      maxWidth={424}
      color="neutrals.400"
      style={{ maxHeight: '424px', overflowY: 'auto' }}
    >
      <ReferenceTable title="Data Points" data={dataPoints} />
      <ReferenceTable title="Tiers" data={transformedTierKeys} />
    </Box>
  )
}

export const TierTransitionsReferencePopover = () => {
  return (
    <ReferencePopover
      trigger={<Info size={14} style={{ marginLeft: 4 }} />}
      subTitle="Reference"
      title="Tier Transitions"
      contentProps={{
        children: <TierTransitionsReferences />,
      }}
    />
  )
}
