import React from 'react'
import { Button, Flex } from '@workwhile/ui'
import { RefreshCcw } from 'lucide-react'
import { Fetching } from './Fetching'
import { Success } from './Success'
import { Error } from './Error'

type QueryStatusProps = {
  dataUpdatedAt: number
  isError: boolean
  error?: string
  isFetching: boolean
  refetch: () => void
}

export const QueryStatus = ({
  dataUpdatedAt,
  isFetching,
  refetch,
  isError,
  error,
}: QueryStatusProps) => {
  return (
    <Flex
      alignItems="center"
      gap={2}
      border="1px solid"
      borderColor="neutrals.100"
      padding={1}
      pl={2}
      pr={1}
      borderRadius={4}
    >
      {isFetching ? (
        <Fetching />
      ) : isError ? (
        <Error error={error} />
      ) : (
        <Success dataUpdatedAt={dataUpdatedAt} />
      )}
      <Button
        kind="small"
        onClick={refetch}
        style={{ padding: 4 }}
        variant="text"
        disabled={isFetching}
      >
        <RefreshCcw size={14} />
      </Button>
    </Flex>
  )
}
