import { useQueryStates, parseAsArrayOf, parseAsInteger } from 'nuqs'

export const useCompanyQueryState = () => {
  const queryStates = useQueryStates(
    {
      companyIds: parseAsArrayOf(parseAsInteger).withDefault([]),
      companyGroupIds: parseAsArrayOf(parseAsInteger).withDefault([]),
    },
    {
      urlKeys: {
        companyIds: 'company-ids',
        companyGroupIds: 'company-group-ids',
      },
    }
  )

  return queryStates
}
