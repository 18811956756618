import React from 'react'
import { createContext } from 'lib/createContext'
import { CompanyDetail } from '../types'
import { useCompanyDetailsByIdQuery } from 'queries/company/useCompanyDetailsByIdQuery'
import { Loading, Message } from '@workwhile/ui'
import { parseErrorMessage } from 'api'
import { useShiftsQuery } from 'queries/shifts/useShiftsQuery'
import { usePaginationQueryState } from '../hooks/usePaginationQueryState'
import { useTimeRangeQueryState } from '../hooks/useTimeRangeQueryState'
import { format } from 'date-fns'
import { SelectableColumns } from '../components/Shifts/ShiftsTable/ColumnsFilter'
import { useSelectedColumnsQueryState } from '../hooks/useSelectedColumnsQueryState'

type CompanyDetailContextState = {
  state: CompanyDetail & {
    dataUpdatedAt: number
    isFetching: boolean
    isError: boolean
    error: string
    shifts: {
      dataUpdatedAt: number
      isFetching: boolean
      isError: boolean
      error: string
      data: ReturnType<typeof useShiftsQuery>['data']
      selectedColumnsFilters: SelectableColumns[]
      pagination: {
        pageIndex: number
        pageSize: number
      }
      timeRangeFilters: {
        type: 'past' | 'ongoing' | 'upcoming'
        startDate: Date
        endDate: Date
      }
    }
  }
  actions: {
    refetch: () => void
    shifts: {
      refetch: () => void
      setTimeRangeFilters: ReturnType<typeof useTimeRangeQueryState>[1]
      setPagination: ReturnType<typeof usePaginationQueryState>[1]
      setSelectedColumnsFilters: ReturnType<
        typeof useSelectedColumnsQueryState
      >[1]
    }
  }
}

const [Context, useCompanyDetailContextValue] =
  createContext<CompanyDetailContextState>({
    name: 'CompanyDetailContext',
  })

export const CompanyDetailProvider = ({
  children,
  companyId,
}: {
  children: React.ReactNode
  companyId: number
}) => {
  const [pagination, setPagination] = usePaginationQueryState()
  const [timeRangeFilters, setTimeRangeFilters] = useTimeRangeQueryState()
  const [selectedColumnsFilters, setSelectedColumnsFilters] =
    useSelectedColumnsQueryState()
  console.log('timeRangeFilters', timeRangeFilters)
  const {
    data,
    isLoading: isCompanyLoading,
    isError: isCompanyError,
    error: companyError,
    dataUpdatedAt: companyDataUpdatedAt,
    refetch: companyRefetch,
    isFetching: isCompanyFetching,
  } = useCompanyDetailsByIdQuery(companyId)

  const {
    data: shifts,
    isPending: isShiftsLoading,
    isError: isShiftsError,
    error: shiftsError,
    dataUpdatedAt: shiftsDataUpdatedAt,
    refetch: refetchShifts,
    isFetching: isShiftsFetching,
  } = useShiftsQuery({
    rangeType: timeRangeFilters.type,
    startDate: timeRangeFilters.startDate
      ? format(timeRangeFilters.startDate, 'yyyy-MM-dd')
      : undefined,
    endDate: timeRangeFilters.endDate
      ? format(timeRangeFilters.endDate, 'yyyy-MM-dd')
      : undefined,
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
    companyIds: [companyId],
  })

  const isLoading = isCompanyLoading || isShiftsLoading

  if (isLoading) {
    return <Loading />
  }

  if (isCompanyError) {
    // eslint-disable-next-line no-console
    console.error(companyError)
    return (
      <Message variant="error">
        Sorry, something went wrong. Please reach out to eng support if this
        persist
      </Message>
    )
  }

  const value = {
    state: {
      ...data,
      dataUpdatedAt: companyDataUpdatedAt,
      isFetching: isCompanyFetching,
      isError: isCompanyError,
      error: parseErrorMessage(companyError),
      shifts: {
        dataUpdatedAt: shiftsDataUpdatedAt,
        isFetching: isShiftsFetching,
        isError: isShiftsError,
        error: parseErrorMessage(shiftsError),
        data: shifts,
        pagination,
        timeRangeFilters,
        selectedColumnsFilters,
      },
    },
    actions: {
      refetch: companyRefetch,
      shifts: {
        refetch: refetchShifts,
        setTimeRangeFilters,
        setPagination,
        setSelectedColumnsFilters,
      },
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useCompanyDetailContextValue }
