import React from 'react'
import { Text } from '@workwhile/ui'

export const Fetching = () => {
  return (
    <Text color="lightText" fontSize={1}>
      Updating...
    </Text>
  )
}
