import * as zod from 'zod'

export const editCompanyNameFlowSchema = zod
  .object({
    currentName: zod.string().optional(),
    companyName: zod
      .string()
      .min(1, { message: 'Company name is required' })
      .max(60, { message: 'Company name must not exceed 60 characters' }),
  })
  .refine(
    (data) => {
      if (data.currentName === data.companyName) {
        return false
      }
      return true
    },
    {
      message: 'New company name cannot be same as the current company name',
      path: ['companyName'],
    }
  )
