import { useQuery } from '@tanstack/react-query'
import { getPositionTemplates } from 'api/company'
import { positionTemplatesKey } from './keys'

export function usePositionTemplates() {
  return useQuery({
    queryKey: positionTemplatesKey,
    queryFn: getPositionTemplates,
    gcTime: 1000 * 60 * 60, // cached for 1 hour since this data rarely changes
  })
}
