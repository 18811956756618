import React from 'react'
import {
  Box,
  Button,
  Field,
  Flex,
  Heading,
  Message,
  Modal,
  RadioGroup,
  Select,
  Text,
  TimeRangeInput,
  TimeRangeInputValue,
  toast,
} from '@workwhile/ui'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEditShiftWorkFlowContextValue } from '../context/EditShiftWorkFlowProvider'
import { editShiftWorkFlowSchema } from '../schemas/editShiftWorkFlowSchema'
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz'
import { WorkStatus } from 'api/typings'
import { addDays } from 'date-fns'

const formatTimeInTimeZone = (time: string | Date | null, timezone: string) => {
  if (!time) {
    return ''
  }
  return formatInTimeZone(time, timezone, 'HH:mm')
}

const workStatusOptions = Object.values(WorkStatus).map((status) => ({
  value: status,
  label: status,
}))

const parseTimeRangeValueToUTCDate = (
  date: Date,
  time: TimeRangeInputValue,
  timezone: string
): Date | undefined => {
  // Standardizes TimeRangeInputValue to a Date object in UTC
  if (time instanceof Date) {
    return fromZonedTime(time, timezone)
  }

  if (typeof time === 'string') {
    const timeParts = time.split(':')
    const inputDate = new Date(date)
    inputDate.setHours(parseInt(timeParts[0]), parseInt(timeParts[1]))

    return fromZonedTime(inputDate, timezone)
  }

  return undefined
}

const parseTimeRangeTupleToUTCDate = (
  date: Date,
  clockedInAt: TimeRangeInputValue,
  clockedOutAt: TimeRangeInputValue,
  timezone: string
): [string | undefined, string | undefined] => {
  // values returned by TimeRangeInput are 24hr time strings (e.g. 19:00) in shift TZ.
  // To save to data state, we need to:
  // - convert to a datetime
  // - convert from shifttz to UTC
  // - if the clockedOutAt time is before the clockedInAt time assume the shift was overnight and add a day to clockedOutAt

  const utcClockedInAt = clockedInAt
    ? parseTimeRangeValueToUTCDate(date, clockedInAt, timezone)
    : undefined

  let utcClockedOutAt = clockedOutAt
    ? parseTimeRangeValueToUTCDate(date, clockedOutAt, timezone)
    : undefined

  if (utcClockedOutAt && utcClockedInAt) {
    if (utcClockedOutAt < utcClockedInAt) {
      utcClockedOutAt = addDays(utcClockedOutAt, 1)
    }
  }

  const formattedutcClockedInAt = utcClockedInAt
    ? utcClockedInAt.toISOString()
    : undefined
  const formattedutcClockedOutAt = utcClockedOutAt
    ? utcClockedOutAt.toISOString()
    : undefined

  return [formattedutcClockedInAt, formattedutcClockedOutAt]
}

export const EditShiftWorkFlowMain = () => {
  const {
    state: {
      formData,
      workId,
      shiftId,
      shiftStartsAt,
      worker,
      timezone,
      mutationState: { isPending, errorMessage: mutationErrorMessage },
    },
    actions: { submitFormToServer, setFormData, close },
  } = useEditShiftWorkFlowContextValue()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(editShiftWorkFlowSchema),
    defaultValues: {
      status: formData.status,
      clockedInAtAndClockedOutAt: [
        formData.clockedInAtAndClockedOutAt[0],
        formData.clockedInAtAndClockedOutAt[1],
      ],
      leftEarly: formData.leftEarly,
      existingValues: {
        status: formData.status,
        clockedInAtAndClockedOutAt: [
          formData.clockedInAtAndClockedOutAt[0],
          formData.clockedInAtAndClockedOutAt[1],
        ],
        leftEarly: formData.leftEarly,
      },
    },
  })

  const errorMessage =
    errors.existingValues?.message?.toString() || mutationErrorMessage

  const onSubmit = handleSubmit((data) => {
    // FIXME: This is a hack to get around the `strictNullChecks` in our tsconfig.json which prevents correct type inference
    setFormData(data as unknown as any)

    const changedFields = Object.keys(data.existingValues).filter(
      (key): key is keyof typeof data.existingValues =>
        JSON.stringify(data[key as keyof typeof data]) !==
        JSON.stringify(data.existingValues[key])
    )

    // It is imporant that we only pass the changed fields to the server.
    // As server makes assumptions about the fields that are present in the request body
    const changedFieldsObject = changedFields.reduce(
      (acc, key) => {
        return {
          ...acc,
          [key]: data[key as keyof typeof data],
        }
      },
      {} as Partial<typeof data>
    )

    submitFormToServer(
      {
        workId,
        status: changedFieldsObject.status,
        clockedInAt: changedFieldsObject.clockedInAtAndClockedOutAt?.[0],
        clockedOutAt: changedFieldsObject.clockedInAtAndClockedOutAt?.[1],
        leftEarly: changedFieldsObject.leftEarly,
      },
      {
        onSuccess: () => {
          toast.success(
            `Updated ${worker.name}'s shift details successfully!`,
            {
              position: 'top-center',
            }
          )
          setFormData({})
          reset()
          close()
        },
      }
    )
  })

  return (
    <Modal
      // 'open' is controlled by Parent component so we set it to true at all times. Instead we just unmount the component when it's closed
      open={true}
      onClose={close}
      position="top"
      loading={isPending}
      showCancel
      customCta={
        <Flex>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save
          </Button>
        </Flex>
      }
    >
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Heading level={3} fontWeight="400" style={{ margin: 0 }}>
          Edit Shift Work
        </Heading>
        <Text mt={3} fontSize={2}>
          Worker
        </Text>
        <Text fontSize={2} color="lightText">
          {worker.name}
        </Text>
        <Flex mt={4} mb={4} alignItems="center">
          <Box width="50%">
            <Text fontSize={2}>Shift</Text>
            <Text fontSize={2} color="lightText">
              {shiftId}
            </Text>
          </Box>
          <Box width="50%">
            <Text fontSize={2}>Work ID</Text>
            <Text fontSize={2} color="lightText">
              {workId}
            </Text>
          </Box>
        </Flex>
        <Controller
          name="status"
          control={control}
          render={({ field, formState: { errors } }) => (
            <Field label="Status" error={errors.status?.message?.toString()}>
              <Select
                {...field}
                value={workStatusOptions.find(
                  (option) => option.value === field.value
                )}
                options={workStatusOptions}
                onChange={(value: any) => {
                  field.onChange(value.value)
                }}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => {
                    return {
                      ...base,
                      // 10004 because our Modal component sets the content's z-index to 10003
                      // Source: https://github.com/workwhile/ui/blob/c6094ceae9a041c7725010e7039448ac59bea30f/src/Modal/styles.ts#L24
                      zIndex: 10004,
                      pointerEvents: 'auto',
                    }
                  },
                }}
              />
            </Field>
          )}
        />
        <Controller
          name="clockedInAtAndClockedOutAt"
          control={control}
          render={({ field }) => {
            const [clockedInAt, clockedOutAt] = field.value
            const zonedClockedInAt = formatTimeInTimeZone(clockedInAt, timezone)
            const zonedClockedOutAt = formatTimeInTimeZone(
              clockedOutAt,
              timezone
            )

            return (
              <Field
                label={'Clocked In - Clocked Out'}
                error={errors.clockedInAtAndClockedOutAt?.message?.toString()}
                // Intentionally disabled. This should work, unsure why it's throwing an error
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mt={4}
              >
                <TimeRangeInput
                  block
                  {...field}
                  value={[zonedClockedInAt, zonedClockedOutAt]}
                  onChange={(value) => {
                    const output = parseTimeRangeTupleToUTCDate(
                      new Date(shiftStartsAt),
                      value[0],
                      value[1],
                      timezone
                    )
                    field.onChange(output)
                  }}
                />
              </Field>
            )
          }}
        />

        <Controller
          name={'leftEarly'}
          control={control}
          render={({ field }) => {
            return (
              <Field
                label={'Left Early'}
                error={errors.leftEarly?.message?.toString()}
                // Intentionally disabled. This should work, unsure why it's throwing an error
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mt={4}
                width="fit-content"
              >
                <RadioGroup
                  {...field}
                  // Intentionally disabled. This should work, unsure why it's throwing an error
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  flexDirection={['column', 'row']}
                  gap={5}
                  options={[
                    {
                      value: 'true',
                      label: 'Yes',
                    },
                    {
                      value: 'false',
                      label: 'No',
                    },
                  ]}
                />
              </Field>
            )
          }}
        />

        {errorMessage ? (
          <Message variant="error" mt={3} borderRadius="small">
            {errorMessage}
          </Message>
        ) : null}
      </Box>
    </Modal>
  )
}
