import * as zod from 'zod'

export const assignShiftFlowSchema = zod.object({
  workerIdInput: zod.coerce
    .number({
      invalid_type_error: 'Worker Id must be a number',
    })
    .optional(),
  workersToAssign: zod
    .array(
      zod.object({
        id: zod.number(),
      })
    )
    .min(1, {
      message: 'Please add at least one worker',
    }),
})

export type AssignShiftFlowSchema = zod.infer<typeof assignShiftFlowSchema>
