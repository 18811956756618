import { Box, Flex, Link, Table, Text, useAppTheme } from '@workwhile/ui'
import React from 'react'
import { useCompanyGroupsContextValue } from '../context/CompanyGroupsProvider'
import { ColumnDef } from '@tanstack/react-table'
import { CompanyGroups } from '../types'
import { formatDate, formatDistanceToNowStrict } from 'date-fns'
import { ArrowRight } from 'lucide-react'

const columns: ColumnDef<CompanyGroups[number]>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Created',
    accessorKey: 'createdAt',
    cell: ({ row }) => {
      const createdOn = formatDate(row.original.createdAt, 'MMM, dd, yyyy')
      const createdAt = formatDate(
        row.original.createdAt,
        'MMM, dd, yyyy, hh:mm a'
      )
      const distance = formatDistanceToNowStrict(row.original.createdAt, {
        addSuffix: true,
      })
      return (
        <Text title={createdAt}>
          {createdOn} ({distance})
        </Text>
      )
    },
  },
  {
    header: 'Companies',
    accessorKey: 'companyCount',
  },
  {
    header: 'Detail',
    cell: ({ row }) => {
      const { colors } = useAppTheme()
      return (
        <Link to={`/company-groups/${row.original.id}`}>
          View <ArrowRight size={14} color={colors.primary} />
        </Link>
      )
    },
  },
]

export const CompanyGroupsTable = () => {
  const {
    state: { filteredData, pagination },
    actions: { setPagination },
  } = useCompanyGroupsContextValue()

  const doesHaveData = filteredData.length > 0
  return (
    <Box>
      {doesHaveData ? (
        <Table
          columns={columns}
          data={filteredData}
          enableSorting
          stickyHeader
          clientPagination={{ state: pagination, setState: setPagination }}
        />
      ) : (
        <Flex
          mb={2}
          color="lightText"
          height={258}
          alignItems="center"
          justifyContent="center"
        >
          No company groups matched your search. Try using different keywords.
        </Flex>
      )}
    </Box>
  )
}
