import React from 'react'
import { ReferencePopover } from 'components/ReferencePopover'
import { CircleAlert, Info } from 'lucide-react'
import { Box, Text, useAppTheme } from '@workwhile/ui'

type ErrorMessageProps = {
  error: string
}

const ErrorMessage = ({ error }: ErrorMessageProps) => {
  return (
    <Box
      maxWidth={424}
      color="neutrals.400"
      style={{ maxHeight: '424px', overflowY: 'auto' }}
    >
      <Text color="errors.200">{error}</Text>
    </Box>
  )
}

type ErrorReferencePopoverProps = ErrorMessageProps

export const ErrorReferencePopover = ({
  error,
}: ErrorReferencePopoverProps) => {
  const { colors } = useAppTheme()
  return (
    <ReferencePopover
      trigger={<CircleAlert size={16} color={colors.errors[200]} />}
      subTitle=""
      title=""
      showCloseButton={false}
      contentProps={{
        children: <ErrorMessage error={error} />,
      }}
    />
  )
}
