import React, {
  ComponentProps,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { defs } from '../../Shared/defs'
import Creatable from 'react-select/creatable'
import makeAnimated from 'react-select/animated'
import { useSearchParams } from 'react-router-dom'
import useCompanies from 'queries/company/useCompanies'
import {
  Box,
  Button,
  DatePicker,
  Flex,
  Heading,
  Input,
  Label,
  Text,
  Select,
  OptionType,
} from '@workwhile/ui'
import { Company, WorkShift } from 'api/typings'
import { useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { DATE_INPUT_FORMAT, GetShiftsOptions } from 'api/shifts'
import moment from 'moment'
import { Sidebar } from 'react-pro-sidebar'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { MdOutlineFilterList } from 'react-icons/md'

const animatedComponents = makeAnimated()

export const QueryParams = {
  range: 'range',
  shiftId: 'shiftId',
  fromDate: 'fromDate',
  toDate: 'toDate',
  companyIds: 'companyIds',
  market: 'market',
  showCancelledShiftsOnly: 'showCancelledShiftsOnly',
  showUnfilledShiftsOnly: 'showUnfilledShiftsOnly',
  companyGroupId: 'companyGroupId',
  minUnfilledSpots: 'minUnfilledSpots',
  groupBy: 'groupBy',
  maxFillPercent: 'maxFillPercent',
  tier: 'tier',
  w2: 'w2',
  '1099': '1099',
  mustHaveRequirementIds: 'mustHaveRequirementIds',
} as const

export const mapQueryToFilters = (query: URLSearchParams): GetShiftsOptions => {
  const range = (
    ['upcoming', 'past', 'ongoing'].includes(query.get(QueryParams.range))
      ? query.get(QueryParams.range)
      : 'upcoming'
  ) as GetShiftsOptions['grouping']

  return {
    grouping: range,
    startsAt: query.get(QueryParams.fromDate),
    endsAt: query.get(QueryParams.toDate),
    companyIds: query.get(QueryParams.companyIds)?.split(','),
    shiftId: query.get(QueryParams.shiftId),
    market: query.get(QueryParams.market),
    isUnfilled:
      range === 'upcoming'
        ? query.get(QueryParams.showUnfilledShiftsOnly) !== 'false'
        : query.get(QueryParams.showUnfilledShiftsOnly) === 'true',
  }
}

const toCompanyOption = (company: Company) =>
  company
    ? {
        label: `${company.name} (${company.id})`,
        value: company.id,
      }
    : null

const toCompanyGroupOption = (company: Company) =>
  company
    ? {
        label: `${company.name.split(' - ')[0]} (${company.companyGroupId})`,
        value: company.companyGroupId,
      }
    : null

const getShiftOptions = (options: SelectOptions, shift: WorkShift) => {
  if (!options.markets.some(({ value }) => value === shift.market)) {
    options.markets.push({
      label: shift.market.toUpperCase(),
      value: shift.market,
    })
  }

  if (!options.shiftIds.some(({ value }) => value === shift.id)) {
    options.shiftIds.push({
      label: shift.id.toString(),
      value: shift.id,
    })
  }

  if (!options.tiers.some(({ value }) => value === shift.listings?.[0]?.tier)) {
    options.tiers.push({
      label: shift.listings[0].tier,
      value: shift.listings[0].tier,
    })
  }

  shift.position.mustHaveRequirements.forEach((requirement) => {
    if (
      !options.mustHaveRequirements.some(
        ({ value }) => value === requirement.id
      )
    ) {
      options.mustHaveRequirements.push({
        label: requirement.name,
        value: requirement.id,
      })
    }
  })

  return options
}

const getUniqueCompanyGroupIds = (groupIds, company: Company) => {
  if (
    !company?.companyGroupId ||
    groupIds.some(({ value }) => value == company.companyGroupId)
  ) {
    return groupIds
  }

  return groupIds.concat(toCompanyGroupOption(company))
}

type SelectOptions = {
  markets: OptionType[]
  shiftIds: OptionType[]
  tiers: OptionType[]
  mustHaveRequirements: OptionType[]
}

const SearchFacet = ({
  children,
  label,
  or,
}: React.PropsWithChildren<{
  label?: string
  or?: boolean
}>) => (
  <Flex
    flex="1 1 auto"
    flexDirection="column"
    mb="12px"
    px={12}
    as="label"
    width="100%"
    style={{ whiteSpace: 'nowrap' }}
  >
    {label && (
      <Text as="span" style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
        {!or && (
          <Text mr="1" as="span" color={defs.mediumBlack}>
            &
          </Text>
        )}
        {label}
      </Text>
    )}
    {children}
  </Flex>
)

type Range = 'upcoming' | 'past' | 'ongoing'
const rangeOptions: { label: string; value: Range }[] = [
  { label: 'Past shifts', value: 'past' },
  { label: 'Ongoing shifts', value: 'ongoing' },
  { label: 'Upcoming shifts', value: 'upcoming' },
]
const rangeCaptions: Record<Range, string> = {
  upcoming: '> 30 mins away + 3 days',
  ongoing: '+/- 30 min from now',
  past: 'Default: Past 2 days',
}

const ShiftsFilterBar = ({
  shifts,
  children,
}: PropsWithChildren<{ shifts: UseQueryResult }>) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [query, setQuery] = useSearchParams()
  const filters = mapQueryToFilters(query)
  const queryClient = useQueryClient()
  const companies = useCompanies('ids', filters)

  const options: SelectOptions = useMemo(
    () =>
      (
        queryClient.getQueryData<WorkShift[]>(['shifts', 'slim', filters]) || []
      ).reduce(getShiftOptions, {
        markets: [],
        shiftIds: [],
        tiers: [],
        mustHaveRequirements: [],
      } satisfies SelectOptions),
    [queryClient, filters]
  )

  const handleCheckbox = useCallback(
    (key) => () => {
      setQuery((params) => {
        if (params.get(key) === 'true') {
          params.set(key, 'false')
        } else {
          params.set(key, 'true')
        }

        return params
      })
    },
    [setQuery]
  )

  const handleSelect = useCallback(
    (key: keyof typeof QueryParams) => (selection: OptionType | null) => {
      setQuery((params) => {
        if (selection?.value) {
          params.set(QueryParams[key], selection?.value.toString())

          /** When selecting a company group ID, set all company IDs in order to hit API */
          if (key === QueryParams.companyGroupId) {
            params.set(
              QueryParams.companyIds,
              companies.data
                .reduce((all, company) => {
                  if (company.companyGroupId == selection?.value) {
                    return all.concat(company.id)
                  }
                  return all
                }, [])
                .toString()
            )
          }

          /** Clear custom dates when changing the range type */
          if (key === QueryParams.range) {
            params.delete(QueryParams.fromDate)
            params.delete(QueryParams.toDate)
          }
        } else {
          params.delete(QueryParams[key])

          /** Remove all company IDs when clearing the company group ID */
          if (key === QueryParams.companyGroupId) {
            params.delete(QueryParams.companyIds)
          }
        }

        return params
      })
    },
    [setQuery]
  )

  const handleMultiSelect = useCallback(
    (key: keyof typeof QueryParams) => (selectedOptions) => {
      setQuery((params) => {
        if (selectedOptions?.length) {
          params.set(
            QueryParams[key],
            selectedOptions?.map((option) => option.value)
          )
        } else {
          params.delete(QueryParams[key])
        }
        return params
      })
    },
    [setQuery]
  )

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target

      setQuery((params) => {
        if (value?.length) {
          params.set(name, value.toString())
        } else {
          params.delete(name)
        }
        return params
      })
    },
    [setQuery]
  )

  const handleSelectDateRange: ComponentProps<typeof DatePicker>['onSelect'] = (
    selection
  ) => {
    setQuery((params) => {
      if (!selection) {
        params.delete(QueryParams.fromDate)
        params.delete(QueryParams.toDate)
      } else if ('from' in selection) {
        const { from, to } = selection
        params.set(QueryParams.fromDate, moment(from).format(DATE_INPUT_FORMAT))
        params.set(
          QueryParams.toDate,
          moment(to || from).format(DATE_INPUT_FORMAT)
        )
      }
      return params
    })
  }

  const handleUnfilled = useCallback(() => {
    setQuery((params) => {
      const range = params.get(QueryParams.range) || 'upcoming'

      if (range === 'upcoming') {
        /** Show unfilled shifts by default for upcoming shifts */
        if (params.get(QueryParams.showUnfilledShiftsOnly) === 'false') {
          params.delete(QueryParams.showUnfilledShiftsOnly)
        } else {
          params.set(QueryParams.showUnfilledShiftsOnly, 'false')
        }
      } else {
        /** Other ranges show all shifts by default */
        handleCheckbox(QueryParams.showUnfilledShiftsOnly)()
      }

      return params
    })
  }, [setQuery, handleCheckbox])

  const rangeCaption = rangeCaptions[query.get(QueryParams.range) || 'upcoming']

  return (
    <Flex
      style={{
        columnGap: defs.marginM,
        overflow: 'hidden',
        height: '100%',
        width: '100%',
      }}
      key="page"
    >
      <Sidebar
        collapsed={!isSidebarOpen}
        key="bar"
        width="255px"
        style={{
          backgroundColor: defs.veryLightBlack,
          minHeight: '100vh',
          overflow: 'hidden',
          zIndex: 0,
        }}
      >
        <Flex
          height={50}
          width={'100%'}
          textAlign="center"
          variant="text"
          style={{
            flexBasis: '50px',
            backgroundColor: defs.lightAlertAqua,
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          px={12}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          {isSidebarOpen ? (
            <ArrowLeft color={defs.wwDarkTeal} size={20} />
          ) : (
            <ArrowRight color={defs.wwDarkTeal} size={20} />
          )}
          <MdOutlineFilterList color={defs.wwDarkTeal} size={20} />
        </Flex>
        {isSidebarOpen && (
          <Box
            py={20}
            key="form"
            style={{
              overflowY: 'scroll',
              height: '100%',
              minHeight: '100%',
              paddingBottom: '50px',
            }}
          >
            <SearchFacet label="Shift ID">
              <Creatable
                id="shiftId"
                isDisabled={!options.shiftIds}
                isSearchable
                isClearable
                value={
                  query.get(QueryParams.shiftId)
                    ? {
                        label: query.get(QueryParams.shiftId),
                        value: query.get(QueryParams.shiftId),
                      }
                    : undefined
                }
                options={options.shiftIds}
                components={animatedComponents}
                name={QueryParams.shiftId}
                placeholder="Shift ID"
                onChange={handleSelect('shiftId')}
              />
            </SearchFacet>

            <Box
              as="form"
              onSubmit={(e) => {
                e.preventDefault()
                shifts.refetch()
              }}
            >
              <SearchFacet label="Search type">
                <Select
                  isMulti={false}
                  value={
                    rangeOptions.find(
                      ({ value }) => value == query.get(QueryParams.range)
                    ) || rangeOptions[2]
                  }
                  options={rangeOptions}
                  components={animatedComponents}
                  name={QueryParams.range}
                  placeholder="Date range"
                  // @ts-ignore
                  onChange={handleSelect('range')}
                />
                {rangeCaption && (
                  <Text mt="1" style={{ fontStyle: 'italic' }}>
                    {rangeCaption}
                  </Text>
                )}
              </SearchFacet>
              {query.get(QueryParams.range) !== 'ongoing' && (
                <SearchFacet
                  label="Date range"
                  key={query.get(QueryParams.range)}
                >
                  <DatePicker
                    placeholder={'Select date range'}
                    mode={'range'}
                    selected={{
                      from: (query.get(QueryParams.range) === 'past'
                        ? moment().subtract(2, 'days')
                        : moment()
                      ).toDate(),
                      to: (query.get(QueryParams.range) === 'past'
                        ? moment()
                        : moment().add(3, 'days')
                      ).toDate(),
                    }}
                    /** @ts-ignore-error - assume DateRange */
                    onSelect={handleSelectDateRange}
                  />
                </SearchFacet>
              )}

              <SearchFacet label="Company group">
                <Select
                  isDisabled={!companies.data}
                  isSearchable
                  isClearable
                  value={
                    query.get(QueryParams.companyGroupId)
                      ? toCompanyGroupOption(
                          companies.data?.find(
                            ({ companyGroupId }) =>
                              query.get(QueryParams.companyGroupId) ==
                              companyGroupId
                          )
                        )
                      : null
                  }
                  options={companies.data
                    ?.reduce(getUniqueCompanyGroupIds, [])
                    ?.sort((a, b) => (a.label < b.label ? -1 : 1))}
                  components={animatedComponents}
                  name={QueryParams.companyGroupId}
                  placeholder="Company group"
                  onChange={handleSelect('companyGroupId')}
                />
              </SearchFacet>
              <SearchFacet label="Companies">
                <Select
                  isDisabled={!companies.data}
                  isMulti
                  isSearchable
                  isClearable
                  value={(companies.data || [])
                    .filter(({ id }) =>
                      query
                        .get(QueryParams.companyIds)
                        ?.split(',')
                        ?.includes(id)
                    )
                    .map(toCompanyOption)}
                  options={companies.data
                    ?.filter((company) =>
                      query.get(QueryParams.companyGroupId)
                        ? company.companyGroupId ==
                          query.get(QueryParams.companyGroupId)
                        : true
                    )
                    .map(toCompanyOption)
                    .sort((a, b) => (a.label < b.label ? -1 : 1))}
                  components={animatedComponents}
                  name={QueryParams.companyIds}
                  placeholder="Companies"
                  onChange={handleMultiSelect('companyIds')}
                />
              </SearchFacet>
              <SearchFacet label="Market">
                <Select
                  isDisabled={!options.markets}
                  isSearchable
                  isClearable
                  value={
                    query.get(QueryParams.market)
                      ? {
                          label: query.get(QueryParams.market).toUpperCase(),
                          value: query.get(QueryParams.market),
                        }
                      : null
                  }
                  options={options.markets?.sort((a, b) =>
                    a.label < b.label ? -1 : 1
                  )}
                  components={animatedComponents}
                  name={QueryParams.market}
                  placeholder="Market"
                  onChange={handleSelect('market')}
                />
              </SearchFacet>

              <SearchFacet label="Min unfilled">
                <Input
                  id={QueryParams.minUnfilledSpots}
                  type="number"
                  name={QueryParams.minUnfilledSpots}
                  placeholder="0"
                  value={query.get(QueryParams.minUnfilledSpots) || ''}
                  onChange={handleInput}
                  min={0}
                />
              </SearchFacet>
              <SearchFacet label="Max fill %">
                <Input
                  id={QueryParams.maxFillPercent}
                  type="number"
                  name={QueryParams.maxFillPercent}
                  placeholder="100"
                  value={query.get(QueryParams.maxFillPercent) || ''}
                  onChange={handleInput}
                  min={0}
                />
              </SearchFacet>
              <SearchFacet label="Tier">
                <Select
                  isDisabled={!options.tiers}
                  isSearchable
                  isClearable
                  value={
                    query.get(QueryParams.tier)
                      ? {
                          label: query.get(QueryParams.tier),
                          value: query.get(QueryParams.tier),
                        }
                      : null
                  }
                  options={options.tiers?.sort((a, b) =>
                    a.label < b.label ? -1 : 1
                  )}
                  components={animatedComponents}
                  name={QueryParams.tier}
                  placeholder="Tier"
                  onChange={handleSelect('tier')}
                />
              </SearchFacet>

              <SearchFacet label="Must have requirements">
                <Select
                  isDisabled={!options.mustHaveRequirements}
                  isSearchable
                  isClearable
                  isMulti
                  value={query
                    .get(QueryParams.mustHaveRequirementIds)
                    ?.split(',')
                    .map((id) =>
                      options.mustHaveRequirements.find(
                        (option) => option.value === id
                      )
                    )}
                  options={options.mustHaveRequirements?.sort((a, b) =>
                    a.label < b.label ? -1 : 1
                  )}
                  components={animatedComponents}
                  name={QueryParams.mustHaveRequirementIds}
                  placeholder="Must have requirements"
                  onChange={handleMultiSelect('mustHaveRequirementIds')}
                />
              </SearchFacet>

              <SearchFacet label="Filters">
                <Flex flexDirection="column" gap="2">
                  <Flex as="label" alignItems="center">
                    <input
                      style={{
                        height: 20,
                        width: 20,
                        accentColor: defs.wwDarkTeal,
                      }}
                      type="checkbox"
                      name={QueryParams.showUnfilledShiftsOnly}
                      onChange={handleUnfilled}
                      checked={
                        filters.grouping === 'upcoming'
                          ? query.get(QueryParams.showUnfilledShiftsOnly) !==
                            'false'
                          : query.get(QueryParams.showUnfilledShiftsOnly) ===
                            'true'
                      }
                    />
                    <Text ml="2">Unfilled shifts only</Text>
                  </Flex>

                  <Flex as="label" alignItems="center">
                    <input
                      style={{
                        height: 20,
                        width: 20,
                        accentColor: defs.wwDarkTeal,
                      }}
                      type="checkbox"
                      name={QueryParams.showCancelledShiftsOnly}
                      onChange={handleCheckbox(
                        QueryParams.showCancelledShiftsOnly
                      )}
                      checked={
                        query.get(QueryParams.showCancelledShiftsOnly) ===
                        'true'
                      }
                    />
                    <Text ml="2">Cancelled shifts only</Text>
                  </Flex>

                  <Flex as="label" alignItems="center">
                    <input
                      style={{
                        height: 20,
                        width: 20,
                        accentColor: defs.wwDarkTeal,
                      }}
                      type="checkbox"
                      name={QueryParams.w2}
                      onChange={handleCheckbox(QueryParams.w2)}
                      checked={query.get(QueryParams.w2) !== 'false'}
                    />
                    <Text ml="2">Show W2 shifts</Text>
                  </Flex>

                  <Flex as="label" alignItems="center">
                    <input
                      style={{
                        height: 20,
                        width: 20,
                        accentColor: defs.wwDarkTeal,
                      }}
                      type="checkbox"
                      name={QueryParams.w2}
                      onChange={handleCheckbox(QueryParams['1099'])}
                      checked={query.get(QueryParams['1099']) !== 'false'}
                    />
                    <Text ml="2">Show 1099 shifts</Text>
                  </Flex>
                </Flex>
              </SearchFacet>
            </Box>
          </Box>
        )}
      </Sidebar>

      <Box key="results" width="100%" mr={20} style={{ overflowY: 'scroll' }}>
        <Flex
          key="groupByAndButton"
          alignItems="end"
          justifyContent="space-between"
        >
          <Box key="groupBy">
            <Heading level="4">Group by</Heading>
            <Flex>
              <Label
                as="button"
                type="button"
                name="groupBy"
                value="date"
                style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                variant={
                  query.get(QueryParams.groupBy) === 'date' ||
                  !query.get(QueryParams.groupBy)
                    ? 'primary'
                    : 'none'
                }
                mr="2"
                onClick={handleInput}
              >
                Date {query.get(QueryParams.range) === 'past' ? 'desc' : 'asc'}
              </Label>
              <Label
                as="button"
                type="button"
                name="groupBy"
                value="companyGroup"
                style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                variant={
                  query.get('groupBy') === 'companyGroup' ? 'primary' : 'none'
                }
                mr="2"
                onClick={handleInput}
              >
                Company group
              </Label>
              <Label
                as="button"
                type="button"
                name="groupBy"
                value="market"
                style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                variant={query.get('groupBy') === 'market' ? 'primary' : 'none'}
                mr="2"
                onClick={handleInput}
              >
                Market
              </Label>
            </Flex>
          </Box>

          <Button
            type="submit"
            disabled={shifts.isFetching}
            width="200px"
            loading={shifts.isFetching}
            onClick={shifts.refetch}
          >
            {shifts.isFetching ? 'Loading shifts' : 'Load shifts'}
          </Button>
        </Flex>
        {children}
      </Box>
    </Flex>
  )
}

export default ShiftsFilterBar
