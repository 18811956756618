import { useMemo } from 'react'
import { PositionTemplate, PositionTemplateGroup } from 'api/company'

export function useAllGroups(
  positionTemplates?: PositionTemplate[]
): PositionTemplateGroup[] {
  return useMemo(() => {
    if (!positionTemplates) {
      return []
    }

    const allGroups = positionTemplates.flatMap((template) =>
      template.relevantPositionTemplateGroups.map((group) => ({
        id: group.id,
        name: group.name,
      }))
    )

    return Array.from(
      new Map(allGroups.map((group) => [group.id, group])).values()
    )
  }, [positionTemplates])
}
