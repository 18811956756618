import React, { useState } from 'react'
import { Button, Flex, Icon, Input, Text } from '@workwhile/ui'
import { usePositionEditorContext } from './PositionEditorContext'
import { SearchIcon } from 'lucide-react'
import { useSearchParams } from 'react-router-dom'

export function PositionIdInput() {
  const [, setSearchParams] = useSearchParams()
  const { positionId, isLoadingPositionData } = usePositionEditorContext()
  const [value, setValue] = useState(positionId?.toString())

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSearchParams({ positionId: value })
  }

  return (
    <form onSubmit={handleSearchSubmit}>
      <Flex px={3} py={2} alignItems={'center'} gap={2}>
        <Text>Position ID:</Text>
        <Input
          icon={<Icon icon={SearchIcon} size={16} />}
          type={'search'}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button type={'submit'} loading={isLoadingPositionData}>
          Search
        </Button>
      </Flex>
    </form>
  )
}
