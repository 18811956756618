import api from 'api'

export const addCompaniesToGroup = async (
  companyGroupId: number,
  companyIds: number[]
) => {
  const response = await api.patch(`/admin/company_group/${companyGroupId}`, {
    company_ids: companyIds,
  })
  return response.data
}
