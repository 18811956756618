import React, { PropsWithChildren, useId, useState } from 'react'
import { EditShiftWorkFlowSchema } from '../schemas/editShiftWorkFlowSchema'
import { createContext } from 'lib/createContext'
import { parseErrorMessage } from 'api'
import { useEditShiftWorkMutation } from 'queries/shifts'
import {
  EditWorkFlowState,
  useGlobalWorkflowsContextValue,
} from '../../GlobalWorkflowManager/GlobalWorkflowManager'

type EditShiftWorkFlowProviderContextState = {
  actions: {
    submitFormToServer: ReturnType<typeof useEditShiftWorkMutation>['mutate']
    setFormData: (data: EditShiftWorkFlowSchema) => void
    close: () => void
  }
  state: {
    isOpen: boolean
    worker: {
      id: number
      name: string
    }
    workId: number
    shiftId: number
    // Used for date parsing
    shiftStartsAt: string
    timezone: string
    formData: EditShiftWorkFlowSchema
    mutationState: {
      isPending: boolean
      isError: boolean
      errorMessage: string | undefined
    }
  }
}

const [Context, useEditShiftWorkFlowContextValue] =
  createContext<EditShiftWorkFlowProviderContextState>({
    name: 'EditShiftWorkFlowContext',
  })

type EditShiftWorkFlowContextProviderProps = PropsWithChildren<{
  initialData: EditWorkFlowState | undefined
  isOpen: boolean
  close: () => void
}>

const EditShiftWorkFlowContextProvider = ({
  children,
  initialData: {
    shiftId,
    workId,
    status,
    clockedInAt,
    clockedOutAt,
    leftEarly,
    timezone,
    worker,
    shiftStartsAt,
  },
  isOpen,
  close,
}: EditShiftWorkFlowContextProviderProps) => {
  const [formData, setFormData] = useState<EditShiftWorkFlowSchema>({
    status,
    clockedInAtAndClockedOutAt: [clockedInAt, clockedOutAt],
    leftEarly,
    existingValues: {
      status,
      clockedInAtAndClockedOutAt: [clockedInAt, clockedOutAt],
      leftEarly,
    },
  })

  const { mutate, isPending, isError, error } =
    useEditShiftWorkMutation(shiftId)

  const value: EditShiftWorkFlowProviderContextState = {
    actions: {
      submitFormToServer: mutate,
      setFormData,
      close,
    },
    state: {
      isOpen,
      worker,
      workId,
      shiftId,
      shiftStartsAt,
      timezone,
      formData,
      mutationState: {
        isPending,
        isError,
        errorMessage: parseErrorMessage(error),
      },
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const EditShiftWorkFlowProvider = ({ children }: PropsWithChildren) => {
  const {
    editShiftWorkFlow: { isOpen, data, close },
  } = useGlobalWorkflowsContextValue()

  const baseId = useId()

  if (!isOpen) {
    return null
  }

  return (
    <EditShiftWorkFlowContextProvider
      initialData={data}
      // resets the initial state when the modal is opened or closed
      key={`${baseId}-${isOpen}`}
      isOpen={isOpen}
      close={close}
    >
      {children}
    </EditShiftWorkFlowContextProvider>
  )
}

export { useEditShiftWorkFlowContextValue }
