import React from 'react'
import { EditShiftWorkFlowMain } from './components/EditShiftWorkFlowMain'
import { EditShiftWorkFlowProvider } from './context/EditShiftWorkFlowProvider'

export const EditShiftWorkFlow = () => {
  return (
    <EditShiftWorkFlowProvider>
      <EditShiftWorkFlowMain />
    </EditShiftWorkFlowProvider>
  )
}
