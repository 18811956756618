import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import SignOutButton from '../SignOut'
import * as ROUTES from '../../constants/routes'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import './nav.css'
import {
  MdSort,
  MdPerson,
  MdHouse,
  MdMessage,
  MdPayments,
  MdPhone,
  MdFolderOpen,
  MdChecklist,
  MdList,
  MdMenu,
  MdCardMembership,
} from 'react-icons/md'
import { FaIdCard } from 'react-icons/fa'
import { Heading } from '@workwhile/ui'
import { useAuthContext } from 'components/auth'
import { useMediaQuery } from 'react-responsive'
import logo from '../../assests/ww_logo.png'
import PropTypes from 'prop-types'
import { Ban, Building2, Command, HardHat, Package, ScrollText } from 'lucide-react'
import { getRouteWithDefaultQueryParams } from 'pages/Shifts/Shifts'
const theme = {
  sidebar: {
    backgroundColor: '#0b2948',
    color: '#607489',
  },
  menu: {
    menuItemText: '#27C8B6',
    icon: '#27C8B6',
    hover: {
      backgroundColor: '#c5e4ff',
      color: '#44596e',
    },
    disabled: {
      color: '#9fb6cf',
    },
  },
}

const menuItemStyles = {
  root: {
    fontSize: '13px',
    fontWeight: 400,
  },
  icon: {
    color: theme.menu.icon,
  },
  SubMenuExpandIcon: {
    color: '#b6b7b9',
  },
  subMenuContent: () => ({
    backgroundColor: theme.sidebar.backgroundColor,
  }),
  button: {
    color: theme.menu.menuItemText,
    '&:hover': {
      backgroundColor: 'rgba(197, 228, 255, 1)',
      color: theme.menu.hover.color,
    },
  },
  label: ({ open }) => ({
    fontWeight: open ? 600 : undefined,
    color: theme.menu.menuItemText,
  }),
}

const SidebarNav = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const [collapsed, setCollapsed] = useState(isMobile)
  const { isAuthenticated } = useAuthContext()

  return (
    <Sidebar
      rootStyles={{
        color: theme.sidebar.color,
        overflowY: 'scroll',
        height: '100%',
      }}
      width="300px"
      backgroundColor={theme.sidebar.backgroundColor}
      collapsed={collapsed}
    >
      {isAuthenticated ? (
        <SidebarNavAuth collapsed={collapsed} setCollapsed={setCollapsed} />
      ) : (
        <NavigationNonAuth />
      )}
    </Sidebar>
  )
}

const SidebarNavAuth = ({ collapsed, setCollapsed }) => {
  const toggleSidebar = () => {
    setCollapsed(!collapsed)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'scroll',
      }}
    >
      <HamburgerContainer>
        <HamburgerButton onClick={toggleSidebar}>
          <MdMenu size={24} color={theme.menu.icon} />
        </HamburgerButton>
      </HamburgerContainer>
      <StyledSidebarHeader>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={logo}
            style={{ width: '30px', height: '30px' }}
            alt="Logo"
          />
          <Heading level={2} ml={3} color={'white'}>
            WorkWhile
          </Heading>
        </div>
      </StyledSidebarHeader>

      <Menu menuItemStyles={menuItemStyles}>
        <MenuItem component={<Link to={'/users'} />} icon={<MdPerson size={15}/>}>
          Users
        </MenuItem>
        <SubMenu
          label="Shifts"
          icon={<ScrollText size={15} />}
          rootStyles={{ color: theme.menu.menuItemText }}
        >
          <MenuItem
            icon={<HardHat size={15} />}
            component={
              <Link
                to={getRouteWithDefaultQueryParams()}
                className="nav-link"
              />
            }
          >
            Shifts
          </MenuItem>
          <MenuItem
            icon={<Ban size={15} />}
            component={
              <Link to={ROUTES.UPCOMING_SHIFTS} className="nav-link" />
            }
          >
            Upcoming Shifts
          </MenuItem>
          <MenuItem
            icon={<Ban size={15} />}
            component={<Link to={ROUTES.ONGOING_SHIFTS} className="nav-link" />}
          >
            Ongoing Shifts
          </MenuItem>
          <MenuItem
            icon={<Ban size={15} />}
            component={<Link to={ROUTES.PAST_SHIFTS} className="nav-link" />}
          >
            Past Shifts
          </MenuItem>
          <MenuItem
            icon={<Ban size={15} />}
            component={<Link to={ROUTES.SINGLE_SHIFT} className="nav-link" />}
          >
            Single Shift
          </MenuItem>
        </SubMenu>

        <SubMenu label="Company" icon={<MdHouse size={15} />}>
          <MenuItem
            component={<Link to={ROUTES.COMPANIES} className="nav-link" />}
            icon={<Building2 size={15} />}
          >
            Companies
          </MenuItem>
          <MenuItem
            component={<Link to={ROUTES.COMPANY_GROUPS} className="nav-link" />}
            icon={<Package size={15} />}
          >
            Company Groups
          </MenuItem>
        </SubMenu>
        <MenuItem
          component={<Link to={ROUTES.POSITIONS} className="nav-link" />}
          icon={<MdPhone size={15} />}
        >
          Positions
        </MenuItem>
        <MenuItem
          component={<Link to={ROUTES.MESSAGES2} className="nav-link" />}
          icon={<MdMessage size={15} />}
        >
          Messages
        </MenuItem>
        <MenuItem
          component={<Link to={ROUTES.PAYMENTS} className="nav-link" />}
          icon={<MdPayments size={15} />}
        >
          Receipts
        </MenuItem>
        <SubMenu label="Review" icon={<MdList size={15} />}>
          <MenuItem
            component={<Link to={ROUTES.REVIEW} className="nav-link" />}
            icon={<MdChecklist size={15} />}
          >
            Admin Review
          </MenuItem>
          <MenuItem
            component={
              <Link to={ROUTES.VERIFY_CREDENTIALS} className="nav-link" />
            }
            icon={<MdCardMembership size={15} />}
          >
            Verify Credentials
          </MenuItem>
          <MenuItem
            component={<Link to={ROUTES.I9_DASHBOARD} className="nav-link" />}
            icon={<FaIdCard size={15} />}
          >
            I9 Dashboard
          </MenuItem>
        </SubMenu>
        <MenuItem
          component={<Link to={ROUTES.COMMANDS} className="nav-link" />}
          icon={<Command size="15" />}
        >
          Commands
        </MenuItem>

        <MenuItem
          component={<Link to={ROUTES.POSITION_EDITOR} className="nav-link" />}
          icon={<MdFolderOpen size={15} />}
        >
          Position Editor
        </MenuItem>

        <SubMenu label="More Options" icon={<MdList size={15} />}>
          <MenuItem
            component={<Link to={ROUTES.BIZLEADS} className="nav-link" />}
          >
            Scraped Leads
          </MenuItem>
          <MenuItem
            component={
              <Link to={ROUTES.WORKERBIZREFERRALS} className="nav-link" />
            }
          >
            Worker-{'>'}Biz Referrals
          </MenuItem>
          <MenuItem component={<Link to={ROUTES.LOGS} className="nav-link" />}>
            Logs
          </MenuItem>
          <MenuItem
            component={
              <Link to={ROUTES.QUEST_DEFINITIONS} className="nav-link" />
            }
          >
            Quest Defintions
          </MenuItem>
          <MenuItem component={<Link to={ROUTES.TIPS} className="nav-link" />}>
            Tips
          </MenuItem>
          <MenuItem
            component={<Link to={ROUTES.SPREADSHEETS} className="nav-link" />}
          >
            Spreadsheets
          </MenuItem>
          <MenuItem
            component={<Link to={ROUTES.LISTINGS} className="nav-link" />}
          >
            Listings
          </MenuItem>
          <MenuItem
            component={
              <Link to={ROUTES.SHIFT_ANALYTICS} className="nav-link" />
            }
          >
            Shift Analytics
          </MenuItem>
          <MenuItem component={<Link to={ROUTES.LTA} className="nav-link" />}>
            LTA
          </MenuItem>
          <MenuItem
            component={<Link to={'/upcoming-listings'} className="nav-link" />}
          >
            Upcoming Listings
          </MenuItem>
          <MenuItem
            component={<Link to={ROUTES.EVERIFY} className="nav-link" />}
          >
            eVerify Call List
          </MenuItem>
          <MenuItem
            component={
              <Link to={ROUTES.EVERIFY_UNPROCESS_FORMS} className="nav-link" />
            }
          >
            eVerify Unprocessed Forms
          </MenuItem>
          <MenuItem
            component={<Link to={ROUTES.WORKER_SUPPLY} className="nav-link" />}
          >
            Worker Supply
          </MenuItem>
        </SubMenu>

        <SignOutButton className="submit-button-smaller" />
      </Menu>
    </div>
  )
}

const NavigationNonAuth = () => <div />

export default SidebarNav

const StyledSidebarHeader = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  > div {
    width: 100%;
    overflow: hidden;
  }
`

const HamburgerContainer = styled.div`
  padding: 0 16px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
`

const HamburgerButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: ${theme.menu.icon};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`

SidebarNavAuth.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
}
