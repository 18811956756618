import { companyGroupDetailKey } from './keys'
import { useQuery } from '@tanstack/react-query'
import { getCompanyGroupDetail } from 'api/companyGroup/companyGroupDetail'

export const useCompanyGroupDetailsByIdQuery = (companyGroupId: number) => {
  return useQuery({
    queryKey: companyGroupDetailKey(companyGroupId),
    queryFn: () => getCompanyGroupDetail(companyGroupId),
    refetchOnReconnect: false,
  })
}
