import React, { useId, useMemo } from 'react'
import {
  useFieldArray,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form'
import { Button, Flex, useAppTheme } from '@workwhile/ui'
import { CircleMinus } from 'lucide-react'
import { AssignShiftFlowSchema } from '../schema/assignShift'

const AddedWorkerRow = ({
  remove,
  index,
  id,
  isLast,
}: {
  remove: UseFieldArrayRemove
  index: number
  id: number
  isLast: boolean
}) => {
  const { colors, radii, space } = useAppTheme()
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={space[2]}
      mb={isLast ? 0 : space[2]}
    >
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        border="1px solid"
        borderColor={colors.neutrals[100]}
        p={2}
        borderRadius={radii.small}
        width="100%"
        position="relative"
      >
        {id}
      </Flex>
      <Button
        onClick={() => remove(index)}
        variant="text"
        color="red"
        size="small"
        danger
        style={{
          margin: 0,
          height: '1.6rem', // This is to resolve a bug in our UI library where the width and height is being set to `small` (literally) instead of a `px` value
        }}
      >
        <CircleMinus size={16} />
      </Button>
    </Flex>
  )
}

export const AddedWorkers = () => {
  const baseId = useId()
  const { watch, control } = useFormContext<AssignShiftFlowSchema>()

  const workers = watch('workersToAssign')

  const { remove } = useFieldArray({
    name: 'workersToAssign',
    control,
  })

  const addedWorkerRows = workers.map((worker, index) => {
    return (
      <AddedWorkerRow
        key={`added-workers-${baseId}-${worker.id}-${index}`}
        remove={remove}
        index={index}
        id={worker.id}
        isLast={index === workers.length - 1}
      />
    )
  })

  return <>{addedWorkerRows}</>
}
