import { createContext } from 'lib/createContext'
import React, { PropsWithChildren, useState } from 'react'

type Workflow<Args extends Record<string, unknown> | undefined = undefined> = {
  open: Args extends undefined ? () => void : (args: Args) => void
  close: () => void
  isOpen: boolean
} & (Args extends undefined ? object : { data?: Args })

type WorkflowState<T extends Record<string, unknown> | undefined = undefined> =
  T extends undefined
    ? boolean
    : {
        isOpen: boolean
        data?: T
      }

export type ShiftDetailWorkflowsContextState = {
  assignShiftFlow: Workflow<{
    shiftId: number
  }>
}

const [ShiftDetailWorkflowsContext, useShiftDetailWorkflowsContextValue] =
  createContext<ShiftDetailWorkflowsContextState>({
    name: 'ShiftDetailWorkflowsContext',
  })

type ShiftDetailWorkflowsProviderProps = PropsWithChildren

export const ShiftDetailWorkflowsProvider = ({
  children,
}: ShiftDetailWorkflowsProviderProps) => {
  const [assignShiftFlow, setAssignShiftFlow] = useState<
    WorkflowState<{
      shiftId: number
    }>
  >({ isOpen: false, data: undefined })

  const value: ShiftDetailWorkflowsContextState = {
    assignShiftFlow: {
      open: (data) => setAssignShiftFlow({ isOpen: true, data }),
      close: () => setAssignShiftFlow({ isOpen: false, data: undefined }),
      isOpen: assignShiftFlow.isOpen,
      data: assignShiftFlow.data,
    },
  }

  return (
    <ShiftDetailWorkflowsContext.Provider value={value}>
      {children}
    </ShiftDetailWorkflowsContext.Provider>
  )
}

export { useShiftDetailWorkflowsContextValue }
