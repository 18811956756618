import { TierType } from 'pages/Shifts/ShiftDetail/types'

const mapTierToLabel: Record<TierType, string> = {
  unset: 'Unset',
  roster: 'Roster',
  favorite: 'Favorite',
  regular_1: 'Regular 1',
  bench_1: 'Bench 1',
  bench_2: 'Bench 2',
  company_1: 'Company 1',
  company_2: 'Company 2',
  position_1: 'Position 1',
  workwhile_1: 'Workwhile 1',
  workwhile_2: 'Workwhile 2',
  workwhile_3: 'Workwhile 3',
  new: 'New',
  reserve: 'Reserve',
}

export const getTierTransitionLabel = (tier: TierType) => {
  return mapTierToLabel[tier] || tier
}
