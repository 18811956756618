export const companyKey = ['company']

export const companyDetailKey = (companyId: number) => [
  ...companyKey,
  companyId,
]

export const companySwitchKey = [...companyKey, 'switch']
export const positionTemplatesKey = [...companyKey, 'positionTemplates']
export const positionTemplateKey = (positionTemplateId?: number) => [
  ...companyKey,
  'positionTemplate',
  positionTemplateId?.toString(),
]
export const positionPositionTemplateMutationKey = (positionId: number) => [
  ...companyKey,
  'positionPositionTemplateMutation',
  positionId.toString(),
]
export const positionKey = (positionId: number | null) => [
  ...companyKey,
  'position',
  positionId?.toString(),
]
