import React from 'react'
import { AccordionCard } from 'components/AccordionCard'
import { useCompanyDetailContextValue } from '../context/CompanyDetailProvider'
import { Box, Button, Flex, Text, useAppTheme } from '@workwhile/ui'
import { MinPayPolicy } from '../types'
import { Plus } from 'lucide-react'
import { useCompanyDetailWorkflowsContextValue } from '../context/CompanyDetailWorkflowProvider'
import {
  format,
  formatDistanceToNowStrict,
  formatDuration,
  isAfter,
  parseISO,
} from 'date-fns'
import { getLongDuration } from 'lib/time'

const PolicyRow = ({
  policy,
  isLast,
}: {
  policy: MinPayPolicy
  isLast: boolean
}) => {
  const { radii } = useAppTheme()

  const isDateInFuture = isAfter(parseISO(policy.startsAt), new Date())
  const formattedDate = format(policy.startsAt, 'MMM d, yyyy')
  const distanceToNow = formatDistanceToNowStrict(policy.startsAt, {
    addSuffix: true,
  })

  const isMoreThanOneHour = policy.minPayLengthInMinutes > 60
  return (
    <Box
      display="block"
      border="1px solid"
      borderColor="neutrals.100"
      p={3}
      borderRadius={radii.standard}
      width="100%"
      mb={isLast ? 0 : 3}
    >
      <Text
        mb={3}
        title={`${format(policy.startsAt, 'MMM d, yyyy hh:mm a')} (${distanceToNow})`}
      >
        {isDateInFuture ? 'Starts on' : 'Active since'} {formattedDate} (
        {distanceToNow})
      </Text>
      <Text fontSize={1} color="lightText">
        {getLongDuration(policy.minPayLengthInMinutes)}
        {isMoreThanOneHour
          ? ` (${formatDuration({ minutes: policy.minPayLengthInMinutes })})`
          : null}
      </Text>
    </Box>
  )
}

export const MinimumPayPolicy = () => {
  const { space } = useAppTheme()
  const {
    state: { activeMinPayPolicy, upcomingMinPayPolicies },
  } = useCompanyDetailContextValue()

  const {
    addMinimumPayPolicyFlow: { open: openAddMinimumPayPolicyFlow },
  } = useCompanyDetailWorkflowsContextValue()

  const upcomingMinPayPolicy = upcomingMinPayPolicies.map((policy, index) => {
    return (
      <PolicyRow
        key={`upcoming-minimum-pay-policy-rows-${policy.id}-${index}`}
        policy={policy}
        isLast={index === upcomingMinPayPolicies.length - 1}
      />
    )
  })

  return (
    <AccordionCard
      size="small"
      title={
        <Flex justifyContent="space-between" alignItems="center" gap={2}>
          <Text fontSize={2} color="lightText">
            Minimum Pay Policies
          </Text>
          <Button
            variant="primary"
            kind="small"
            style={{ paddingLeft: space[2], paddingRight: space[2] }}
            onClick={openAddMinimumPayPolicyFlow}
          >
            <Plus size={14} />
            <Text as="span" ml={1}>
              Add
            </Text>
          </Button>
        </Flex>
      }
      // Intentionally disabled. This should work, unsure why it's throwing an error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      maxWidth="50%"
      isAccordion
      defaultOpen={false}
    >
      <Text mb={3} color="success200" fontWeight={500}>
        Active Policy
      </Text>
      {activeMinPayPolicy ? (
        <PolicyRow policy={activeMinPayPolicy} isLast={false} />
      ) : (
        <Text mb={3}>
          No custom policy set; defaulting to full shift payment.
        </Text>
      )}

      {upcomingMinPayPolicy.length > 0 ? (
        <>
          <Text mb={3} fontWeight={500}>
            Upcoming Policies
          </Text>
          {upcomingMinPayPolicy}
        </>
      ) : null}
    </AccordionCard>
  )
}
