import React from 'react'
import { useShiftDetailContextValue } from '../../context/ShiftDetailProvider'
import { Header } from 'components/Header'
import { Flex, DropdownMenu, Button } from '@workwhile/ui'
import { UserPlus, Ellipsis, ExternalLink } from 'lucide-react'
import { DynamicPageTitle } from './DynamicPageTitle'
import { QueryStatus } from 'components/QueryStatus'
import { useShiftDetailWorkflowsContextValue } from '../../context/ShiftDetailWorkflowsProvider'
import { useNavigate } from 'react-router-dom'
export const ShiftDetailHeader = () => {
  const { state, actions } = useShiftDetailContextValue()
  const { assignShiftFlow } = useShiftDetailWorkflowsContextValue()
  const navigate = useNavigate()

  return (
    <Header
      title={<DynamicPageTitle />}
      subTitle="Shift Detail"
      leftContent={
        state.company.logoUrl ? (
          <img
            src={state.company.logoUrl}
            alt={state.company.name}
            width={50}
            height={50}
            style={{ objectFit: 'contain' }}
          />
        ) : undefined
      }
      rightContent={
        <Flex alignItems="center" gap={2}>
          <QueryStatus
            dataUpdatedAt={state.dataUpdatedAt}
            isFetching={state.isFetching}
            refetch={actions.refetch}
            isError={state.isError}
            error={state.error}
          />
          <DropdownMenu
            trigger={
              <Button variant="secondary" style={{ padding: 4 }} kind="medium">
                <Ellipsis />
              </Button>
            }
          >
            <DropdownMenu.Item>
              <Flex
                as="span"
                alignItems="center"
                style={{ gap: 8 }}
                onClick={() => {
                  assignShiftFlow.open({ shiftId: state.id })
                }}
              >
                <UserPlus size={14} />
                Assign shift
              </Flex>
            </DropdownMenu.Item>
            <DropdownMenu.Item>
              <Flex
                as="span"
                alignItems="center"
                style={{ gap: 8 }}
                onClick={() => {
                  window.open(
                    `/company/switch/${state.company.id}?redirectUrl=https://business.workwhilejobs.com/shift/${state.id}`,
                    '_blank'
                  )
                }}
              >
                <ExternalLink size={14} />
                View on biz portal
              </Flex>
            </DropdownMenu.Item>
          </DropdownMenu>
        </Flex>
      }
    />
  )
}
