import { addDays } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { getLocalTimezone } from 'lib/time'
import { useQueryStates, parseAsStringEnum, createParser } from 'nuqs'
import { useCallback, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

function compareDates(a: Date, b: Date) {
  return a.valueOf() === b.valueOf()
}

export const parseAsZonedDate = createParser({
  parse: (v) => {
    const date = new Date(v.slice(0, 10))

    if (Number.isNaN(date.valueOf())) {
      return null
    }
    return toZonedTime(v, getLocalTimezone())
  },
  serialize: (v: Date) =>
    toZonedTime(v, getLocalTimezone()).toISOString().slice(0, 10),
  eq: compareDates,
})

export const useTimeRangeQueryState = () => {
  const isFirstRender = useRef(true)

  useEffect(() => {
    isFirstRender.current = false
  }, [])

  const [state, setState] = useQueryStates(
    {
      startDate: parseAsZonedDate,
      endDate: parseAsZonedDate,
      type: parseAsStringEnum([
        'past',
        'ongoing',
        'upcoming',
      ] as const).withDefault(undefined),
    },
    {
      urlKeys: {
        startDate: 'shifts-start-date',
        endDate: 'shifts-end-date',
        type: 'shifts-range-type',
      },
    }
  )
  const set = useCallback(
    (value: {
      startDate: Date
      endDate: Date
      type?: 'past' | 'ongoing' | 'upcoming'
    }) =>
      setState({
        startDate: value?.startDate ?? null,
        endDate: value?.endDate ?? null,
        type: value.type ?? null,
      }),
    [setState]
  )

  const queryState = {
    startDate: state.startDate,
    endDate: state.endDate,
    type: state.type === null ? undefined : state.type,
  }

  return [queryState, set] as const
}
