import { createContext } from 'lib/createContext'
import React, { PropsWithChildren, useState } from 'react'

type Workflow = {
  open: () => void
  close: () => void
  isOpen: boolean
}

export type CompanyDetailWorkflowsContextState = {
  editCompanyNameFlow: Workflow
  addCancellationPolicyFlow: Workflow
  addMinimumPayPolicyFlow: Workflow
  editMoneyFlow: Workflow
  inviteUserToCompanyFlow: Workflow
  manageLogoFlow: Workflow
  editGeneralDetailsFlow: Workflow
}

const [CompanyDetailWorkflowsContext, useCompanyDetailWorkflowsContextValue] =
  createContext<CompanyDetailWorkflowsContextState>({
    name: 'CompanyDetailWorkflowsContext',
  })

type CompanyDetailWorkflowsProviderProps = PropsWithChildren

/**
 * Provides the state for the company detail workflows such as the edit company name, etc.
 */
export const CompanyDetailWorkflowsProvider = ({
  children,
}: CompanyDetailWorkflowsProviderProps) => {
  const [isEditCompanyNameFlowOpen, setIsEditCompanyNameFlowOpen] =
    useState(false)

  const [isAddCancellationPolicyFlowOpen, setIsAddCancellationPolicyFlowOpen] =
    useState(false)

  const [isAddMinimumPayPolicyFlowOpen, setIsAddMinimumPayPolicyFlowOpen] =
    useState(false)

  const [isEditMoneyFlowOpen, setIsEditMoneyFlowOpen] = useState(false)

  const [isInviteUserToCompanyFlowOpen, setIsInviteUserToCompanyFlowOpen] =
    useState(false)

  const [isManageLogoFlowOpen, setIsManageLogoFlowOpen] = useState(false)

  const [isEditGeneralDetailsFlowOpen, setIsEditGeneralDetailsFlowOpen] =
    useState(false)

  const value: CompanyDetailWorkflowsContextState = {
    editCompanyNameFlow: {
      open: () => setIsEditCompanyNameFlowOpen(true),
      close: () => setIsEditCompanyNameFlowOpen(false),
      isOpen: isEditCompanyNameFlowOpen,
    },
    addCancellationPolicyFlow: {
      open: () => setIsAddCancellationPolicyFlowOpen(true),
      close: () => setIsAddCancellationPolicyFlowOpen(false),
      isOpen: isAddCancellationPolicyFlowOpen,
    },
    addMinimumPayPolicyFlow: {
      open: () => setIsAddMinimumPayPolicyFlowOpen(true),
      close: () => setIsAddMinimumPayPolicyFlowOpen(false),
      isOpen: isAddMinimumPayPolicyFlowOpen,
    },
    editMoneyFlow: {
      open: () => setIsEditMoneyFlowOpen(true),
      close: () => setIsEditMoneyFlowOpen(false),
      isOpen: isEditMoneyFlowOpen,
    },
    inviteUserToCompanyFlow: {
      open: () => setIsInviteUserToCompanyFlowOpen(true),
      close: () => setIsInviteUserToCompanyFlowOpen(false),
      isOpen: isInviteUserToCompanyFlowOpen,
    },
    manageLogoFlow: {
      open: () => setIsManageLogoFlowOpen(true),
      close: () => setIsManageLogoFlowOpen(false),
      isOpen: isManageLogoFlowOpen,
    },
    editGeneralDetailsFlow: {
      open: () => setIsEditGeneralDetailsFlowOpen(true),
      close: () => setIsEditGeneralDetailsFlowOpen(false),
      isOpen: isEditGeneralDetailsFlowOpen,
    },
  }

  return (
    <CompanyDetailWorkflowsContext.Provider value={value}>
      {children}
    </CompanyDetailWorkflowsContext.Provider>
  )
}

export { useCompanyDetailWorkflowsContextValue }
