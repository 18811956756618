import { Work } from '../types'

const removedReasonMap: Record<Work['removedReason'], string> = {
  admin: 'Removed by admin',
  background_check_did_not_clear: 'Background check did not clear',
  background_check_pending_too_long: 'Background check pending for too long',
  callout_approved: 'Callout approved',
  demo_account: 'Demo account',
  employer_blocked: 'Blocked by the customer',
  penalty: 'Blocked by penalty policy',
  replace_unconfirmed: 'Worker did not confirm',
  reverse_payment_transfer: 'Removed as a result of payment reversal',
  roster_removal: 'User was removed from a roster',
  shadow_blocked: 'Shadow blocked',
  shift_time_change_schedule_conflict:
    'Shift time changed, user had a schedule conflict',
  sub_company_reassign: 'Sub company reassign',
  w2_failed_tin_verification: 'Failed Everee TIN verification',
  w2_inactive: 'Worker was offboarded from W2',
}

export const getRemovedReason = (removedReason: Work['removedReason']) => {
  return removedReasonMap[removedReason] || removedReason.replace('_', ' ')
}
