import { useMutation, useQueryClient } from '@tanstack/react-query'
import { assignShift } from 'api/shifts/assignShift'
import { shiftDetailKey, shiftsKey } from './keys'

export const useAssignShiftMutation = (shiftId: number) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: assignShift,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: shiftDetailKey(shiftId) })
      queryClient.invalidateQueries({ queryKey: shiftsKey })
    },
  })
}
