import api from 'api'

export type CommandFieldSchema = {
  name: string
  required?: boolean
  type: 'number' | 'checkbox' | 'text' | 'select' | 'datetime'
  multiple?: boolean
  label: string
  help?: string
  choices?: string[]
}

export type CommandSchema = {
  app_group: string
  help: string
  name: string
  args: Pick<CommandFieldSchema, 'label' | 'name' | 'required' | 'type'>[]
  options: CommandFieldSchema[]
}

export async function getCommands() {
  const response = await api.get('/admin/commands')
  return response.data as CommandSchema[]
}
