import React, { PropsWithChildren, useState } from 'react'
import { createContext } from 'lib/createContext'
import { parseErrorMessage } from 'api'
import { useAssignShiftMutation } from 'queries/shifts/useAssignShiftMutation'
import { useShiftDetailContextValue } from 'pages/Shifts/ShiftDetail/context/ShiftDetailProvider'
import { AssignShiftFlowSchema } from '../schema/assignShift'

type AssignShiftFlowProviderContextState = {
  actions: {
    submitFormToServer: ReturnType<typeof useAssignShiftMutation>['mutate']
    setFormData: (data: AssignShiftFlowSchema) => void
  }
  state: {
    shiftId: number
    formData: AssignShiftFlowSchema
    mutationState: {
      isPending: boolean
      isError: boolean
      errorMessage: string | undefined
    }
  }
}

const [Context, useAssignShiftFlowContextValue] =
  createContext<AssignShiftFlowProviderContextState>({
    name: 'AssignShiftFlowContext',
  })

export const AssignShiftFlowProvider = ({ children }: PropsWithChildren) => {
  const {
    state: { id: shiftId },
  } = useShiftDetailContextValue()

  const [formData, setFormData] = useState<AssignShiftFlowSchema>({
    workerIdInput: undefined,
    workersToAssign: [],
  })

  const { mutate, isPending, isError, error } = useAssignShiftMutation(shiftId)

  const value: AssignShiftFlowProviderContextState = {
    actions: {
      submitFormToServer: mutate,
      setFormData,
    },
    state: {
      shiftId,
      formData,
      mutationState: {
        isPending,
        isError,
        errorMessage: parseErrorMessage(error),
      },
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useAssignShiftFlowContextValue }
