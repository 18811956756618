import React, { PropsWithChildren, useState } from 'react'

import * as zod from 'zod'
import { inviteUserToCompanyFlowSchema } from '../schemas/inviteUserToCompanyFlowSchema'
import { createContext } from 'lib/createContext'
import { useCompanyDetailContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailProvider'
import { parseErrorMessage } from 'api'
import { useInviteUserToCompanyMutation } from 'queries/company'

type InviteUserToCompanyFlowProviderContextState = {
  actions: {
    submitFormToServer: ReturnType<
      typeof useInviteUserToCompanyMutation
    >['mutate']
    setFormData: (data: zod.infer<typeof inviteUserToCompanyFlowSchema>) => void
  }
  state: {
    formData: zod.infer<typeof inviteUserToCompanyFlowSchema>
    mutationState: {
      isPending: boolean
      isError: boolean
      errorMessage: string | undefined
    }
  }
}

const [Context, useInviteUserToCompanyFlowContextValue] =
  createContext<InviteUserToCompanyFlowProviderContextState>({
    name: 'InviteUserToCompanyFlowContext',
  })

export const InviteUserToCompanyFlowProvider = ({
  children,
}: PropsWithChildren) => {
  const [inviteUserToCompanyFormData, setInviteUserToCompanyFormData] =
    useState<zod.infer<typeof inviteUserToCompanyFlowSchema>>({})

  const {
    state: { id: companyId },
  } = useCompanyDetailContextValue()

  const { mutate, isPending, isError, error } =
    useInviteUserToCompanyMutation(companyId)

  const value: InviteUserToCompanyFlowProviderContextState = {
    actions: {
      submitFormToServer: mutate,
      setFormData: (data: zod.infer<typeof inviteUserToCompanyFlowSchema>) => {
        setInviteUserToCompanyFormData((prev) => ({
          ...prev,
          ...data,
        }))
      },
    },
    state: {
      formData: inviteUserToCompanyFormData,
      mutationState: {
        isPending,
        isError,
        errorMessage: parseErrorMessage(error),
      },
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useInviteUserToCompanyFlowContextValue }
