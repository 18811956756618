import { createContext as createReactContext, useContext } from 'react'

export interface CreateContextOptions<T> {
  name: string
  strict?: boolean
  defaultValue?: T
}

const getErrorMessage = (contextName: string) => {
  return `${contextName} error: hook returned \`undefined\`. Did you forget to wrap your components with provider?`
}

export function createContext<T>({
  name,
  defaultValue,
  strict = true,
}: CreateContextOptions<T>) {
  const Context = createReactContext<T>(defaultValue as T)
  Context.displayName = name

  function useContextHook() {
    const context = useContext(Context)
    if (!context && strict) {
      const error = new Error(getErrorMessage(name))
      error.name = 'ContextError'
      Error.captureStackTrace?.(error, useContextHook)
      throw error
    }

    return context
  }

  return [Context, useContextHook] as const
}
