import React from 'react'
import {
  createBrowserRouter,
  Route,
  useLocation,
  ScrollRestoration,
} from 'react-router-dom'
import {
  createRoutesFromElements,
  Navigate,
  Outlet,
  RouterProvider,
  Routes,
} from 'react-router'
import * as ROUTES from 'constants/routes'
import Users from 'components/Users/Users'
import LandingPage from 'components/Landing'
import PasswordForgetPage from 'components/PasswordForget'
import UpcomingShifts from 'components/Shifts/upcoming_shifts'
import SingleShift from 'components/Shifts/single_shift'
import OngoingShifts from 'components/Shifts/ongoing_shifts'
import PastShifts from 'components/Shifts/past_shifts'
import Payments from 'components/features/payments'
import AccountPage from 'components/Account'
import AdminPage from 'components/Admin'
import Listings from 'components/features/listing'
import Logs from 'components/features/logs'
import Company from 'components/features/Company/company'
import BusinessLeads from 'components/features/business_leads'
import WorkerBusinessReferral from 'components/features/WorkerBusinessReferral'
import Review from 'components/Review/Review'
import { ReviewPage } from 'pages/review'
import Messaging2 from 'components/messaging2/messaging'
import Positions from 'components/features/positions'
import QuestDefinitions from 'components/features/quest_definitions'
import TipsContainer from 'components/Tips/TipsContainer'

import Spreadsheets from 'components/features/Spreadsheets'
import ShiftAnalytics from 'components/features/ShiftAnalytics'
import SidebarNav from 'components/Navigation/SidebarNav'
import 'bootstrap/dist/css/bootstrap.min.css'
import { AuthGuard } from 'components/auth'
import { NotFound } from 'pages/NotFound'
import { LTAPage } from './lta'
import UpcomingListings from './UpcomingListings'
import { EVerifyPage } from './e-verify'
import { CallRoom } from './e-verify/CallRoom'
import UnprocessedI9FormsPage from './e-verify/processing'
import WorkerSupply from 'components/features/WorkerSupply/WorkerSupply'
import ShiftSearch from 'components/Shifts/Search'
import LegacyShiftDetail from 'components/Shifts/Detail'
import VerifyCredentials from 'pages/VerifyCredentials/VerifyCredentials'
import { CompanySwitch } from './Company/CompanySwitch/Switch'
import { I9Dashboard } from './I9Dashboard/I9Dashboard'
import { PositionEditor } from 'components/PositionEditor'
import { CompanyDetail } from './Company/CompanyDetail/CompanyDetail'
import { CompanyGroupDetail } from './CompanyGroupDetail'
import { ShiftDetail } from './Shifts/ShiftDetail/ShiftDetail'
import { CommandPanel } from 'components/CommandPanel'
import CommandsPage from './Commands/CommandsPage'
import { CommandProvider } from 'components/features/Commands/CommandProvider'
import { GlobalWorkflows } from '../global-workflows'
import { CompanyGroups } from './CompanyGroups'
import { Companies } from './Companies'
import { Shifts } from './Shifts/Shifts'

const PageRoutes = () => {
  const location = useLocation()
  const backgroundStack =
    (location.state && location.state.backgroundStack) || []

  return (
    <CommandProvider>
      <div style={{ display: 'flex', height: '100vh' }}>
        <SidebarNav />
        <main
          style={{ overflowY: 'auto', flex: 1, paddingBottom: '80px' }}
          className="main-container"
        >
          <ScrollRestoration />
          <Routes
            location={
              backgroundStack.length > 0 ? backgroundStack[0] : location
            }
          >
            <Route
              path={ROUTES.LANDING}
              element={
                <AuthGuard.Authenticated
                  fallback={<Navigate to={ROUTES.SIGNIN} />}
                >
                  <Users />
                </AuthGuard.Authenticated>
              }
            />
            <Route path={ROUTES.SIGNIN} element={<LandingPage />} />
            <Route
              path={ROUTES.PASSWORD_FORGET}
              element={<PasswordForgetPage />}
            />
            <Route
              path={'/'}
              element={
                <AuthGuard.Authenticated
                  fallback={<Navigate to={ROUTES.SIGNIN} />}
                >
                  <GlobalWorkflows>
                    <Outlet />
                  </GlobalWorkflows>
                </AuthGuard.Authenticated>
              }
            >
              <Route path={ROUTES.USERS} element={<Users />} />
              <Route
                path={ROUTES.UPCOMING_SHIFTS}
                element={<UpcomingShifts />}
              />
              <Route path={ROUTES.SHIFTS} element={<Shifts />} />
              <Route
                path={ROUTES.SHIFT_SEARCH_LEGACY}
                element={<ShiftSearch />}
              />
              <Route
                path={ROUTES.SHIFT_DETAIL_LEGACY}
                element={<LegacyShiftDetail />}
              />
              <Route path={ROUTES.SHIFT_DETAIL} element={<ShiftDetail />} />
              <Route path={ROUTES.SINGLE_SHIFT} element={<SingleShift />} />
              <Route path={ROUTES.ONGOING_SHIFTS} element={<OngoingShifts />} />
              <Route path={ROUTES.PAST_SHIFTS} element={<PastShifts />} />
              <Route path={ROUTES.PAYMENTS} element={<Payments />} />
              <Route path={ROUTES.ACCOUNT} element={<AccountPage />} />
              <Route path={ROUTES.ADMIN} element={<AdminPage />} />
              <Route path={ROUTES.LISTINGS} element={<Listings />} />
              <Route path={ROUTES.LOGS} element={<Logs />} />
              <Route path={ROUTES.COMPANY} element={<Company />} />
              <Route path={ROUTES.COMPANIES} element={<Companies />} />
              <Route path={ROUTES.COMPANY_DETAIL} element={<CompanyDetail />} />
              <Route path={ROUTES.COMPANY_SWITCH} element={<CompanySwitch />} />
              <Route path={ROUTES.COMPANY_GROUPS} element={<CompanyGroups />} />
              <Route
                path={ROUTES.COMPANY_GROUP_DETAIL}
                element={<CompanyGroupDetail />}
              />
              <Route path={ROUTES.BIZLEADS} element={<BusinessLeads />} />
              <Route
                path={ROUTES.WORKERBIZREFERRALS}
                element={<WorkerBusinessReferral />}
              />
              <Route path={ROUTES.REVIEW} element={<Review />} />
              <Route path={ROUTES.REVIEW2} element={<ReviewPage />} />
              <Route path={ROUTES.MESSAGES2} element={<Messaging2 />} />
              <Route path={ROUTES.POSITIONS} element={<Positions />} />
              <Route
                path={ROUTES.QUEST_DEFINITIONS}
                element={<QuestDefinitions />}
              />
              <Route path={ROUTES.TIPS} element={<TipsContainer />} />
              <Route path={ROUTES.COMMANDS} element={<CommandsPage />} />
              <Route
                path={ROUTES.POSITION_EDITOR}
                element={<PositionEditor />}
              />
              <Route path={ROUTES.SPREADSHEETS} element={<Spreadsheets />} />
              <Route
                path={ROUTES.SHIFT_ANALYTICS}
                element={<ShiftAnalytics />}
              />
              <Route path={ROUTES.LTA} element={<LTAPage />} />
              <Route path={ROUTES.EVERIFY} element={<EVerifyPage />} />
              <Route
                path={`${ROUTES.EVERIFY}/:callId`}
                element={<CallRoom />}
              />
              <Route
                path={ROUTES.EVERIFY_UNPROCESS_FORMS}
                element={<UnprocessedI9FormsPage />}
              />
              <Route path={'*'} element={<NotFound />} />
              <Route path="/upcoming-listings" element={<UpcomingListings />} />
              <Route path={ROUTES.WORKER_SUPPLY} element={<WorkerSupply />} />
              <Route
                path={ROUTES.VERIFY_CREDENTIALS}
                element={<VerifyCredentials />}
              />
              <Route path={ROUTES.I9_DASHBOARD} element={<I9Dashboard />} />
            </Route>
          </Routes>
        </main>
        <CommandPanel />
      </div>
    </CommandProvider>
  )
}

const router = createBrowserRouter(
  createRoutesFromElements(<Route path={'*'} Component={PageRoutes} />)
)

export function Pages() {
  return (
    <RouterProvider router={router} future={{ v7_startTransition: true }} />
  )
}
