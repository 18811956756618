import { useQuery } from '@tanstack/react-query'
import { companyGroupsKey } from './keys'
import { getCompanyGroups } from 'api/companyGroup/getCompanyGroups'

export const useCompanyGroupsQuery = () => {
  return useQuery({
    queryKey: companyGroupsKey,
    queryFn: getCompanyGroups,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    // 5 hours of stale time since the data is not expected to change often
    staleTime: 5 * 60 * 60 * 1000,
  })
}
