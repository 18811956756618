import api from 'api'

type AddCancellationPolicyWindow = {
  cutoff_time: number
  charge_percentage: number
}

type AddCancellationPolicyRequest = {
  company_id: number
  starts_at: string
  policy_windows: AddCancellationPolicyWindow[]
}

export const addCancellationPolicy = async (
  request: AddCancellationPolicyRequest
): Promise<void> => {
  return api.post(`/admin/company/${request.company_id}/cancellation_policy`, {
    starts_at: request.starts_at,
    policy_windows: request.policy_windows,
  })
}
