import React from 'react'
import moment from 'moment'
import { toast } from '@workwhile/ui'

export function showErrorToast(text) {
  toast.error(text, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 10000,
    draggable: true,
  })
}

export function getDateTime(day, time) {
  // convert month to 1-12 instead of 0-11
  const hour = time.split(':')[0]
  const min = time.split(':')[1]
  const newObj = moment(day)
  return newObj
    .year(day.year())
    .month(day.month())
    .date(day.date())
    .hour(hour)
    .minute(min)
    .seconds(0)
}

export function parseEndTime(endTime, startTime, day) {
  if (
    moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'), 'minutes') < 0
  ) {
    const overnight = day.add(1, 'days')
    return getDateTime(overnight, endTime)
  }
  return getDateTime(day, endTime)
}

export const FILLED_STATUSES = [
  'scheduled',
  'started',
  'completed',
  'approved',
  'employer_review',
  'employer_approved',
  'paid',
  'admin_review',
]

export const COMPLETED_STATUSES = [
  'completed',
  'approved',
  'employer_review',
  'employer_approved',
  'paid',
]

export const parseStringifiedObjects = (set) => {
  return [...set].map((item) => {
    if (typeof item === 'string') return JSON.parse(item)
    else if (typeof item === 'object') return item
  })
}

export const formatCompanyOptionsForSelect = (shifts) => {
  if (!shifts) return null
  let companies = new Set()
  for (const shift of shifts) {
    const formattedCompany = {
      value: parseInt(shift.company.id),
      label: shift.company.name,
    }
    if (companies.has(formattedCompany)) console.log('already in set!')
    companies.add(JSON.stringify(formattedCompany))
  }
  const formattedSet = parseStringifiedObjects(companies)
  return Array.from(formattedSet)
}

export const formatPositionOptionsForSelect = (shifts) => {
  if (!shifts) return null
  let positions = new Set()
  for (const shift of shifts) {
    const formattedPosition = {
      value: parseInt(shift.position.id),
      label: shift.position.name,
    }
    positions.add(JSON.stringify(formattedPosition))
  }
  const formattedSet = parseStringifiedObjects(positions)
  return Array.from(formattedSet)
}

export const formatLocationOptionsForSelect = (shifts) => {
  if (!shifts) return null
  let locations = new Set()
  for (const shift of shifts) {
    const formattedLocation = {
      value: parseInt(shift.location.id),
      label: shift.location.name,
      obj: shift.location,
    }
    locations.add(JSON.stringify(formattedLocation))
  }
  const formattedSet = parseStringifiedObjects(locations)
  return Array.from(formattedSet)
}

export const formatMarketOptionsForSelect = (shifts) => {
  if (!shifts) return null
  let markets = new Set()
  for (const shift of shifts) {
    markets.add(shift.market)
  }
  const toReturn = []
  markets.forEach((market) => {
    toReturn.push({ value: market, label: market })
  })
  return toReturn
}

export const formatWorkerOptionsForSelect = (shifts) => {
  if (!shifts) return null
  let workers = new Set()
  for (const shift of shifts) {
    for (const work of shift.work) {
      const formattedWorkers = {
        value: parseInt(work.worker.id),
        label: work.worker.name,
      }
      workers.add(JSON.stringify(formattedWorkers))
    }
  }
  const formattedSet = parseStringifiedObjects(workers)
  return Array.from(formattedSet)
}

export const formatWorkerIdOptionsForSelect = (shifts) => {
  if (!shifts) return null
  let workers = new Set()
  for (const shift of shifts) {
    for (const work of shift.work) {
      const formattedWorkers = {
        value: parseInt(work.worker.id),
        label: work.worker.id,
      }
      workers.add(JSON.stringify(formattedWorkers))
    }
  }
  const formattedSet = parseStringifiedObjects(workers)
  return Array.from(formattedSet)
}

export const formatShiftOptionsForSelect = (shifts) => {
  if (!shifts) return null
  let shiftSet = new Set()
  for (const shift of shifts) {
    const formattedShifts = { value: parseInt(shift.id), label: shift.id }
    shiftSet.add(JSON.stringify(formattedShifts))
  }
  const formattedSet = parseStringifiedObjects(shiftSet)
  return Array.from(formattedSet)
}

export const formatListingOptionsForSelect = (shifts) => {
  if (!shifts) return null
  let listings = new Set()
  for (const shift of shifts) {
    if (!shift.listings) continue
    for (const listing of shift.listings) {
      const formattedListing = {
        value: parseInt(listing.id),
        label: listing.id,
      }
      listings.add(JSON.stringify(formattedListing))
    }
  }
  const formattedSet = parseStringifiedObjects(listings)
  return Array.from(formattedSet)
}

export const formatStatusOptionsForSelect = () => {
  const statuses = [
    'scheduled',
    'rejected',
    'started',
    'bailed',
    'completed',
    'approved',
    'offered',
    'paid',
    'removed',
    'admin_review',
    'employer_review',
    'employer_approved',
    'employer_cancelled',
  ]

  const formattedStatuses = statuses.map((status) => {
    return { value: status, label: status }
  })

  return Array.from(formattedStatuses)
}

export const FILTERS = {
  company: [],
  location: [],
  position: [],
  worker: [],
  workerId: [],
  shiftId: [],
  listingId: [],
  status: [],
  fromDate: '',
  toDate: '',
  showCancelledShifts: false,
  showUnfilledShifts: false,
}

// Take in shifts and a map of filters, return shifts which meet those filters.
export function filterShifts(shifts, filters) {
  console.log('filters: ', filters)
  if (filtersAreEmpty(filters)) return shifts
  let filteredShifts = []
  for (const shift of shifts) {
    if (matchesFilters(filters, shift)) filteredShifts.push(shift)
  }
  return filteredShifts
}

export const filtersAreEmpty = (filters) => {
  // if a filter exists that is not empty, return false. Otherwise, return true.
  if (filters && (filters.showCancelledShifts || filters.showUnfilledShifts)) {
    return false
  }
  const arrays = Object.values(filters)
  for (let i = 0; i < arrays.length; i += 1) {
    const filter = arrays[i]
    if (filter && filter.length > 0) return false
  }
  return true
}

export const matchesFilters = (filters, shift) => {
  let [
    matchCompany,
    matchLocation,
    matchPosition,
    matchWorker,
    matchWorkerId,
    matchShiftId,
    matchListingId,
    matchFromDate,
    matchToDate,
    matchCancelled,
    matchMarket,
    matchUnfilled,
    matchStatus,
  ] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]
  const { company, location, position, startsAt } = shift
  const workers = shift.work

  const cancelledFilter = filters.showCancelledShifts
  if (cancelledFilter) {
    matchCancelled = shift.cancelledAt !== null ? true : false
  } else {
    matchCancelled = true
  }

  const unfilledFilter = filters.showUnfilledShifts
  if (unfilledFilter) {
    const filledspots =
      shift.numWorkersScheduled +
      shift.numWorkersStarted +
      shift.numWorkersFinished
    //Currently, if there are more workers needed than shceduled, then it's a match. We filter out "cancelled" shifts.
    matchUnfilled =
      shift.workersNeeded > filledspots && shift.cancelledAt == null
        ? true
        : false
  } else {
    matchUnfilled = true
  }

  const companyFilter = filters.company
  if (companyFilter && companyFilter.length > 0) {
    const comapnyForComparison = {
      value: parseInt(company.id),
      label: company.name,
    }
    for (let i = 0; i < companyFilter.length; i += 1) {
      if (companyFilter[i].value === comapnyForComparison.value)
        matchCompany = true
    }
  } else {
    matchCompany = true
  }

  // if shift.location is in the location filter return true;
  const locationFilter = filters.location
  if (locationFilter && locationFilter.length > 0) {
    // special case for locationless shifts
    const locationForComparison = !location
      ? { value: null, label: 'Locationless' }
      : { value: parseInt(location.id), label: location.name }
    for (let i = 0; i < locationFilter.length; i += 1) {
      if (locationFilter[i].value === locationForComparison.value)
        matchLocation = true
    }
  } else {
    matchLocation = true
  }

  // if shift.position is in the position filter return true;
  const positionFilter = filters.position
  if (positionFilter && positionFilter.length > 0) {
    for (let j = 0; j < positionFilter.length; j += 1) {
      if (positionFilter[j].value === parseInt(position.id))
        matchPosition = true
    }
  } else {
    matchPosition = true
  }

  // if one of the workers in shift.work is in the workers filter return true;
  const workerFilter = filters.worker
  if (workerFilter && workerFilter.length > 0) {
    for (let k = 0; k < workers.length; k += 1) {
      const workerForComparison = workers[k].worker
      for (let l = 0; l < workerFilter.length; l += 1) {
        const worker = workerFilter[l]
        if (worker.value === parseInt(workerForComparison.id))
          matchWorker = true
      }
    }
  } else {
    matchWorker = true
  }

  const workerIdFilter = filters.workerId
  // if one of the workers in shift.work is in the workerId filter return true;
  if (workerIdFilter && workerIdFilter.length > 0) {
    for (let k = 0; k < workers.length; k += 1) {
      const workerForComparison = workers[k].worker
      for (let l = 0; l < workerIdFilter.length; l += 1) {
        const worker = workerIdFilter[l]
        if (worker.value === parseInt(workerForComparison.id))
          matchWorkerId = true
      }
    }
  } else {
    matchWorkerId = true
  }

  const shiftIdFilter = filters.shiftId
  if (shiftIdFilter && shiftIdFilter.length > 0) {
    for (let i = 0; i < shiftIdFilter.length; i += 1) {
      if (shiftIdFilter[i].value === parseInt(shift.id)) matchShiftId = true
    }
  } else {
    matchShiftId = true
  }

  const listingIdFilter = filters.listingId
  if (listingIdFilter && listingIdFilter.length > 0) {
    for (let i = 0; i < listingIdFilter.length; i += 1) {
      for (const listing of shift.listings) {
        if (listingIdFilter[i].value === parseInt(listing.id))
          matchListingId = true
      }
    }
  } else {
    matchListingId = true
  }

  const fromDateFilter = filters.fromDate
  if (fromDateFilter && fromDateFilter !== '') {
    if (
      moment(fromDateFilter).format('MM-DD-YYYY') <=
      moment(startsAt).local().format('MM-DD-YYYY')
    )
      matchFromDate = true
  } else {
    matchFromDate = true
  }

  const toDateFilter = filters.toDate
  if (toDateFilter && toDateFilter !== '') {
    if (
      moment(startsAt).local().format('MM-DD-YYYY') <=
      moment(toDateFilter).format('MM-DD-YYYY')
    )
      matchToDate = true
  } else {
    matchToDate = true
  }

  const marketFilter = filters.market
  if (marketFilter && marketFilter.length > 0) {
    for (let i = 0; i < marketFilter.length; i += 1) {
      if (marketFilter[i].value === shift.market) matchMarket = true
    }
  } else {
    matchMarket = true
  }

  const statusFilter = filters.status
  if (statusFilter && statusFilter.length > 0) {
    console.log(statusFilter)
    console.log(shift.work)
    for (const work of shift.work) {
      for (const filterStatus of statusFilter)
        if (filterStatus.value === work.status) {
          matchStatus = true
          break
        }
      if (matchStatus) break
    }
  } else {
    matchStatus = true
  }

  if (
    matchCompany &&
    matchLocation &&
    matchPosition &&
    matchWorker &&
    matchWorkerId &&
    matchShiftId &&
    matchListingId &&
    matchFromDate &&
    matchToDate &&
    matchCancelled &&
    matchMarket &&
    matchUnfilled &&
    matchStatus
  )
    return true

  return false
}

export function filterShiftsLocally(
  shifts,
  showCancelledShifts,
  showUnfilledShifts
) {
  let filteredShifts = []
  for (const shift of shifts) {
    if (localFilterMatch(showCancelledShifts, showUnfilledShifts, shift))
      filteredShifts.push(shift)
  }
  return filteredShifts
}

const localFilterMatch = (showCancelledShifts, showUnfilledShifts, shift) => {
  let [
    matchCompany,
    matchLocation,
    matchPosition,
    matchWorker,
    matchWorkerId,
    matchShiftId,
    matchListingId,
    matchFromDate,
    matchToDate,
    matchCancelled,
    matchMarket,
    matchUnfilled,
    matchStatus,
  ] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]
  const { company, location, position, startsAt } = shift
  const workers = shift.work

  if (showCancelledShifts) {
    matchCancelled = shift.cancelledAt !== null ? true : false
  } else {
    matchCancelled = true
  }

  if (showUnfilledShifts) {
    const filledspots =
      shift.numWorkersScheduled +
      shift.numWorkersStarted +
      shift.numWorkersFinished
    //Currently, if there are more workers needed than shceduled, then it's a match. We filter out "cancelled" shifts.
    matchUnfilled =
      shift.workersNeeded > filledspots && shift.cancelledAt == null
        ? true
        : false
  } else {
    matchUnfilled = true
  }
  if (matchCancelled && matchUnfilled) return true

  return false
}

export function shouldShowOvernightBanner(startTime, endTime) {
  if (startTime && endTime) {
    const momentStartDate = startTime && moment(startTime).format('DD')
    const momentEndDate = endTime && moment(endTime).format('DD')
    if (momentEndDate > momentStartDate) return true
  }
  return false
}

export const getRowStyles = (row, rowIndex) => {
  let bgColor = '#ffffff'

  if (
    row.cancelledAt == null &&
    Number(row.workersNeeded) > Number(row.numWorkersFilled)
  )
    bgColor = '#f08080'

  return { backgroundColor: bgColor, fontSize: 12 }
}

export const formatShiftsForSelect = (shifts) => {
  if (!shifts) return null
  const shiftsArr = shifts.map((shift) => ({
    value: parseInt(shift.id),
    label: `${shift.position.name}, ${shift.location.name}, ${moment(shift.startsAt).format('ddd MM, hh:mm')}`,
    obj: shift,
  }))
  return shiftsArr
}

export const stripHtml = (html) => {
  let tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}
