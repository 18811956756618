import React from 'react'
import { Box, Button, Flex, Heading, Text } from '@workwhile/ui'
import { PositionTemplate } from 'api/company'
import { usePositionTemplateContext } from './PositionTemplateContext'

export function TemplateItem({ template }: { template: PositionTemplate }) {
  const { selectedTemplateId, setSelectedTemplateId } =
    usePositionTemplateContext()
  const isSelected = selectedTemplateId === template.id

  const onSelectClick = () => {
    if (template.id) {
      setSelectedTemplateId(template.id)
    }
  }

  return (
    <Box py={3} borderBottom={'1px solid'} borderColor={'offWhite'}>
      <Flex alignItems={'center'}>
        <Box flex={1}>
          <Heading level={4} my={0}>
            {template.name}
          </Heading>
          <Text fontSize={1} color={'lightText'}>
            {template.relevantPositionTemplateWorkerCategories
              .map((category) => category.name)
              .join(', ')}
          </Text>
          {/* TODO: Add description */}
          {/* <Text color={'lightText'}>Description goes here</Text> */}
        </Box>
        <Box>
          {isSelected ? (
            <Flex alignItems={'center'}>
              <Text color={'primary'} fontWeight={'bold'} mr={2}>
                Selected
              </Text>
            </Flex>
          ) : (
            <Button
              variant={'secondary'}
              kind={'medium'}
              onClick={onSelectClick}
            >
              Select
            </Button>
          )}
        </Box>
      </Flex>
    </Box>
  )
}
