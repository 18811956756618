import React, { PropsWithChildren, useState } from 'react'
import * as zod from 'zod'
import { createContext } from 'lib/createContext'
import { useCompanyDetailContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailProvider'
import { parseErrorMessage } from 'api'
import { editMoneyFlowSchema } from '../schemas/editMoneyFlow'
import { useEditCompanyMoneyMutation } from 'queries/company/useEditCompanyMoneyMutation'

type EditMoneyFlowProviderContextState = {
  actions: {
    submitFormToServer: ReturnType<typeof useEditCompanyMoneyMutation>['mutate']
    setFormData: (data: zod.infer<typeof editMoneyFlowSchema>) => void
  }
  state: {
    formData: zod.infer<typeof editMoneyFlowSchema>
    mutationState: {
      isPending: boolean
      isError: boolean
      errorMessage: string | undefined
    }
  }
}

const [Context, useEditMoneyFlowContextValue] =
  createContext<EditMoneyFlowProviderContextState>({
    name: 'EditMoneyFlowContext',
  })

export const EditMoneyFlowProvider = ({ children }: PropsWithChildren) => {
  const [editMoneyFormData, setEditMoneyFormData] = useState<
    zod.infer<typeof editMoneyFlowSchema>
  >({})

  const {
    state: { id: companyId },
  } = useCompanyDetailContextValue()

  const {
    mutate: editCompanyNameMutation,
    isPending,
    isError,
    error,
  } = useEditCompanyMoneyMutation(companyId)

  const value: EditMoneyFlowProviderContextState = {
    actions: {
      submitFormToServer: editCompanyNameMutation,
      setFormData: (data: zod.infer<typeof editMoneyFlowSchema>) => {
        setEditMoneyFormData((prev) => ({
          ...prev,
          ...data,
        }))
      },
    },
    state: {
      formData: editMoneyFormData,
      mutationState: {
        isPending,
        isError,
        errorMessage: parseErrorMessage(error),
      },
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useEditMoneyFlowContextValue }
