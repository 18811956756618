import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { getLegacyShiftById } from 'api/shifts'
import { WorkShift } from 'api/typings'

const QUERIES = {
  detail: `{
      id,
      market,
      cancelledAt, isTryout,
      startsAt, endsAt, lunchLength,
      supervisor, supervisorPhone,
      assignment { name, id }
      company { name, id }
      location {
        id
        address { street, city, state, zip, lat, long, timezone }
        clockInLat,
        clockInLong,
        clockInRadius,
      },
      listings {
          id,
          tier,
          tierTransitions,
          totalBonus,
          totalPay,
          freeze
      },
      position {
          id,
          name,
          isDrivingPosition,
          needsCar,
          needsFullTime,
          needsLicense,
          needsW2,
          mustHaveRequirements {
              id,
              name,
          }
      }
  }`,

  /** Shift fulfillment statistics */
  fill: `{
    id,
    workersNeeded, numCushionWorkersNeeded, numWorkersScheduled,
    numWorkersStarted, numWorkersFinished(excludeEmployerCancelled:true),
    numWorkersRejected, numWorkersRemoved,
    rosters {
      id, isSpecificWorkerRequest, name,
      workers {
          id, name, profilePicUrl
      }
    },
    rostersHoldExpiresAt,
    listings { numWaitlist },
  }`,

  /** Basic fill stats and tier transition information */
  listingTiers: `{
    id, startsAt,
    numWorkersFilled, workersNeeded, numCushionWorkersNeeded,
    listings { id, tier, tierTransitions, numWaitlist },
    location { address { timezone }, clockInRadius },
    position {
      id,
      name,
      isDrivingPosition,
      needsCar,
      needsFullTime,
      needsLicense,
      needsW2,
      mustHaveRequirements {
          id,
          name,
      }
    }
  }`,

  /** Pay and bonus-related information */
  pay: `{
    id,
    numWorkersFilled,
    pay, payRate, payLumpSum,
    shiftBonuses { amount, createdAt, companyPaid, isDynamicBonus, isNewWorkerBonus },
    location { address { timezone } },
  }`,

  /** List of scheduled workers and their status */
  work: `{
    work (status:"all") {
      id, status, confirmedAt,
      worker { id, name, backgroundCheckStatus, backgroundCheckMvrStatus }
      shift { position { isDrivingPosition } }
      isReplaceable
      replacedWork {
        id,
        worker {
          id
          name
        }
      }
      replacedAt
    }
  }`,
}

/**
 * Merges together all shift-related data for this shift ID even if different GQL queries are used
 * @example useShiftDetails(shiftId, 'listingTiers', { enabled: false })
 */
export function useLegacyShiftDetails(
  shiftId: WorkShift['id'] | string,
  queryKey?: keyof typeof QUERIES,
  options?: Partial<UseQueryOptions>
) {
  const queryClient = useQueryClient()

  return useQuery<WorkShift>({
    queryKey: ['shift', shiftId, queryKey].filter(Boolean),
    queryFn: async () => getLegacyShiftById(shiftId, QUERIES[queryKey]),
    enabled: !!shiftId,
    retry: false,
    /** Do not remove existing data when additional data is fetched */
    placeholderData: keepPreviousData,
    /** Merge together all data related to this shift id */
    initialData: queryClient.getQueryData(['shift', shiftId]),
    ...options,
    staleTime: 10000,
    initialDataUpdatedAt: queryClient.getQueryState(['shift', shiftId])
      ?.dataUpdatedAt,
  })
}
