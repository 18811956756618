import React from 'react'
import { useCreateCompanyGroupFlowContextValue } from '../context/CreateCompanyGroupFlowProvider'
import {
  Box,
  Button,
  Field,
  Flex,
  Heading,
  Input,
  Message,
  Modal,
  toast,
} from '@workwhile/ui'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { createCompanyGroupFlowSchema } from '../schemas/companyGroup'
import { useCompanyGroupsWorkflowsContextValue } from 'pages/CompanyGroups/context/CompanyGroupsWorkflowsProvider'

export const CreateCompanyGroupFlowMain = () => {
  const {
    createCompanyGroupFlow: { isOpen, close },
  } = useCompanyGroupsWorkflowsContextValue()

  const {
    state: {
      formData,
      mutationState: { isPending, errorMessage },
    },
    actions: { submitFormToServer, setFormData },
  } = useCreateCompanyGroupFlowContextValue()

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(createCompanyGroupFlowSchema),
    defaultValues: formData,
  })

  const onSubmit = handleSubmit((data) => {
    setFormData({ companyGroupName: data.companyGroupName })
    submitFormToServer(data.companyGroupName, {
      onSuccess: () => {
        toast.success(
          `Successfully created "${data.companyGroupName}" company group!`,
          {
            position: 'top-center',
          }
        )
        setFormData({ companyGroupName: '' })
        reset()
        close()
      },
    })
  })

  return (
    <Modal
      open={isOpen}
      onClose={close}
      position="top"
      loading={isPending}
      showCancel
      customCta={
        <Flex>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Create
          </Button>
        </Flex>
      }
    >
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Heading level={3} fontWeight="400" style={{ margin: 0 }}>
          Create company group
        </Heading>
        <Field
          label={'Group name'}
          error={errors.companyGroupName?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mt={3}
        >
          <Input
            {...register('companyGroupName')}
            aria-label={'Group name'}
            placeholder={`e.g. Acne Inc.`}
          />
        </Field>
        {errorMessage ? (
          <Message variant="error" mt={3} borderRadius="small">
            {errorMessage}
          </Message>
        ) : null}
      </Box>
    </Modal>
  )
}
