import { useQuery } from '@tanstack/react-query'
import { commandsListKey } from './keys'
import { getCommands } from 'api/commands'

export function useCommandsList() {
  return useQuery({
    queryKey: commandsListKey,
    queryFn: getCommands,
  })
}
