import React from 'react'
import { useAppTheme, Flex, Heading, Text } from '@workwhile/ui'

type HeaderProps = {
  imgSrc?: string
  // must provide one of title or subTitle
  subTitle?: string
  title?: string | React.ReactNode
  rightContent?: React.ReactNode
  leftContent?: React.ReactNode
}

export const Header = ({
  title,
  subTitle,
  leftContent,
  rightContent,
}: HeaderProps) => {
  const { colors } = useAppTheme()

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid"
      borderBottomColor={colors.neutrals[100]}
      padding={3}
      paddingBottom={subTitle ? 1 : 3}
      width="100%"
    >
      <Flex alignItems="flex-start" gap={2} flexDirection="column">
        {subTitle ? (
          <Text
            color="lightText"
            style={{
              textTransform: 'uppercase',
            }}
            fontSize={0}
          >
            {subTitle}
          </Text>
        ) : null}
        <Flex alignItems="center" gap={2}>
          {leftContent}
          {typeof title === 'string' ? (
            <Heading level={1} style={{ margin: 0 }} fontWeight="400">
              {title}
            </Heading>
          ) : (
            title
          )}
        </Flex>
      </Flex>
      {rightContent}
    </Flex>
  )
}
