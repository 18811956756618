import React from 'react'
import { Flex } from '@workwhile/ui'
import { useShiftDetailContextValue } from '../../context/ShiftDetailProvider'
import { StatCard } from '../StatCard'
import { isBefore, subMinutes } from 'date-fns'

export const ShiftWorkerStats = () => {
  const {
    state: {
      numWorkersStarted,
      numWorkersFinished,
      numWorkersRejected,
      numWorkersRemoved,
      numWorkersBailed,
      startsAt,
    },
  } = useShiftDetailContextValue()

  // We check if the shift has started or is about to start to display these stats
  const hasShiftStartedOrIsAboutToStart = isBefore(
    startsAt,
    subMinutes(new Date(), 10)
  )

  if (!hasShiftStartedOrIsAboutToStart) {
    return null
  }

  return (
    <Flex
      flexWrap="wrap"
      gap={3}
      style={{ flexBasis: 'calc(33.33% - 1rem)', maxWidth: '452px' }}
    >
      {numWorkersStarted ? (
        <StatCard title="Started" value={numWorkersStarted} />
      ) : null}
      <StatCard title="Completed" value={numWorkersFinished} />
      <StatCard
        title="Bailed"
        value={numWorkersBailed > 0 ? numWorkersBailed : '-'}
        color={numWorkersBailed > 0 ? 'errors.100' : undefined}
      />
      <StatCard
        title="Rejected"
        value={numWorkersRejected > 0 ? numWorkersRejected : '-'}
      />
      <StatCard
        title="Removed"
        value={numWorkersRemoved > 0 ? numWorkersRemoved : '-'}
      />
    </Flex>
  )
}
