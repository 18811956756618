import React, { PropsWithChildren } from 'react'
import { GlobalWorkflowsProvider } from './GlobalWorkflowManager/GlobalWorkflowManager'
import { EditShiftWorkFlow } from './EditShiftWorkFlow'

export const GlobalWorkflows = ({ children }: PropsWithChildren) => {
  return (
    <GlobalWorkflowsProvider>
      <EditShiftWorkFlow />
      {children}
    </GlobalWorkflowsProvider>
  )
}
