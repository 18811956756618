import React, { useState } from 'react'
import {
  Work,
  WorkerRequirement,
  WorkShift,
  User,
} from '../../Shared/common_defs'
import { FILLED_STATUSES } from '../../utils/util'
import WorkerDetail from './WorkerDetail'

interface Props {
  work: Work
  shift: WorkShift
  needsWorkers: boolean
  fetchWorkStats: () => void
  fetchWorkers: (showLoading?: boolean) => void
}

const WorkerBasicInfo = ({
  work,
  shift,
  fetchWorkStats,
  fetchWorkers,
}: Props) => {
  const [showDetail, setShowDetail] = useState(false)
  let backgroundCheckStatusDisplay = ''
  if (work.worker.backgroundCheckStatus === 'pending') {
    backgroundCheckStatusDisplay = '[BGC_PENDING]'
  } else if (
    work.worker.backgroundCheckMvrStatus === 'pending' &&
    shift.position.isDrivingPosition
  ) {
    backgroundCheckStatusDisplay = '[MVR_PENDING]'
  } else {
    backgroundCheckStatusDisplay = ''
  }

  const findVerifiedCredential = (
    requirement: WorkerRequirement,
    worker: User
  ) => {
    // Step 1: Check if requirement is a certificate type
    if (requirement.verificationType !== 'certificate') return null

    // Step 2: Find matching certificate in workProfile
    const matchingCertificate = worker.workProfile?.certificates?.find(
      (cert) => Number(cert.workerRequirement.id) === Number(requirement.id)
    )
    if (!matchingCertificate) return null

    // Steps 3-5: Find matching credential and check verification
    const verifiedCredential = worker.credentials?.find(
      (cred) =>
        Number(cred.id) === Number(matchingCertificate.id) && cred.verifiedAt
    )

    // Step 6: Return the verified credential if found
    return verifiedCredential
      ? {
          credentialId: verifiedCredential.id,
          requirementName: requirement.name,
        }
      : null
  }

  return (
    <div>
      <span
        data-context-key="user_id" data-context-value={work.worker.id} data-context-label={work.worker.name}
        style={
          FILLED_STATUSES.includes(work.status)
            ? { color: '#000000' }
            : { color: 'rgba(0,0,0,0.5)' }
        }
      >
        [{work.status}]{work.isReplaceable ? '[REPLACEABLE]' : ''}
        {work.replacedAt ? '[REPLACED]' : ''}
        {backgroundCheckStatusDisplay} {work.worker.name} ({work.worker.id})
        {shift.position.requirements2
          ?.filter((req) => req.verificationType === 'certificate')
          .map((req) => {
            const verifiedCred = findVerifiedCredential(req, work.worker)
            return verifiedCred ? (
              <a
                key={req.id}
                href={`https://console.cloud.google.com/storage/browser/ww-credentials/${verifiedCred.credentialId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {` ${verifiedCred.requirementName} verified`}
              </a>
            ) : null
          })}
        {work.confirmedAt !== 'N/A' ? '*' : ''}
      </span>

      <div>
        <a
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: 'rgba(0,0,0,0.5)',
          }}
          onClick={() => setShowDetail(!showDetail)}
        >
          {showDetail ? 'Hide' : 'Show'} Detail
        </a>
      </div>

      {showDetail ? (
        <WorkerDetail
          workId={work.id}
          workerId={work.worker.id}
          shift={shift}
          fetchWorkStats={fetchWorkStats}
          fetchWorkers={fetchWorkers}
        />
      ) : null}
    </div>
  )
}

export default WorkerBasicInfo
