import React from 'react'
import {
  Box,
  Flex,
  Heading,
  Pluralize,
  useAppTheme,
  Text,
  Link,
} from '@workwhile/ui'
import { WorkerRow } from '../WorkerRow'
import { useShiftDetailContextValue } from '../../context/ShiftDetailProvider'
import { LeadShift } from '../../types'

const LeadShiftRow = ({
  leadShift,
  isLast,
}: {
  leadShift: LeadShift
  isLast: boolean
}) => {
  const { radii } = useAppTheme()

  const workRows = leadShift.work.map((w, index) => {
    return <WorkerRow key={`lead-shift-worker-${w.id}-${index}`} work={w} />
  })

  return (
    <Box
      display="block"
      border="1px solid"
      borderColor="neutrals.100"
      p={3}
      borderRadius={radii.standard}
      width="100%"
      mb={isLast ? 0 : 3}
    >
      <Text mb={3}>
        Shift{' '}
        <Link to={`/shifts/${leadShift.id}`} target="_blank">
          ({leadShift.id})
        </Link>
      </Text>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
        gap={3}
      >
        {workRows.length > 0 ? (
          workRows
        ) : (
          <Text color="lightText">No workers scheduled</Text>
        )}
      </Flex>
    </Box>
  )
}

export const LeadShifts = () => {
  const {
    state: { leadShifts },
  } = useShiftDetailContextValue()

  const leadShiftRows = leadShifts.map((ls, index) => {
    return (
      <LeadShiftRow
        key={`lead-shift-${ls.id}-${index}`}
        leadShift={ls}
        isLast={index === leadShifts.length - 1}
      />
    )
  })

  if (leadShiftRows.length === 0) {
    return null
  }

  return (
    <>
      {/* We add spaces inside the component because sometimes it could return null */}
      <br />
      <br />
      <Box>
        <Heading level={2} fontWeight="400">
          <Pluralize
            oneText="Lead Shift"
            manyText="Lead Shifts"
            hideCount
            count={leadShifts.length}
          />
        </Heading>
        <Flex gap={3} flexDirection="column">
          {leadShiftRows}
        </Flex>
      </Box>
    </>
  )
}
