import React from 'react'
import { useParams } from 'react-router-dom'
import { CompanyGroupDetailProvider } from './context/CompanyGroupDetailProvider'
import { CompanyGroupDetailWorkflowsProvider } from './context/CompanyGroupDetailWorkflowProvider'
import { CompanyGroupDetailMain } from './CompanyGroupDetailMain'
import { RemoveCompanyFromGroupFlow } from './workflows/RemoveCompanyFromGroupFlow'
import { AddCompaniesFlow } from './workflows/AddCompaniesFlow/AddCompaniesFlow'

export const CompanyGroupDetail = () => {
  const { id } = useParams<{ id: string }>()
  const companyGroupId = Number(id)

  return (
    <CompanyGroupDetailProvider companyGroupId={companyGroupId}>
      <CompanyGroupDetailWorkflowsProvider>
        <CompanyGroupDetailMain />
        <RemoveCompanyFromGroupFlow />
        <AddCompaniesFlow />
      </CompanyGroupDetailWorkflowsProvider>
    </CompanyGroupDetailProvider>
  )
}
