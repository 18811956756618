import api from 'api'

type AddMinimumPayPolicyRequest = {
  companyId: number
  startsAt: string
  minPayLengthInMinutes: number
}

export const addMinimumPayPolicy = async (
  request: AddMinimumPayPolicyRequest
): Promise<void> => {
  return api.post(`/admin/company/${request.companyId}/min_pay_policy`, {
    policy_start_date: request.startsAt,
    min_pay_length_in_minutes: request.minPayLengthInMinutes,
  })
}
