import React, { PropsWithChildren, useMemo } from 'react'
import { createContext } from 'lib/createContext'
import { useSearchParams } from 'react-router-dom'
import { useCompanyPosition } from 'queries/company'
import { Heading, Loading } from '@workwhile/ui'
import { PositionIdInput } from './PositionIdInput'
import { CompanyPosition } from 'api/company'

interface PositionContextValueProps {
  positionId: number | null
  positionData?: CompanyPosition
  refetchPositionData: () => void
  isLoadingPositionData: boolean
}

export const [PositionEditorContext, usePositionEditorContext] =
  createContext<PositionContextValueProps>({
    name: 'PositionEditorContext',
  })

export function PositionEditorContextProvider({ children }: PropsWithChildren) {
  const [searchParams] = useSearchParams()
  const positionId = searchParams.get('positionId')
  const {
    data: positionData,
    isFetching: isLoadingPositionData,
    refetch: refetchPositionData,
  } = useCompanyPosition(positionId ? Number(positionId) : null)

  const value = useMemo(
    () => ({
      positionId: positionId ? Number(positionId) : null,
      positionData,
      refetchPositionData,
      isLoadingPositionData,
    }),
    [positionId, positionData, refetchPositionData, isLoadingPositionData]
  )

  return (
    <PositionEditorContext.Provider value={value}>
      <Heading level={2}>
        ✏️ Position {positionData?.isApproved ? 'Editor' : 'Approval'} Checklist
      </Heading>
      <PositionIdInput />
      {isLoadingPositionData ? <Loading /> : positionData ? children : null}
    </PositionEditorContext.Provider>
  )
}
