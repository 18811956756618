import React from 'react'
import { ShiftDetailHeader } from './components/ShiftDetailHeader/ShiftDetailHeader'
import { Flex, Box } from '@workwhile/ui'
import { GeneralDetails } from './components/GeneralDetails'
import { Pay } from './components/Pay'
import { QuickStats } from './components/QuickStats/QuickStats'
import { Workers } from './components/Workers/Workers'
import { LeadShifts } from './components/LeadShifts/LeadShifts'
import { ListingsOverview } from './components/ListingsOverview'
import { LongTermAssignment } from './components/LongTermAssignment'
export const ShiftDetailMain = () => {
  return (
    <>
      <ShiftDetailHeader />
      <Box margin={3}>
        <Flex gap={3} flexWrap="wrap">
          <GeneralDetails />
          <Pay />
          <LongTermAssignment />
        </Flex>
        <br />
        <br />
        <QuickStats />
        <br />
        <br />
        <ListingsOverview />
        <LeadShifts />
        <br />
        <br />
        <Workers />
      </Box>
    </>
  )
}
