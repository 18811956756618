import React, { useState } from 'react'
import { createContext } from 'lib/createContext'
import { TierDetails, TierType } from 'pages/Shifts/ShiftDetail/types'
import { useListingsOverviewContextValue } from './ListingsOverviewProvider'

const fakeTierDetails: TierDetails = {
  listingViews: [
    {
      date: '2025-02-11',
      views: 10,
    },
    {
      date: '2025-02-12',
      views: 56,
    },
    {
      date: '2025-02-13',
      views: 122,
    },
    {
      date: '2025-02-14',
      views: 198,
    },
    {
      date: '2025-02-15',
      views: 221,
    },
    {
      date: '2025-02-16',
      views: 350,
    },
  ],
  notInterestedReasons: [
    {
      reason: 'Pay is too low',
      count: 14,
    },
    {
      reason: "It's an inconvenient time",
      count: 8,
    },
    {
      reason: 'The shift is too long',
      count: 5,
    },
  ],
  ineligibilityReasons: [
    {
      reason: 'Lorem ipsum dolor sit amet',
      count: 34,
    },
    {
      reason: 'Consectetur adipiscing elit',
      count: 28,
    },
    {
      reason:
        'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
      count: 12,
    },
  ],
}

type TierStatsContextState = {
  state: {
    tierDetails: TierDetails | undefined
    selectedTier: TierType | undefined
  }
  actions: {
    refetch: () => void
    setSelectedTier: (tier: TierType | undefined) => void
  }
}

const [Context, useTierStatsContextValue] =
  createContext<TierStatsContextState>({
    name: 'TierStatsContext',
  })

export const TierStatsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const {
    state: { eligibilities },
    actions: { refetch },
  } = useListingsOverviewContextValue()

  const [selectedTier, setSelectedTier] = useState<TierType | undefined>(
    undefined
  )

  const data = eligibilities.find(
    (eligibility) => eligibility.tier === selectedTier
  )

  const value = {
    state: {
      tierDetails: data?.tierDetails ?? fakeTierDetails, // temporary, we'll remove this once we have the real data
      selectedTier,
    },
    actions: { refetch, setSelectedTier },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useTierStatsContextValue }
