import React from 'react'
import { Flex, Heading } from '@workwhile/ui'
import { QueryStatus } from 'components/QueryStatus'

type SectionHeadingProps = Parameters<typeof QueryStatus>[0] & {
  listingId?: number
}

export const SectionHeading = ({
  listingId,
  dataUpdatedAt,
  isFetching,
  refetch,
  isError,
  error,
}: SectionHeadingProps) => {
  return (
    <Flex alignItems="center" gap={4}>
      <Heading level={2} fontWeight="400">
        Listing Overview {listingId ? `(${listingId})` : ''}
      </Heading>
      <QueryStatus
        dataUpdatedAt={dataUpdatedAt}
        isFetching={isFetching}
        refetch={refetch}
        isError={isError}
        error={error}
      />
    </Flex>
  )
}
