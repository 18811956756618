import React from 'react'
import moment from 'moment-timezone'
import { dateCommaTimeFormat } from '../utils/general_util'
import WorkerDetail from '../Shifts/Workers/WorkerDetail'
import { Link } from '@workwhile/ui'

export const USER_WORK_QUERY = `
query getUserWork(
  $userId: Int!
  $limit: Int!
  $grouping: String
  $status: String
  $startDate: Date
  $endDate: Date
) {
  admin {
    user(userId: $userId) {
      work(
        limit: $limit
        grouping: $grouping
        status: $status
        startDate: $startDate
        endDate: $endDate
      ) {
        id
        status
        earnings
        startedAt
        completedAt
        potentialBonus
        isReplaceable
        replacedWork {
          id
          worker {
            id
            name
          }
        }
        replacedAt
        isInitiallyReserved
        trips {
          embedUrl
        }
        shift {
          id
          startsAt
          endsAt
          payRate
          payLumpSum
          pay
          company {
            id
            name
          }
          position {
            id
            name
          }
          location {
            id
            address {
              city
              state
              street
              zip
              lat
              long
              timezone
            }
          }
          listings {
            id
          }
          supervisor
          supervisorPhone
          supervisors {
            id
            name
            phoneNumber
            userId
            status
          }
        }
        worker {
          id
        }
        supervisorReasonEarlyClockOut
        reasonEarlyClockout
      }
    }
  }
}
`

export const workColumns = (requestWork?: () => void) => [
  {
    dataField: 'id',
    text: 'IDs',
    sort: true,
    headerStyle: { width: '4em', fontSize: 12 },
    formatter: (cell, row) => {
      return (
        <p>
          <b>work_id</b>:{cell} ||{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://admin.workwhilejobs.com/single_shift?id=${row.shift.id}`}
            // link to shift details
          >
            <b>shift_id</b>:{row.shift.id}{' '}
          </a>
          {row.shift.listings &&
            row.shift.listings.length > 0 &&
            row.shift.listings.map((listing) => (
              <>
                || <b>listing_id</b>:{listing.id}{' '}
              </>
            ))}
        </p>
      )
    },
  },
  {
    dataField: 'shift',
    text: 'Shift Details',
    sort: true,
    headerStyle: { width: '10em', fontSize: 12 },
    formatter: (cell, row) => {
      const { location } = cell
      const timezone =
        location && location.address
          ? location.address.timezone
          : moment.tz.guess(true)
      const startsAt = moment
        .tz(cell.startsAt, timezone)
        .format(dateCommaTimeFormat)
      const endsAt = moment
        .tz(cell.endsAt, timezone)
        .format(dateCommaTimeFormat)
      return (
        <>
          <p>
            {cell.company?.name}{' '}
            <Link to={`/companies/${cell.company?.id}`} target="_blank">
              ({cell.company?.id})
            </Link>{' '}
            || {cell.position?.name}({cell.position?.id}) || (
            {cell.location?.id})
            {cell.location && cell.location.address
              ? cell.location.address.street +
                ' ' +
                cell.location.address.city +
                ' ' +
                cell.location.address.state +
                ' ' +
                cell.location.address.zip
              : 'No Address'}{' '}
            ||
            {cell.payRate
              ? cell.payRate + '/hr' + ' total: $' + cell.pay
              : cell.payLumpSum + ' for the whole shift'}{' '}
            ||
            {row.potentialBonus > 0 ? ` Bonus: ${row.potentialBonus} || ` : ``}
          </p>
          <p>
            startsAt: {startsAt} || endsAt: {endsAt}
          </p>
        </>
      )
    },
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    headerStyle: { width: '5em', fontSize: 12 },
    formatter: (cell, row) => {
      let content = null
      if (cell === 'paid') {
        content = <p>amount:{row.earnings}</p>
      } else if (cell === 'employer_cancelled' && row.earnings > 0) {
        content = <p>Paid amount: {row.earnings}</p>
      }
      return (
        <>
          <p>
            {cell} {row.isReplaceable ? '[REPLACEABLE]' : ''}{' '}
            {row.replacedAt ? '[REPLACED]' : ''}
          </p>
          {content}
          <WorkerDetail
            workId={row.id}
            workerId={row.worker.id}
            shift={row.shift}
            fetchWorkers={requestWork}
            hideWorkerInfo
          />
        </>
      )
    },
  },
  {
    dataField: 'startedAt',
    text: 'Work Info',
    sort: true,
    headerStyle: { width: '5em', fontSize: 12 },
    formatter: (cell, row) => {
      const location = row.shift.location
      const timezone =
        location && location.address
          ? location.address.timezone
          : moment.tz.guess(true)
      const started_at = moment.tz(cell, timezone)
      const completed_at = moment.tz(row.completedAt, timezone)
      const ms = moment(completed_at).diff(started_at)
      var d = moment.duration(ms)
      var diff =
        Math.floor(d.asHours()) + 'h ' + moment.utc(ms).format('mm') + 'mins'
      return (
        <>
          <p>
            {started_at.format(dateCommaTimeFormat)} -{' '}
            {completed_at.format(dateCommaTimeFormat)}
          </p>
          <p>worked:{diff}</p>
          {row.reasonEarlyClockout && (
            <p>Reason early clockout by worker: {row.reasonEarlyClockout}</p>
          )}
          {row.supervisorReasonEarlyClockOut && (
            <p>
              Reason early clockout by supervisor:{' '}
              {row.supervisorReasonEarlyClockOut}
            </p>
          )}
          {row.trips && row.trips.length > 0 && (
            <p>
              trips:{' '}
              {row.trips.map((trip, idx) => (
                <a target="_blank" href={trip.embedUrl}>
                  location-{idx}
                </a>
              ))}
            </p>
          )}
          {row.isInitiallyReserved && <p>Shift is/was reserved</p>}
          {row.shift.supervisors.map((supervisor) => (
            <div key={supervisor.name}>
              <p>
                {supervisor.name}. Phone: {supervisor.phoneNumber || 'N/A'}
              </p>
            </div>
          ))}
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://console.cloud.google.com/logs/query;duration=PT72H;query=%22work_id%3D${row.id}%22?folder=true&organizationId=136924707921&project=workwhile-247820`}
              // link to GCP logs
            >
              View Work ID Log
            </a>
          </p>
        </>
      )
    },
  },
]
