import { Box, Button, DropdownMenu, useAppTheme } from '@workwhile/ui'

import React, { useMemo } from 'react'
import { Text, Flex, Link, LabelVariant, Label } from '@workwhile/ui'
import { AccordionCard } from 'components/AccordionCard'
import { Work } from '../types'
import { useShiftDetailContextValue } from '../context/ShiftDetailProvider'
import { formatInTimeZone } from 'date-fns-tz'
import { differenceInMinutes } from 'date-fns'
import { getLongDuration } from 'lib/time'
import { formatCurrency } from 'lib/currency'
import { InfoTable } from 'components/InfoTable'
import { Ban, Clock, Database, Ellipsis, HardHat, Pencil } from 'lucide-react'
import styled from 'styled-components'
import { getRemovedReason } from '../utils/getRemovedReason'
import { useGlobalWorkflowsContextValue } from 'global-workflows/GlobalWorkflowManager/GlobalWorkflowManager'

type WorkerRowProps = {
  work: Work
} & (
  | {
      shiftId: number
      allowEdit: true
      shiftStartsAt: string
    }
  | {
      shiftId?: never
      allowEdit?: never
      shiftStartsAt?: never
    }
)

const ReadOnlyBox = styled(Text)`
  color: ${({ theme }) => theme.colors.lightText};
  background-color: ${({ theme }) => theme.colors.neutrals[100]};
  padding: ${({ theme }) => theme.space[2]};
  padding-left: ${({ theme }) => theme.space[3]};
  padding-right: ${({ theme }) => theme.space[3]};
  border-radius: ${({ theme }) => theme.radii.standard};
`

const StyledInfoTable = styled(InfoTable)`
  td {
    vertical-align: text-top;
  }
`

const leftTdProps = {
  textAlign: 'left',
  display: 'inline-flex',
  alignItems: 'center',
  marginRight: 4,
}

const rightTdProps = {
  textAlign: 'left',
}

export const WorkerRow = ({
  work: {
    id: workId,
    worker: { penaltyCount, totalShiftsCount, id: workerId, name },
    startedAt,
    completedAt,
    status,
    earnings,
    confirmedAt,
    removedReason,
    leftEarly,
  },
  allowEdit,
  shiftId,
  shiftStartsAt,
}: WorkerRowProps) => {
  const { space } = useAppTheme()
  const {
    state: {
      location: {
        address: { timezone },
      },
    },
  } = useShiftDetailContextValue()

  const { editShiftWorkFlow } = useGlobalWorkflowsContextValue()

  const shiftStatus: string | undefined = useMemo(() => {
    if (status === 'started') {
      const workerClockedInAt = startedAt
        ? formatInTimeZone(startedAt, timezone, 'h:mm a z')
        : undefined

      return workerClockedInAt
        ? `Clocked in at ${workerClockedInAt}`
        : undefined
    }

    if (
      status &&
      [
        'completed',
        'approved',
        'paid',
        'employer_approved',
        'admin_review',
        'employer_review',
      ].includes(status)
    ) {
      const startTime = startedAt
        ? formatInTimeZone(startedAt, timezone, 'h:mm a')
        : undefined

      const endTime = completedAt
        ? formatInTimeZone(completedAt, timezone, 'h:mm a z')
        : undefined

      const estimatedTimeWorkedInMinutes = differenceInMinutes(
        completedAt,
        startedAt
      )

      const estimatedTimeWorked = getLongDuration(estimatedTimeWorkedInMinutes)

      return startTime && endTime
        ? `${startTime} - ${endTime} (${estimatedTimeWorked})`
        : undefined
    }

    if (status === 'removed') {
      return removedReason ? getRemovedReason(removedReason) : ''
    }

    return undefined
  }, [status, startedAt, completedAt, removedReason])

  const workerStatus: {
    label: string
    color: LabelVariant
  } = useMemo(() => {
    if (status === 'scheduled') {
      return {
        label: 'Scheduled',
        color: 'neutral',
      }
    }

    if (status === 'started') {
      return {
        label: 'Started',
        color: 'information',
      }
    }

    if (status === 'rejected') {
      return {
        label: 'Rejected',
        color: 'none',
      }
    }

    if (status === 'bailed') {
      return {
        label: 'Bailed',
        color: 'error',
      }
    }

    if (status === 'approved') {
      return {
        label: 'Approved',
        color: 'success',
      }
    }

    if (status === 'paid') {
      return {
        label: 'Paid',
        color: 'success',
      }
    }

    if (status === 'removed') {
      return {
        label: 'Removed',
        color: 'none',
      }
    }

    if (status === 'employer_approved') {
      return {
        label: 'Employer Approved',
        color: 'success',
      }
    }

    if (status === 'admin_review') {
      return {
        label: 'Admin Review',
        color: 'error',
      }
    }

    if (status === 'employer_review') {
      return {
        label: 'Employer Review',
        color: 'error',
      }
    }

    if (status === 'employer_cancelled') {
      return {
        label: 'Employer Cancelled',
        color: 'neutral',
      }
    }

    if (status === 'worker_declined_req') {
      return {
        label: 'Worker Declined Request',
        color: 'none',
      }
    }

    if (status === 'system_declined') {
      return {
        label: 'System Declined',
        color: 'neutral',
      }
    }

    if (status === 'completed') {
      return {
        label: 'Completed',
        color: 'success',
      }
    }

    return {
      label: status,
      color: 'none',
    }
  }, [status])

  return (
    <AccordionCard
      title={
        <>
          <Flex
            alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
            width="100%"
            gap={[2, 2, 2, 4]}
            flexDirection={['column', 'column', 'column', 'row']}
          >
            <Flex
              flexDirection="column"
              gap={1}
              minWidth="400px"
              maxWidth="400px"
            >
              <Text>
                {name}{' '}
                <Link to={`/users/${workerId}`} target="_blank">
                  ({workerId})
                </Link>
              </Text>
              {shiftStatus ? (
                <Text color="lightText">
                  {shiftStatus.split('\n').map((line, index) => (
                    <Text key={`shift-status-line-${workerId}-${index}`}>
                      {line}
                    </Text>
                  ))}
                </Text>
              ) : null}
            </Flex>
            <Box as="span" minWidth="200px">
              <Label variant={workerStatus.color} size="small">
                {workerStatus.label}
              </Label>
            </Box>
            {typeof earnings === 'number' && (
              <Text>Est. {formatCurrency(earnings)}</Text>
            )}
          </Flex>
          {allowEdit ? (
            <Button
              variant="text"
              kind="medium"
              title="Edit Work"
              onClick={() => {
                editShiftWorkFlow.open({
                  shiftId,
                  workId,
                  worker: {
                    id: workerId,
                    name,
                  },
                  shiftStartsAt,
                  status,
                  clockedInAt: startedAt,
                  clockedOutAt: completedAt,
                  leftEarly,
                  timezone,
                })
              }}
            >
              <Pencil size={16} />
            </Button>
          ) : null}
        </>
      }
      isAccordion
      defaultOpen={false}
      contentWrapperProps={{
        display: 'flex',
        flexDirection: 'row',
        gap: space[5],
      }}
    >
      {/* Please do not put actions here. We want to keep actions as "modals" and use the workflow pattern to achieve them. This is a view only component. */}

      <StyledInfoTable
        style={{
          maxWidth: 'fit-content',
          tableLayout: 'auto',
          borderSpacing: `0 ${space[2]}`,
        }}
      >
        <InfoTable.Row
          icon={<Database size={14} />}
          label="Work ID"
          value={<ReadOnlyBox>{workId}</ReadOnlyBox>}
          leftTdProps={leftTdProps}
          rightTdProps={rightTdProps}
        />
        <InfoTable.Row
          icon={<Clock size={14} />}
          label="Confirmed at"
          value={
            <ReadOnlyBox>
              {confirmedAt
                ? formatInTimeZone(
                    confirmedAt,
                    timezone,
                    'EE, MMMM d, yyyy, h:mm a z'
                  )
                : 'N/A'}
            </ReadOnlyBox>
          }
          leftTdProps={leftTdProps}
          rightTdProps={rightTdProps}
        />
        <InfoTable.Row
          icon={<Ban size={14} />}
          label="Penalties"
          value={<ReadOnlyBox>{penaltyCount}</ReadOnlyBox>}
          leftTdProps={leftTdProps}
          rightTdProps={rightTdProps}
        />
        <InfoTable.Row
          icon={<HardHat size={14} />}
          label="Total Shifts"
          value={<ReadOnlyBox>{totalShiftsCount}</ReadOnlyBox>}
          leftTdProps={leftTdProps}
          rightTdProps={rightTdProps}
        />
      </StyledInfoTable>
    </AccordionCard>
  )
}
