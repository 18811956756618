import { parseAsArrayOf, parseAsStringLiteral, useQueryState } from 'nuqs'

import {
  SelectableColumns,
  selectableColumns,
} from '../components/ShiftsTable/ColumnsFilter/columns'

export const useSelectedColumnsQueryState = () => {
  const selectedColumnsParser = parseAsStringLiteral(
    selectableColumns.map((column) => column.id)
  )

  const queryStates = useQueryState(
    'selected-columns',
    parseAsArrayOf<SelectableColumns>(selectedColumnsParser).withDefault([
      'time',
      'company',
      'location',
      'position',
      'schedule-rate',
      'fill-rate',
      'tier',
    ])
  )

  return queryStates
}
