import React, { useState } from 'react'
import { AddCancellationPolicySchema } from '../schemas/cancellationPolicy'
import { createContext } from 'lib/createContext'
import { useAddCancellationPolicyMutation } from 'queries/company'
import { useCompanyDetailContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailProvider'
import { parseErrorMessage } from 'api'

type AddCancellationPolicyFlowProviderContextState = {
  actions: {
    submitFormToServer: ReturnType<
      typeof useAddCancellationPolicyMutation
    >['mutate']
    setFormData: (data: AddCancellationPolicySchema) => void
  }
  state: {
    formData: AddCancellationPolicySchema
    mutationState: {
      isPending: boolean
      isError: boolean
      errorMessage: string | undefined
    }
  }
}

const [Context, useAddCancellationPolicyFlowContextValue] =
  createContext<AddCancellationPolicyFlowProviderContextState>({
    name: 'AddCancellationPolicyFlowContext',
  })

type AddCancellationPolicyFlowContextProps = {
  children?: React.ReactNode
}

const AddCancellationPolicyFlowContextProvider = ({
  children,
}: AddCancellationPolicyFlowContextProps) => {
  const [addCancellationPolicyFormData, setAddCancellationPolicyFormData] =
    useState<AddCancellationPolicySchema>({})

  const {
    state: { id: companyId },
  } = useCompanyDetailContextValue()

  const {
    mutate: addCancellationPolicyMutation,
    isPending,
    isError,
    error,
  } = useAddCancellationPolicyMutation(companyId)

  const value: AddCancellationPolicyFlowProviderContextState = {
    actions: {
      submitFormToServer: addCancellationPolicyMutation,
      setFormData: (data: AddCancellationPolicySchema) => {
        setAddCancellationPolicyFormData((prev) => ({
          ...prev,
          ...data,
        }))
      },
    },
    state: {
      formData: addCancellationPolicyFormData,
      mutationState: {
        isPending,
        isError,
        errorMessage: parseErrorMessage(error),
      },
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const AddCancellationPolicyFlowProvider = ({
  children,
}: AddCancellationPolicyFlowContextProps) => {
  return (
    <AddCancellationPolicyFlowContextProvider>
      {children}
    </AddCancellationPolicyFlowContextProvider>
  )
}

export { useAddCancellationPolicyFlowContextValue }
