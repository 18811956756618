import React, { PropsWithChildren } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Loading,
  Message,
  Text,
} from '@workwhile/ui'
import { X } from 'lucide-react'
import { useTierStatsContextValue } from '../../context/TierStatsProvider'
import { useListingsOverviewContextValue } from '../../context/ListingsOverviewProvider'
import { getTierTransitionLabel } from './utils/getTierTransitionLabel'
import { ExploreTier } from './ExploreTier/ExploreTier'

const TierTransitionExplorerSwitch = () => {
  const {
    state: { isAnyTierEligibleForExploration },
  } = useListingsOverviewContextValue()

  const {
    state: { selectedTier, tierDetails },
  } = useTierStatsContextValue()

  if (isAnyTierEligibleForExploration) {
    if (selectedTier) {
      if (tierDetails) {
        return <ExploreTier />
      }

      return (
        <Flex
          display="flex"
          alignItems="center"
          justifyContent="center"
          border="1px solid"
          borderRadius="standard"
          borderColor="neutrals.100"
          height="100%"
          p={4}
          color="lightText"
        >
          No tier details available for this tier.
        </Flex>
      )
    }

    return (
      <Flex
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="1px solid"
        borderRadius="standard"
        borderColor="neutrals.100"
        height="100%"
        p={4}
        color="lightText"
      >
        Select a tier to explore and gain deeper insights into its progress and
        results
      </Flex>
    )
  }

  return (
    <Flex
      display="flex"
      alignItems="center"
      justifyContent="center"
      border="1px solid"
      borderRadius="standard"
      borderColor="neutrals.50"
      height="100%"
      p={4}
      color="lightText"
      backgroundColor="neutrals.50"
    >
      No tier transitions to explore yet.
      <br />
      Once a tier transition is initiated or completed, you will be able to
      explore the results here.
    </Flex>
  )
}

export const TierTransitionExplorer = () => {
  const {
    state: { selectedTier },
    actions: { setSelectedTier },
  } = useTierStatsContextValue()

  return (
    <Flex
      flexDirection="column"
      p={3}
      width="100%"
      borderLeft="1px solid"
      borderColor="neutrals.100"
    >
      <Flex justifyContent="space-between" mb={4}>
        <Box>
          <Text fontSize={2} color="lightText">
            Tier Transition Explorer
          </Text>
          <Heading
            level={4}
            fontWeight="400"
            style={{
              margin: 0,
              marginBottom: 10,
            }}
          >
            {getTierTransitionLabel(selectedTier) || 'No Tier Selected'}
          </Heading>
        </Box>
        {selectedTier ? (
          <Button
            variant="secondary"
            onClick={() => setSelectedTier(undefined)}
            kind="medium"
            style={{
              padding: 6,
            }}
          >
            <X size={16} />
          </Button>
        ) : null}
      </Flex>
      <TierTransitionExplorerSwitch />
    </Flex>
  )
}
