import React, { useMemo } from 'react'
import { createContext } from 'lib/createContext'
import { Loading, Message, Flex, Heading, Box } from '@workwhile/ui'
import { TierEligibilityStats } from 'pages/Shifts/ShiftDetail/types'
import { useTierEligibilitiesByShiftIdQuery } from 'queries/shifts/useTierEligibilitiesByShiftIdQuery'
import { useShiftDetailContextValue } from '../../../context/ShiftDetailProvider'
import { parseErrorMessage } from 'api'
import { SectionHeading } from '../components/SectionHeading'

type ListingsOverviewContextState = {
  state: TierEligibilityStats & {
    dataUpdatedAt: number
    isFetching: boolean
    isError: boolean
    error: string
    isAnyTierEligibleForExploration: boolean
  }
  actions: {
    refetch: () => void
  }
}

const [Context, useListingsOverviewContextValue] =
  createContext<ListingsOverviewContextState>({
    name: 'ListingsOverviewContext',
  })

export const ListingsOverviewProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const {
    state: { id: shiftId },
  } = useShiftDetailContextValue()

  const {
    data,
    isLoading,
    isError,
    error,
    dataUpdatedAt,
    refetch,
    isFetching,
  } = useTierEligibilitiesByShiftIdQuery(shiftId)

  const isAnyTierEligibleForExploration = useMemo(() => {
    return data?.eligibilities.some(
      (eligibility) =>
        eligibility.activeStatus === 'past' ||
        eligibility.activeStatus === 'active'
    )
  }, [data])

  if (isLoading) {
    return (
      <Box>
        <SectionHeading
          dataUpdatedAt={dataUpdatedAt}
          isFetching={isFetching}
          refetch={refetch}
          isError={isError}
          error={error}
        />
        <Flex
          minHeight={200}
          justifyContent="center"
          alignItems="center"
          border="1px solid"
          borderRadius="standard"
          borderColor="neutrals.100"
        >
          <Loading />
        </Flex>
      </Box>
    )
  }

  const parsedError = parseErrorMessage(error)
  // If there is data, user is likely refetching
  if (isError && !data) {
    // eslint-disable-next-line no-console
    console.error(error)
    return (
      <Box>
        <SectionHeading
          dataUpdatedAt={dataUpdatedAt}
          isFetching={isFetching}
          refetch={refetch}
          isError={isError}
          error={parsedError}
          listingId={data?.listingId}
        />
        <Message variant="error" m={4} borderRadius="small">
          Sorry, something went wrong. Please reach out to eng support if this
          persists.
        </Message>
      </Box>
    )
  }

  const value = {
    state: {
      ...data,
      dataUpdatedAt,
      isFetching,
      isError,
      error: parsedError,
      isAnyTierEligibleForExploration,
    },
    actions: { refetch },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { useListingsOverviewContextValue }
