import React, { useRef, useState } from 'react'
import {
  Modal,
  ModalCtaContainer,
  Box,
  Button,
  Flex,
  toast,
  Loading,
} from '@workwhile/ui'
import { PositionTemplatePicker } from './PositionTemplatePicker'
import { RequirementsCompare } from './RequirementsCompare'
import { usePositionTemplate } from 'queries/company'
import { useUpdatePositionPositionTemplateMutation } from 'queries/company/useUpdatePositionPositionTemplateMutation'
import { useCurrentRequirements } from './useCurrentRequirements'
import { usePositionEditorContext } from '../PositionEditorContext'

export function PositionTemplateModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const { positionData, refetchPositionData } = usePositionEditorContext()
  const [selectedPositionTemplateId, setPositionTemplateId] = useState<number>()
  const [isComparingRequirements, setIsComparingRequirements] = useState(false)
  const {
    data: selectedPositionTemplate,
    isLoading: isLoadingSelectedPositionTemplate,
  } = usePositionTemplate(selectedPositionTemplateId)
  const selectedRequirementsRef = useRef<string[]>([])
  const currentRequirementIds = useCurrentRequirements(positionData).map((r) =>
    r.id.toString()
  )
  const { mutate: updatePositionPositionTemplate, isPending } =
    useUpdatePositionPositionTemplateMutation(Number(positionData.id), {
      onSuccess: () => {
        toast.success(
          `Position updated with new template: ${selectedPositionTemplate?.name}`
        )
        refetchPositionData()
        handleClose()
      },
      onError: (error) => {
        toast.error(error.message)
      },
    })

  const handleClose = () => {
    setPositionTemplateId(undefined)
    setIsComparingRequirements(false)
    selectedRequirementsRef.current = []
    onClose()
  }

  const handleBack = () => {
    if (isComparingRequirements) {
      setIsComparingRequirements(false)
    } else {
      handleClose()
    }
  }

  const handleNext = () => {
    if (isComparingRequirements) {
      const removedRequirementIds = currentRequirementIds.filter(
        (r) => !selectedRequirementsRef.current.includes(r)
      )
      const newRequirementIds = selectedRequirementsRef.current.filter(
        (id) => !currentRequirementIds.includes(id)
      )
      updatePositionPositionTemplate({
        newPositionTemplateId: selectedPositionTemplateId,
        removedRequirementIds,
        newRequirementIds,
      })
      return
    }

    if (
      positionData.positionTemplate?.id.toString() ===
      selectedPositionTemplateId.toString()
    ) {
      // same template, do nothing
      handleClose()
      return
    }

    // show requirements comparison screen
    setIsComparingRequirements(true)
  }

  return (
    <Modal
      // @ts-ignore
      width={[1, 'auto']}
      minWidth={[1, '70rem']}
      open={isOpen}
      onClose={handleClose}
    >
      <Flex flexDirection={'column'} width={[1, '70rem']} height={'80vh'}>
        <Box flex={1} overflow={'auto'} mt={-3}>
          {isComparingRequirements ? (
            isLoadingSelectedPositionTemplate ? (
              <Loading />
            ) : (
              <RequirementsCompare
                selectedPositionTemplate={selectedPositionTemplate}
                onRequirementsChange={(ids) => {
                  selectedRequirementsRef.current = ids
                }}
              />
            )
          ) : (
            <PositionTemplatePicker
              positionTemplateId={selectedPositionTemplateId}
              onSelect={setPositionTemplateId}
            />
          )}
        </Box>
        <ModalCtaContainer>
          {selectedPositionTemplate ? (
            <Box flex={1}>
              New template: <strong>{selectedPositionTemplate?.name}</strong>
            </Box>
          ) : null}

          <Button variant={'secondary'} kind={'medium'} onClick={handleBack}>
            {isComparingRequirements ? 'Back' : 'Cancel'}
          </Button>
          <Button
            variant={'primary'}
            kind={'medium'}
            onClick={handleNext}
            disabled={!selectedPositionTemplateId}
            loading={isPending}
          >
            {isPending
              ? 'Updating...'
              : isComparingRequirements
                ? 'Save'
                : 'Next'}
          </Button>
        </ModalCtaContainer>
      </Flex>
    </Modal>
  )
}
