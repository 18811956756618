import { useMemo } from 'react'
import { PositionTemplate } from 'api/company'

export function useVisibleTemplates({
  positionTemplates,
  keyword,
  selectedGroupId,
}: {
  positionTemplates?: PositionTemplate[]
  keyword: string
  selectedGroupId?: number
}) {
  return useMemo(() => {
    if (!positionTemplates) {
      return []
    }

    // if there is a keyword, filter the templates
    if (keyword) {
      return positionTemplates.filter((template) =>
        template.name.toLowerCase().includes(keyword.toLowerCase())
      )
    }

    // if filtered by group
    if (selectedGroupId) {
      return positionTemplates.filter((template) =>
        template.relevantPositionTemplateGroups.some(
          (group) => group.id.toString() === selectedGroupId.toString()
        )
      )
    }

    return positionTemplates
  }, [positionTemplates, selectedGroupId, keyword])
}
