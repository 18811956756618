import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addCancellationPolicy } from 'api/company/addCancellationPolicy'
import { companyDetailKey } from './keys'
import { CompanyDetail } from 'pages/Company/CompanyDetail/types'
import { addMinutes } from 'date-fns'

export const useAddCancellationPolicyMutation = (companyId: number) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (request: Parameters<typeof addCancellationPolicy>[0]) =>
      addCancellationPolicy(request),
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: companyDetailKey(companyId) })

      const previousCompanyDetail = queryClient.getQueryData(
        companyDetailKey(companyId)
      )

      // Optimistically update to the new value
      queryClient.setQueryData<CompanyDetail>(
        companyDetailKey(companyId),
        (old) => {
          const newCompanyDetail = {
            ...old,
          }

          const newUpcomingCancellationPolicy = [
            ...old.upcomingCancellationPolicies,
            {
              id: Math.floor(Math.random() * 1000000),
              isDefault: false,
              startsAt: variables.starts_at
                ? variables.starts_at
                : addMinutes(Date.now(), 1).toISOString(),
              policyWindows: variables.policy_windows.map((window) => ({
                cutoffTimeHours: window.cutoff_time,
                chargePercentage: window.charge_percentage,
              })),
            },
          ]

          newCompanyDetail.upcomingCancellationPolicies =
            newUpcomingCancellationPolicy

          return newCompanyDetail
        }
      )

      return { previousCompanyDetail }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        companyDetailKey(companyId),
        context.previousCompanyDetail
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: companyDetailKey(companyId) })
    },
  })
}
