import React from 'react'
import { AccordionCard } from 'components/AccordionCard'
import { InfoTable } from 'components/InfoTable'
import { Calendar } from 'lucide-react'
import { useCompanyGroupDetailContextValue } from '../context/CompanyGroupDetailProvider'
import { formatInTimeZone } from 'date-fns-tz'
import { getLocalTimezone } from 'lib/time'
import { formatDistanceToNowStrict } from 'date-fns'

export const GeneralDetails = () => {
  const {
    state: { createdAt },
  } = useCompanyGroupDetailContextValue()

  const timezone = getLocalTimezone()

  const createdOnDay = formatInTimeZone(createdAt, timezone, 'MMM d, yyyy')

  const createdOnDayDistance = formatDistanceToNowStrict(createdAt, {
    addSuffix: true,
  })

  return (
    <AccordionCard title="General Details" size="small">
      <InfoTable
        style={{ textAlign: 'right', maxWidth: '80%', tableLayout: 'auto' }}
      >
        <InfoTable.Row
          icon={<Calendar size={14} />}
          label="Created on"
          value={`${createdOnDay} (${createdOnDayDistance})`}
        />
      </InfoTable>
    </AccordionCard>
  )
}
