import React from 'react'
import { ManageLogoFlowContextProvider } from './context/ManageLogoFlowContextProvider'
import { ManageLogoFlowMain } from './components/ManageLogoFlowMain'

export const ManageLogoFlow = () => {
  return (
    <ManageLogoFlowContextProvider>
      <ManageLogoFlowMain />
    </ManageLogoFlowContextProvider>
  )
}
