import { WorkStatus } from 'api/typings'
import { createContext } from 'lib/createContext'
import React, { PropsWithChildren, useState } from 'react'

type Workflow<Args extends Record<string, unknown> | undefined = undefined> = {
  open: Args extends undefined ? () => void : (args: Args) => void
  close: () => void
  isOpen: boolean
} & (Args extends undefined ? object : { data?: Args })

type WorkflowState<T extends Record<string, unknown> | undefined = undefined> =
  T extends undefined
    ? boolean
    : {
        isOpen: boolean
        data?: T
      }

export type EditWorkFlowState = {
  shiftId: number
  workId: number
  worker: {
    id: number
    name: string
  }
  status: WorkStatus
  clockedInAt: string
  clockedOutAt: string
  leftEarly: boolean
  timezone: string
  shiftStartsAt: string
}

/**
 * Workflow = small, independent and executable unit of work with one or more steps (ideally 1-5 steps)
 *
 * Global Workflow allows workflows to be launched from anywhere from the app. Therefore, you must be mindful about the performance + usage of API calls.
 *
 * Workflow for UX atm is Modal based. Separate pages are not considered to be "workflows"
 *
 * For eg.
 * - Edit some attributes of shift could belong here
 * - Editing the whole shift MUST NOT be a modal and rather be a separate page
 *
 * If you're unsure whether your usecase could be part of a workflow, consult the frontend engineering team
 */
export type GlobalWorkflowsContextState = {
  editShiftWorkFlow: Workflow<EditWorkFlowState>
}

const [GlobalWorkflowsContext, useGlobalWorkflowsContextValue] =
  createContext<GlobalWorkflowsContextState>({
    name: 'GlobalWorkflowsContext',
  })

type GlobalWorkflowsProviderProps = PropsWithChildren

export const GlobalWorkflowsProvider = ({
  children,
}: GlobalWorkflowsProviderProps) => {
  const [editShiftWorkFlow, setEditShiftWorkFlow] = useState<
    WorkflowState<EditWorkFlowState>
  >({ isOpen: false, data: undefined })

  const value: GlobalWorkflowsContextState = {
    editShiftWorkFlow: {
      open: (data) => setEditShiftWorkFlow({ isOpen: true, data }),
      close: () => setEditShiftWorkFlow({ isOpen: false, data: undefined }),
      isOpen: editShiftWorkFlow.isOpen,
      data: editShiftWorkFlow.data,
    },
  }

  return (
    <GlobalWorkflowsContext.Provider value={value}>
      {children}
    </GlobalWorkflowsContext.Provider>
  )
}

export { useGlobalWorkflowsContextValue }
