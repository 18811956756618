import { Flex, Box, Button, DropdownMenu } from '@workwhile/ui'
import { Ellipsis, Plus } from 'lucide-react'
import React from 'react'
import { useCompaniesContextValue } from '../context/CompaniesProvider'
import { QueryStatus } from 'components/QueryStatus'
import { Header } from 'components/Header'
import { CompaniesTableContainer } from './CompaniesTableContainer'
import { Search } from './Search'
import { useCompaniesWorkflowsContextValue } from '../context/CompaniesWorkflowsProvider'

const CompaniesHeader = () => {
  const { state, actions } = useCompaniesContextValue()
  const { createCompanyFlow } = useCompaniesWorkflowsContextValue()

  return (
    <Header
      title="Companies"
      rightContent={
        <Flex alignItems="center" gap={2}>
          <QueryStatus
            dataUpdatedAt={state.dataUpdatedAt}
            isFetching={state.isFetching}
            refetch={actions.refetch}
            isError={state.isError}
            error={state.error}
          />
          <DropdownMenu
            trigger={
              <Button variant="secondary" style={{ padding: 4 }} kind="medium">
                <Ellipsis />
              </Button>
            }
          >
            <DropdownMenu.Item onClick={createCompanyFlow.open} asChild>
              <Flex as="span" alignItems="center" style={{ gap: 8 }}>
                <Plus size={14} />
                Create Company
              </Flex>
            </DropdownMenu.Item>
          </DropdownMenu>
        </Flex>
      }
    />
  )
}

export const CompaniesMain = () => {
  return (
    <>
      <CompaniesHeader />
      <Box margin={3}>
        <Search />
        <CompaniesTableContainer />
      </Box>
    </>
  )
}
