import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import UserRecord from './UserRecord'
import { Box, Card, Flex, Loading, Message } from '@workwhile/ui'
import { useSearchUsers } from 'queries/user'
import { SearchBox, SearchParams } from './SearchBox'

const Users = () => {
  // hooks to get and set url params
  const { keyword }: SearchParams = useParams<SearchParams>()
  const {
    data: users,
    error,
    refetch,
    isFetching,
  } = useSearchUsers({
    keyword,
    enabled: false, // trigger manually on click
  })

  useEffect(() => {
    if (keyword) {
      refetch()
    }
  }, [keyword])

  return (
    <Flex flexDirection={'column'} height={'100vh'}>
      <SearchBox isLoading={isFetching} />
      {isFetching && !users ? (
        <Loading />
      ) : (
        <Box flex={1} overflow={'auto'}>
          {error ? <Message variant={'error'}>{error.message}</Message> : null}
          {(users ?? []).map((user) => (
            <Card key={user.id} m={4} p={4} backgroundColor={'offWhite50'}>
              <UserRecord search={refetch} user={user} />
            </Card>
          ))}
        </Box>
      )}
    </Flex>
  )
}

export default Users
