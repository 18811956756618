import React, { useState } from 'react'
import { Box as ChakraBox, Button, Flex, Input, Text } from '@workwhile/ui'
import { SearchIcon } from 'lucide-react'
import PlacesAutocomplete from 'react-places-autocomplete'

interface SearchBoxProps {
  isLoading: boolean
  onLocationSelect: (address: string) => void
}

export function SearchBox({ isLoading, onLocationSelect }: SearchBoxProps) {
  const [address, setAddress] = useState('')

  const handleSelect = async (selectedAddress: string) => {
    setAddress(selectedAddress)
    onLocationSelect(selectedAddress)
  }

  return (
    <Flex p={3} px={4} backgroundColor={'offWhite'}>
      <ChakraBox width={400} mr={2}>
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <ChakraBox position="relative">
              <Input
                {...getInputProps({
                  placeholder: 'Enter a location...',
                  disabled: isLoading,
                })}
                block
                icon={<SearchIcon size={18} />}
              />
              {suggestions.length > 0 && (
                <ChakraBox
                  position="absolute"
                  top="100%"
                  left={0}
                  right={0}
                  bg="white"
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="md"
                  boxShadow="sm"
                  maxHeight="300px"
                  overflowY="auto"
                  zIndex={1000}
                >
                  {loading && (
                    <ChakraBox p={3} textAlign="center" color="gray.500">
                      loading...
                    </ChakraBox>
                  )}
                  {suggestions.map((suggestion) => (
                    <ChakraBox
                      {...getSuggestionItemProps(suggestion)}
                      key={suggestion.placeId}
                      p={3}
                      cursor="pointer"
                      _hover={{ bg: 'gray.50' }}
                      borderBottom="1px solid"
                      borderColor="gray.100"
                      _last={{ borderBottom: 'none' }}
                    >
                      <Text>{suggestion.description}</Text>
                    </ChakraBox>
                  ))}
                </ChakraBox>
              )}
            </ChakraBox>
          )}
        </PlacesAutocomplete>
      </ChakraBox>
      <Button width={150} disabled={isLoading}>
        Search
      </Button>
    </Flex>
  )
}
