import React from 'react'
import { Flex, Heading, Label, Link, useAppTheme } from '@workwhile/ui'
import { ChevronRight } from 'lucide-react'
import { useShiftDetailContextValue } from '../../context/ShiftDetailProvider'

export const DynamicPageTitle = () => {
  const {
    state: { id, company },
  } = useShiftDetailContextValue()
  const { colors } = useAppTheme()

  return (
    <Flex
      flexDirection={['column', 'column', 'row']}
      alignItems={['flex-start', 'flex-start', 'center']}
      gap={3}
    >
      <Heading level={1} fontWeight={'normal'} style={{ margin: 0 }}>
        {company.name}{' '}
        <Link to={`/companies/${company.id}`} target="_blank">
          ({company.id})
        </Link>
      </Heading>

      <ChevronRight
        size={32}
        color={colors.neutrals[200]}
        absoluteStrokeWidth
      />

      <Heading level={1} fontWeight={'normal'} style={{ margin: 0 }}>
        {id}
      </Heading>
    </Flex>
  )
}
