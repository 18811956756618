import React from 'react'
import { AccordionCard } from 'components/AccordionCard'
import { Flex, Text } from '@workwhile/ui'
import { ShiftListFilters } from './ShiftListFilters/ShiftListFilters'
import { ShiftsTable } from './ShiftsTable'
import { ColumnsFilter } from './ShiftsTable/ColumnsFilter/ColumnsFilter'
import { QueryStatus } from 'components/QueryStatus'
import { useCompanyDetailContextValue } from '../../context/CompanyDetailProvider'

export const Shifts = () => {
  const {
    state: {
      shifts: { isFetching, isError, error, dataUpdatedAt },
    },
    actions: {
      shifts: { refetch },
    },
  } = useCompanyDetailContextValue()

  return (
    <AccordionCard
      title={
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Text fontSize={2} color="lightText">
            Shifts
          </Text>
          <QueryStatus
            isFetching={isFetching}
            isError={isError}
            error={error}
            refetch={refetch}
            dataUpdatedAt={dataUpdatedAt}
          />
        </Flex>
      }
      // Intentionally disabled. This should work, unsure why it's throwing an error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      width="100%"
      isAccordion
    >
      <Flex justifyContent="space-between" alignItems="center" mb={3}>
        <ShiftListFilters />
        <ColumnsFilter />
      </Flex>
      <ShiftsTable />
    </AccordionCard>
  )
}
