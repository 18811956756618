import React from 'react'
import { CreateCompanyFlowProvider } from './context/CreateCompanyFlowProvider'
import { CreateCompanyFlowMain } from './components/CreateCompanyFlowMain'

export const CreateCompanyFlow = () => {
  return (
    <CreateCompanyFlowProvider>
      <CreateCompanyFlowMain />
    </CreateCompanyFlowProvider>
  )
}
