import React, { Children, ReactNode } from 'react'
import { Box, BoxProps, Flex, useAppTheme } from '@workwhile/ui'
import { LucideIcon } from 'lucide-react'

type FlexibleInfoTable = BoxProps & {
  Icon: LucideIcon
  children: ReactNode
  iconColor?: string
}

export const FlexibleInfoTable = ({
  Icon,
  children,
  iconColor,
  ...rest
}: FlexibleInfoTable) => {
  const { colors, space } = useAppTheme()
  const isSingleLine = Children.count(children) === 1
  return (
    <Flex
      flexDirection="row"
      alignItems={isSingleLine ? 'center' : 'flex-start'}
      style={{ gap: space[2] }}
      {...rest}
    >
      <Icon
        size={14}
        color={iconColor || colors.neutrals[400]}
        style={isSingleLine ? { marginTop: -1 } : { marginTop: space[1] }}
      />
      {isSingleLine ? children : <Box>{children}</Box>}
    </Flex>
  )
}
