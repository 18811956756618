import * as zod from 'zod'

export const addCancellationPolicySchema = zod.object({
  startsAt: zod.date({
    required_error: 'Start date is required',
  }),
  policyWindows: zod
    .array(
      zod.object({
        cutoffTime: zod.coerce
          .number({
            invalid_type_error: 'Cutoff time hours must be a number',
            required_error: 'Cutoff time hours is required',
          })
          .min(1, { message: 'Cutoff time hours is required' })
          .max(48, { message: 'Cutoff time hours must be less than 48' }),
        chargePercentage: zod.coerce
          .number({
            invalid_type_error: 'Charge percentage must be a number',
            required_error: 'Charge percentage is required',
          })
          .min(1, { message: 'Charge percentage is required' })
          .max(100, { message: 'Charge percentage must be less than 100' }),
      }),
      {
        required_error: 'Please add at least one window',
      }
    )
    .min(1, { message: 'Please add at least one window' }),
})

export type AddCancellationPolicySchema = zod.infer<
  typeof addCancellationPolicySchema
>
