import React from 'react'
import {
  Controller,
  useFieldArray,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form'
import { FiltersSchema } from '../filtersSchema'
import { Box, Button, Field, Flex, Input, useAppTheme } from '@workwhile/ui'
import { CircleMinus } from 'lucide-react'

const AddedShiftRow = ({
  remove,
  index,
  id,
  isLast,
}: {
  remove: UseFieldArrayRemove
  index: number
  id: number
  isLast: boolean
}) => {
  const { colors, radii, space } = useAppTheme()
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={space[2]}
      mb={isLast ? 0 : space[2]}
    >
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        border="1px solid"
        borderColor={colors.neutrals[100]}
        p={2}
        borderRadius={radii.small}
        width="100%"
        position="relative"
      >
        {id}
      </Flex>
      <Button
        onClick={() => remove(index)}
        variant="text"
        color="red"
        size="small"
        danger
        style={{
          margin: 0,
          height: '1.6rem', // This is to resolve a bug in our UI library where the width and height is being set to `small` (literally) instead of a `px` value
        }}
      >
        <CircleMinus size={16} />
      </Button>
    </Flex>
  )
}

const AddedShiftIds = () => {
  const { watch, control } = useFormContext<FiltersSchema>()

  const { remove } = useFieldArray({
    name: 'shift.ids',
    control,
  })

  const shiftIds = watch('shift.ids')

  const addedShiftRows = shiftIds.map(({ id }, index) => (
    <AddedShiftRow
      key={`added-shift-${id}-${index}`}
      remove={remove}
      index={index}
      id={id}
      isLast={index === shiftIds.length - 1}
    />
  ))
  return <>{addedShiftRows}</>
}

export const ShiftIdsFilter = () => {
  const { control, trigger, getValues, resetField } =
    useFormContext<FiltersSchema>()

  const { append } = useFieldArray({
    name: 'shift.ids',
    control,
  })

  return (
    <Box>
      <Controller
        control={control}
        name="shift.shiftIdUserInput"
        render={({ field, fieldState: { error, invalid } }) => (
          <Field error={error?.message?.toString()}>
            <Flex gap={2}>
              <Input
                placeholder={`e.g. 331281`}
                {...field}
                // We're explicitly setting the type to string to prevent react from throwing an error
                // This is a limitation of react-hook-form. See https://github.com/shadcn-ui/ui/issues/410 for more details
                value={field.value ?? ''}
                type="number"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mb={2}
              />
              <Button
                variant="primary"
                disabled={invalid}
                onClick={async () => {
                  const isInputValid = await trigger('shift.shiftIdUserInput')
                  const shiftId = Number(getValues('shift.shiftIdUserInput'))
                  const addedShifts = getValues('shift.ids')
                  const doesInputExistInCurrentGroup = addedShifts.some(
                    (shift) => shift.id === shiftId
                  )

                  const isValid = isInputValid && !doesInputExistInCurrentGroup
                  if (isValid) {
                    append({ id: Number(shiftId) })
                    resetField('shift.shiftIdUserInput')
                  }

                  if (doesInputExistInCurrentGroup) {
                    resetField('shift.shiftIdUserInput')
                  }
                }}
              >
                Add
              </Button>
            </Flex>
          </Field>
        )}
      />

      <AddedShiftIds />
    </Box>
  )
}
