import React, { useState } from 'react'

import * as zod from 'zod'
import { editCompanyNameFlowSchema } from '../schemas/companyName'
import { createContext } from 'lib/createContext'
import { useEditCompanyNameMutation } from 'queries/company'
import { useCompanyDetailContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailProvider'
import { parseErrorMessage } from 'api'

type EditCompanyNameFlowProviderContextState = {
  actions: {
    submitFormToServer: ReturnType<typeof useEditCompanyNameMutation>['mutate']
    setFormData: (data: zod.infer<typeof editCompanyNameFlowSchema>) => void
  }
  state: {
    formData: zod.infer<typeof editCompanyNameFlowSchema>
    mutationState: {
      isPending: boolean
      isError: boolean
      errorMessage: string | undefined
    }
  }
}

const [Context, useEditCompanyNameFlowContextValue] =
  createContext<EditCompanyNameFlowProviderContextState>({
    name: 'EditCompanyNameFlowContext',
  })

type EditCompanyNameFlowContextProps = {
  children?: React.ReactNode
}

const EditCompanyNameFlowContextProvider = ({
  children,
}: EditCompanyNameFlowContextProps) => {
  const [editCompanyNameFormData, setEditCompanyNameFormData] = useState<
    zod.infer<typeof editCompanyNameFlowSchema>
  >({})

  const {
    state: { id: companyId },
  } = useCompanyDetailContextValue()

  const {
    mutate: editCompanyNameMutation,
    isPending,
    isError,
    error,
  } = useEditCompanyNameMutation(companyId)

  const value: EditCompanyNameFlowProviderContextState = {
    actions: {
      submitFormToServer: editCompanyNameMutation,
      setFormData: (data: zod.infer<typeof editCompanyNameFlowSchema>) => {
        setEditCompanyNameFormData((prev) => ({
          ...prev,
          ...data,
        }))
      },
    },
    state: {
      formData: editCompanyNameFormData,
      mutationState: {
        isPending,
        isError,
        errorMessage: parseErrorMessage(error),
      },
    },
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const EditCompanyNameFlowProvider = ({
  children,
}: EditCompanyNameFlowContextProps) => {
  return (
    <EditCompanyNameFlowContextProvider>
      {children}
    </EditCompanyNameFlowContextProvider>
  )
}

export { useEditCompanyNameFlowContextValue }
