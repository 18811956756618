import { useMutation, useQueryClient } from '@tanstack/react-query'
import { companyDetailKey } from './keys'
import { CompanyDetail } from 'pages/Company/CompanyDetail/types'
import { editCompanyName } from 'api/company/editCompanyName'

export const useEditCompanyNameMutation = (companyId: number) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ companyName }: { companyName: string }) =>
      editCompanyName(companyId, companyName),
    onMutate: async ({ companyName }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: companyDetailKey(companyId) })

      const previousCompanyDetail = queryClient.getQueryData(
        companyDetailKey(companyId)
      )

      // Optimistically update to the new value
      queryClient.setQueryData<CompanyDetail>(
        companyDetailKey(companyId),
        (old) => ({
          ...old,
          name: companyName,
        })
      )

      return { previousCompanyDetail }
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(
        companyDetailKey(companyId),
        context.previousCompanyDetail
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: companyDetailKey(companyId) })
    },
  })
}
