import api from 'api'
import { CompanyDetail } from 'pages/Company/CompanyDetail/types'

type EditGeneralInfoRequest = {
  type?: CompanyDetail['type']
}

export const editGeneralInfo = async (
  companyId: number,
  request: EditGeneralInfoRequest
): Promise<void> => {
  const response = await api.patch(`/admin/company/${companyId}`, request)
  return response.data
}
