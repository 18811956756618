import React from 'react'
import { Flex } from '@workwhile/ui'
import { TierTransitions } from './TierTransitions'
import { TierTransitionExplorer } from './TierTransitionExplorer'
import { useQueryBasedFeatureFlag } from 'pages/Shifts/ShiftDetail/hooks/useQueryBasedFeatureFlag'

export const TierInsightsPanel = () => {
  const { tierExploration } = useQueryBasedFeatureFlag()

  return (
    <Flex
      flexDirection="row"
      maxHeight="80vh"
      border="1px solid"
      borderRadius="standard"
      borderColor="neutrals.100"
      width={tierExploration ? '100%' : 'fit-content'}
    >
      <TierTransitions />
      {tierExploration ? <TierTransitionExplorer /> : null}
    </Flex>
  )
}
