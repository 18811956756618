import React, { ReactNode } from 'react'
import { Button, Flex, Text, Timeline, useAppTheme } from '@workwhile/ui'
import { useListingsOverviewContextValue } from '../../context/ListingsOverviewProvider'
import { getTierTransitionLabel } from './utils/getTierTransitionLabel'
import { StatCard } from '../../../StatCard'
import { TierTransitionsReferencePopover } from './TierTransitionsReferencePopover'
import { formatNumber } from 'lib/number'
import { useShiftDetailContextValue } from 'pages/Shifts/ShiftDetail/context/ShiftDetailProvider'
import { TierEligibility } from 'pages/Shifts/ShiftDetail/types'
import { getTierTransitionTimestamp } from './utils/getTierTransitionTimestamp'
import { useTierStatsContextValue } from '../../context/TierStatsProvider'
import { Circle, CircleCheck, Loader } from 'lucide-react'
import { useQueryBasedFeatureFlag } from '../../../../hooks/useQueryBasedFeatureFlag'

export const TierTransitions = () => {
  const {
    state: { eligibilities },
  } = useListingsOverviewContextValue()
  const { tierExploration } = useQueryBasedFeatureFlag()
  const {
    state: {
      location: {
        address: { timezone },
      },
    },
  } = useShiftDetailContextValue()

  const {
    state: { selectedTier },
    actions: { setSelectedTier },
  } = useTierStatsContextValue()

  const { colors } = useAppTheme()

  const colorMap: Record<TierEligibility['activeStatus'], string> = {
    not_started: colors.neutrals[200],
    active: colors.primaries[200],
    past: colors.neutrals[200],
  }

  const bulletMap: Record<TierEligibility['activeStatus'], ReactNode> = {
    not_started: (
      <Circle size={14} strokeWidth={3} color={colorMap['not_started']} />
    ),
    active: <Loader size={14} strokeWidth={3} color={colorMap['active']} />,
    past: <CircleCheck size={14} strokeWidth={3} color={colorMap['past']} />,
  }

  const timelineItems = eligibilities.map((eligibility, index) => {
    const isPossibleToExplore =
      eligibility.activeStatus !== 'not_started' && tierExploration
    const timeString = getTierTransitionTimestamp(eligibility, timezone)
    const bullet = bulletMap[eligibility.activeStatus]

    return (
      <Timeline.Item
        key={`tier-transition-${eligibility.tier}-${index}`}
        bullet={bullet}
        color={colorMap[eligibility.activeStatus]}
      >
        <Text color="lightText" fontSize={1}>
          {timeString}
        </Text>
        <Text fontSize={3}>{getTierTransitionLabel(eligibility.tier)}</Text>
        <Flex gap={3} my={2} flexWrap="wrap">
          <StatCard
            title="Total"
            value={formatNumber(eligibility.total)}
            size="fit"
          />
          <StatCard
            title="Eligible"
            value="-"
            // FIXME: Add this back in once we have the data
            // value={formatNumber(eligibility.eligible)}
            size="fit"
          />
          {eligibility.activeStatus !== 'not_started' ? (
            <StatCard
              title="Views"
              value="-"
              // FIXME: Add this back in once we have the data
              //value={formatNumber(eligibility.views)}
              size="fit"
            />
          ) : null}
        </Flex>
        {isPossibleToExplore ? (
          <Button
            variant="primary"
            kind="medium"
            disabled={selectedTier === eligibility.tier}
            onClick={() => setSelectedTier(eligibility.tier)}
            mt={3}
          >
            {selectedTier === eligibility.tier ? 'Selected' : 'Explore'}
          </Button>
        ) : null}
      </Timeline.Item>
    )
  })

  return (
    <Flex flexDirection="column" width={360} p={3}>
      <Text fontSize={2} color="lightText">
        Tier Transitions
        <TierTransitionsReferencePopover />
      </Text>
      <Text fontSize={1}>
        Explore Tier Transition to spot trends and make quick informed decisions
      </Text>
      {timelineItems.length > 0 ? (
        <Timeline mt={4} overflowY="auto">
          {timelineItems}
        </Timeline>
      ) : (
        <Text mt={4} color="lightText">
          No transitions to explore
        </Text>
      )}
    </Flex>
  )
}
