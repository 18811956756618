import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { getAllShifts } from 'api/shifts/getAllShifts'
import { shiftsKeyWithParams } from './keys'

export const useShiftsQuery = (request: Parameters<typeof getAllShifts>[0]) => {
  return useQuery({
    queryKey: shiftsKeyWithParams(Object.values(request)),
    queryFn: () => getAllShifts(request),
    placeholderData: keepPreviousData,
  })
}
