import api from 'api'
import { CompanyGroups } from 'pages/CompanyGroups/types'

export const createCompanyGroup = async (name: string) => {
  // We use this response to add the new company group to the list of company groups without
  // having to refetch the list of company groups
  const response = await api.post<CompanyGroups[number]>(
    `/admin/company_group`,
    {
      name,
    }
  )

  return response.data
}
