import React, { useState, useCallback } from 'react'
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerClusterer,
  InfoWindow,
  Libraries,
} from '@react-google-maps/api'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import api from '../../../api'
import { GOOGLE_MAPS_API_KEY } from 'env'
import { Box, Card, Flex, Link, Loading, Message, Text } from '@workwhile/ui'
import { SearchBox } from './SearchBox'
import { useFilteredData, WorkerData } from './useFilteredData'
import { WorkerFilters } from './WorkerFilters'

// Define libraries outside of the component
const libraries: Libraries = ['places']

interface WorkerLocation {
  lat: number
  lng: number
}

interface NearbyCompany {
  id: string
  name: string
  positions: string[]
  shift_count: number
  locations: [number, number][]
}

interface WageRecommendation {
  market: string
  position_type: string
  shift_length_category: string
  lower_bound: number
  upper_bound: number
}

const WorkerSupply: React.FC = () => {
  const [address, setAddress] = useState('')
  const [mapCenter, setMapCenter] = useState<WorkerLocation>({ lat: 0, lng: 0 })
  const [workerData, setWorkerData] = useState<WorkerData | null>(null)
  const [markerOptions, setMarkerOptions] = useState({})
  const [isLoadingWorkerData, setIsLoadingWorkerData] = useState(false)
  const [isLoadingNearbyCompanies, setIsLoadingNearbyCompanies] =
    useState(false)
  const [error, setError] = useState<string | null>(null)
  const [nearbyCompanies, setNearbyCompanies] = useState<NearbyCompany[]>([])
  const [selectedCompany, setSelectedCompany] = useState<NearbyCompany | null>(
    null
  )
  const [wageRecommendations, setWageRecommendations] = useState<
    WageRecommendation[]
  >([])
  const [isLoadingWageRecommendations, setIsLoadingWageRecommendations] =
    useState(false)

  // Initialize with reasonable defaults
  const [filterInputs, setFilterInputs] = useState({
    distance: 70,
    minShifts: 0,
    lastActive: 30,
    isW2Active: false,
    isW2Eligible: false,
  })

  const [appliedFilters, setAppliedFilters] = useState(filterInputs)

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  })

  const handleSelect = async (value: string) => {
    // Reset all states
    setWorkerData(null)
    setNearbyCompanies([])
    setWageRecommendations([])
    setSelectedCompany(null)
    setError(null)

    try {
      const results = await geocodeByAddress(value)
      const latLng = await getLatLng(results[0])
      setAddress(value)
      setMapCenter(latLng)
      await Promise.all([
        fetchWorkerData(value),
        fetchNearbyCompanies(value),
        fetchWageRecommendations(value),
      ])
    } catch (error) {
      setError('Error fetching data. Please try again.')
    }
  }

  const fetchWorkerData = async (location: string) => {
    setIsLoadingWorkerData(true)
    try {
      const response = await api.get(
        `/admin/worker_supply?location=${encodeURIComponent(location)}`
      )
      setWorkerData(response.data)
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message ||
        'Error fetching worker data. Please try again.'
      setError(errorMessage)
    } finally {
      setIsLoadingWorkerData(false)
    }
  }

  const fetchNearbyCompanies = async (location: string) => {
    setIsLoadingNearbyCompanies(true)
    try {
      const response = await api.get(
        `/admin/nearby_clients?location=${encodeURIComponent(location)}`
      )
      setNearbyCompanies(response.data)
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message ||
        'Error fetching nearby companies. Please try again.'
      setError(errorMessage)
    } finally {
      setIsLoadingNearbyCompanies(false)
    }
  }

  const fetchWageRecommendations = useCallback(async (location: string) => {
    setIsLoadingWageRecommendations(true)
    try {
      const response = await api.get(
        `/admin/wage_recommendations?location=${encodeURIComponent(location)}`
      )
      setWageRecommendations(response.data)
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message ||
        'Error fetching wage recommendations. Please try again.'
      setError(errorMessage)
    } finally {
      setIsLoadingWageRecommendations(false)
    }
  }, [])

  const mapOptions = {
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ],
  }

  const onLoad = useCallback(() => {
    setMarkerOptions({
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 3,
        fillColor: '#FF0000',
        fillOpacity: 0.5,
        strokeWeight: 0,
      },
    })
  }, [])

  // Add validation to filter changes
  const handleFilterChange = (field: string, value: string | boolean) => {
    if (field === 'isW2Active' || field === 'isW2Eligible') {
      setFilterInputs((prev) => ({ ...prev, [field]: value as boolean }))
    } else {
      const numValue = value === '' ? 0 : Number(value)
      if (!isNaN(numValue)) {
        const validatedValue = Math.max(numValue, field === 'distance' ? 1 : 0)
        setFilterInputs((prev) => ({ ...prev, [field]: validatedValue }))
      }
    }
  }

  // Apply all filters at once
  const applyFilters = () => {
    setAppliedFilters(filterInputs)
  }

  const stats = useFilteredData(workerData, appliedFilters)

  if (loadError) {
    return (
      <Message variant={'error'}>
        Map cannot be loaded right now, sorry.
      </Message>
    )
  }

  if (!isLoaded) {
    return <Loading />
  }

  return (
    <Flex flexDirection={'column'} p={2}>
      <Box mb={3}>
        <SearchBox
          isLoading={isLoadingWorkerData}
          onLocationSelect={handleSelect}
        />
      </Box>

      {workerData ? (
        <Box mb={3}>
          <Card p={3} backgroundColor={'offWhite50'}>
            <Flex gap={4}>
              <Text>
                In Existing Markets:{' '}
                <Text
                  as="span"
                  color={workerData.in_market ? 'primary' : 'error'}
                >
                  {workerData.in_market
                    ? 'Yes'
                    : 'No, please reach out to ops before shifts are posted'}
                </Text>
              </Text>
              <Text>
                W2 State:{' '}
                <Text
                  as="span"
                  color={workerData.is_w2_state ? 'primary' : 'error'}
                >
                  {workerData.is_w2_state ? 'Yes' : 'No'}
                </Text>
              </Text>
              <Text>
                W2 Auto Eligibility State:{' '}
                <Text
                  as="span"
                  color={
                    workerData.is_w2_auto_eligibility_state
                      ? 'primary'
                      : 'error'
                  }
                >
                  {workerData.is_w2_auto_eligibility_state ? 'Yes' : 'No'}
                </Text>
              </Text>
            </Flex>
          </Card>
        </Box>
      ) : null}

      <Box mb={3}>
        {isLoadingWorkerData ? (
          <Loading />
        ) : (
          workerData && (
            <Card p={6} backgroundColor={'offWhite50'}>
              <WorkerFilters
                workerData={workerData}
                filterInputs={filterInputs}
                appliedFilters={appliedFilters}
                stats={stats}
                onFilterChange={handleFilterChange}
                onApplyFilters={applyFilters}
              />
              <Box height="500px">
                <GoogleMap
                  onLoad={onLoad}
                  center={mapCenter}
                  zoom={10}
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  options={mapOptions}
                >
                  <MarkerClusterer>
                    {(clusterer) => (
                      <>
                        {workerData.users.map((user, index) => (
                          <Marker
                            key={`worker-${index}`}
                            position={{
                              lat: user.lat,
                              lng: user.lng,
                            }}
                            options={markerOptions}
                            clusterer={clusterer}
                          />
                        ))}
                      </>
                    )}
                  </MarkerClusterer>
                </GoogleMap>
              </Box>
            </Card>
          )
        )}
      </Box>

      <Box mb={3}>
        {isLoadingNearbyCompanies ? (
          <Loading />
        ) : (
          nearbyCompanies.length > 0 && (
            <Card p={6} backgroundColor={'offWhite50'}>
              <Text fontSize={24} fontWeight="bold" mb={4}>
                Nearby Companies
              </Text>
              <Box height="500px">
                <GoogleMap
                  center={mapCenter}
                  zoom={10}
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  options={mapOptions}
                >
                  {nearbyCompanies.map((company) =>
                    company.locations.map((location, index) => (
                      <Marker
                        key={`company-${company.id}-${index}`}
                        position={{ lat: location[0], lng: location[1] }}
                        onClick={() => setSelectedCompany(company)}
                        icon={{
                          path: google.maps.SymbolPath.CIRCLE,
                          scale: 7,
                          fillColor: '#0000FF',
                          fillOpacity: 0.7,
                          strokeWeight: 1,
                          strokeColor: '#FFFFFF',
                        }}
                      />
                    ))
                  )}
                  {selectedCompany ? (
                    <InfoWindow
                      position={{
                        lat: selectedCompany.locations[0][0],
                        lng: selectedCompany.locations[0][1],
                      }}
                      onCloseClick={() => setSelectedCompany(null)}
                    >
                      <div>
                        <h3>
                          {selectedCompany.name}{' '}
                          <Link
                            to={`/companies/${selectedCompany.id}`}
                            target="_blank"
                          >
                            ({selectedCompany.id})
                          </Link>
                        </h3>
                        <p>Shift Count: {selectedCompany.shift_count}</p>
                      </div>
                    </InfoWindow>
                  ) : null}
                </GoogleMap>
              </Box>
            </Card>
          )
        )}
      </Box>

      {wageRecommendations.length > 0 && (
        <Card p={6} mb={3}>
          <Box mb={4}>
            <Text fontSize={24} fontWeight="bold">
              Worker Pay Range Recommendations
            </Text>
            <Text variant="subtitle" color="neutrals.300" my={1}>
              Wage data is based on shifts with 85% or higher schedule rates,
              using the base pay rate as well as bonus applied, if any. The
              bottom end of the range represents the average wage and the upper
              end represents the 90th percentile among such shifts.
            </Text>
          </Box>
          <Box overflowX="auto">
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr style={{ borderBottom: '2px solid #E2E8F0' }}>
                  <th style={{ padding: '12px', textAlign: 'left' }}>
                    Position
                  </th>
                  {Array.from(
                    new Set(
                      wageRecommendations.map((w) => w.shift_length_category)
                    )
                  ).map((length) => (
                    <th
                      key={length}
                      style={{ padding: '12px', textAlign: 'right' }}
                    >
                      {length}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Array.from(
                  new Set(wageRecommendations.map((w) => w.position_type))
                ).map((position) => (
                  <tr
                    key={position}
                    style={{ borderBottom: '1px solid #E2E8F0' }}
                  >
                    <td style={{ padding: '12px' }}>{position}</td>
                    {Array.from(
                      new Set(
                        wageRecommendations.map((w) => w.shift_length_category)
                      )
                    ).map((length) => {
                      const wage = wageRecommendations.find(
                        (w) =>
                          w.position_type === position &&
                          w.shift_length_category === length
                      )
                      return (
                        <td
                          key={length}
                          style={{ padding: '12px', textAlign: 'right' }}
                        >
                          {wage
                            ? `$${wage.lower_bound.toFixed(2)} - $${wage.upper_bound.toFixed(2)}`
                            : '-'}
                        </td>
                      )
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </Card>
      )}
    </Flex>
  )
}

export default WorkerSupply
