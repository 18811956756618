import api from 'api'

type EditMoneyRequest = {
  companyId: number
  invoiceDueDays?: number
  // Only used for 1099 companies
  takeRate?: number
  bonusTakeRate?: number
  tipTakeRate?: number

  // Only used for W2 companies
  w2RegularTakeRate?: number
  w2DoubleTakeRate?: number
  w2OvertimeTakeRate?: number
}

export const editMoney = async (data: EditMoneyRequest) => {
  const response = await api.put(`/admin/company/${data.companyId}/money`, {
    // Only used for 1099 companies
    take_rate: data.takeRate,
    bonus_take_rate: data.bonusTakeRate,
    tip_take_rate: data.tipTakeRate,

    // Only used for W2 companies
    w2_regular_take_rate: data.w2RegularTakeRate,
    w2_double_take_rate: data.w2DoubleTakeRate,
    w2_overtime_take_rate: data.w2OvertimeTakeRate,

    // Only used for companies that have payment method set to "invoice"
    invoice_due_days: data.invoiceDueDays,
  })

  return response.data
}
