import { Box, Flex, Heading } from '@workwhile/ui'
import React from 'react'
import { ScheduleRate } from './ScheduleRate'
import { ConfidenceAndCushion } from './ConfidenceAndCushion'
import { ShiftWorkerStats } from './ShiftWorkerStats'
import { WorkerSupplyInsights } from './WorkerSupplyInsights'
import { TotalListingViews } from './TotalListingViews'
import { FillRate } from './FillRate'

export const QuickStats = () => {
  return (
    <Box>
      <Heading level={2} fontWeight="400">
        Quick Stats
      </Heading>
      <Flex gap={3} flexWrap="wrap">
        <ScheduleRate />
        <FillRate />
        <ConfidenceAndCushion />
        <ShiftWorkerStats />
        <WorkerSupplyInsights />
        <TotalListingViews />
      </Flex>
    </Box>
  )
}
