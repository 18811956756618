import React from 'react'
import { CheckIcon, XIcon } from 'lucide-react'
import { Text, Icon } from '@workwhile/ui'

export function BooleanIcon({ value }: { value: boolean }) {
  return (
    <Text textAlign={'center'}>
      {value ? (
        <Icon icon={CheckIcon} color={'success'} />
      ) : (
        <Icon icon={XIcon} color={'error'} />
      )}
    </Text>
  )
}
