import { useCompanyDetailWorkflowsContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailWorkflowProvider'
import React from 'react'
import {
  Box,
  Button,
  DatePicker,
  Field,
  Flex,
  Heading,
  Message,
  Modal,
  toast,
} from '@workwhile/ui'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { addCancellationPolicySchema } from '../schemas/cancellationPolicy'
import { useAddCancellationPolicyFlowContextValue } from '../context/AddCancellationPolicyFlowProvider'
import { AddPolicyWindows } from './AddPolicyWindows'
import { useCompanyDetailContextValue } from 'pages/Company/CompanyDetail/context/CompanyDetailProvider'
import { isToday } from 'date-fns'
export const AddCancellationPolicyFlowMain = () => {
  const {
    addCancellationPolicyFlow: { isOpen, close },
  } = useCompanyDetailWorkflowsContextValue()

  const {
    state: {
      formData,
      mutationState: { isPending, errorMessage },
    },
    actions: { submitFormToServer, setFormData },
  } = useAddCancellationPolicyFlowContextValue()

  const {
    state: { id: companyId },
  } = useCompanyDetailContextValue()

  const methods = useForm({
    resolver: zodResolver(addCancellationPolicySchema),
    defaultValues: {
      startsAt: formData?.startsAt ? new Date(formData.startsAt) : new Date(),
      policyWindows:
        formData?.policyWindows?.length > 0
          ? formData.policyWindows
          : [
              {
                cutoffTime: undefined,
                chargePercentage: undefined,
              },
            ],
    },
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = methods

  const onSubmit = handleSubmit((data) => {
    setFormData({
      startsAt: data.startsAt,
      policyWindows: data.policyWindows,
    })
    submitFormToServer(
      {
        company_id: companyId,
        starts_at: isToday(data.startsAt)
          ? undefined // We set undefined if the start date is today to let backend handle the "today" case
          : data.startsAt.toISOString(),
        policy_windows: data.policyWindows.map((window) => ({
          cutoff_time: window.cutoffTime,
          charge_percentage: window.chargePercentage,
        })),
      },
      {
        onSuccess: () => {
          toast.success('Added new cancellation policy successfully!', {
            position: 'top-center',
          })
          setFormData({})
          reset()
          close()
        },
      }
    )
  })

  const startsAt = watch('startsAt')
  return (
    <Modal
      open={isOpen}
      onClose={close}
      position="top"
      loading={isPending}
      showCancel
      customCta={
        <Flex>
          <Button variant="text" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Add
          </Button>
        </Flex>
      }
    >
      <Box
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Heading level={3} fontWeight="400" style={{ margin: 0 }}>
          Add cancellation policy
        </Heading>
        <Field
          label="Start date"
          error={errors.startsAt?.message?.toString()}
          // Intentionally disabled. This should work, unsure why it's throwing an error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mt={3}
        >
          <DatePicker
            {...register('startsAt')}
            mode="single"
            aria-label={'Start date'}
            disabled={{
              before: new Date(),
            }}
            selected={startsAt}
            onSelect={(date) => {
              setValue('startsAt', date as Date)
            }}
          />
        </Field>
        <FormProvider {...methods}>
          <AddPolicyWindows />
        </FormProvider>
        {errorMessage ? (
          <Message variant="error" mt={3} borderRadius="small">
            {errorMessage}
          </Message>
        ) : null}
      </Box>
    </Modal>
  )
}
