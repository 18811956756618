/**
 * @returns {string} the user's local timezone e.g. America/Los_Angeles
 */
export const getLocalTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const getLongDuration = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  // Manually constructing the duration string
  const formattedDuration = [
    hours ? `${hours} hrs` : '', // Include hours part only if it's non-zero
    mins ? `${mins} mins` : '', // Include minutes part only if it's non-zero
  ]
    .filter(Boolean)
    .join(' ') // Join non-empty parts with space, removing any potential empty strings

  return formattedDuration
}
