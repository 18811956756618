import { Flex } from '@workwhile/ui'
import React from 'react'
import { TimeRangeFilters } from './TimeRangeFilters/TimeRangeFilters'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { filtersSchema, FiltersSchema } from './filtersSchema'
import { SearchButton } from './SearchButton'
import { useCompanyDetailContextValue } from '../../../context/CompanyDetailProvider'

export const ShiftListFilters = () => {
  const {
    state: {
      shifts: { timeRangeFilters },
    },
  } = useCompanyDetailContextValue()

  const methods = useForm<FiltersSchema>({
    resolver: zodResolver(filtersSchema),
    reValidateMode: 'onChange',
    mode: 'all',
    defaultValues: {
      timeRange: {
        type: null,
        dateRange: {
          from: null,
          to: null,
        },
      },
    },
    values: {
      timeRange: {
        type: timeRangeFilters.type,
        dateRange: {
          from: timeRangeFilters.startDate,
          to: timeRangeFilters.endDate,
        },
      },
    },
  })

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        flexDirection="row"
        gap={2}
        alignItems="center"
        onSubmit={(event) => {
          event.preventDefault()
        }}
      >
        <TimeRangeFilters />
        <SearchButton />
      </Flex>
    </FormProvider>
  )
}
