import * as z from 'zod'

export const filtersSchema = z
  .object({
    timeRange: z
      .object({
        type: z.enum(['past', 'ongoing', 'upcoming']).nullable().optional(),
        dateRange: z
          .object({
            from: z.date().nullable().optional(),
            to: z.date().nullable().optional(),
          })
          .optional(),
      })
      .optional(),
    shift: z
      .object({
        // used to store the shift id input from the user, not actually shipped to the backend
        shiftIdUserInput: z.coerce
          .number({
            invalid_type_error: 'Shift Id must be a number',
          })
          .min(1, { message: 'Shift Id must be a number' })
          .optional(),
        ids: z
          .array(
            z.object({
              id: z.number().positive(),
            })
          )
          .optional(),
        marketCodeUserInput: z
          .string()
          .min(1, { message: 'Provide a valid market code' })
          .optional(),
        marketCodes: z
          .array(
            z.object({
              code: z.string(),
            })
          )
          .optional(),
        scheduleRateMin: z.coerce
          .number({
            invalid_type_error: 'Schedule rate must be a valid number',
          })
          .min(0, { message: 'Schedule rate minimum must be greater than 0' })
          .max(100, { message: 'Schedule rate maximum must be less than 100' })
          .nullable()
          .optional(),
        scheduleRateMax: z.coerce
          .number({
            invalid_type_error: 'Schedule rate must be a valid number',
          })
          .min(0, { message: 'Schedule rate maximum must be greater than 0' })
          .max(1000, {
            message: 'Schedule rate maximum must be less than 1000',
          })
          .nullable()
          .optional(),
        fillRateMin: z.coerce
          .number({
            invalid_type_error: 'Fill rate must be a valid number',
          })
          .min(0, { message: 'Fill rate minimum must be greater than 0' })
          .max(100, { message: 'Fill rate maximum must be less than 100' })
          .nullable()
          .optional(),
        fillRateMax: z.coerce
          .number({
            invalid_type_error: 'Fill rate must be a valid number',
          })
          .min(0, { message: 'Fill rate maximum must be greater than 0' })
          .max(1000, { message: 'Fill rate maximum must be less than 1000' })
          .nullable()
          .optional(),
      })
      .optional(),
    company: z
      .object({
        // used to store the company id input from the user, not actually shipped to the backend
        companyIdUserInput: z.coerce
          .number({
            invalid_type_error: 'Company Id must be a number',
          })
          .min(1, { message: 'Company Id must be a number' })
          .optional(),
        companyIds: z
          .array(
            z.object({
              id: z.number().positive(),
            })
          )
          .optional(),
        // used to store the company group id input from the user, not actually shipped to the backend
        companyGroupIdUserInput: z.coerce
          .number({
            invalid_type_error: 'Company Group Id must be a number',
          })
          .min(1, { message: 'Company Group Id must be a number' })
          .optional(),
        companyGroupIds: z
          .array(
            z.object({
              id: z.number().positive(),
            })
          )
          .optional(),
      })
      .optional(),
  })
  .superRefine((data, ctx) => {
    const type = data.timeRange?.type
    const dateRange = data.timeRange?.dateRange
    const isOneOfDateRangePresent = Boolean(dateRange?.from ?? dateRange?.to)

    if (typeof type == 'string' && !isOneOfDateRangePresent) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please select a valid date range',
        path: ['timeRange', 'dateRange'],
      })
      return false
    }

    if (
      typeof data.shift?.scheduleRateMin === 'number' &&
      typeof data.shift?.scheduleRateMax === 'number'
    ) {
      if (data.shift.scheduleRateMin > data.shift.scheduleRateMax) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Schedule rate min must be less than schedule rate max',
          path: ['shift', 'scheduleRateMin'],
        })
        return false
      }
    }

    if (data.shift?.scheduleRateMin === null && data.shift?.scheduleRateMax) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Schedule rate min must be provided',
        path: ['shift', 'scheduleRateMin'],
      })
      return false
    }

    if (data.shift?.scheduleRateMin && data.shift?.scheduleRateMax === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Schedule rate max must be provided',
        path: ['shift', 'scheduleRateMax'],
      })
      return false
    }

    if (
      typeof data.shift?.fillRateMin === 'number' &&
      typeof data.shift?.fillRateMax === 'number'
    ) {
      if (data.shift.fillRateMin > data.shift.fillRateMax) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Fill rate min must be less than fill rate max',
          path: ['shift', 'fillRateMin'],
        })
        return false
      }
    }

    if (data.shift?.fillRateMin === null && data.shift?.fillRateMax) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Fill rate min must be provided',
        path: ['shift', 'fillRateMin'],
      })
      return false
    }

    if (data.shift?.fillRateMin && data.shift?.fillRateMax === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Fill rate max must be provided',
        path: ['shift', 'fillRateMax'],
      })
      return false
    }

    return true
  })

export type FiltersSchema = z.infer<typeof filtersSchema>
